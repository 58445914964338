import gql from 'graphql-tag';

export const JOBS_COUNT = gql`
  query {
    jobsCount {
      availableJobsCount
      warrantyCasesCount
    }
  }
`;

export const JOBS_COUNT_FINANCE = gql`
  query {
    jobsCountFinance {
      ongoingJobsCount
      warrantyJobsCount
      completedJobsCount
    }
  }
`;

export const CASE_COUNT = gql`
  query caseCount {
    caseCount {
      warrantyCasesCount
      notPaidJobsCount
      upcomingJobsCount
    }
  }
`;
