import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DonutChart from 'components/shared/DonutChart';
import ControlInput from 'components/shared/DonutChart/ControlInput';
import styles from './styles.module.css';

const ChartSection = ({
  header,
  series,
  onHandleChangeValue,
  additionalText,
  isReadOnlyInput,
  residentialVSCommercial,
  type,
}) => {
  const [invalidInputs, setInvalidInputs] = useState(false);
  const residentialCondition = residentialVSCommercial === 100 && type === 'Commercial';
  const commercialCondition = residentialVSCommercial === 0 && type === 'Residential';
  const handleInputsChange = () => {
    const result = Object.values(series).reduce(
      (total, { value }) => total + parseInt(value, 10),
      0
    );

    if (result === 100 || residentialCondition || commercialCondition) {
      setInvalidInputs(false);
    } else {
      setInvalidInputs(true);
    }
  };

  useEffect(() => {
    handleInputsChange();
  }, [series]);

  return (
    <>
      <div
        className={[styles.header, invalidInputs ? styles.headerError : null].join(' ')}
      >
        {header}
      </div>
      <div className={styles.chartWrapper}>
        <div className={styles.controllersWrapper}>
          {Object.entries(series).map((item, index) => (
            <ControlInput
              key={index + item[0]}
              className={styles.controller}
              color={item[1].color}
              title={item[1].name}
              value={item[1].value}
              invalid={invalidInputs}
              handleChange={({ value }) => {
                onHandleChangeValue({ key: item[0], value: Number(value) });
              }}
              isReadOnlyInput={isReadOnlyInput}
            />
          ))}
        </div>
        <DonutChart
          className={styles.chart}
          series={Object.values(series)}
          size={220}
          strokeWidth={40}
          text={additionalText}
          chartWidth={220}
          chartHeight={220}
        />
      </div>
    </>
  );
};
ChartSection.propTypes = {
  series: PropTypes.object.isRequired,
  onHandleChangeValue: PropTypes.func.isRequired,
  residentialVSCommercial: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  header: PropTypes.node,
  additionalText: PropTypes.node,
  isReadOnlyInput: PropTypes.bool,
};

ChartSection.defaultProps = {
  header: <div> Header </div>,
  additionalText: <div> Text </div>,
  isReadOnlyInput: false,
};

export default ChartSection;
