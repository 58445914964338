import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isDisabled } from 'utils/application/checkIsDisabled';
import Spinner from 'components/shared/Spinner';
import OtherServicesSection from './OtherServicesSection';
import CoverageCrewSection from './CoverageCrewSection';
import ServicesSection from './ServicesSection';
import DefinedWorkAreas from '../../CompanyProfile/DefinedWorkAreas';
import EstimateSection from './EstimateSection';
import PreferenceSection from './PreferenceSection';
import styles from './styles.module.css';

const MyApplicationPart = ({
  appStatus,
  handleChange,
  setTouched,
  loadCreateApp,
  definedWorkAreas: { units, setUnits, catchmentUnits, setCatchmentUnits },
  ...tabsProps
}) => {
  const { residentialVSCommercial } = tabsProps.values.myApplication;
  const [residentialVSCommercialValue, setResidentialVSCommercial] = useState(
    residentialVSCommercial
  );
  const { blockHistory } = tabsProps;
  return (
    <div className={styles.sectionWrapper}>
      {loadCreateApp && (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      <ServicesSection
        {...tabsProps}
        appStatus={appStatus}
        setTouched={setTouched}
        residentialVSCommercial={residentialVSCommercialValue}
        setResidentialVSCommercial={setResidentialVSCommercial}
      />

      <div className={styles.spreadLine} />
      <EstimateSection
        {...tabsProps}
        setTouched={setTouched}
        residentialVSCommercial={residentialVSCommercialValue}
      />

      <div className={styles.spreadLine} />
      <OtherServicesSection {...tabsProps} />

      <div className={styles.spreadLine} />
      <CoverageCrewSection
        {...tabsProps}
        handleChange={handleChange}
        appStatus={appStatus}
      />

      <div className={styles.spreadLine} />
      <PreferenceSection
        {...tabsProps}
        handleChange={handleChange}
        appStatus={appStatus} />

      <div className={styles.spreadLine} />
      <DefinedWorkAreas
        className={styles.onBoardingMapWrapper}
        appStatus={appStatus}
        units={units}
        application
        setUnits={setUnits}
        catchmentUnits={catchmentUnits}
        setCatchmentUnits={setCatchmentUnits}
        isReadOnlyInput={isDisabled(appStatus)}
        blockHistory={blockHistory}
      />
    </div>
  );
};

MyApplicationPart.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  appStatus: PropTypes.string.isRequired,
  loadCreateApp: PropTypes.bool.isRequired,
  definedWorkAreas: PropTypes.shape({
    units: PropTypes.shape({
      states: PropTypes.array,
      counties: PropTypes.array,
      cities: PropTypes.array,
    }),
    catchmentUnits: PropTypes.shape({
      states: PropTypes.array,
      counties: PropTypes.array,
      cities: PropTypes.array,
    }),
    setUnits: PropTypes.func.isRequired,
    setCatchmentUnits: PropTypes.func.isRequired,
  }).isRequired,
};

export default MyApplicationPart;
