import React, { useContext } from 'react';
import AppConfigContext from 'context/AppConfigContext';
import styles from './styles.module.css';

const HeaderBanner = () => {
  const config = useContext(AppConfigContext);
  return (
    <div
      className={styles.placeholder}
      style={{ backgroundImage: `url(${config.bannerUrl})` }}
    />
  );
};

HeaderBanner.propTypes = {};

export default HeaderBanner;
