import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { useHistory } from 'react-router-dom';
import Link from 'components/shared/Link';
import { rightColored } from 'assets/icons';
import MobileContext from 'context/MobileContext';
import Icon from '../../shared/IconSVG';
import styles from './style.module.css';
import Button from '../../shared/Button';

const ConfirmEmail = ({ isCreatePassword, email }) => {
  const { t } = useTranslation();
  const formText = (
    <Text color="#292929" size="s14" lineHeight="h20" tag="p">
      {t('forgotPasswordModal.body.p_1', 'Check the email')}
      {email ? (
        <Text
          color="#000000"
          size="s14"
          lineHeight="h20"
          weight="wb"
          className={styles.email}
        >
          {email}
        </Text>
      ) : (
        t('forgotPasswordModal.body.p_2', 'account associated with your user name')
      )}
      {t('forgotPasswordModal.body.p_3', 'for instructions on')}{' '}
      {isCreatePassword
        ? t('forgotPasswordModal.body.p_4', 'creating')
        : t('forgotPasswordModal.body.p_5', 'resetting')}{' '}
      {t('forgotPasswordModal.body.p_6', 'your password.')}
      <br />
      {t(
        'forgotPasswordModal.body.p_7',
        'Remember to look in your spam folder, where automated messages sometimes filter.'
      )}
      <br />
      {t(
        'forgotPasswordModal.body.p_8',
        " If you still can't log in, contact your administrator."
      )}
    </Text>
  );
  const isMobile = useContext(MobileContext);
  const history = useHistory();
  // {
  //   t('main.head', {
  //     defaultValue: 'You have 1 apple other {{numApples}}.',
  //     numApples,
  //   });
  // }
  return (
    <form className={styles.wrapper}>
      <Text color="#00000D" weight="wb" size="s24" lineHeight="h22">
        {t('forgotPasswordModal.title.deck', 'Thank you!')}
      </Text>
      <div className={styles.wrapperBody}>
        <div className={styles.wrapperContent}>{formText}</div>
      </div>

      <div className={styles.wrapperContent}>
        {isMobile ? (
          <div>
            <Button size="large" handleClick={() => history.push('/sign-in')}>
              {t('forgotPasswordModal.title.mobile', 'Back to login')}
            </Button>
            <br />
            <br />
            <div>
              <Link
                href={`mailto:${process.env.REACT_APP_ROOFCALIM_EMAIL}`}
                size="s14"
                bold
              >
                {t('forgotPasswordModal.contactUs', 'Contact Us')}
              </Link>
            </div>
          </div>
        ) : (
          <Link href="/sign-in" size="s16" bold>
            <Icon src={rightColored} width="15px" height="auto" className={styles.back} />
            {t('forgotPasswordModal.backLogin', 'Back to login')}
          </Link>
        )}
      </div>
    </form>
  );
};

ConfirmEmail.propTypes = {
  email: PropTypes.string,
  isCreatePassword: PropTypes.bool,
};

ConfirmEmail.defaultProps = {
  email: '',
  isCreatePassword: false,
};

export default ConfirmEmail;
