import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'components/shared/Text';
import IconSVG from 'components/shared/IconSVG';
import { quotationIcon } from 'assets/icons';
import styles from '../styles.module.css';

const HeaderComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.headerWrapper}>
      <IconSVG
        src={quotationIcon}
        width="40px"
        height="40px"
        className={styles.headerIcon}
      />
      <Text className={styles.headerText} size="s14" tag="p" color="#292929">
        {t(
          'inspectionModal.title.p1',
          'Please read carefully before completing the job.'
        )}
        <br />
        <br />
        {t(
          'inspectionModal.title.p2',
          'In order to submit job status, checkboxes should be checked on each page.'
        )}
      </Text>
    </div>
  );
};

export default HeaderComponent;
