import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import TabletContext from 'context/TabletContext';
import AuthRoleContext from 'context/AuthRoleContext';
import JobsCountContext from 'context/JobsCountContext';
import AppStatusContext from 'context/AppStatusContext';
import { applicationStatus } from 'enums';
import Text from 'components/shared/Text';
import SidebarItem from './SidebarItem';
import useMenuItemsList from './menuItems';
import styles from './style.module.css';

function Sidebar({ showSidebar, setShowSidebar, userData }) {
  const jobsCount = useContext(JobsCountContext)?.jobsCount;

  const isTablet = useContext(TabletContext);
  const currentUserRole = useContext(AuthRoleContext);
  const { appStatus } = useContext(AppStatusContext);
  const isAppApproved = appStatus === applicationStatus.approved;

  const {
    firstPartMenu,
    secondPartMenu,
    thirdPartMenu,
    fourthPartMenu,
  } = useMenuItemsList({
    userRole: currentUserRole,
    jobsCount,
    isAppApproved,
  });

  const renderItemMenu = (item) => {
    if (!item.isAllowView) return null;
    return <SidebarItem {...item} key={item.name} setShowSidebar={setShowSidebar} />;
  };

  return (
    <div
      className={
        showSidebar
          ? styles.containerSidebarMobile
          : isTablet
          ? styles.containerSidebarHiddenMobile
          : styles.containerSidebar
      }
    >
      {userData && (
        <>
          {firstPartMenu.length > 0 && (
            <>
              {firstPartMenu.map(renderItemMenu)}
              <div className={styles.separationLine} />
            </>
          )}
          {secondPartMenu.length > 0 && (
            <>
              {secondPartMenu.map(renderItemMenu)}
              <div className={styles.separationLine} />
            </>
          )}
          {thirdPartMenu.length > 0 && (
            <>
              {thirdPartMenu.map(renderItemMenu)}
              <div className={styles.separationLine} />
            </>
          )}
          {fourthPartMenu.map(renderItemMenu)}
          <div className={styles.separationLine} />
          <div className={styles.contactInfoContainer}>
            <Text color="#837f7d" weight="wr" size="s16" lineHeight="h18">
              <b>
                <a
                  href={`tel:${process.env.REACT_APP_ROOFCALIM_PHONE}`}
                  style={{ color: '#837f7d' }}
                >
                  {process.env.REACT_APP_ROOFCALIM_PHONE}
                </a>{' '}
              </b>
              <br />
              <b>
                <a
                  href={`mailto:${process.env.REACT_APP_ROOFCALIM_EMAIL}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: '#837f7d' }}
                >
                  {process.env.REACT_APP_ROOFCALIM_EMAIL}
                </a>
              </b>
            </Text>
          </div>
        </>
      )}
    </div>
  );
}

Sidebar.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  setShowSidebar: PropTypes.func.isRequired,
  userData: PropTypes.object,
};

Sidebar.defaultProps = {
  userData: {},
};

export default Sidebar;
