import React, { useState } from 'react';
import LockIcon from 'assets/icons/lock.svg';
import { ReactComponent as ShowIcon } from 'assets/icons/password.svg';
import { ReactComponent as HideIcon } from 'assets/icons/seepassword.svg';
import Input from '../index';
import styles from './style.module.css';

const iconStyles = {
  pointerEvents: 'none',
  width: '15px',
};

const PasswordInput = (props) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  return (
    <Input
      {...props}
      rightIcon={LockIcon}
      type={isShowPassword ? 'text' : 'password'}
      leftIcon={
        <div
          className={styles.eyeIcon}
          onClick={() => setIsShowPassword(!isShowPassword)}
        >
          {isShowPassword ? (
            <ShowIcon style={iconStyles} />
          ) : (
            <HideIcon style={iconStyles} />
          )}
        </div>
      }
    />
  );
};

export default PasswordInput;
