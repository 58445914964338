import React, { useContext, useState, useEffect } from 'react';
import { useDetLang } from 'services/localization';
import LanguageContext from 'context/LanguageContext';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import Icon from 'components/shared/IconSVG';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import {
  menuIcon,
  nofitIcon,
  accountIcon,
  closeIcon,
  spainIcon,
  usaIcon,
  chevronIcon,
} from 'assets/icons';
import TabletContext from 'context/TabletContext';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_USER_NOTIFICATIONS } from 'graphql/queries/notifications';
import { getSocket } from 'utils/socket';
import { READ_NOTIFICATIONS } from 'graphql/mutations/notifications';
import Text from 'components/shared/Text';
import NotificationsModal from 'components/shared/modals/NotificationsModal';
import AccountMenuModal from 'components/shared/modals/AccountMenuModal';
import LangMenuModal from 'components/shared/modals/LangMenuModal';
import useVisibleComponent from 'services/hooks/useComponentVisible';
import notificationStatuses from 'enums/notificationStatuses';
import styles from './styles.module.css';

const Header = ({ setShowSidebar, showSidebar }) => {
  const isTablet = useContext(TabletContext);
  const { language } = useContext(LanguageContext);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const {
    ref: notificationRef,
    isComponentVisible: notificationPopupVisible,
    handleClick: setNotificationPopupVisible,
  } = useVisibleComponent(false);

  const {
    ref: accountIconRef,
    isComponentVisible: accountMenuPopupVisible,
    handleClick: setAccountMenuPopupVisible,
  } = useVisibleComponent(false);

  const {
    ref: langIconRef,
    isComponentVisible: langPopupVisible,
    handleClick: setLangPopupVisible,
  } = useVisibleComponent(false);

  const history = useHistory();

  const { data, client } = useQuery(GET_USER_NOTIFICATIONS);
  const [readNotification] = useMutation(READ_NOTIFICATIONS);

  const unreadNotifications = data?.notifications?.reduce((acc, curr) => {
    if (curr.status === notificationStatuses.notRead) acc.push(curr.id);
    return acc;
  }, []);

  useEffect(() => {
    const socketConnection = getSocket();
    socketConnection.on('notification', (incomingNotifications) => {
      setHasNewNotifications(true);
      const { notifications: existedNotifications } = client.readQuery({
        query: GET_USER_NOTIFICATIONS,
      });
      const updatedNotifications = uniqBy(
        orderBy(
          [...existedNotifications, incomingNotifications],
          ['createdAt', 'status'],
          ['desc', 'asc']
        ),
        'id'
      );
      client.writeQuery({
        query: GET_USER_NOTIFICATIONS,
        data: { notifications: updatedNotifications },
      });
    });
  }, [client]);

  useEffect(() => {
    const handleReadNotifications = async () => {
      if (unreadNotifications?.length) {
        await readNotification({
          variables: { notificationsIds: unreadNotifications },
          update: (proxy, { data: { readNotifications: notifications } }) => {
            proxy.writeQuery({
              query: GET_USER_NOTIFICATIONS,
              data: { notifications },
            });
          },
        });
      }
    };

    if (notificationPopupVisible) handleReadNotifications();
  }, [notificationPopupVisible, data]);

  useEffect(() => {
    if (unreadNotifications?.length) setHasNewNotifications(true);
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.menuContainer}>
        {isTablet && !showSidebar && (
          <Icon
            src={menuIcon}
            height="20px"
            width="20px"
            className={styles.menuIcon}
            handleClick={() => {
              setShowSidebar(!showSidebar);
            }}
          />
        )}
        {isTablet && showSidebar && (
          <Icon
            src={closeIcon}
            height="22px"
            width="22px"
            className={styles.closeIcon}
            handleClick={() => {
              setShowSidebar(!showSidebar);
            }}
          />
        )}
        <NavLink exact to="/">
          <Text className={styles.siteName}>RoofClaim.com</Text>
        </NavLink>
      </div>

      <div className={styles.iconContainer}>
        <div ref={langIconRef}>
          <div
            className={styles.langWrapper}
            onClick={
              !showSidebar
                ? () => {
                    setLangPopupVisible(!langPopupVisible);
                  }
                : () => {}
            }
          >
            <Icon src={useDetLang(spainIcon, usaIcon)} height="20px" width="20px" />
            <div className={styles.langArrow}>
              <Text weight="wb" size="s14" lineHeight="h23" className={styles.langText}>
                {language.toUpperCase()}
              </Text>
              <Icon
                src={chevronIcon}
                height="20px"
                width="20px"
                className={[
                  styles.langIcon,
                  !langPopupVisible ? styles.langIconClose : styles.langIconOpen,
                ].join(' ')}
              />
            </div>
          </div>
          <LangMenuModal isShow={langPopupVisible} handleClose={setLangPopupVisible} />
        </div>
        <div ref={notificationRef}>
          <div className={styles.iconWrapper}>
            <Icon
              src={nofitIcon}
              height="20px"
              width="20px"
              className={styles.notificationIcon}
              handleClick={
                !showSidebar
                  ? () => {
                      setNotificationPopupVisible(!notificationPopupVisible);
                      setHasNewNotifications(false);
                    }
                  : () => {}
              }
            />
            {hasNewNotifications ? <div className={styles.newNotificationBadge} /> : null}
          </div>
          <NotificationsModal
            notifications={data?.notifications}
            isShow={notificationPopupVisible}
          />
        </div>

        <div ref={accountIconRef}>
          <Icon
            src={accountIcon}
            height="20px"
            width="20px"
            className={
              history.location.pathname === '/my-account'
                ? styles.accountIconActive
                : styles.accountIcon
            }
            handleClick={
              !showSidebar
                ? () => {
                    setAccountMenuPopupVisible(!accountMenuPopupVisible);
                  }
                : () => {}
            }
          />
          <AccountMenuModal
            isShow={accountMenuPopupVisible}
            handleClose={setAccountMenuPopupVisible}
          />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  setShowSidebar: PropTypes.func.isRequired,
  showSidebar: PropTypes.bool.isRequired,
};

export default Header;
