import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import styles from './styles.module.css';

const PerformanceCard = ({ amount, title, icon, handleClick }) => {
  const isMobile = useContext(MobileContext);
  return (
    <div className={styles.cardContainer} onClick={handleClick}>
      <div className={styles.performanceTitle}>
        <Text tag="h2" size="s24" weight="wb" lineHeight="h29" color="#000000">
          {amount}
        </Text>
        <Text
          tag="h2"
          size={isMobile ? 's17' : 's19'}
          weight="wr"
          lineHeight="h21"
          color="#000000"
        >
          {title}
        </Text>
      </div>
      <div className={styles.iconContainer}>
        <Icon
          src={icon}
          className={[
            title === 'Upcoming Projects' ? styles.upcomingIcon : styles.otherIcon,
            styles.icon,
          ].join(' ')}
        />
      </div>
    </div>
  );
};
PerformanceCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
};
export default PerformanceCard;
