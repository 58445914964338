import React, { useContext } from 'react';
import SignInForm from 'containers/SignIn/SignInContainer';
import AppConfigContext from 'context/AppConfigContext';
import MobileSignedOutHeader from 'layouts/Mobile/MobileSignedOutHeader';
import styles from './style.module.css';
// import WelcomeSplashScreen from 'pages/WelcomeSplashScreen/Welcome-Splash-Screen';

const SignInPage = () => {
  const config = useContext(AppConfigContext);
  return (
    <MobileSignedOutHeader>
      {/* <WelcomeSplashScreen /> */}
      <main>
        <div
          className={styles.contentPage}
          style={{ backgroundImage: `url(${config?.signInImageUrl})` }}
        >
          <SignInForm />
        </div>
      </main>
    </MobileSignedOutHeader>
  );
};

export default SignInPage;
