import React from 'react';
import PropTypes from 'prop-types';
import useComponentVisible from 'services/hooks/useComponentVisible';
import arrowIcon from 'assets/icons/arrowDown.svg';
import Icon from 'components/shared/IconSVG';
import styles from './style.module.css';

const getClassName = (isBlack, value) =>
  styles[`${value}${isBlack ? 'Secondary' : 'Primary'}`];

const renderDropdownItems = (items, isBlack) => {
  return items.map((item, index) => {
    return (
      <button
        type="button"
        onClick={item.handleClick}
        className={[styles.dropdownItem, getClassName(isBlack, 'dropdownItem')].join(' ')}
        key={index}
        disabled={item.isDisabled}
      >
        <span className={styles.dropdownItemText}>{item.text}</span>
      </button>
    );
  });
};

const DropdownButton = ({ title, items, black, ...props }) => {
  const { ref, isComponentVisible: isShow, handleClick } = useComponentVisible(false);
  return (
    <div
      className={[styles.wrapper, isShow ? styles.active : ''].join(' ')}
      onClick={handleClick}
      ref={ref}
    >
      <button
        type="button"
        className={[styles.button, getClassName(black, 'button')].join(' ')}
        {...props}
      >
        <div className={styles.buttonText}>{title}</div>
        <Icon
          src={arrowIcon}
          height="12px"
          width="14px"
          className={[
            styles.dropdownIcon,
            getClassName(black, 'dropdownIcon'),
            isShow ? styles.dropdownIconActive : '',
          ].join(' ')}
        />
      </button>
      <div className={[styles.dropdown, getClassName(black, 'dropdown')].join(' ')}>
        {renderDropdownItems(items, black)}
      </div>
    </div>
  );
};

DropdownButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      handleClick: PropTypes.func,
    })
  ),
  black: PropTypes.bool,
};

DropdownButton.defaultProps = {
  items: [],
  black: false,
};

export default DropdownButton;
