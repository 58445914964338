import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import AppStatusContext from 'context/AppStatusContext';
import Text from 'components/shared/Text';
import { applicationStatus } from 'enums';
import styles from './style.module.css';

const TabsComponent = ({
  tabList = [],
  currentTabIndex = 0,
  handleClick,
  projectPgId,
  isMyAccount,
}) => {
  const history = useHistory();
  const { appStatus } = useContext(AppStatusContext);
  const { t } = useTranslation();
  return (
    <Tabs className={styles.tabs} selectedIndex={currentTabIndex} onSelect={() => {}}>
      <TabList className={styles.tabList}>
        {tabList.map(({ title: tabTitle, isDisabled }, index) => (
          <Tab
            key={`${index}tab`}
            className={styles.tab}
            selectedClassName={styles.selectedTab}
            disabled={isDisabled}
            disabledClassName={styles.disabledTab}
            onClick={() => {
              if (isDisabled) return;
              if (
                tabTitle === 'Roof Project' &&
                history.location.pathname.includes('roof-project') &&
                projectPgId
              ) {
                history.push(`/my-projects/roofs/${projectPgId}`);
                handleClick(index);
              } else handleClick(index);
            }}
          >
            {tabTitle}
          </Tab>
        ))}
      </TabList>

      {appStatus === applicationStatus.approved || isMyAccount ? (
        <>
          {tabList.map(({ children }, index) => (
            <TabPanel
              key={`${index}tabPanel`}
              className={styles.tabPanel}
              selectedClassName={styles.selectedTabPanel}
            >
              {children}
            </TabPanel>
          ))}
        </>
      ) : (
        <div className={styles.textWrapper}>
          <Text color="#000000" size="s16" lineHeight="h20" weight="wr">
            {t(
              'notAvailablePage',
              'This page will be available only when application is approved'
            )}
          </Text>
        </div>
      )}
    </Tabs>
  );
};

TabsComponent.propTypes = {
  tabList: PropTypes.array.isRequired,
  currentTabIndex: PropTypes.number,
  handleClick: PropTypes.func,
  projectPgId: PropTypes.string,
  isMyAccount: PropTypes.bool,
};

TabsComponent.defaultProps = {
  currentTabIndex: 0,
  isMyAccount: false,
  handleClick: () => {},
  projectPgId: '',
};

export default TabsComponent;
