import React from 'react';
import PropTypes from 'prop-types';
import PageCounter from './PageCounter';
import styles from './style.module.css';

const TablePagination = ({ pagesCount, activePage, itemsCount, gotoPage, ...props }) => {
  if (itemsCount < 1 || pagesCount < 2) return null;
  return (
    <div className={styles.container} {...props}>
      <PageCounter activePage={activePage} pagesCount={pagesCount} gotoPage={gotoPage} />
    </div>
  );
};

TablePagination.propTypes = {
  pagesCount: PropTypes.number,
  activePage: PropTypes.number,
  itemsCount: PropTypes.number,
  gotoPage: PropTypes.func,
};

TablePagination.defaultProps = {
  pagesCount: null,
  activePage: null,
  itemsCount: null,
  gotoPage: () => {},
};

export default TablePagination;
