import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import image from 'assets/backgrounds/no_lead_img.png';
import MobileContext from 'context/MobileContext';
import AppConfigContext from 'context/AppConfigContext';
import styles from './style.module.css';
import welcomeStyles from '../../style.module.css';

const NoLeadGreetingBlock = ({ handleSubmit, handleLoginSubmit }) => {
  const isMobile = useContext(MobileContext);
  const config = useContext(AppConfigContext);
  const { t } = useTranslation();
  return (
    <div className={styles.contentWrapper}
      style={{ backgroundImage: `url(${config?.signInImageUrl})` }}
    >
      <div className={styles.blockWrapper}>
        <div className={styles.textWrapper}>
          <Text
            tag="h2"
            size={isMobile ? 's19' : 's24'}
            lineHeight={isMobile ? 'h25' : 'h28'}
            weight="wb"
            color="#FFFFFF"
            className={styles.greetingTitle}
          >
            {t('welcomePage.header.title', 'Welcome to the RoofClaim.com Community!')}
          </Text>
          <Text
            tag="p"
            size={isMobile ? 's15' : 's19'}
            lineHeight={isMobile ? 'h20' : 'h30'}
            weight="wr"
            color="#FFFFFF"
            className={styles.greetingText}
          >
            {t(
              'welcomePage.header.p_1',
              'From initial quote to final invoice, we take care of our contractors.'
            )}
            <br />
            <br />
            <span className={styles.boldGreetingText}>
              {t('welcomePage.header.p_2_bold', 'Join our Elite Contractor Nexus')}
            </span>{' '}
            {t(
              'welcomePage.header.p_2',
              'to access a project portal, install-ready jobs, and a team of likeminded, success-driven professionals.'
            )}
            <br />
            <br />
            {t(
              'welcomePage.header.p_3',
              'At RoofClaim.com, we can help you cut stress, save time, and build up your business.'
            )}
          </Text>
          <Button
            size="larger"
            type="submit"
            handleClick={handleSubmit}
            className={`${welcomeStyles.welcomeButton} ${styles.greetingButton}`}
          >
            {t('welcomePage.header.joinButton', 'Join Our Network Now!')}
          </Button>
          <br />
          <Button
            size="larger"
            type="submit"
            handleClick={handleLoginSubmit}
            className={`${welcomeStyles.welcomeButton} ${styles.greetingButton}`}
          >
            {t('welcomePage.header.loginButton', 'Login')}
          </Button>
        </div>
        <div className={styles.imageWrapper}>
          <img src={image} className={styles.image} alt="" title="" />
        </div>
      </div>
    </div>
  );
};

NoLeadGreetingBlock.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleLoginSubmit: PropTypes.func.isRequired,
};

NoLeadGreetingBlock.defaultProps = {};

export default NoLeadGreetingBlock;
