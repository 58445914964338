import React from 'react';
import filterTable from 'assets/icons/filter_table.svg';
import PropTypes from 'prop-types';
import IconSVG from '../../shared/IconSVG';
import styles from './style.module.css';

const SortIcon = ({ isSorted, isSortedDesc }) => {
  if (!isSorted) return null;
  return (
    <IconSVG
      width="10px"
      height="10px"
      className={isSortedDesc ? styles.iconDown : styles.iconUp}
      src={filterTable}
    />
  );
};

SortIcon.propTypes = {
  isSorted: PropTypes.bool.isRequired,
  isSortedDesc: PropTypes.bool,
};

SortIcon.defaultProps = {
  isSortedDesc: false,
};

export default SortIcon;
