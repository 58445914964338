import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import SubHeader from 'components/shared/NavigationSubHeader';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import DesktopTabs from 'components/shared/Tabs/Desktop';
import MobileTabs from 'components/shared/Tabs/Mobile';
import useTabIndex from 'services/hooks/useTabIndex';
import DropdownButton from 'components/shared/DropdownButton';
import useModal from 'services/hooks/useModal';
import { jobStatus, roles } from 'enums';
import countDiffDays from 'utils/countDaysBetweenDays';
import { isAllowAction } from 'services/permissions';
import RescheduleJobModal from './RescheduleJobModal';
import CancelJobModal from './CancelJobModal';
import getTabList from './tabList';
import styles from '../pageStyles.module.css';

const AvailableJobDetailsPage = ({ job = {}, files, userCurrentRole, ...props }) => {
  const isMobile = useContext(MobileContext);

  const isAllowActions = isAllowAction(
    [roles.admin, roles.finance, roles.superintendent],
    userCurrentRole
  );
  const {
    isShow: isShowRescheduleModal,
    handleClick: handleClickRescheduleModal,
  } = useModal();
  const { isShow: isShowCancelModel, handleClick: handleChickCancelModal } = useModal();

  const diffDaysDateOfInstall = job?.dateOfInstall
    ? countDiffDays(job?.dateOfInstall)
    : 1;
  const { currentTabIndex, handleClick: setCurrentTabIndex } = useTabIndex(
    diffDaysDateOfInstall === 0 ? 1 : 0
  );

  const isDisableActions = [
    jobStatus.canceled,
    jobStatus.completed,
    jobStatus.reschedule_requested,
  ].includes(job.status);

  const isDisableCancel = [jobStatus.canceled, jobStatus.completed].includes(job.status);

  const tabList = getTabList({
    currentAwardedJob: job,
    isDisableActions,
    isDisableActivityTab: diffDaysDateOfInstall > 0,
    userCurrentRole,
    files: files || [],
    ...props,
  });
  const { t } = useTranslation();
  return (
    <LayoutWithSidebar>
      <div className={styles.container}>
        <SubHeader
          items={[
            {
              title: t('jobs.awarded.details.header.title', 'Awarded Jobs'),
              link: '/jobs?tab=awarded',
            },
            {
              title: job.reference,
              link: null,
            },
          ]}
          rightComponent={
            isAllowActions && (
              <DropdownButton
                title={t('jobs.awarded.details.header.action', 'Actions')}
                black
                items={[
                  {
                    text: t(
                      'jobs.awarded.details.header.reschedule',
                      'Reschedule the job'
                    ),
                    handleClick: handleClickRescheduleModal,
                    isDisabled: isDisableActions,
                  },
                  {
                    text: t('jobs.awarded.details.header.cancel', 'Cancel the job'),
                    handleClick: handleChickCancelModal,
                    isDisabled: isDisableCancel,
                  },
                ]}
              />
            )
          }
        />
        {isMobile ? (
          <MobileTabs
            tabList={tabList}
            currentTabIndex={currentTabIndex}
            handleClick={setCurrentTabIndex}
          />
        ) : (
          <DesktopTabs
            tabList={tabList}
            currentTabIndex={currentTabIndex}
            handleClick={setCurrentTabIndex}
          />
        )}
      </div>

      <RescheduleJobModal
        isShow={isShowRescheduleModal}
        handleClose={handleClickRescheduleModal}
        id={job.sfId}
      />

      <CancelJobModal
        isShow={isShowCancelModel}
        handleClose={handleChickCancelModal}
        id={job.sfId}
      />
    </LayoutWithSidebar>
  );
};

AvailableJobDetailsPage.propTypes = {
  job: PropTypes.object.isRequired,
  files: PropTypes.array,
  userCurrentRole: PropTypes.string.isRequired,
};

AvailableJobDetailsPage.defaultProps = {
  files: [],
};

export default AvailableJobDetailsPage;
