import React, { useContext, useMemo, useState } from 'react';
import GoogleMap from 'components/common/GoogleMap';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import styles from './style.module.css';

const MapView = ({
  children,
  locations = [],
  zones = [],
  foundIds,
  centerLocation,
  isShowMarker,
  isShowCircle,
  ...props
}) => {
  const [selectedZonesIds, setSelectedZonesIds] = useState();
  const [mapCenter, setMapCenter] = useState(centerLocation);
  const isMobile = useContext(MobileContext);

  const currentZones = useMemo(() => {
    if (!zones.length) return zones;
    const filteredZone =
      foundIds.length > 0 ? zones.filter(({ id }) => foundIds.includes(id)) : zones;
    return filteredZone.map((zone) =>
      selectedZonesIds?.includes(zone.id)
        ? {
            ...zone,
            options: {
              strokeColor: '#F1C400',
              strokeWeight: 2,
              fillColor: '#F1C400',
              zIndex: 2,
            },
          }
        : zone
    );
  }, [foundIds, zones, selectedZonesIds]);

  const currentLocations = useMemo(() => {
    if (!locations.length) return locations;
    const filteredLocations =
      foundIds.length > 0
        ? locations.filter(({ sfId }) => foundIds.includes(sfId))
        : locations;
    return filteredLocations.map((location) =>
      // selectedZonesIds.includes(location.id)
      selectedZonesIds === location.id
        ? {
            ...location,
            isSelected: true,
          }
        : location
    );
  }, [foundIds, locations, selectedZonesIds]);

  const handleSelectLocation = (location, value) => {
    setMapCenter({
      lat: location.lat,
      lng: location.lng,
    });
    // if (value) setSelectedZonesIds([...selectedZonesIds, location.id]);
    // else setSelectedZonesIds(selectedZonesIds.filter((id) => id !== location.id));
    if (value) setSelectedZonesIds(location.id);
  };

  const handleSelectZone = (zone) => {
    setSelectedZonesIds([zone.id]);
    setMapCenter({ lat: zone.center.lat, lng: zone.center.lng });
  };

  const renderProps = {
    handleSelectLocation,
    handleSelectZone,
    locations: currentLocations,
    selectedZonesIds,
  };

  return (
    <div className={styles.gridMap}>
      {isMobile ? (
        <>
          <div className={styles.gridMapArea}>
            <div className={styles.googleMapContainer}>
              <GoogleMap
                {...props}
                locations={currentLocations}
                zones={currentZones}
                centerLocation={mapCenter}
                isShowMarker={isShowMarker}
                isShowCircle={isShowCircle}
              />
            </div>
            <div className={styles.gridMapLocations}>{children(renderProps)}</div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.gridMapLocations}>{children(renderProps)}</div>
          <div className={styles.gridMapArea}>
            <GoogleMap
              {...props}
              locations={currentLocations}
              zones={currentZones}
              centerLocation={mapCenter}
              isShowMarker={isShowMarker}
              isShowCircle={isShowCircle}
            />
          </div>
        </>
      )}
    </div>
  );
};

MapView.propTypes = {
  children: PropTypes.func.isRequired,
  locations: PropTypes.array,
  zones: PropTypes.array,
  centerLocation: PropTypes.object,
  foundIds: PropTypes.array,
  isShowMarker: PropTypes.bool,
  isShowCircle: PropTypes.bool,
};

MapView.defaultProps = {
  locations: [],
  zones: [],
  centerLocation: null,
  foundIds: [],
  isShowMarker: true,
  isShowCircle: true,
};

export default MapView;
