import React from 'react';
import PropTypes from 'prop-types';
import { jobStatus } from 'enums';
import ReadViewCompleteSection from './ReadView';
import FormViewCompleteSection from './FormView';

const CompleteSection = ({ status, ...props }) => {
  if (status === jobStatus.completed)
    return <ReadViewCompleteSection status={status} {...props} />;

  return <FormViewCompleteSection {...props} status={status} />;
};

CompleteSection.propTypes = {
  status: PropTypes.string.isRequired,
};

export default CompleteSection;
