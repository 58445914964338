import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ProgressBar from 'components/shared/ProgressBar';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import AppConfigContext from 'context/AppConfigContext';
import MobileContext from 'context/MobileContext';
import moment from 'moment';
import { applicationStatus } from 'enums';
import styles from './styles.module.css';

const TopPart = ({
  handleSubmit,
  createdAt,
  isAllTabsFilled,
  progress,
  setIsSaveDraft,
  appStatus,
}) => {
  const NUMBER_OF_DAYS_TO_COMPLETE = 90;

  const dueDate = moment(+createdAt)
    .add(NUMBER_OF_DAYS_TO_COMPLETE, 'days')
    .format('MM/DD/YYYY');

  const isMobile = useContext(MobileContext);
  const config = useContext(AppConfigContext);
  const { t } = useTranslation();

  return (
    <div className={styles.bannerWrapper}>
      {appStatus === applicationStatus.draft ? (
        <div
          className={styles.wrapper}
          style={{ backgroundImage: `url(${config?.myApplicationImageUrl})` }}
        >
          <Text
            className={styles.applicationCompetition}
            tag="h2"
            size={isMobile ? 's21' : 's24'}
            lineHeight={isMobile ? 'h25' : 'h28'}
          >
            {t('myApplication.topPart.title', 'Application completion')}
          </Text>

          <ProgressBar
            className={styles.progressBar}
            progress={progress}
            width={isMobile ? '333px' : '570px'}
            height={isMobile ? '24px' : '40px'}
            fontSize={isMobile ? '13px' : '24px'}
          />

          <Text
            className={styles.dueDate}
            tag="p"
            size={isMobile ? 's14' : 's16'}
            lineHeight={isMobile ? 'h16' : 'h19'}
          >
            {t('myApplication.topPart.dueDate', 'Due date:')} {dueDate.toString()}
          </Text>
          <Button
            handleClick={() => {
              setIsSaveDraft(false);
              handleSubmit();
            }}
            className={styles.submitButton}
            disabled={isAllTabsFilled}
          >
            {t('myApplication.submitButton', 'Submit Application')}
          </Button>
        </div>
      ) : (
        <div
          className={[styles.wrapper, styles.congratulationsWrapper].join(' ')}
          style={{ backgroundImage: `url(${config?.myApplicationImageUrl})` }}
        >
          <Text
            className={styles.congratulationsTitle}
            tag="h2"
            size={isMobile ? 's21' : 's32'}
            lineHeight={isMobile ? 'h25' : 'h37'}
          >
            {appStatus === applicationStatus.pendingRR ||
            appStatus === applicationStatus.pendingCU
              ? t('myApplication.topPart.submitted.p_1', 'Application has been submitted')
              : t('myApplication.topPart.submitted.p_2', 'Application is submitted')}
          </Text>
          <Text
            className={styles.dueDate}
            tag="p"
            size={isMobile ? 's14' : 's16'}
            lineHeight={isMobile ? 'h16' : 'h19'}
          >
            {appStatus === applicationStatus.pendingRR ||
            appStatus === applicationStatus.pendingCU
              ? t(
                  'myApplication.topPart.submitted.p_3',
                  'Thank you! Your application is under review.'
                )
              : t(
                  'myApplication.topPart.submitted.p_4',
                  'Our team is reviewing your application. It may take 72 hours before validation.'
                )}
          </Text>
        </div>
      )}
    </div>
  );
};
TopPart.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setIsSaveDraft: PropTypes.func.isRequired,
  createdAt: PropTypes.string.isRequired,
  isAllTabsFilled: PropTypes.bool.isRequired,
  progress: PropTypes.string.isRequired,
  appStatus: PropTypes.string,
};

TopPart.defaultProps = {
  appStatus: 'Draft',
};

export default TopPart;
