import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-GB';
import './react-datepicker.css';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { useDetLang } from 'services/localization';

import styles from './style.module.css';

const CustomInput = ({
  value,
  onClick,
  isError,
  customClass,
  isMyApplication,
  isWarranty,
}) => {
  const classNames = [
    styles.datepickerCustomInput,
    isError ? styles.errorInput : '',
    customClass,
  ].join(' ');

  const localDate = useDetLang(moment(value).locale('es'), moment(value).locale('en'));

  return (
    <div
      onClick={isMyApplication ? () => {} : onClick}
      className={[
        styles.datepickerInputWrapper,
        isMyApplication ? styles.hideCursor : null,
      ].join(' ')}
    >
      <input
        type="text"
        readOnly
        className={classNames}
        value={
          value
            ? isWarranty
              ? localDate.format('MM/DD/YYYY')
              : localDate.format('dddd MM/DD/YYYY')
            : ''
        }
        onClick={onClick}
      />

      <div className={styles.datepickerCalendarIcon}>
        <CalendarIcon style={{ width: '15px', height: '15px' }} />
      </div>
    </div>
  );
};

CustomInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  isError: PropTypes.bool,
  isMyApplication: PropTypes.bool,
  isWarranty: PropTypes.bool,
  customClass: PropTypes.string,
};

CustomInput.defaultProps = {
  value: '',
  onClick: () => {},
  isError: false,
  isMyApplication: false,
  isWarranty: false,
  customClass: '',
};

const Datepicker = ({
  handleChange,
  value,
  name,
  isDisabled,
  isError,
  locale,
  customDatePickerClass,
  isMyApplication,
  isWarranty,
  ...props
}) => {
  registerLocale('es', es);
  registerLocale('en', en);
  const { t } = useTranslation();
  return (
    <DatePicker
      {...props}
      selected={value ? new Date(value) : null}
      onChange={handleChange}
      value={value}
      name={name}
      locale={locale}
      todayButton={t('datePicker.today', 'Today')}
      minDate={isMyApplication ? null : new Date()}
      disabled={isDisabled}
      className={styles.customDatepicker}
      calendarClassName={styles.datepickerCalendar}
      customInput={
        <CustomInput
          isError={isError}
          customClass={customDatePickerClass}
          isMyApplication={isMyApplication}
          isWarranty={isWarranty}
        />
      }
    />
  );
};

Datepicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  locale: PropTypes.string,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isMyApplication: PropTypes.bool,
  isWarranty: PropTypes.bool,
  customDatePickerClass: PropTypes.string,
};

Datepicker.defaultProps = {
  value: '',
  name: '',
  locale: 'en',
  handleChange: () => {},
  isDisabled: false,
  isError: false,
  isMyApplication: false,
  isWarranty: false,
  customDatePickerClass: '',
};

export default Datepicker;
