import { formatDate } from 'utils/dateFormatters';
import Button from 'components/shared/Button';
import React from 'react';
import { useJobStatusLocal } from 'localization';
import colorStatus from 'utils/statusColor';
import { jobStatus } from 'enums';
import getJobStatus from 'utils/workOrderCurrentStatus';
import { useDetLang } from 'services/localization';
import styles from '../style.module.css';
import { isHideButton } from '../../../utils/jobs/isHideButton';

export default ({
  handleReschedule = () => {},
  handleClickColumn = () => {},
  isAllowActions,
  columnTitles,
}) => {
  const {
    jobReference,
    installDate,
    location,
    permit,
    stat,
    action,
    actionText,
    notRequired,
  } = columnTitles;

  const columns = [
    {
      Header: jobReference,
      accessor: 'reference',
      onClick: handleClickColumn,
      className: styles.tableFirstCell,
      Cell: (row) => {
        return (
          <span className={[styles.firstCell, styles.firstCellPrimary].join(' ')}>
            {row.value}
          </span>
        );
      },
      bold: true,
    },
    {
      Header: installDate,
      accessor: 'dateOfInstall',
      onClick: handleClickColumn,
      Cell: (row) => formatDate(row.value),
    },
    {
      Header: location,
      onClick: handleClickColumn,
      accessor: 'opportunity.account.address',
      isCutText: true,
      Cell: (row) => {
        const addressArray = row.value?.split(',') ?? [];
        addressArray.splice(3, 1);
        return <div>{addressArray.join(',')}</div>;
      },
    },
    {
      Header: permit,
      onClick: handleClickColumn,
      accessor: 'permitNumber',
      isCutText: true,
      Cell: (row) => {
        return <div className={styles.permitNumber}>{row.value || notRequired}</div>;
      },
    },
    {
      Header: stat,
      accessor: 'status',
      onClick: handleClickColumn,
      Cell: (row) => {
        const status = getJobStatus(row.value);
        return (
          <div>
            <span
              className={[styles.circle, styles[`circle${colorStatus(row.value)}`]].join(
                ' '
              )}
            />
            {useJobStatusLocal()[status]}
          </div>
        );
      },
    },
  ];

  if (isAllowActions)
    columns.push({
      Header: action,
      accessor: '',
      Cell: (row) => {
        const buttonWidth = useDetLang('smaller', 'smallest');
        if (isHideButton(row.row.original.status)) return null;

        const isDisableButton =
          row.row.original.status === jobStatus.reschedule_requested;

        return (
          <Button
            color="secondary"
            textSize="small"
            size={buttonWidth}
            handleClick={() => handleReschedule(row.row.original)}
            disabled={isDisableButton}
          >
            {actionText}
          </Button>
        );
      },
      textAlignHeader: 'center',
      textAlignCell: 'center',
    });

  return columns;
};
