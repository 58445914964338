import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/IconSVG';
import { alertIcon } from 'assets/icons';
import Modal from 'components/shared/modals/SunnyModal';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import MobileContext from 'context/MobileContext';
import close from 'assets/icons/close.svg';
import styles from '../modal.module.css';

const AcknowledgmentModal = ({
  handleClick,
  handleClose,
  isShow,
  description,
  title = 'Acknowledgement Box',
  buttonText = 'I understand',
  footer,
}) => {
  const isMobile = useContext(MobileContext);
  if (!isShow) return null;

  const size = isMobile ? '50px' : '60px';
  return (
    <Modal
      isSmallSize
      headerContent={
        <>
          <Icon
            src={close}
            height="20px"
            width="20px"
            className={styles.closeIcon}
            handleClick={handleClose}
          />
          <Icon
            src={alertIcon}
            height={size}
            width={size}
            className={styles.questionIcon}
          />
          {isMobile && (
            <div className={styles.modalHeaderTitle}>
              <Text size="s19" lineHeight="h22" color="#000" weight="wb">
                {title}
              </Text>
            </div>
          )}
        </>
      }
      mainContent={
        <div className={styles.modalMainContent}>
          {!isMobile && (
            <Text size="s19" color="#000" weight="wb">
              {title}
            </Text>
          )}

          <div className={styles.modalContentSmall}>
            <Text size="s16" color="#292929" weight="wm" lineHeight="h21">
              {description}
            </Text>
          </div>
          <div className={styles.formButton}>
            {footer || (
              <Button handleClick={handleClick} size="medium">
                {buttonText}
              </Button>
            )}
          </div>
        </div>
      }
    />
  );
};

AcknowledgmentModal.propTypes = {
  handleClick: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  isShow: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  buttonText: PropTypes.string,
  footer: PropTypes.node,
};

AcknowledgmentModal.defaultProps = {
  isShow: false,
  handleClick: () => {},
  description: '',
  title: 'Acknowledgement Box',
  buttonText: 'I understand',
  footer: null,
};

export default AcknowledgmentModal;
