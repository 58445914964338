import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { FIRST_TIME_SEE_DENIED_MUTATION } from 'graphql/mutations/application';
import { MY_APPLICATION } from 'graphql/queries/application';

export function useFirstSeeDenied() {
  const [showModal, setShowModal] = useState(false);

  const [firstTimeSeeDenied] = useMutation(FIRST_TIME_SEE_DENIED_MUTATION, {
    update: (cache) => {
      const { application } = cache.readQuery({ query: MY_APPLICATION });
      application.isSeeDenied = false;
      cache.writeQuery({ query: MY_APPLICATION, data: { application } });
    },
  });

  const checkFirstTimeSeeDenied = async () => {
    await firstTimeSeeDenied();
  };

  const handleModalClose = async () => {
    setShowModal(false);
    await checkFirstTimeSeeDenied();
  };

  return { handleModalClose, showModal, setShowModal };
}
