import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import UploadFileComponent from 'components/MyApplication/UploadApplicationFiles';
import { isSectionFilled } from 'utils/application/isSectionFilled';
import { qualificationTypes, certificationFields } from 'enums';
import DocumentDescription from './DocumentDescription';

const AutoLiability = ({
  myCertification,
  errors,
  handleChange,
  appStatus,
  qualificationSfId,
  deleteUploadedFiles,
  isOnBoarding,
  touched,
}) => {
  const {
    auto: { docs_0 },
  } = myCertification;

  const { t } = useTranslation();
  return (
    <WrapperBlock
      tag="h2"
      title={t('Auto Liability')}
      isOnBoarding={isOnBoarding}
      isHasTooltip="true"
      toolTipText={t(
        'myApplication.thirdTab.general.p_2',
        'The company is in good standing within each state of registration'
      )}
      isChecked={isSectionFilled(myCertification, qualificationTypes.al)}
    >
      <DocumentDescription
        policyNumber={docs_0.policyNumber}
        carrier={docs_0.carrier}
        expirationDate={docs_0.expirationDate}
        policyName={certificationFields.policyNumber}
        carrierName={certificationFields.carrier}
        expirationName={certificationFields.expirationDate}
        handleChange={(event) => handleChange(`myCertification.auto.docs_0`, event)}
        errors={errors}
        files={docs_0.files}
        touched={touched}
        status={appStatus}
        myCertification={myCertification}
        type={qualificationTypes.al}
      />
      <UploadFileComponent
        accept=".jpeg, .png, .jpg, .pdf, .gif, .docx, .doc"
        maxCountDocument={5}
        uploadFiles={docs_0.files}
        buttonText={t('uploadButton', 'Upload file(s)')}
        handleChange={(event) => handleChange(`myCertification.auto.docs_0`, event)}
        name="files"
        parentId={qualificationSfId}
        deleteUploadedFiles={deleteUploadedFiles}
      />
    </WrapperBlock>
  );
};
AutoLiability.propTypes = {
  myCertification: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  deleteUploadedFiles: PropTypes.func.isRequired,
  qualificationSfId: PropTypes.array,
  appStatus: PropTypes.string.isRequired,
  isOnBoarding: PropTypes.bool,
};
AutoLiability.defaultProps = {
  errors: {},
  touched: {},
  qualificationSfId: [],
  isOnBoarding: false,
};

export default AutoLiability;
