import {useTranslation} from 'react-i18next';

export const usePreferredManufacturer = () => {
    const {t} = useTranslation();
    return [
        {
            label: t('Atlas'),
            value: 'Atlas',
        },
        {
            label: t('Certainteed'),
            value: 'Certainteed',
        },
        {
            label: t('GAF'),
            value: 'GAF',
        },
        {
            label: t('Owens Corning'),
            value: 'Owens Corning',
        },
        {
            label: t('TAMKO'),
            value: 'TAMKO',
        },
        {
            label: t('Other'),
            value: 'Other'
        }
    ];
};