import React from 'react';
import PropTypes from "prop-types";
import Text from "../../../../shared/Text";
import IconSVG from "../../../../shared/IconSVG";
import styles from './style.module.css';

const PresentationIcon = ({image, text}) => {
    return (
        <div className={styles.iconWrapper}>
            <div className={styles.icon}>
                <IconSVG src={image}/>
            </div>
            <Text tag="p"
                  size="s16"
                  weight="wb"
                  lineHeight="h22"
                  color="#FFFFFF"
                  className={styles.iconText}>
                {text}
            </Text>
        </div>
    );
}

PresentationIcon.propTypes = {
    image: PropTypes.string,
    text: PropTypes.string
};

PresentationIcon.defaultProps = {
    image: "",
    text: ""
};

export default PresentationIcon;