import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SectionHeader from 'components/shared/SectionHeader';
import Text from 'components/shared/Text';
import { isDisabled } from 'utils/application/checkIsDisabled';
import RangeSlider from '../../../common/RangeSlider';
import styles from './styles.module.css';

const ServicesSection = ({
  values: { myApplication },
  setFieldValue,
  setTouched,
  appStatus,
  residentialVSCommercial,
  setResidentialVSCommercial,
  blockHistory,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.section}>
      <SectionHeader
        text={t('myApplication.secondTab.services.title', 'Services')}
        isHasTooltip
        toolTipText={t(
          'myApplication.secondTab.services.body.p_1',
          'When considering you for available jobs, we look at your service model, as well as your work capacity.'
        )}
      />
      <div className={styles.textWrapper}>
        <Text color="#292929" size="s16">
          {t(
            'myApplication.secondTab.services.body.p_2',
            'Complete your application and tell us about your services.'
          )}
          {<br />}
          {t(
            'myApplication.secondTab.services.body.p_3',
            'In order to provide you with the most relevant available jobs, we consider your current model and capacity'
          )}
        </Text>
      </div>

      <RangeSlider
        isDisabled={isDisabled(appStatus)}
        objectName="myApplication"
        restorationVSRetail={myApplication.restorationVSRetail}
        residentialVSCommercial={residentialVSCommercial}
        setResidentialVSCommercial={setResidentialVSCommercial}
        setFieldValue={setFieldValue}
        setTouched={setTouched}
        blockHistory={blockHistory}
      />
    </div>
  );
};

ServicesSection.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  blockHistory: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  appStatus: PropTypes.string.isRequired,
  setResidentialVSCommercial: PropTypes.func.isRequired,
  residentialVSCommercial: PropTypes.number.isRequired,
};

export default ServicesSection;
