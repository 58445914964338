import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CHANGE_LANGUAGE } from 'graphql/mutations/language';
import { updateUserLangCache } from 'graphql/cache/language';
import LanguageContext from 'context/LanguageContext';
import { useDetLang } from 'services/localization';
import { useTranslation } from 'react-i18next';
import { spainIcon, usaIcon } from 'assets/icons';
import Icon from 'components/shared/IconSVG';
import styles from './style.module.css';
import Text from '../../Text';

const LangMenuModal = ({ isShow, handleClose, isRegistration }) => {
  const [changeLanguage] = useMutation(CHANGE_LANGUAGE);

  const { i18n, t } = useTranslation();
  const { setLanguage } = useContext(LanguageContext);
  const ref = useRef({});
  const refLang = ref.current?.children?.[1].innerHTML.toLowerCase();

  const handleLanguage = async () => {
    handleClose();
    setLanguage(refLang);
    i18n.changeLanguage(refLang);

    if (!isRegistration) {
      await changeLanguage({
        variables: { language: refLang },
        update: (cache, { data: { changeLanguage: newLanguage } }) => {
          updateUserLangCache(newLanguage);
        },
      });
    }
  };

  return (
    <div style={{ display: `${isShow ? 'block' : 'none'}` }}>
      <div
        className={[
          isRegistration ? styles.regWrapper : styles.wrapper,
          styles['slide-bottom'],
        ].join(' ')}
      >
        <div className={styles.langModalContainer} onClick={handleLanguage} ref={ref}>
          <Icon src={useDetLang(usaIcon, spainIcon)} height="20px" width="20px" />
          <Text
            color="000"
            weight="wb"
            size="s14"
            lineHeight="h23"
            className={styles.langText}
          >
            {useDetLang('EN', 'ES')}
          </Text>
        </div>
      </div>
      <span
        className={[
          isRegistration ? styles.regTriangle : styles.triangle,
          styles['slide-bottom'],
        ].join(' ')}
      />
    </div>
  );
};

LangMenuModal.propTypes = {
  isShow: PropTypes.bool,
  isRegistration: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

LangMenuModal.defaultProps = {
  isShow: false,
  isRegistration: false,
};
export default LangMenuModal;
