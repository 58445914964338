import { useMutation } from '@apollo/react-hooks';
import { DELETE_QUALIFICATION_MUTATION } from 'graphql/mutations/application';
import { updateQualificationCache } from 'graphql/cache/application';

export function useDeleteDocument() {
  const [deleteQualification] = useMutation(DELETE_QUALIFICATION_MUTATION);

  const handleDeleteDocument = (license, index, handleChange, type, unblockHistory) => {
    let copyOfObject = { ...license };
    const deletedKey = `docs_${index + 1}`;

    if (deletedKey in copyOfObject) {
      delete copyOfObject[deletedKey];
    } else {
      const arrayOfKeyValues = Object.entries(copyOfObject).filter(
        (item, ind) => ind !== index + 1
      );
      copyOfObject = Object.fromEntries(arrayOfKeyValues);
    }

    const value = {
      ...copyOfObject,
    };

    const oldLicenseValues = Object.values(license);
    const newLicenseValues = Object.values(value);

    oldLicenseValues.forEach((item) => {
      if (!newLicenseValues.includes(item)) {
        handleChange(
          'myCertification',
          {
            target: { name: type, value },
          },
          unblockHistory
        );
        if (item.sfId) {
          deleteQualification({
            variables: { sfId: item.sfId },
            update: (cache, { data: qualification }) =>
              updateQualificationCache(qualification),
          });
        }
      }
    });
  };
  return { handleDeleteDocument };
}
