import React from 'react';
import PropTypes from 'prop-types';
import ReadView from './ReadView';
import FormView from './FormView';

const MaterialSection = ({ isDeliveredMaterials, isDisable, ...props }) => {
  if (isDeliveredMaterials)
    return (
      <fieldset disabled>
        <ReadView {...props} isDeliveredMaterials={isDeliveredMaterials} />
      </fieldset>
    );

  return (
    <fieldset disabled={isDisable}>
      <FormView {...props} isDisable={isDisable} />
    </fieldset>
  );
};

MaterialSection.propTypes = {
  isDeliveredMaterials: PropTypes.bool,
  isDisable: PropTypes.bool,
};

MaterialSection.defaultProps = {
  isDeliveredMaterials: false,
  isDisable: false,
};

export default MaterialSection;
