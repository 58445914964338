import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Text from 'components/shared/Text';
import UploadFileArea from 'components/shared/UploadFile/container';
import WrapperBlock from 'components/shared/WrapperBlock';
import { FormColumn } from 'components/shared/Form';
import styles from '../style.module.css';

const ReadViewPlanks = ({
  files,
  deliveredPlanksQuantity: quantity,
  deliveredPlanksComment: comment,
}) => {
  const { t } = useTranslation();
  return (
    <WrapperBlock
      title={t('jobs.awarded.details.activity.planks.readView.title', 'Planks')}
      classNameMainWrapper={styles.wrapper}
    >
      <div className={styles.sectionContent}>
        {comment && (
          <FormColumn>
            <Text color="#000" weight="wb">
              {t('jobs.awarded.details.activity.planks.readView.note', 'Note:')}{' '}
            </Text>
            <Text color="#837F7D">{comment}</Text>
          </FormColumn>
        )}

        <FormColumn>
          <Text color="#000" weight="wb">
            {t('jobs.awarded.details.activity.planks.readView.quantity', 'Quantity:')}{' '}
          </Text>
          <Text color="#837F7D">{quantity}</Text>
        </FormColumn>
        <div>
          <div className={styles.label}>
            <Text color="#837F7D" tag="p">
              {t(
                'jobs.awarded.details.activity.planks.readView.uploaded',
                'Uploaded pics'
              )}
            </Text>
          </div>
          <UploadFileArea
            buttonText={t('uploadButton', 'Upload file(s)')}
            name="files"
            uploadFiles={files}
            handleChange={() => {}}
            maxCountDocument={5}
          />
        </div>
      </div>
    </WrapperBlock>
  );
};

ReadViewPlanks.propTypes = {
  files: PropTypes.array,
  deliveredPlanksQuantity: PropTypes.number,
  deliveredPlanksComment: PropTypes.string,
};

ReadViewPlanks.defaultProps = {
  files: [],
  deliveredPlanksQuantity: null,
  deliveredPlanksComment: '',
};

export default ReadViewPlanks;
