import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { ReactComponent as TimerIcon } from 'assets/icons/Timer.svg';
import '../react-datepicker.css';
import styles from '../style.module.css';

const CustomInput = ({ value, onClick }) => {
  return (
    <div
      className={[styles.datepickerInputWrapper, styles.timePickerWrapper].join(' ')}
      onClick={onClick}
    >
      <input
        type="text"
        className={styles.datepickerCustomInput}
        value={value}
        onChange={onClick}
      />
      <div className={styles.datepickerCalendarIcon}>
        <TimerIcon style={{ width: '15px', height: '15px' }} />
      </div>
    </div>
  );
};

CustomInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
};

CustomInput.defaultProps = {
  value: '',
  onClick: () => {},
};

const TimePicker = ({ handleChange, value, name, isDisabled, ...props }) => {
  return (
    <DatePicker
      {...props}
      selected={value}
      onChange={handleChange}
      name={name}
      disabled={isDisabled}
      customInput={<CustomInput />}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={30}
      timeCaption="Time"
      dateFormat="hh:mm aa"
    />
  );
};

TimePicker.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

TimePicker.defaultProps = {
  value: '',
  name: '',
  handleChange: () => {},
  isDisabled: false,
};

export default TimePicker;
