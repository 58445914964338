import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { roofIcon, completedProjectIcon, warrantyIcon } from 'assets/icons';
import { useHistory } from 'react-router-dom';
import roles from 'enums/roles';
import PerformanceCard from './PerformanceCard';
import styles from './styles.module.css';

const MonthPerformance = ({
  userRole,
  caseCount,
  notPaidJobsCount,
  upcomingJobsCount,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const performanceList = useMemo(() => {
    return [
      {
        amount: upcomingJobsCount,
        title: t('dashboard.monthPerformance.upcomingProjects', 'Upcoming Projects'),
        icon: roofIcon,
        path: '/jobs?tab=awarded',
      },
      {
        amount: notPaidJobsCount,
        title: t(
          'dashboard.monthPerformance.pendingPayment',
          'Completed Pending Payment '
        ),
        icon: completedProjectIcon,
        path: '/jobs?tab=warranty',
      },
      {
        amount: caseCount,
        title: t('dashboard.monthPerformance.warrantyCases', 'Open warranty Cases'),
        icon: warrantyIcon,
        path: '/warranty',
      },
    ];
  }, [caseCount, notPaidJobsCount, upcomingJobsCount]);

  if (userRole === roles.superintendent) delete performanceList[1];
  return (
    <div className={styles.wrapper}>
      {performanceList.map(({ amount, title, icon, path }) => (
        <PerformanceCard
          key={title}
          amount={amount}
          title={title}
          icon={icon}
          handleClick={() => history.push(path)}
        />
      ))}
    </div>
  );
};
MonthPerformance.propTypes = {
  userRole: PropTypes.string.isRequired,
  caseCount: PropTypes.number,
  notPaidJobsCount: PropTypes.number,
  upcomingJobsCount: PropTypes.number,
};
MonthPerformance.defaultProps = {
  caseCount: 0,
  notPaidJobsCount: 0,
  upcomingJobsCount: 0,
};
export default MonthPerformance;
