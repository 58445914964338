import { qualificationTypes } from 'enums';
import { isUniquePolicyNumber } from './uniquePolicyNumbers';

export const isPolicyUnique = (myCertification, policyNumber) => {
  const {
    arrayOfPolicyNumbers,
    uniquePolicyNumbers,
  } = isUniquePolicyNumber(myCertification, [
    qualificationTypes.gl,
    qualificationTypes.wc,
    qualificationTypes.lc,
  ]);

  const repetitiveNumbers = uniquePolicyNumbers.map((item) => {
    if (arrayOfPolicyNumbers.filter((elem) => elem === item).length > 1) return item;
    return 'notUnique';
  });
  return repetitiveNumbers.includes(policyNumber);
};
