import { useTranslation } from 'react-i18next';
import {
  hubIcon,
  contactIcon,
  warrantyIcon,
  faqIcon,
  shopIcon,
  jobsIcon,
  financialIcon,
  membersIcon,
} from 'assets/icons';
import { roles } from 'enums';

const useMenuItemsList = ({ userRole, jobsCount, isAppApproved }) => {
  const { t } = useTranslation();
  let firstPartMenu = [];
  if (isAppApproved)
    firstPartMenu = [
      {
        name: t('sidebar.dash', 'Dashboard'),
        icon: hubIcon,
        path: '/dashboard',
        isAllowView: true,
      },
    ];
  if (!isAppApproved && [roles.admin].includes(userRole))
    firstPartMenu = [
      {
        name: t('sidebar.app', 'My Application'),
        icon: hubIcon,
        path: '/my-application',
        isAllowView: true,
      },
    ];

  const secondPartMenu = [
    {
      name: t('sidebar.job', 'Jobs List'),
      icon: jobsIcon,
      path: '/jobs',
      isExact: false,
      isAllowView: true,
      badge: jobsCount?.availableJobsCount,
    },
    {
      name: t('sidebar.case', 'Warranty Cases'),
      icon: warrantyIcon,
      path: '/warranty',
      isAllowView: true,
      isExact: false,
      badge: jobsCount?.warrantyCasesCount,
    },
  ];
  const thirdPartMenu = [
    {
      name: t('sidebar.prof', 'Company Profile'),
      icon: shopIcon,
      path: '/company-profile',
      isAllowView: true,
    },
    {
      name: t('sidebar.fin', 'Financials'),
      icon: financialIcon,
      path: '/financials',
      isAllowView: [roles.admin, roles.finance].includes(userRole),
    },
    {
      name: t('sidebar.memb', 'Members'),
      icon: membersIcon,
      path: '/members',
      isAllowView: true,
    },
  ];
  const fourthPartMenu = [
    {
      name: t('sidebar.cont', 'Contact Us'),
      icon: contactIcon,
      path: '/contact-us',
      isAllowView: true,
    },
    { name: t('sidebar.faq', 'FAQ'), icon: faqIcon, path: '/faq', isAllowView: true },
  ];

  return {
    firstPartMenu,
    secondPartMenu: isAppApproved ? secondPartMenu : [],
    thirdPartMenu:
      isAppApproved && userRole && ![roles.crew].includes(userRole) ? thirdPartMenu : [],
    fourthPartMenu,
  };
};

export default useMenuItemsList;
