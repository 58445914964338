import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FIRST_TIME_SIGN_IN_MUTATION } from 'graphql/mutations/auth';
import { GET_CURRENT_USER } from 'graphql/cache';

export function useFirstTimeSignIn() {
  const [isShowWelcomeModal, setIsShowWelcomeModal] = useState(false);

  const { data } = useQuery(GET_CURRENT_USER);

  const [firstTimeSignIn] = useMutation(FIRST_TIME_SIGN_IN_MUTATION, {
    update: (cache) => {
      const { user } = cache.readQuery({ query: GET_CURRENT_USER });
      user.isFirstLogin = false;
      cache.writeQuery({ query: GET_CURRENT_USER, data: { user } });
    },
  });

  const uncheckFirstTimeSignIn = async () => {
    if (data?.user?.isFirstLogin)
      await firstTimeSignIn({
        variables: { id: data.user?.pgId },
      });
  };

  const handleModalClose = async () => {
    setIsShowWelcomeModal(false);
    await uncheckFirstTimeSignIn();
  };

  return { handleModalClose, isShowWelcomeModal, setIsShowWelcomeModal };
}
