import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_FAQS } from 'graphql/queries/faq';
import FAQ from 'components/FAQ';
import Spinner from 'components/shared/Spinner';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import WithError from 'services/HOCs/withError';

const FAQsPage = () => {
  const { loading, data, error } = useQuery(GET_FAQS);

  if (loading)
    return (
      <LayoutWithSidebar>
        <Spinner />
      </LayoutWithSidebar>
    );

  return (
    <WithError isShow={!!error}>
      <LayoutWithSidebar>
        <FAQ faqList={data?.getFAQs} />
      </LayoutWithSidebar>
    </WithError>
  );
};

export default FAQsPage;
