import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import WrapperBlock from 'components/shared/WrapperBlock';
import UploadFileComponent from 'components/MyApplication/UploadApplicationFiles';
import { FormColumn as Col } from 'components/shared/Form';
import LanguageContext from 'context/LanguageContext';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import { states, qualificationTypes, certificationFields } from 'enums';
import { useDeleteDocument } from 'utils/application/useDeleteDocument';
import { handleAddDocument } from 'utils/application/addDocument';
import Datepicker from 'components/shared/Datepicker';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import useModal from 'services/hooks/useIsShowComponent';
import { isSectionFilled } from 'utils/application/isSectionFilled';
import { isError } from 'utils/application/isError';
import { isDisabled } from 'utils/application/checkIsDisabled';
import { useDetLang } from 'services/localization';
import DeleteButton from './DeleteButton';
import styles from './style.module.css';

const License = ({
  myCertification,
  errors,
  touched,
  deleteUploadedFiles,
  handleChange,
  qualificationSfId,
  isOnBoarding,
  loadCreateApp,
  appStatus,
}) => {
  const OTHER_ISSUER = 'Other';

  const { handleDeleteDocument } = useDeleteDocument();
  const { license } = myCertification;

  const { isShow: isOpenModal, handleClick: handleClickModal } = useModal(false);

  const { t } = useTranslation();
  const localButtonContainerStyle = useDetLang(styles.localButtonContainer, null);
  const localButtonHeight = useDetLang('local', '');
  const { language } = useContext(LanguageContext);
  return (
    <WrapperBlock
      tag="h2"
      title={t('myApplication.thirdTab.license.p_1', 'License (State, County, Other)')}
      isOnBoarding={isOnBoarding}
      isHasTooltip="true"
      toolTipText={t(
        'myApplication.thirdTab.license.p_2',
        'The company is in good standing within each state of registration'
      )}
      isChecked={isSectionFilled(myCertification, qualificationTypes.lc)}
    >
      {Object.values(license).length ? (
        <div className={[styles.addButtonContainer, localButtonContainerStyle].join(' ')}>
          <Button
            color="white"
            size="large"
            handleClick={() =>
              handleAddDocument(license, handleChange, qualificationTypes.lc, true)
            }
            height={localButtonHeight}
          >
            {t('myApplication.thirdTab.license.p_3', '+ Add Extra Document')}
          </Button>
        </div>
      ) : null}

      {Object.values(license).map(
        ({ policyNumber, carrier, files, otherIssuer, expirationDate }, index) => (
          <div key={index}>
            <div className={styles.columnWrapper}>
              <Col>
                <Field
                  name={certificationFields.carrier}
                  label={t('myApplication.thirdTab.license.issuerLabel', 'Issuer')}
                  type="select"
                  value={carrier}
                  onChange={(e) => {
                    handleChange(`myCertification.license.docs_${index}`, e);
                  }}
                  options={[{ label: OTHER_ISSUER, value: OTHER_ISSUER }, ...states]}
                />
              </Col>

              {carrier === OTHER_ISSUER && (
                <Col className={styles.hiddenBlock}>
                  <Field
                    name="otherIssuer"
                    label={t(
                      'myApplication.thirdTab.license.otherLabel',
                      'Please enter other issuer'
                    )}
                    type="text"
                    value={otherIssuer}
                    onChange={(e) => {
                      handleChange(`myCertification.license.docs_${index}`, e);
                    }}
                    isError={
                      errors.message?.includes('Other') &&
                      !otherIssuer &&
                      touched.license?.[`docs_${index}`]?.otherIssuer
                    }
                  />
                </Col>
              )}

              <Col>
                <Field
                  name={certificationFields.policyNumber}
                  label={
                    isError(
                      errors,
                      files,
                      policyNumber,
                      touched,
                      index,
                      myCertification,
                      qualificationTypes.lc
                    ) ? (
                      <span className={styles.errorLabel}>
                        {' '}
                        {t(
                          'myApplication.thirdTab.license.policyError',
                          'Number should be unique'
                        )}
                      </span>
                    ) : (
                      t('myApplication.thirdTab.license.numberLabel', 'Number')
                    )
                  }
                  type="text"
                  value={policyNumber}
                  onChange={(e) => {
                    handleChange(`myCertification.license.docs_${index}`, e);
                  }}
                  isError={isError(
                    errors,
                    files,
                    policyNumber,
                    touched,
                    index,
                    myCertification,
                    qualificationTypes.lc
                  )}
                />
              </Col>
              <Col>
                <label className={styles.label}>
                  {t('myApplication.thirdTab.license.dateLabel', 'Expiration Date')}
                </label>
                <Datepicker
                  value={expirationDate}
                  locale={language}
                  name={certificationFields.expirationDate}
                  handleChange={(date) => {
                    handleChange(`myCertification.license.docs_${index}`, {
                      target: {
                        value: moment(date).format('MM/DD/YYYY'),
                        name: certificationFields.expirationDate,
                      },
                    });
                  }}
                  customDatePickerClass={styles.datepickerInput}
                  isDisabled={isDisabled(appStatus)}
                />
              </Col>
            </div>
            <UploadFileComponent
              accept=".jpeg, .png, .jpg, .pdf, .gif, .docx, .doc"
              maxCountDocument={5}
              uploadFiles={files}
              buttonText={t('uploadButton', 'Upload file(s)')}
              handleChange={(event) =>
                handleChange(`myCertification.license.docs_${index}`, event)
              }
              name="files"
              parentId={qualificationSfId}
              deleteUploadedFiles={deleteUploadedFiles}
            />
            {index !== Object.values(license).length - 1 ? (
              <>
                <div className={styles.spreadLine} />
                <DeleteButton
                  handleDeleteDocument={() => handleClickModal()}
                  disabled={isDisabled(appStatus) || loadCreateApp}
                />
              </>
            ) : null}
            <AcknowledgmentModal
              handleClose={() => {
                handleClickModal();
              }}
              footer={
                <div>
                  <Button
                    size="medium"
                    handleClick={() => {
                      handleDeleteDocument(
                        license,
                        index - 1,
                        handleChange,
                        qualificationTypes.lc,
                        true
                      );

                      handleClickModal();
                    }}
                  >
                    {t('acknowledgmentModal.yes', ' Yes')}
                  </Button>
                  <span className={styles.modalButtonsLine} />
                  <Button
                    size="medium"
                    color="white"
                    handleClick={() => handleClickModal()}
                    className={styles.rightButton}
                  >
                    {t('acknowledgmentModal.no', 'No')}
                  </Button>
                </div>
              }
              title={t('acknowledgmentModal.title', 'What would you like to do?')}
              description={t(
                'acknowledgmentModal.documents.description',
                'Are you sure you would like to delete this document?'
              )}
              isShow={isOpenModal}
            />
          </div>
        )
      )}
    </WrapperBlock>
  );
};
License.propTypes = {
  myCertification: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  deleteUploadedFiles: PropTypes.func.isRequired,
  loadCreateApp: PropTypes.bool.isRequired,
  qualificationSfId: PropTypes.array,
  isOnBoarding: PropTypes.bool,
  appStatus: PropTypes.string,
};
License.defaultProps = {
  errors: {},
  touched: {},
  qualificationSfId: [],
  isOnBoarding: false,
  appStatus: '',
};

export default License;
