import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RemoveScroll } from 'react-remove-scroll';
import Button from 'components/shared/Button';
import Text from '../../Text';
import styles from './modal.module.css';

export const ModalFooterButtons = ({
  handleSubmit,
  handleClose,
  submitButtonText,
  disableSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <footer className={styles.formModalFooter}>
      <div className={styles.formModalButtons}>
        <div className={styles.formModalButtonWrapper}>
          <Button
            size="medium"
            handleClick={handleSubmit}
            type="submit"
            disabled={disableSubmit}
          >
            {submitButtonText || t('submitButton', 'Submit')}
          </Button>
        </div>
        <Button size="medium" color="white" handleClick={handleClose}>
          {t('cancelButton', 'Cancel')}
        </Button>
      </div>
    </footer>
  );
};

ModalFooterButtons.propTypes = {
  submitButtonText: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  disableSubmit: PropTypes.bool,
};

ModalFooterButtons.defaultProps = {
  submitButtonText: '',
  disableSubmit: false,
  handleSubmit: () => {},
  handleClose: () => {},
};

const FormModal = ({
  className,
  title,
  children,
  submitButtonText,
  handleSubmit,
  handleClose,
  isShow,
  isIncludeFooter,
  disableSubmit,
}) => {
  const { t } = useTranslation();

  if (!isShow) return null;
  return (
    <RemoveScroll enabled allowPinchZoom>
      <div className={styles.modalBack}>
        <div className={[styles.formModalWrapper, className].join(' ')}>
          <div className={[styles.sunBox].join(' ')}>
            <header>
              <Text color="#000" size="s18" weight="wb">
                {title}
              </Text>
              <div className={styles.formModalHeaderLie} />
            </header>
          </div>
          <div className={styles.mainContent}>{children}</div>
          {isIncludeFooter && (
            <ModalFooterButtons
              disableSubmit={disableSubmit}
              submitButtonText={submitButtonText || t('submitButton', 'Submit')}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          )}
        </div>
      </div>
    </RemoveScroll>
  );
};

export default FormModal;

FormModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  submitButtonText: PropTypes.string,
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  isShow: PropTypes.bool,
  isIncludeFooter: PropTypes.bool,
  disableSubmit: PropTypes.bool,
};

FormModal.defaultProps = {
  className: '',
  title: '',
  submitButtonText: '',
  children: null,
  isShow: false,
  disableSubmit: false,
  isIncludeFooter: false,
  handleSubmit: () => {},
  handleClose: () => {},
};
