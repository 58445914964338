import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'components/shared/Text';
import PropTypes from 'prop-types';
import styles from '../style.module.css';

const ReadViewCrewSection = ({ assignedContacts }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={`${styles.headerBlockSpace} ${styles.headerBlockCenter}`}>
        <Text color="#000" size="s16">
          {t(
            'jobs.awarded.details.general.crew.p_1',
            'Crew members assigned to this job'
          )}
        </Text>
      </div>

      <div>
        <br />
        {assignedContacts.map((contact, index) => {
          return (
            <Text color="#686868" lineHeight="h30" tag="p" key={index}>
              {contact.name}
            </Text>
          );
        })}
      </div>
    </>
  );
};

ReadViewCrewSection.propTypes = {
  assignedContacts: PropTypes.array,
};

ReadViewCrewSection.defaultProps = {
  assignedContacts: [],
};

export default ReadViewCrewSection;
