export function handleAddDocument(license, handleChange, type, unblockHistory) {
  const newKey = `docs_${Object.values(license).length}`;
  const value = {
    ...license,
    [newKey]: {
      policyNumber: '',
      carrier: '',
      expirationDate: null,
      files: [],
      type,
      otherIssuer: '',
      sfId: '',
    },
  };

  handleChange(
    'myCertification',
    {
      target: { name: type, value },
    },
    unblockHistory
  );
}
