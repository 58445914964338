import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Input from 'components/shared/Input';
import styles from './styles.module.css';

const ControlInput = ({
  className,
  title,
  value,
  color,
  invalid,
  handleChange,
  isReadOnlyInput,
}) => {
  return (
    <div className={className}>
      <div className={styles.wrapper}>
        <Text color="#000000" className={styles.groupName}>
          {`% ${title}`}
        </Text>
        <div className={styles.inputWrapper}>
          <Input
            className={styles.input}
            defaultValue={value}
            onChange={(e) => handleChange({ value: e.target.value })}
            invalid={invalid}
            type="number"
            min="0"
            max="100"
            isReadOnly={isReadOnlyInput}
          />
          <div className={styles.colorDot} style={{ backgroundColor: color }} />
          <span className={styles.percentIcon}>%</span>
        </div>
      </div>
    </div>
  );
};

ControlInput.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  isReadOnlyInput: PropTypes.bool,
};

ControlInput.defaultProps = {
  handleChange: PropTypes.func,
  isReadOnlyInput: false,
};

export default ControlInput;
