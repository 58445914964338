import gql from 'graphql-tag';

export const MESSAGES = gql`
  query getMessages($parentId: String!, $type: String!) {
    messages(parentId: $parentId, type: $type) {
      type
      sfId
      body
      title
      createdDate
    }
  }
`;
