import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import {
  updateFileListJob,
  updateCurrentJobList,
} from 'graphql/resolvers/opportunity/cache';
import { CONFIRM_PERMIT } from 'graphql/mutations/opportunity';
import UploadFileArea from 'components/shared/UploadFile/container';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/Spinner';
import Text from 'components/shared/Text';
import Checkbox from 'components/shared/CheckBox';
import { useAppConfigLocal } from 'localization';
import AppConfigContext from 'context/AppConfigContext';
import { toBase64UploadedFiles } from 'utils/toBase64File';
import styles from '../style.module.css';
import {CURRENT_JOB} from "../../../../graphql/queries/opportunities";
import {roles} from "../../../../enums";

const PermitSection = ({ sfId: jobId, ...props }) => {
  const { t } = useTranslation();
  const appConfigLocal = useAppConfigLocal();
  const config = useContext(AppConfigContext);

  const { match, userCurrentRole } = props;
  const [confirmPermit, { loading }] = useMutation(CONFIRM_PERMIT, {
    refetchQueries: [
      { query: CURRENT_JOB(userCurrentRole !== roles.crew), variables: {
          contentDocumentId: match?.params?.id,
        }, }
    ]
  });
  const [filesForUpload, setFilesForUpload] = useState([]);
  const [permitNeed, setPermitNeed] = useState(false);

  function handleFiles({ target }) {
    setFilesForUpload(target?.value);
  }

  const handleConfirmPermit = ({ files }) => {
    confirmPermit({
      variables: {
        jobId: [jobId],
        type: 'permitFile',
        files,
      },
      update: (_, { data: { uploadFiles: newFiles } }) => {
        updateCurrentJobList(
          jobId,
          {
            isPermitUpload: true,
          },
          { isAwarded: true }
        );
        updateFileListJob(jobId, newFiles);
      },
    });
  };

  const handleSubmitPermit = async () => {
    const convertedFiles = await toBase64UploadedFiles(filesForUpload);
    handleConfirmPermit({ files: convertedFiles });
  };
  if (loading) return <Spinner />;
  return (
    <>
      <div className={styles.confirmContainer}>
        <Checkbox
          isContractorsStyle
          value={permitNeed}
          textSize="big"
          text={appConfigLocal[config.textPermit]}
          onChange={() => setPermitNeed(!permitNeed)}
        />
      </div>
      {permitNeed ? (
        <div className={styles.uploadContainer}>
          <div className={styles.label}>
            <Text color="#837F7D" tag="p">
              {t(
                'jobs.awarded.details.activity.planks.formView.upload',
                'Upload pics before if necessary.'
              )}
            </Text>
          </div>
          <UploadFileArea
            buttonText={t('uploadButton', 'Upload file(s)')}
            accept=".jpeg, .png, .jpg"
            name="files"
            uploadFiles={filesForUpload}
            handleChange={handleFiles}
            maxCountDocument={5}
          />
          <Button
            size="medium"
            handleClick={handleSubmitPermit}
            className={styles.confirmButton}
          >
            {t('confirmButton', 'Confirm')}
          </Button>
        </div>
      ) : null}
    </>
  );
};

PermitSection.propTypes = {
  sfId: PropTypes.string.isRequired,
  uploadSubmitted: PropTypes.func.isRequired,
  userCurrentRole: PropTypes.string,
  match: PropTypes.object
};

PermitSection.defaultProps = {
  userCurrentRole: '',
  match: {}
};

export default PermitSection;
