import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SectionTabs from 'components/shared/SectionTab/Desktop';
import StatusTabsMobile from 'components/shared/StatusTabs/Mobile';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from 'graphql/cache';
import Button from 'components/shared/Button';
import {
  accountIcon,
  calendarIcon,
  qualificationIcon,
  quotationIcon,
  validateIcon,
} from 'assets/icons';
import { isTabFilled } from 'utils/application/isTabFilled';
import { isMyCertificationTabFilled } from 'utils/application/isMyCertificationTabFilled';
import { progressBarPercent } from 'utils/progressBarFilled';
import { applicationStatus } from 'enums';
import { isDisabled } from 'utils/application/checkIsDisabled';
import { useFirstTimeSignIn } from 'services/hooks/useFirstTimeSignIn';
import { setCurrentTab } from 'utils/application/setCurrentTab';
import { useDefinedWorkArea } from 'services/hooks/useDefinedWorkArea';
import MobileContext from 'context/MobileContext';
import TopPart from './TopPart';
import OnBoardingModal from './OnBoardingModal';
import MyAccountPart from './MyAccountPart';
import MyApplicationPart from './MyApplicationPart';
import MySchedulePart from './MySchedulePart';
import MyCertificationsPart from './MyCertificationsPart';
import NextPrevButton from '../common/NextPrevButton';
import styles from './styles.module.css';

const MyApplication = ({
  handleSubmit,
  handleChange,
  createdAt,
  appStatus,
  setIsSaveDraft,
  isSaveDraft,
  qualificationSfId,
  loadingAttachments,
  applicationData,
  loadCreateApp,
  errors,
  touched,
  setTouched,
  blockHistory,
  ...formikProps
}) => {
  const isMobile = useContext(MobileContext);
  const { data } = useQuery(GET_CURRENT_USER);

  const {
    handleModalClose,
    isShowWelcomeModal,
    setIsShowWelcomeModal,
  } = useFirstTimeSignIn();

  const tabsProps = {
    errors,
    appStatus,
    touched,
    loadCreateApp,
    blockHistory,
    ...formikProps,
  };

  useEffect(() => {
    if (data?.user?.isFirstLogin) setIsShowWelcomeModal(true);
  }, [data]);

  const { units, setUnits, catchmentUnits, setCatchmentUnits } = useDefinedWorkArea(
    tabsProps.initialValues.myApplication.definedWorkAreas
  );

  function uniqueElements(newElements, cacheElements) {
    return newElements.filter(
      (item) => !cacheElements.map((el) => el.id).includes(item.id)
    );
  }

  useEffect(() => {
    tabsProps.setFieldValue(
      'myApplication.definedWorkAreas.states',
      catchmentUnits.states
    );
  }, [catchmentUnits.states]);

  useEffect(() => {
    tabsProps.setFieldValue(
      'myApplication.definedWorkAreas.counties',
      catchmentUnits.counties
    );
  }, [catchmentUnits.counties]);

  useEffect(() => {
    tabsProps.setFieldValue(
      'myApplication.definedWorkAreas.cities',
      catchmentUnits.cities
    );
  }, [catchmentUnits.cities]);

  const { t } = useTranslation();

  const tabList = useMemo(() => {
    return [
      {
        title: <span>{t('myApplication.tabName.account', 'My Account')}</span>,
        icon: accountIcon,
        isAvailableNextStep: true,
        validateIcon,
        isTabCompleted: isTabFilled(formikProps.values.myAccount, [
          'dunBradstreet',
          'suiteNumber',
          'companyNameDBA',
        ]),
        children: (
          <MyAccountPart
            {...tabsProps}
            handleChange={(event) => {
              handleChange('myAccount', event);
            }}
          />
        ),
      },
      {
        title: <span>{t('myApplication.tabName.application', 'My Application')}</span>,
        icon: quotationIcon,
        isAvailableNextStep: true,
        validateIcon,
        isTabCompleted: isTabFilled(
          formikProps.values.myApplication,
          ['serviceOffered', 'onsiteManagementDays', 'preferredManufacturer2', 'preferredManufacturer3', 'preferredManufacturerOther'],
          [{ field: 'definedWorkAreas', cb: (val) => val.states.length > 0 }]
        ),
        children: (
          <MyApplicationPart
            {...tabsProps}
            handleChange={(event) => {
              handleChange('myApplication', event);
            }}
            definedWorkAreas={{ units, setUnits, catchmentUnits, setCatchmentUnits }}
            setTouched={setTouched}
            blockHistory={blockHistory}
          />
        ),
      },
      {
        title: (
          <span>{t('myApplication.tabName.certification', 'My Certifications')}</span>
        ),
        icon: qualificationIcon,
        isAvailableNextStep: true,
        validateIcon,
        isTabCompleted: isMyCertificationTabFilled(formikProps.values.myCertification, [
          '__typename',
          'sfId',
          'otherIssuer',
          'type',
        ]).generalPercent,
        children: (
          <MyCertificationsPart
            {...tabsProps}
            handleChange={handleChange}
            qualificationSfId={qualificationSfId}
            loadingAttachments={loadingAttachments}
          />
        ),
      },
      {
        title: <span>{t('myApplication.tabName.schedule', 'My Schedule')}</span>,
        icon: calendarIcon,
        isAvailableNextStep: false,
        validateIcon,
        isTabCompleted: 100,
        children: <MySchedulePart applicationData={applicationData} />,
      },
    ];
  }, [formikProps, applicationData]);
  if (!appStatus || appStatus === applicationStatus.draft) {
    delete tabList[3];
  }

  const isAllTabsFilled = tabList.every(({ isTabCompleted }) => isTabCompleted === 100);

  const tabListSize = useMemo(() => {
    return appStatus !== applicationStatus.draft && appStatus
      ? tabList.length
      : tabList.length - 1;
  }, [appStatus]);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {
    setCurrentTab(errors, touched, setCurrentTabIndex);
  }, [errors, touched]);

  useEffect(() => {
    if (appStatus !== applicationStatus.draft && appStatus) {
      setCurrentTabIndex(3);
    }
  }, [appStatus]);

  const handleBackStep = () => {
    if (currentTabIndex !== 0) setCurrentTabIndex(currentTabIndex - 1);
  };
  const handleNextStep = () => {
    if (currentTabIndex < tabListSize - 1) setCurrentTabIndex(currentTabIndex + 1);
  };

  return (
    <div
      className={[
        styles.wrapper,
        appStatus !== applicationStatus.draft && appStatus ? styles.disabledWrapper : '',
      ].join(' ')}
    >
      <TopPart
        handleSubmit={handleSubmit}
        createdAt={createdAt || new Date()}
        isAllTabsFilled={!isAllTabsFilled}
        progress={progressBarPercent(tabList)}
        setIsSaveDraft={setIsSaveDraft}
        appStatus={appStatus}
      />
      <div className={styles.mainContent}>
        {isMobile ? (
          <StatusTabsMobile
            tabList={tabList}
            tabListSize={tabListSize}
            currentTabIndex={currentTabIndex}
            setCurrentTabIndex={setCurrentTabIndex}
            handlePreviousClick={handleBackStep}
            handleNextClick={handleNextStep}
            isContractors
            appStatus={appStatus}
          />
        ) : (
          <SectionTabs
            tabList={tabList}
            currentTabIndex={currentTabIndex}
            handleSelectTab={setCurrentTabIndex}
            appStatus={appStatus}
            isClickable
            isContractors
          />
        )}
      </div>
      {!isDisabled(appStatus) && (
        <div className={styles.footer}>
          {appStatus !== applicationStatus.pendingCU && (
            <Button className={styles.draftButton} handleClick={handleSubmit}>
              {t('myApplication.draftButton', 'Save as Draft')}
            </Button>
          )}
          <Button
            className={styles.submitButton}
            disabled={!isAllTabsFilled}
            handleClick={() => {
              setIsSaveDraft(false);
              setTimeout(handleSubmit, 0);
            }}
          >
            {t('myApplication.submitButton', 'Submit Application')}
          </Button>
        </div>
      )}
      <NextPrevButton
        handleBackClick={handleBackStep}
        handleNextClick={handleNextStep}
        currentTabIndex={currentTabIndex}
        tabListSize={tabListSize}
        className={
          !isDisabled(appStatus) ? styles.lessPaddingNavBtn : styles.morePaddingNavBtn
        }
      />
      <OnBoardingModal isShow={isShowWelcomeModal} onButtonClick={handleModalClose} />
    </div>
  );
};

MyApplication.propTypes = {
  isSaveDraft: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  blockHistory: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  applicationData: PropTypes.object,
  setIsSaveDraft: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  appStatus: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  qualificationSfId: PropTypes.array,
  loadingAttachments: PropTypes.bool.isRequired,
  loadCreateApp: PropTypes.bool.isRequired,
};
MyApplication.defaultProps = {
  qualificationSfId: [],
  applicationData: {},
};
export default MyApplication;
