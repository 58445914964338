import PropTypes from 'prop-types';
import React from 'react';
import Label from '../Field/label';
import styles from './style.module.css';

const TextArea = ({
  handleChange,
  name,
  isError,
  differentMaxHeight,
  label,
  ...props
}) => {
  const handleInput = (e) => {
    handleChange(e);

    e.target.style.height = 'inherit';
    const computed = window.getComputedStyle(e.target);

    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      e.target.scrollHeight +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    e.target.style.height = `${height}px`;
  };
  const classNames = [styles.textArea, isError ? styles.invalidInput : ''].join(' ');
  return (
    <>
      <Label name={name}>{label}</Label>
      <textarea
        {...props}
        name={name}
        contentEditable
        suppressContentEditableWarning
        className={classNames}
        onInput={handleInput}
        onChange={handleChange}
        style={{
          maxHeight: differentMaxHeight ? '210px' : 'calc(100% - 30px)',
          minHeight: differentMaxHeight ? '130px' : 'calc(100% - 30px)',
        }}
      />
    </>
  );
};

export default TextArea;

TextArea.propTypes = {
  handleChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  isError: PropTypes.bool,
  differentMaxHeight: PropTypes.bool,
};

TextArea.defaultProps = {
  handleChange: () => {},
  name: '',
  label: '',
  isError: false,
  differentMaxHeight: false,
};
