import gql from 'graphql-tag';

export const GET_APP_CONFIG_CONTRACTOR = gql`
  query getAppConfigContractor {
    appConfigContractor {
      textContactPreferenceEmail
      textContactPreferencePhone
      textContactPreferenceSms
      membersPageText
      textPermit
      textInspection
      urlAppStore
      urlGooglePlay
      bannerUrl
      signInImageUrl
      signUpImageUrl
      myApplicationImageUrl
    }
  }
`;
