import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import MobileContext from 'context/MobileContext';
import SubHeader from 'components/shared/NavigationSubHeader';
import WrapperBlock from 'components/shared/WrapperBlock';
import PropTypes from 'prop-types';
import useTabIndex from 'services/hooks/useTabIndex';
import DesktopTabs from 'components/shared/Tabs/Desktop';
import MobileTabs from 'components/shared/Tabs/Mobile';
import MessagesTab from 'components/common/MessagesTab';
import { roles, attachmentTypes, caseStatus } from 'enums';
import InformationSection from '../AwardedJobDetailsPage/InformationTab/InformationSection';
import DetailsSection from '../AwardedJobDetailsPage/InformationTab/DetailsSection';
import ActivitySection from './ActivitySection';
import styles from '../pageStyles.module.css';

const WarrantyJobDetailsPage = ({ job = {}, files, userCurrentRole }) => {
  const isMobile = useContext(MobileContext);
  const { currentTabIndex, handleClick: setCurrentTabIndex } = useTabIndex(0);
  const { t } = useTranslation();

  const openCaseSfId = job.warrantyCases?.filter(({ status }) =>
    [caseStatus.inProgress, caseStatus.new].includes(status)
  )[0]?.sfId;

  const tabList = [
    {
      title: t('jobs.warranty.details.header.generalTab', 'General'),
      children: (
        <div className={[styles.tabContentWrapper, styles.mainContent].join(' ')}>
          <WrapperBlock
            title={t('jobs.warranty.details.general.activity.title', 'Activity')}
            classNameMainWrapper={styles.wrapper}
          >
            <ActivitySection
              {...job}
              materialFiles={files.filter(
                ({ description }) => description === attachmentTypes.materialFile
              )}
              plankFiles={files.filter(
                ({ description }) => description === attachmentTypes.plankFile
              )}
              plywoodFiles={files.filter(
                ({ description }) => description === attachmentTypes.plywoodFile
              )}
              deliveredMaterialsDate={`${job.dateOfInstall} 08:00`}
              deliveredPlanksDate={`${job.dateOfInstall} 13:00`}
              completedDate={`${job.dateOfInstall} 20:00`}
            />
          </WrapperBlock>
          <WrapperBlock
            title={t('jobs.warranty.details.general.info.title', 'Information')}
            classNameMainWrapper={styles.wrapper}
          >
            <InformationSection
              {...job.opportunity}
              number={job.number}
              location={job.opportunity?.location}
              address={job.opportunity?.account?.address}
              gateCode={job.opportunity?.account?.gateCode}
              dateOfInstall={job.dateOfInstall}
              awardAmount={
                [roles.admin, roles.finance].includes(userCurrentRole) ? job.amount : null
              }
            />
          </WrapperBlock>
          <WrapperBlock
            title={t('jobs.warranty.details.general.details.title', 'Details')}
            classNameMainWrapper={styles.wrapper}
          >
            <DetailsSection
              opportunity={job.opportunity}
              description={job.description}
              openCaseSfId={openCaseSfId}
              warrantyJobs
            />
          </WrapperBlock>
        </div>
      ),
    },
    {
      title: t('jobs.warranty.details.header.messageTab', 'Messages'),
      children: <MessagesTab parentId={job.sfId} type="workOrder" />,
    },
  ];

  return (
    <LayoutWithSidebar>
      <div className={styles.container}>
        <SubHeader
          items={[
            {
              title: t('jobs.warranty.details.header.title', 'Warranty Jobs'),
              link: '/jobs?tab=warranty',
            },
            {
              title: job.reference,
              link: null,
            },
          ]}
        />
        {isMobile ? (
          <MobileTabs
            tabList={tabList}
            currentTabIndex={currentTabIndex}
            handleClick={setCurrentTabIndex}
          />
        ) : (
          <DesktopTabs
            tabList={tabList}
            currentTabIndex={currentTabIndex}
            handleClick={setCurrentTabIndex}
          />
        )}
      </div>
    </LayoutWithSidebar>
  );
};

WarrantyJobDetailsPage.propTypes = {
  files: PropTypes.array,
  job: PropTypes.object.isRequired,
  userCurrentRole: PropTypes.string.isRequired,
};

WarrantyJobDetailsPage.defaultProps = {
  files: [],
};

export default WarrantyJobDetailsPage;
