import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { updateApplicationCache } from 'graphql/cache/application';
import { CREATE_APPLICATION_MUTATION } from 'graphql/mutations/application';
import { DOWNLOAD_FILES_DESCRIPTION } from 'graphql/queries/file';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import Button from 'components/shared/Button';
import { useFirstSeeDenied } from 'services/hooks/useFirstSeeDenied';
import MyApplication from 'components/MyApplication';
import AppStatusContext from 'context/AppStatusContext';
import { useDetLang } from 'services/localization';
import {
  restructureCertificationData,
  checkIsNewFilesUpload,
} from 'utils/application/restructureData/restructureCertificationData';
import { applicationStatus } from 'enums';
import useModal from 'services/hooks/useIsShowComponent';
import {
  saveDraftValidationSchema,
  submitValidationSchema,
} from 'validation/myApplication';
import { initValues } from './initialValues';
import styles from './styles.module.css';

const MyApplicationContainer = ({ user, app }) => {
  const {
    data: { downloadFilesDescription } = { downloadFilesDescription: [] },
    loading: loadingAttachments,
    refetch: refetchDocuments,
  } = useQuery(DOWNLOAD_FILES_DESCRIPTION, {
    variables: {
      data: app?.qualification?.map(({ sfId }) => sfId),
    },
  });

  const [createApplication, { loading: loadCreateApp }] = useMutation(
    CREATE_APPLICATION_MUTATION
  );

  const history = useHistory();
  const { appStatus, setAppStatus } = useContext(AppStatusContext);

  const [path, setPath] = useState('');
  const [isSaveDraft, setIsSaveDraft] = useState(true);

  const { handleModalClose, showModal, setShowModal } = useFirstSeeDenied();

  const {
    isShow: isOpenSaveDraftModal,
    handleClick: handleClickSaveDraftModal,
  } = useModal(false);

  function blockHistory() {
    history.block(({ pathname }) => {
      setPath(pathname);
      handleClickSaveDraftModal();

      return false;
    });
  }

  function unBlockHistory() {
    history.block(() => {});
  }

  useEffect(() => {
    history.listen(() => {
      unBlockHistory();
    });
  }, [history]);

  useEffect(() => {
    if (appStatus === applicationStatus.denied) setShowModal(true);
  }, [appStatus]);

  const _handleSubmit = async ({ myAccount, myApplication, myCertification }) => {
    unBlockHistory();

    await createApplication({
      variables: {
        data: {
          myAccount,
          myApplication,
          myCertification: await restructureCertificationData(myCertification),
        },

        status: isSaveDraft ? applicationStatus.draft : applicationStatus.pendingRR,
      },
      update: (cache, { data: application }) => {
        updateApplicationCache(application);
        if (checkIsNewFilesUpload(myCertification)) {
          refetchDocuments();
        }
        setAppStatus(application?.createApplication.application.status);
      },
    });
  };
  const { t } = useTranslation();
  const buttonHeight = useDetLang('local', '');
  return (
    <Formik
      enableReinitialize
      initialValues={initValues(app, user, downloadFilesDescription)}
      validationSchema={isSaveDraft ? saveDraftValidationSchema : submitValidationSchema}
      validateOnMount
      onSubmit={_handleSubmit}
    >
      {({ handleChange, ...formikProps }) => {
        const _handleChange = (name, { target }, unblockHistory = false) => {
          handleChange({
            target: { name: `${name}.${target.name}`, value: target.value },
          });
          if (!unblockHistory) {
            blockHistory();
          }
          formikProps.setTouched({}, false);
        };

        return (
          <fieldset disabled={loadCreateApp}>
            <MyApplication
              handleChange={_handleChange}
              {...formikProps}
              createdAt={user.account.createdAt}
              appStatus={appStatus}
              setIsSaveDraft={setIsSaveDraft}
              isSaveDraft={isSaveDraft}
              qualificationSfId={app?.qualification?.map((item) => item.sfId)}
              loadingAttachments={loadingAttachments}
              applicationData={app?.application}
              loadCreateApp={loadCreateApp}
              blockHistory={blockHistory}
            />
            <AcknowledgmentModal
              handleClose={() => {
                handleClickSaveDraftModal();
              }}
              footer={
                <div>
                  <Button
                    size="medium"
                    height={buttonHeight}
                    handleClick={() => {
                      _handleSubmit(formikProps.values);
                      handleClickSaveDraftModal();
                    }}
                  >
                    {t('acknowledgmentModal.myApplication.history.save', 'Save as Draft')}
                  </Button>
                  <span className={styles.modalButtonsLine} />
                  <Button
                    size="medium"
                    color="white"
                    height={buttonHeight}
                    handleClick={() => {
                      unBlockHistory();
                      if (path === 'reload') {
                        history.go();
                      } else {
                        history.push(path);
                      }
                    }}
                  >
                    {t('acknowledgmentModal.myApplication.history.discard', 'Discard')}
                  </Button>
                </div>
              }
              title={t('acknowledgmentModal.title', 'What would you like to do?')}
              description={t(
                'acknowledgmentModal.myApplication.history.description',
                'You have unsaved changes on your Application page. You can save information that you have entered by clicking on "Save As Draft" or continue and discard any unsaved changes.'
              )}
              isShow={isOpenSaveDraftModal}
            />
            <AcknowledgmentModal
              title={t(
                'acknowledgmentModal.myApplication.statusDenied.title',
                'Application status information'
              )}
              description={
                <span>
                  {t(
                    'acknowledgmentModal.myApplication.statusDenied.description.p_1',
                    'We’re sorry. Your application has been denied. Please'
                  )}{' '}
                  <span
                    className={styles.link}
                    onClick={() => {
                      handleModalClose();
                      setTimeout(() => history.push('/contact-us'), 0);
                    }}
                  >
                    {t(
                      'acknowledgmentModal.myApplication.statusDenied.description.p_2',
                      'Contact Us'
                    )}
                  </span>
                </span>
              }
              handleClick={handleModalClose}
              handleClose={handleModalClose}
              isShow={showModal && !app?.application.seeDenied}
              buttonText="OK"
            />
          </fieldset>
        );
      }}
    </Formik>
  );
};
MyApplicationContainer.propTypes = {
  user: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
};

MyApplicationContainer.defaultProps = {};
export default MyApplicationContainer;
