import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { AVAILABLE_OPPORTUNITIES } from 'graphql/queries/opportunities';
import { updateCurrentJobList } from 'graphql/resolvers/opportunity/cache';
import { CLAIM_OPPORTUNITY } from 'graphql/mutations/opportunity';
import PropTypes from 'prop-types';
import TableMapViewLayout from 'layouts/TableMapView';
import Spinner from 'components/shared/Spinner';
import { jobStatus as originalJobStatuses, jobStatus, roles } from 'enums';
import { isAllowAction } from 'services/permissions';
import MobileContext from 'context/MobileContext';
import tableColumns from './columns';
import ProjectCard from './ProjectCard';
import TableCardMobile from './TableCardMobile';
import tableStyles from '../tableStyles.module.css';

const AvailableJobsTab = ({ history, userCurrentRole, ...props }) => {
  const isMobile = useContext(MobileContext);

  const { data: loadedData, loading } = useQuery(AVAILABLE_OPPORTUNITIES);
  const [claimOpportunity, { loading: loadingClaim }] = useMutation(CLAIM_OPPORTUNITY);

  const isAllowViewAward = isAllowAction([roles.admin, roles.finance], userCurrentRole);
  const isAllowClaim = isAllowAction([roles.admin, roles.finance], userCurrentRole);

  const jobs = loadedData?.workOrders ?? [];

  const zones = useMemo(
    () =>
      jobs.map((object) => ({
        id: object.sfId,
        center: object.opportunity?.location,
        radius: 8000,
      })),
    [jobs]
  );

  const handleClaim = ({ sfId }) => {
    claimOpportunity({
      variables: {
        id: sfId,
      },
      update: () => {
        updateCurrentJobList(sfId, { status: jobStatus.requested });
      },
    });
  };

  const handleShowDetails = ({ sfId }) => {
    history.push(`/jobs/available/${sfId}`);
  };

  const locations = useMemo(
    () =>
      jobs.map((object) => ({
        sfId: object.sfId,
        lat: object.opportunity?.location?.lat,
        lng: object.opportunity?.location?.lng,
        address: object.opportunity?.account?.shortAddress,
        dateOfInstall: object.dateOfInstall,
        number: object.number,
        status: object.status,
        amount: isAllowViewAward ? object.amount : null,
        handleShowDetails: () => handleShowDetails(object),
        handleClaim: () => handleClaim(object),
        isDisabled: object.status === originalJobStatuses.requested,
      })),
    [jobs]
  );
  const { t } = useTranslation();

  const columnTitles = {
    jobReference: t('jobs.available.table.refTitle', 'Job Reference'),
    installDate: t('jobs.available.table.dateTitle', 'Date of install'),
    location: t('jobs.available.table.locationTitle', 'Location'),
    awardAmount: t('jobs.available.table.amountTitle', 'Award Amount'),
    stat: t('jobs.available.table.statusTitle', 'Status'),
    action: t('jobs.available.table.actionTitle', 'Action'),
    actionText: t('jobs.available.table.actionText', 'Claim'),
    actionTextClaimed: t('jobs.available.table.actionTextClaimed', 'Claimed'),
  };
  if (loading) return <Spinner absolute />;

  return (
    <fieldset disabled={loadingClaim}>
      <TableMapViewLayout
        {...props}
        searchValues={['reference', 'opportunity.account.shortAddress']}
        dataList={jobs}
        tableColumns={tableColumns({
          handleClickClaim: handleClaim,
          handleClickColumn: handleShowDetails,
          isAllowViewAward,
          isAllowClaim,
          columnTitles,
        })}
        mapOptions={{
          centerLocation: zones[0]?.center,
          zones,
          zoomLevel: 9,
          locations,
          isShowMarker: false,
          isShowCircle: true,
        }}
        tableOptions={{
          mobileTableRowClassName: [
            tableStyles.mobileTableRow,
            tableStyles.mobileTableRowPrimary,
            tableStyles.mobileTableRowVertical,
          ].join(' '),
          mobileContent: ({ data }, index) => {
            return (
              <TableCardMobile
                {...data}
                key={index}
                location={data.opportunity.account?.shortAddress}
                isDisabled={data.status === originalJobStatuses.requested}
                handleClick={() => handleShowDetails(data)}
                handleClaim={() => handleClaim(data)}
                awardAmount={isAllowViewAward ? data.amount : null}
                isAllowClaim={isAllowClaim}
              />
            );
          },
        }}
        searchPlaceholder={t(
          'jobs.available.searchPlaceholder',
          'Search by reference or address'
        )}
        emptyPageText={t(
          'jobs.available.emptyPageText',
          'Currently, no jobs are available in your selected and approved areas.'
        )}
      >
        {({ handleSelectZone, selectedZonesIds, data }) => {
          const handleSelect = (job) => {
            handleSelectZone({
              id: job.sfId,
              center: job.opportunity.location,
            });
          };

          return (
            <>
              {data.map((job, index) => {
                const cardProps = {
                  ...job,
                  key: index,
                  location: job.opportunity?.account?.shortAddress,
                  dateOfInstall: job.dateOfInstall,
                  handleShowDetails: () => handleShowDetails(job),
                  handleClick: () => handleSelect(job),
                  handleClaim: () => handleClaim(job),
                  isDisabled: job.status === originalJobStatuses.requested,
                  isSelected: selectedZonesIds?.includes(job.sfId),
                };

                if (isMobile)
                  return (
                    <div
                      className={[
                        tableStyles.mobileTableRow,
                        selectedZonesIds?.includes(job.sfId)
                          ? tableStyles.mobileTableRowPrimary
                          : '',
                        tableStyles.mobileTableRowHorizontal,
                      ].join(' ')}
                    >
                      <TableCardMobile
                        {...cardProps}
                        awardAmount={isAllowViewAward ? job.amount : null}
                        isAllowClaim={isAllowClaim}
                      />
                    </div>
                  );
                return <ProjectCard {...cardProps} />;
              })}
            </>
          );
        }}
      </TableMapViewLayout>
    </fieldset>
  );
};

AvailableJobsTab.propTypes = {
  history: PropTypes.object.isRequired,
  userCurrentRole: PropTypes.string.isRequired,
  data: PropTypes.object,
};

AvailableJobsTab.defaultProps = {
  data: {},
};

export default AvailableJobsTab;
