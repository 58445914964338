import { useState } from 'react';

const useIsShowComponent = (value = false) => {
  const [isShow, setIsShow] = useState(value);

  const handleClick = (event) => {
    if (event) event.preventDefault();

    const show = !isShow;
    setIsShow(show);
  };

  const handleClose = () => {
    setIsShow(false);
  };

  const handleOpen = () => {
    setIsShow(true);
  };

  return {
    isShow,
    handleClick,
    handleClose,
    handleOpen,
  };
};

export default useIsShowComponent;
