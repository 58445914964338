import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'components/shared/Text';
import { useMutation } from '@apollo/react-hooks';
import { ASSIGN_CREW_JOB } from 'graphql/mutations/opportunity';
import { updateCurrentJobList } from 'graphql/resolvers/opportunity/cache';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import useModal from 'services/hooks/useModal';
import MobileContext from 'context/MobileContext';
import styles from '../style.module.css';
import AssignCrewModal from '../../AssignCrewModal';

const CrewSection = ({ contacts, assignedContacts, ...props }) => {
  const { isShow, handleClose, handleOpen } = useModal();
  const isMobile = useContext(MobileContext);
  const isAssignedContacts = assignedContacts.length > 0;

  const [assignCrewToJob, { loading }] = useMutation(ASSIGN_CREW_JOB);

  const handleSubmit = (selectedContacts) => {
    assignCrewToJob({
      variables: { contactIds: selectedContacts, id: props.sfId },
      update: (cache, { data: { assignContactsToWork } }) => {
        updateCurrentJobList(
          props.sfId,
          {
            assignedContacts: assignContactsToWork,
          },
          { isAwarded: true }
        );
        handleClose();
      },
    });
  };
  const { t } = useTranslation();
  const ButtonSection = () => (
    <Button size="medium" handleClick={handleOpen} className={styles.disableButton}>
      {isAssignedContacts
        ? t('jobs.awarded.details.general.crew.modify', 'Modify')
        : t('jobs.awarded.details.general.crew.assign', 'Assign Crew')}
    </Button>
  );

  return (
    <>
      <div className={`${styles.headerBlockSpace} ${styles.headerBlockCenter}`}>
        <Text color="#000" size="s16" lineHeight="h20">
          {t(
            'jobs.awarded.details.general.crew.p_2',
            'Select the members assigned to this job'
          )}
        </Text>

        {!isMobile && <ButtonSection />}
      </div>

      {isAssignedContacts && (
        <div>
          <br />
          {assignedContacts.map((contact, index) => {
            return (
              <Text color="#686868" lineHeight="h30" tag="p" key={index}>
                {contact.name}
              </Text>
            );
          })}
        </div>
      )}

      {isMobile && (
        <>
          <br />
          <ButtonSection />
        </>
      )}

      <fieldset disabled={loading}>
        <AssignCrewModal
          isShow={isShow}
          handleClose={handleClose}
          handleSave={handleSubmit}
          contacts={contacts}
          assignedContacts={assignedContacts.map(({ sfId }) => sfId)}
          {...props}
        />
      </fieldset>
    </>
  );
};

CrewSection.propTypes = {
  contacts: PropTypes.array.isRequired,
  assignedContacts: PropTypes.array,
  sfId: PropTypes.string.isRequired,
};

CrewSection.defaultProps = {
  assignedContacts: [],
};

export default CrewSection;
