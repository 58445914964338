import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { editIcon } from 'assets/icons';
import Icon from 'components/shared/IconSVG';
import styles from '../style.module.css';

const LeaveNoteButton = ({ handleClick, isDisable, children }) => {
  const { t } = useTranslation();
  if (isDisable)
    return (
      <div className={styles.button}>
        <Text color="#000" weight="wb">
          {children}
        </Text>
      </div>
    );

  return (
    <div onClick={handleClick} className={styles.button}>
      <Icon src={editIcon} width="14px" height="14px" className={styles.icon} />
      <Text color="#837F7D">{t('leaveNoteButton', 'Leave a note')}</Text>
    </div>
  );
};

LeaveNoteButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isDisable: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

LeaveNoteButton.defaultProps = {
  isDisable: false,
};

export default LeaveNoteButton;
