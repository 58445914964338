import { useQuery } from '@apollo/react-hooks';

import { GET_CURRENT_USER } from 'graphql/cache';

export function useRole() {
  const { data } = useQuery(GET_CURRENT_USER);

  return {
    userRole: data?.user.contractorRole,
    accountSfId: data?.user.accountSfId,
    sfId: data?.user.sfId,
  };
}
