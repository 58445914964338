import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppStatusContext from 'context/AppStatusContext';
import Text from 'components/shared/Text';
import { applicationStatus, paymentStatus as payStatus } from 'enums';
import Spinner from 'components/shared/Spinner';
import { useQuery } from '@apollo/react-hooks';
import { JOBS_COUNT_FINANCE } from 'graphql/queries/jobsCount';
import { PAYMENT_DATA_FINANCE } from 'graphql/queries/payment';
import Incomes from 'components/Dashboard/Incomes';
import PaymentsSection from './PaymentsSection';
import ShareOfBusiness from './Share';
import LayoutWithSidebar from '../../layouts/LayoutWithSidebar';
import styles from './style.module.css';

const FinancialsPage = () => {
  const { appStatus } = useContext(AppStatusContext);
  const { data: share } = useQuery(JOBS_COUNT_FINANCE);
  const { data, loading } = useQuery(PAYMENT_DATA_FINANCE);

  const completedPaidJobs = data?.paymentDataFinance.filter(
    ({ paymentStatus }) => paymentStatus === payStatus.paid
  );
  const { t } = useTranslation();

  return (
    <LayoutWithSidebar>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {appStatus === applicationStatus.approved ? (
            <div className={styles.pageWrapper}>
              <PaymentsSection jobs={data?.paymentDataFinance} />
              <div className={styles.row}>
                <Incomes completedPaidJobs={completedPaidJobs} />
                {share ? (
                  <ShareOfBusiness jobsCount={share?.jobsCountFinance} />
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          ) : (
            <div className={styles.textWrapper}>
              <Text color="#000000" size="s16" lineHeight="h20" weight="wr">
                {t(
                  'notAvailablePage',
                  'This page will be available only when application is approved'
                )}
              </Text>
            </div>
          )}
        </>
      )}
    </LayoutWithSidebar>
  );
};

export default FinancialsPage;
