import React from 'react';
import { formatDate } from 'utils/dateFormatters';
import { withBracketsNumber } from 'utils/phoneNumberFormatter';
import { shortAddress } from 'utils/shortAddress';
import { caseStatus } from 'enums';
import { useCaseTypeLocal, useCaseStatusLocal } from 'localization';
import styles from './style.module.css';

export default ({ handleClickColumn = () => {}, columnTitles }) => {
  const { ref, date, address, name, phone, type, status } = columnTitles;

  return [
    {
      Header: ref,
      accessor: 'caseNumber',
      onClick: handleClickColumn,
      Cell: (row) => (row.value ? `${row.value}` : ''),
    },
    {
      Header: date,
      accessor: 'repairDateTime',
      onClick: handleClickColumn,
      Cell: (row) => formatDate(row.value),
    },
    {
      Header: address,
      accessor: 'account.address',
      onClick: handleClickColumn,
      Cell: (row) => shortAddress(row.value),
    },
    {
      Header: name,
      accessor: 'contact.name',
      onClick: handleClickColumn,
      Cell: (row) => (row.value ? `${row.value}` : ''),
    },
    {
      Header: phone,
      accessor: 'contact.phone',
      onClick: handleClickColumn,
      Cell: (row) => (row.value ? `${withBracketsNumber(row.value)}` : ''),
    },
    {
      Header: type,
      accessor: 'type',
      onClick: handleClickColumn,
      Cell: (row) => useCaseTypeLocal()[row.value] || '',
    },
    {
      Header: status,
      accessor: 'status',
      onClick: handleClickColumn,
      Cell: (row) => {
        return (
          <div>
            <span
              className={[
                styles.circle,
                row.value === caseStatus.new ? styles.circleGreen : styles.circleYellow,
              ].join(' ')}
            />
            {useCaseStatusLocal()[row.value]}
          </div>
        );
      },
    },
  ];
};
