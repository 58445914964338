import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SectionHeader from 'components/shared/SectionHeader';
import CheckBox from 'components/shared/CheckBox';
import { initialServices } from 'enums';
import { useOtherServicesLocal } from 'localization';
import styles from './styles.module.css';

const OtherServicesSection = ({
  values: { myApplication },
  setFieldValue,
  blockHistory,
}) => {
  const initialValues = myApplication.serviceOffered
    ? initialServices
        .filter(({ label }) => myApplication.serviceOffered.split(';').includes(label))
        .map(({ id }) => id)
    : [];

  const handleList = ({ key }) => {
    const copyArr = [...initialValues];
    blockHistory();
    if (copyArr.includes(key)) {
      setFieldValue(
        'myApplication.serviceOffered',
        copyArr
          .filter((item) => item !== key)
          .map((item) => initialServices[item - 1].label)
          .join(';')
      );
    } else {
      copyArr.push(key);
      setFieldValue(
        'myApplication.serviceOffered',
        copyArr.map((item) => initialServices[item - 1].label).join(';')
      );
    }
  };

  const { t } = useTranslation();
  const otherServicesLocal = useOtherServicesLocal();
  return (
    <div className={styles.section}>
      <SectionHeader
        className={styles.header}
        text={t('myApplication.secondTab.other.title', 'Other services offered')}
      />
      <div className={styles.checkBoxList}>
        {otherServicesLocal.map((item) => (
          <div className={styles.checkBoxWrapper} key={item.id}>
            <CheckBox
              text={item.label}
              textSize="s14"
              checked={initialValues.includes(item.id)}
              onChange={() => handleList({ key: item.id })}
              isContractorsStyle
            />
          </div>
        ))}
      </div>
    </div>
  );
};

OtherServicesSection.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  blockHistory: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

OtherServicesSection.defaultProps = {};

export default OtherServicesSection;
