import React from 'react';
import { useTranslation } from 'react-i18next';
import WrapperBlock from 'components/shared/WrapperBlock';
import PropTypes from 'prop-types';
import { roles } from 'enums';
import styles from '../../pageStyles.module.css';
import DetailsSection from './DetailsSection';
import InformationSection from './InformationSection';
import PermitSection from './PermitSection';
import InspectSection from "./InspectSection";
import CrewSection from './CrewSection';
import ReadViewCrewSection from './CrewSection/readViewSection';

const InformationTab = ({
  opportunity,
  description,
  number,
  members,
  assignedContacts,
  userCurrentRole,
  isDisableActions,
  amount,
  permitRequired,
  isPermitUpload,
  approvedInspectionUpload,
  ...props
}) => {
  const { dateOfInstall } = props;
  const { t } = useTranslation();
  return (
    <div className={[styles.tabContentWrapper, styles.mainContent].join(' ')}>
      <WrapperBlock
        title={t('jobs.awarded.details.general.crew.title', 'Crew')}
        classNameMainWrapper={styles.wrapper}
      >
        {[roles.crew].includes(userCurrentRole) || isDisableActions ? (
          <ReadViewCrewSection assignedContacts={assignedContacts} />
        ) : (
          <CrewSection
            {...props}
            contacts={members}
            assignedContacts={assignedContacts}
          />
        )}
      </WrapperBlock>

      <WrapperBlock
        title={t('jobs.awarded.details.general.permit.title', 'Permit')}
        toolTipText={t(
          'jobs.awarded.details.general.permit.titleNote',
          '(please tick if required)'
        )}
        isHasTooltip
        classNameMainWrapper={styles.wrapper}
      >
        <PermitSection isPermitUpload={isPermitUpload} {...props} />
      </WrapperBlock>

        <WrapperBlock
            title={t('Inspection')}
            toolTipText={t(
                '(Is a final inspection required for local jurisdiction?)'
            )}
            isHasTooltip
            classNameMainWrapper={styles.wrapper}
        >
            <InspectSection approvedInspectionUpload={approvedInspectionUpload} {...props} />
        </WrapperBlock>

      <WrapperBlock
        title={t('jobs.awarded.details.general.info.title', 'Information')}
        classNameMainWrapper={styles.wrapper}
      >
        <InformationSection
          {...opportunity}
          number={number}
          gateCode={opportunity?.account?.gateCode}
          location={opportunity?.location}
          address={opportunity?.account?.address}
          dateOfInstall={dateOfInstall}
          awardAmount={
            [roles.admin, roles.finance].includes(userCurrentRole) ? amount : null
          }
        />
      </WrapperBlock>
      <WrapperBlock
        title={t('jobs.awarded.details.general.details.title', 'Details')}
        classNameMainWrapper={styles.wrapper}
      >
        <DetailsSection opportunity={opportunity} description={description} {...props} />
      </WrapperBlock>
    </div>
  );
};

InformationTab.propTypes = {
  opportunity: PropTypes.object.isRequired,
  number: PropTypes.string.isRequired,
  dateOfInstall: PropTypes.string,
  description: PropTypes.string,
  members: PropTypes.array,
  assignedContacts: PropTypes.array,
  userCurrentRole: PropTypes.string.isRequired,
  isDisableActions: PropTypes.bool,
  permitRequired: PropTypes.bool,
  isPermitUpload: PropTypes.bool,
  approvedInspectionUpload: PropTypes.bool,
  amount: PropTypes.number,
};

InformationTab.defaultProps = {
  description: '',
  dateOfInstall: '',
  assignedContacts: [],
  members: [],
  isDisableActions: false,
  permitRequired: false,
  isPermitUpload: false,
  approvedInspectionUpload: false,
  amount: null,
};

export default InformationTab;
