import gql from 'graphql-tag';

const CaseFragment = gql`
  fragment CaseFragment on WarrantyCase {
    pgId
    sfId
    caseNumber
    createdAt
    status
    subject
    description
    type
    dateOfInstall
    totalAmount
    repairDateTime
    account {
      address
      billingStreet
      billingCity
      billingCountry
      billingState
      billingPostalCode
      billingLatitude
      billingLongitude
      gateCode
    }
    contact {
      name
      phone
    }
    workOrder {
      dateOfInstall
    }
  }
`;
export const Case = {
  fragments: {
    case: CaseFragment,
  },
};
