import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SectionHeader from 'components/shared/SectionHeader';
import Field from 'components/shared/Field';
import {
    FormColumn as Column,
    FormContent,
    FormRow as Row,
} from 'components/shared/Form';
import { usePreferredManufacturer } from 'enums/application/preferences';
import styles from './styles.module.css';


const PreferenceSection = ({
    values: { myApplication },
    errors,
    touched,
    handleChange,
    setFieldValue,
    appStatus,
}) => {
    const { t } = useTranslation();
    const manfOptions = usePreferredManufacturer();

    return (
        <div className={styles.section}>
            <SectionHeader
                text={t('Preferences')}
            />
            <div className={styles.formWrapper}>
                <form>
                    <FormContent>
                        {/* Row #1 */}
                        <Row>
                            <Column>
                                <Field
                                    label={t('Preferred Manufacturer #1')}
                                    type="select"
                                    inputMode="text"
                                    options={manfOptions}
                                    name="preferredManufacturer1"
                                    onChange={handleChange}
                                    value={myApplication.preferredManufacturer1}
                                />
                            </Column>
                            <Column>
                                <Field
                                    label={t('Preferred Distributor')}
                                    type="text"
                                    inputMode="text"
                                    name="preferredDistributor"
                                    value={myApplication.preferredDistributor}
                                    onChange={handleChange}
                                    isError={
                                        touched.myApplication?.preferredDistributor &&
                                        Boolean(errors.myApplication?.preferredDistributor)
                                    }
                                />
                            </Column>
                        </Row>
                        {/* Row #2 */}
                        <Row>
                            {myApplication.preferredDistributor !==
                                '' && myApplication.preferredManufacturer1 !== '' ? (
                                <Column>
                                    <Field
                                        label={t('Preferred Manufacturer #2')}
                                        type="select"
                                        inputMode="text"
                                        options={manfOptions.filter((option) => {
                                            return option.value !== myApplication.preferredManufacturer1;
                                        })}
                                        name="preferredManufacturer2"
                                        onChange={handleChange}
                                        value={myApplication.preferredManufacturer2}
                                    />
                                </Column>
                            ) : null}
                        </Row>
                        {/* Row #3 */}
                        <Row>
                            {myApplication.preferredManufacturer2 !== '' ? (
                                <Column>
                                    <Field
                                        label={t('Preferred Manufacturer #3')}
                                        type="select"
                                        inputMode="text"
                                        options={manfOptions.filter((option) => {
                                            return option.value !== myApplication.preferredManufacturer1 && option.value !== myApplication.preferredManufacturer2;
                                        })}
                                        name="preferredManufacturer3"
                                        onChange={handleChange}
                                        value={myApplication.preferredManufacturer3}
                                    />
                                </Column>
                            ) : null}
                        </Row>
                        {/* Other */}
                        <Row>
                            {myApplication.preferredManufacturer1 === 'Other' || myApplication.preferredManufacturer2 === 'Other' || myApplication.preferredManufacturer3 === 'Other' ? (
                                <Column>
                                    <Field
                                        label={t('Preferred Manufacturer Other')}
                                        type="text"
                                        inputMode="text"
                                        name="preferredManufacturerOther"
                                        onChange={handleChange}
                                        value={myApplication.preferredManufacturerOther}
                                    />
                                </Column>
                            ) : null}
                        </Row>
                    </FormContent>
                </form>
            </div>
        </div>
    );
};

PreferenceSection.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    appStatus: PropTypes.string.isRequired,
};

export default PreferenceSection;