import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import UploadFileComponent from 'components/MyApplication/UploadApplicationFiles';
import Button from 'components/shared/Button';
import { useDeleteDocument } from 'utils/application/useDeleteDocument';
import { handleAddDocument } from 'utils/application/addDocument';
import { isSectionFilled } from 'utils/application/isSectionFilled';
import { isDisabled } from 'utils/application/checkIsDisabled';
import { qualificationTypes, certificationFields } from 'enums';
import useModal from 'services/hooks/useIsShowComponent';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import { useDetLang } from 'services/localization';
import DocumentDescription from './DocumentDescription';
import DeleteButton from './DeleteButton';
import styles from './style.module.css';

const WorkersCompensation = ({
  myCertification,
  errors,
  touched,
  deleteUploadedFiles,
  handleChange,
  appStatus,
  qualificationSfId,
  loadCreateApp,
  isOnBoarding,
}) => {
  const { workersCompensation } = myCertification;
  const { handleDeleteDocument } = useDeleteDocument();

  const { isShow: isOpenModal, handleClick: handleClickModal } = useModal(false);

  const { t } = useTranslation();
  const localButtonContainerStyle = useDetLang(styles.localButtonContainer, null);
  const localButtonHeight = useDetLang('local', '');
  return (
    <WrapperBlock
      tag="h2"
      title={t('myApplication.thirdTab.worker.p_1', 'Workers Compensation')}
      isOnBoarding={isOnBoarding}
      isHasTooltip="true"
      toolTipText={t(
        'myApplication.thirdTab.worker.p_2',
        'The company is in good standing within each state of registration'
      )}
      isChecked={isSectionFilled(myCertification, qualificationTypes.wc)}
    >
      {Object.values(workersCompensation).length ? (
        <div className={[styles.addButtonContainer, localButtonContainerStyle].join(' ')}>
          <Button
            color="white"
            size="large"
            handleClick={() =>
              handleAddDocument(
                workersCompensation,
                handleChange,
                qualificationTypes.wc,
                true
              )
            }
            height={localButtonHeight}
          >
            {t('myApplication.thirdTab.worker.p_3', '+ Add Extra Document')}
          </Button>
        </div>
      ) : null}
      {Object.values(workersCompensation).map(
        ({ policyNumber, carrier, expirationDate, files }, index) => (
          <div key={index}>
            <DocumentDescription
              policyNumber={policyNumber}
              carrier={carrier}
              expirationDate={expirationDate}
              policyName={certificationFields.policyNumber}
              carrierName={certificationFields.carrier}
              expirationName={certificationFields.expirationDate}
              files={files}
              index={index}
              handleChange={(event) =>
                handleChange(`myCertification.workersCompensation.docs_${index}`, event)
              }
              errors={errors}
              touched={touched}
              status={appStatus}
              myCertification={myCertification}
              type="workersCompensation"
            />
            <UploadFileComponent
              accept=".jpeg, .png, .jpg, .pdf, .gif, .docx, .doc"
              maxCountDocument={5}
              uploadFiles={files}
              buttonText={t('uploadButton', 'Upload file(s)')}
              handleChange={(event) =>
                handleChange(`myCertification.workersCompensation.docs_${index}`, event)
              }
              name="files"
              parentId={qualificationSfId}
              deleteUploadedFiles={deleteUploadedFiles}
            />
            <AcknowledgmentModal
              handleClose={() => {
                handleClickModal();
              }}
              footer={
                <div>
                  <Button
                    size="medium"
                    handleClick={() => {
                      handleDeleteDocument(
                        workersCompensation,
                        index - 1,
                        handleChange,
                        qualificationTypes.wc,
                        true
                      );

                      handleClickModal();
                    }}
                  >
                    {t('acknowledgmentModal.yes', 'Yes')}
                  </Button>

                  <Button
                    size="medium"
                    color="white"
                    handleClick={() => handleClickModal()}
                    className={styles.rightButton}
                  >
                    {t('acknowledgmentModal.no', 'No')}
                  </Button>
                </div>
              }
              title={t('acknowledgmentModal.title', 'What would you like to do?')}
              description={t(
                'acknowledgmentModal.documents.description',
                'Are you sure you would like to delete this document?'
              )}
              isShow={isOpenModal}
            />

            {index !== Object.values(workersCompensation).length - 1 ? (
              <>
                <div className={styles.spreadLine} />

                <DeleteButton
                  handleDeleteDocument={() => handleClickModal()}
                  disabled={isDisabled(appStatus) || loadCreateApp}
                />
              </>
            ) : null}
          </div>
        )
      )}
    </WrapperBlock>
  );
};
WorkersCompensation.propTypes = {
  myCertification: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  deleteUploadedFiles: PropTypes.func.isRequired,
  qualificationSfId: PropTypes.array,
  appStatus: PropTypes.string.isRequired,
  loadCreateApp: PropTypes.bool.isRequired,
  isOnBoarding: PropTypes.bool,
};
WorkersCompensation.defaultProps = {
  errors: {},
  qualificationSfId: [],
  touched: {},
  isOnBoarding: false,
};

export default WorkersCompensation;
