import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const FormHeader = ({ children }) => (
  <div className={[styles.formHeader].join(' ')}>{children}</div>
);

FormHeader.propTypes = {
  children: PropTypes.node,
};

FormHeader.defaultProps = {
  children: '',
};

export default FormHeader;
