import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Documents from 'components/Documents';
import SectionHeader from 'components/shared/SectionHeader';
import Text from 'components/shared/Text';
import AppConfigContext from 'context/AppConfigContext';
import Spinner from 'components/shared/Spinner';
import useError from 'services/hooks/useErrors/useErrorCertifications';
import { useAppConfigLocal } from 'localization';
import styles from './styles.module.css';

const MyCertificationsPart = ({
  appStatus,
  errors,
  touched,
  loadingAttachments,
  loadCreateApp,
  handleChange,
  qualificationSfId,
  ...formikProps
}) => {
  const { myCertification } = errors;

  useError(myCertification, touched);

  const config = useContext(AppConfigContext);

  const { t } = useTranslation();
  const appConfigLocal = useAppConfigLocal();
  return (
    <div className={styles.section}>
      {loadCreateApp && (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      <SectionHeader text={t('myApplication.thirdTab.title', 'Documents')} />
      <div className={styles.textWrapper}>
        <Text color="#292929" size="s16" lineHeight="h19" weight="wm">
          {appConfigLocal[config.textPermit]}
        </Text>
      </div>

      <Documents
        {...formikProps}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        className={styles.documentWrapper}
        qualificationSfId={qualificationSfId}
        loadingAttachments={loadingAttachments}
        loadCreateApp={loadCreateApp}
        appStatus={appStatus}
        isOnBoarding
      />
    </div>
  );
};

MyCertificationsPart.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  qualificationSfId: PropTypes.array,
  loadingAttachments: PropTypes.bool.isRequired,
  loadCreateApp: PropTypes.bool.isRequired,
  appStatus: PropTypes.string.isRequired,
};
MyCertificationsPart.defaultProps = {
  qualificationSfId: [],
};
export default MyCertificationsPart;
