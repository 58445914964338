import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import calendarIcon from 'assets/icons/calendar.svg';
import { formatDate } from 'utils/dateFormatters';
import { caseStatus } from 'enums';
import styles from '../../tableStyles.module.css';

const WarrantyTableCardMobile = ({
  location,
  reference,
  dateOfInstall,
  isSelected,
  handleSelect,
  warrantyCases,
  className,
}) => {
  const textStyles = isSelected
    ? {
        weight: 'wb',
      }
    : {
        weight: 'wr',
      };
  const { t } = useTranslation();
  return (
    <div
      className={[
        styles.projectCardWrapper,
        className,
        isSelected ? styles.projectCardWrapperSelected : '',
      ].join(' ')}
    >
      <div onClick={() => handleSelect(!isSelected)}>
        <div className={styles.projectCardHeader}>
          <Text color="#000" size="s16" weight="wb">
            {reference}
          </Text>
        </div>
        <div>
          <div className={styles.textOverflow}>
            <Text color="#767676" tag="p" lineHeight="h18">
              {t('jobs.warranty.mapCard.mobile.location', ' Location:')} {location}
            </Text>
          </div>
          <div>
            <Icon src={calendarIcon} width="12px" className={styles.calendarIcon} />
            <Text color="#767676">
              {t('jobs.warranty.mapCard.mobile.date', 'Date of install:')}{' '}
              {dateOfInstall ? formatDate(dateOfInstall) : 'Not set yet'}
            </Text>
          </div>

          <div>
            <Text color="#000" {...textStyles}>
              {t('jobs.warranty.mapCard.mobile.repair', 'Total repair cases:')}{' '}
              {warrantyCases.length}
            </Text>
          </div>
          <div>
            <Text color="#f1c400" {...textStyles}>
              {t('jobs.warranty.mapCard.mobile.open', 'Open cases:')}{' '}
              {
                warrantyCases.filter(({ status }) =>
                  [caseStatus.inProgress, caseStatus.new].includes(status)
                ).length
              }
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

WarrantyTableCardMobile.propTypes = {
  location: PropTypes.string,
  reference: PropTypes.string.isRequired,
  dateOfInstall: PropTypes.string,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  handleClick: PropTypes.func,
  handleSelect: PropTypes.func,
  warrantyCases: PropTypes.array,
};

WarrantyTableCardMobile.defaultProps = {
  dateOfInstall: '',
  className: '',
  isSelected: false,
  location: '',
  handleClick: () => {},
  handleSelect: () => {},
  warrantyCases: [],
};

export default WarrantyTableCardMobile;
