import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import IconSVG from 'components/shared/IconSVG';
import { isDisabled } from 'utils/application/checkIsDisabled';
import { applicationStatus } from 'enums';
import styles from './style.module.css';

const StatusTabsComponent = ({
  tabList,
  currentTabIndex,
  relative,
  handleSelectTab = () => {},
  isClickable,
  isContractors,
  appStatus,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentTabIndex]);

  return (
    <Tabs
      className={styles.tabsPlanning}
      selectedIndex={currentTabIndex}
      onSelect={(index) => handleSelectTab(index)}
    >
      <TabList className={styles.tabListPlanning}>
        {tabList.map(
          ({ title: tabTitle, isClosed, icon, isTabCompleted, validateIcon }, index) => {
            if (!tabTitle) return null;

            return (
              <Tab
                key={`${index}tab`}
                className={[
                  isContractors ? styles.tabPlanningContractors : ``,
                  styles.tabPlanning,
                  index <= currentTabIndex
                    ? isContractors
                      ? styles.finishedTabPlanningContractors
                      : styles.finishedTabPlanning
                    : styles.defaultTabPlaning,
                  index <= currentTabIndex && isClickable
                    ? styles.tabPlanningClickable
                    : '',
                ].join(' ')}
                selectedClassName={
                  !isClosed
                    ? isContractors
                      ? styles.selectedTabPlanningContractors
                      : styles.selectedTabPlanning
                    : ''
                }
                disabledClassName={styles.disabledTabPlaning}
              >
                <div className={styles.titleContent}>
                  {icon ? (
                    <IconSVG
                      className={styles.titleIcon}
                      src={icon}
                      width="18px"
                      height="18px"
                    />
                  ) : null}
                  {tabTitle}
                </div>
                {isTabCompleted === 100 && appStatus === applicationStatus.draft ? (
                  <div className={styles.validateIconContainer}>
                    <IconSVG
                      className={styles.validateIcon}
                      src={validateIcon}
                      width="18px"
                      height="18px"
                    />
                  </div>
                ) : null}
              </Tab>
            );
          }
        )}
      </TabList>

      {tabList.map(({ children }, index) => (
        <TabPanel
          key={`${index}tabPanel`}
          className={styles.tabPanelPlanning}
          selectedClassName={[
            styles.selectedTabPanelPlanning,
            relative ? styles.selectedTabPanelPlanningRelative : '',
          ].join(' ')}
        >
          {isContractors ? (
            <fieldset disabled={isDisabled(appStatus)}>{children}</fieldset>
          ) : (
            children
          )}
        </TabPanel>
      ))}
    </Tabs>
  );
};

StatusTabsComponent.propTypes = {
  tabList: PropTypes.array.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  relative: PropTypes.bool,
  handleSelectTab: PropTypes.func,
  isClickable: PropTypes.bool,
  isContractors: PropTypes.bool,
  appStatus: PropTypes.string,
};

StatusTabsComponent.defaultProps = {
  relative: false,
  handleSelectTab: () => {},
  isClickable: false,
  isContractors: false,
  appStatus: 'Draft',
};

export default StatusTabsComponent;
