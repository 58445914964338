import React from 'react';
import { InfoBox } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import { formatDate } from 'utils/dateFormatters';
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import Text from 'components/shared/Text';
import colorStatus from 'utils/statusColor';
import styles from './style.module.css';

const JobsInfo = ({ position, location }) => {
  return (
    <InfoBox
      position={position}
      options={{ closeBoxURL: ``, enableEventPropagation: true }}
    >
      <div className={styles.infoBoxWrapper}>
        <div className={styles.infoBoxHeader}>
          <div onClick={location.handleShowDetails} className={styles.titleWrapper}>
            <Text
              tag="p"
              color="#00000D"
              size="s16"
              lineHeight="h19"
              weight="wb"
              className={styles.title}
            >
              #{location.number}
            </Text>
            <AlertIcon
              style={{ width: '14px', height: '14px' }}
              className={styles.infoIcon}
            />
          </div>
          <div className={styles.statusWrapper}>
            <Text
              weight="wm"
              color="#000"
              size="s10"
              lineHeight="h12"
              className={[
                styles.text,
                styles[`text${colorStatus(location.status)}`],
              ].join(' ')}
            >
              {location.status}
            </Text>
          </div>
        </div>
        <div className={styles.infoBoxBody}>
          <div className={styles.infoBoxBodyBlock}>
            <Text tag="p" color="#000000" size="s14" lineHeight="h16">
              Date of install:{' '}
              {location.dateOfInstall
                ? formatDate(location.dateOfInstall)
                : 'Not set yet'}
            </Text>
            <Text tag="p" color="#767676" size="s14" lineHeight="h16">
              {location.address}
            </Text>
            {location.amount ? (
              <Text tag="p" color="#767676" size="s14" lineHeight="h16">
                Award Amount: ${Intl.NumberFormat().format(location.amount)}
              </Text>
            ) : null}
          </div>
          <div className={styles.infoBoxBodyBlock}>
            <Button
              height="small"
              textSize="small"
              disabled={location.isDisabled}
              handleClick={location.handleClaim}
            >
              {location.isDisabled ? 'Claimed' : 'Claim'}
            </Button>
          </div>
        </div>
      </div>
    </InfoBox>
  );
};
JobsInfo.propTypes = {
  position: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default JobsInfo;
