import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DonutChart from 'components/shared/DonutChart';
import WrapperBlock from 'components/shared/WrapperBlock';
import LegendItem from 'components/common/LegendItem';
import styles from '../style.module.css';

const Share = ({ jobsCount = {} }) => {
  const { t } = useTranslation();
  const share = useMemo(() => {
    return [
      {
        color: '#6C9961',
        value: jobsCount.ongoingJobsCount,
        name: t('finance.share.ongoing', 'Ongoing Projects'),
      },
      {
        color: '#5162D3',
        value: jobsCount.warrantyJobsCount,
        name: t('finance.share.warrantied', 'Warrantied'),
      },
      {
        color: '#F1C400',
        value: jobsCount.completedJobsCount,
        name: t('finance.share.finished', 'Finished Projects'),
      },
    ];
  }, []);
  return (
    <div className={styles.shareWrapper}>
      <WrapperBlock title={t('finance.share.title', 'Share of business')}>
        <div className={styles.legend}>
          {share.map((el, index) => (
            <LegendItem color={el.color} key={el.color + index}>
              {el.name}
            </LegendItem>
          ))}
        </div>
        <div className={styles.centeredChart}>
          <DonutChart series={share} size={280} strokeWidth={70} />
        </div>
      </WrapperBlock>
    </div>
  );
};
Share.propTypes = {
  jobsCount: PropTypes.object.isRequired,
};
Share.defaultProps = {};

Share.defaultProps = {};
export default Share;
