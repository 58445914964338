import gql from 'graphql-tag';

export const GET_ATTACHMENTS = gql`
  query downloadFiles($contentDocumentId: String!, $entityName: String) {
    files(contentDocumentId: $contentDocumentId, entityName: $entityName) {
      contentDocumentId
      name
      description
      contentType
    }
  }
`;

export const DOWNLOAD_FILES_DATA = gql`
  query downloadFilesData($contentDocumentId: String!) {
    downloadFile(contentDocumentId: $contentDocumentId) {
      base64String
      type
      name
      contentDocumentId
    }
  }
`;
export const DOWNLOAD_FILES_DESCRIPTION = gql`
  query downloadFilesDescription($data: [String!]!) {
    downloadFilesDescription(data: $data) {
      contentDocumentId
      sfId
      name
      description
      contentType
      parentSfId
    }
  }
`;
