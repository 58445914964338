import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { CANCEL_JOB } from 'graphql/mutations/opportunity';
import { updateCurrentJobList } from 'graphql/resolvers/opportunity/cache';
import { FormColumn } from 'components/shared/Form';
import Modal from 'components/shared/modals/FormModal';
import TextArea from 'components/shared/TextArea';
import { jobStatus } from 'enums';
import styles from '../style.module.css';

const CancelJobModal = ({ isShow, handleClose, id }) => {
  const [comment, setComment] = useState('');
  const [isError, serIsError] = useState(false);
  const [rescheduleJob, { loading }] = useMutation(CANCEL_JOB);

  const handleSubmit = () => {
    if (!comment.length) return serIsError(true);
    return rescheduleJob({
      variables: {
        comment,
        id,
      },
      update: () => {
        updateCurrentJobList(id, { status: jobStatus.canceled }, { isAwarded: true });
        handleClose();
      },
    });
  };
  const { t } = useTranslation();
  return (
    <fieldset disabled={loading}>
      <Modal
        isShow={isShow}
        title={t('cancelModal.title', 'Cancel Job')}
        handleClose={() => {
          serIsError(false);
          setComment('');
          handleClose();
        }}
        handleSubmit={handleSubmit}
        isIncludeFooter
      >
        <FormColumn>
          <div className={styles.wrapperTextarea}>
            <TextArea
              name="comment"
              value={comment}
              placeholder={t('messagePlaceholder', 'Your message...')}
              label={t('messageLabel', 'Add a comment')}
              handleChange={({ target }) => {
                serIsError(false);
                setComment(target.value);
              }}
              isError={isError}
            />
          </div>
        </FormColumn>
      </Modal>
    </fieldset>
  );
};

CancelJobModal.propTypes = {
  isShow: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

CancelJobModal.defaultProps = {
  isShow: false,
};

export default CancelJobModal;
