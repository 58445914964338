import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import install from 'assets/icons/welcome_page/install.png';
import myaccount from 'assets/icons/welcome_page/myaccount.png';
import guarantee from 'assets/icons/welcome_page/guarantee.png';
import industrytraining from 'assets/icons/welcome_page/industrytraining.png';
import marketingopportunities from 'assets/icons/welcome_page/marketingopportunities.png';
import Text from '../../../shared/Text';
import PresentationIcon from './PresentationIcon';
import Button from '../../../shared/Button';
import styles from './style.module.css';
import welcomeStyles from '../../style.module.css';
import MobileContext from '../../../../context/MobileContext';

const LeadPresentationBlock = ({ handleSubmit }) => {
  const isMobile = useContext(MobileContext);
  return (
    <div className={styles.blockWrapper}>
      <div className={styles.block}>
        <Text
          tag="h2"
          size={isMobile ? 's21' : 's32'}
          lineHeight={isMobile ? 'h28' : 'h37'}
          weight="wb"
          color="#F3C306"
          className={styles.presentationTitle}
        >
          Why we are the best
        </Text>
        <Text
          tag="p"
          size={isMobile ? 's14' : 's19'}
          lineHeight={isMobile ? 'h20' : 'h30'}
          weight="wr"
          color="#FFFFFF"
          className={styles.presentationText}
        >
          RoofClaim.com sets your team up for success.
          <br />
          Every contractor in our network enjoys:
        </Text>
      </div>

      <div className={styles.presentationIconsBlock}>
        <PresentationIcon
          image={install}
          text={['Ready to Install Jobs', <br />, '(We do not SELL you leads)']}
        />
        <PresentationIcon
          image={myaccount}
          text={['Customer', <br />, 'Connection Portal']}
        />
        <PresentationIcon
          image={guarantee}
          text={['Million Dollar Guarantee', <br />, 'to protect all parties']}
        />
        <PresentationIcon
          image={industrytraining}
          text={['Industry', <br />, 'Training']}
        />
        <PresentationIcon
          image={marketingopportunities}
          text={['Marketing', <br />, 'Opportunities']}
        />
      </div>
      <div className={styles.block}>
        <Button
          size="larger"
          type="submit"
          handleClick={handleSubmit}
          className={welcomeStyles.welcomeButton}
        >
          Start My Application Now!
        </Button>
        <Text
          tag="p"
          size="s16"
          weight="wr"
          lineHeight="h19"
          color="#FFFFFF"
          className={styles.contactUsText}
        >
          Have questions?
          <a
            className={styles.contactUsLink}
            href="https://www.roofclaim.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <span> </span>
            Contact Us
          </a>
        </Text>
      </div>
    </div>
  );
};

LeadPresentationBlock.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

LeadPresentationBlock.defaultProps = {};

export default LeadPresentationBlock;
