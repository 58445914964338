import gql from 'graphql-tag';
import { Application } from 'graphql/fragments/application.fragment';
import { Qualification } from 'graphql/fragments/qualification.fragment';

export const CREATE_APPLICATION_MUTATION = gql`
  mutation CREATE_APPLICATION_MUTATION($data: createApplicationData!, $status: String!) {
    createApplication(data: $data, status: $status) {
      application {
        ...FullApplic
      }
      qualification {
        ...FullQualification
      }
    }
  }
  ${Application.fragments.FullApplic}
  ${Qualification.fragments.FullQualification}
`;

export const DELETE_QUALIFICATION_MUTATION = gql`
  mutation DELETE_QUALIFICATION_MUTATION($sfId: String!) {
    deleteQualification(sfId: $sfId) {
      ...FullQualification
    }
  }

  ${Qualification.fragments.FullQualification}
`;
export const FIRST_TIME_SEE_DENIED_MUTATION = gql`
  mutation FIRST_TIME_SEE_DENIED_MUTATION {
    firstTimeSeeDenied {
      message
    }
  }
`;