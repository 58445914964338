import React, { useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_USER_NOTIFICATIONS_SETTINGS } from 'graphql/queries/notifications';
import { useMutation, useQuery } from '@apollo/react-hooks';
import update from 'immutability-helper';
import { UPDATE_NOTIFICATIONS_SETTINGS } from 'graphql/mutations/notifications';
import isEmpty from 'lodash/isEmpty';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import client from 'graphql/client';
import { roles } from 'enums';
import AuthRoleContext from 'context/AuthRoleContext';
import Text from 'components/shared/Text';
import LanguageContext from 'context/LanguageContext';
import WrapperBlock from 'components/shared/WrapperBlock';
import Spinner from 'components/shared/Spinner';
import Checkbox from 'components/shared/CheckBox';
import Button from 'components/shared/Button';
import { useNotifTitlesLocal, useNotifTextLocal } from 'localization';
import { useDetLang } from 'services/localization';
import styles from './style.module.css';

const SettingsPage = () => {
  const [_settingsTemplate, setSettingsTemplate] = useState(null);
  const [isShowButtons, setShowButtons] = useState(false);
  const { data: settingsTemplate, loading } = useQuery(GET_USER_NOTIFICATIONS_SETTINGS, {
    onCompleted: () => {
      setSettingsTemplate(settingsTemplate.notificationsSettings);
    },
  });

  const { t } = useTranslation();
  const notifTitlesLocal = useNotifTitlesLocal();
  const notifTextLocal = useNotifTextLocal();
  const buttonHeight = useDetLang('local', '');

  const role = useContext(AuthRoleContext);
  const { language } = useContext(LanguageContext);

  const [saveNotificationsSettings, { loading: updateLoading }] = useMutation(
    UPDATE_NOTIFICATIONS_SETTINGS
  );

  const submit = async () => {
    await saveNotificationsSettings({
      variables: {
        settings: _settingsTemplate,
      },
      update: () => {
        client.cache.writeQuery({
          query: GET_USER_NOTIFICATIONS_SETTINGS,
          data: { notificationsSettings: _settingsTemplate },
        });
      },
    });
  };
  const cancel = () => setSettingsTemplate(settingsTemplate.notificationsSettings);

  function handleChange({ divisionIndex, typeIndex, target }) {
    if (isShowButtons) setShowButtons(false);

    setSettingsTemplate(
      update(_settingsTemplate, {
        [divisionIndex]: {
          types: {
            [typeIndex]: {
              value: {
                [target]: {
                  $apply(x) {
                    return !x;
                  },
                },
              },
            },
          },
        },
      })
    );
  }

  const hasFormChanged = useMemo(
    () =>
      !isEmpty(
        differenceWith(
          settingsTemplate?.notificationsSettings,
          _settingsTemplate,
          isEqual
        )
      ),
    [settingsTemplate, _settingsTemplate]
  );

  const getCheckboxValue = (divisionIndex, typeIndex) =>
    _settingsTemplate?.[divisionIndex].types[typeIndex].value ?? {
      email: false,
      push: false,
    };

  if (loading) return <Spinner />;

  const titlePermission = (division) => {
    if (division.title === `Today's Jobs` && [roles.admin, roles.finance].includes(role))
      return false;

    if (
      division.title === `Warranty Case` &&
      [roles.superintendent, roles.crew].includes(role)
    )
      return false;

    return true;
  };

  const typePermission = (type) => {
    const notForSuperintendent_Crew = [
      'New Job become available',
      'Claimed Job status change',
      'Job is assigned to crew (for admin)',
    ];

    if (
      type.name === `Job is assigned to crew` &&
      [roles.admin, roles.finance].includes(role)
    )
      return false;

    if (type.name === `Check-in for job required` && role === roles.crew) return false;

    if (
      notForSuperintendent_Crew.includes(type.name) &&
      [roles.superintendent, roles.crew].includes(role)
    )
      return false;

    return true;
  };

  return (
    <div className={styles.pageWrapper}>
      <WrapperBlock title={t('myAccount.settings.title', 'Notification Settings')}>
        <div className={styles.row}>
          {/*  // POSTPONE: Email settings
          <Text color="#686868" size="s14" className={styles.emailTitle}>
          Email
          </Text> */}
          <Text color="#686868" size="s14" className={styles.emailTitle}>
            {t('myAccount.settings.push', 'Push')}
          </Text>
        </div>
        {settingsTemplate?.notificationsSettings.map((division, divisionIndex) => (
          <>
            {titlePermission(division) ? (
              <div className={styles.division} key={division.title}>
                <Text
                  weight="wb"
                  color="#000000"
                  size="s16"
                  style={{ textDecoration: 'underline' }}
                >
                  {notifTitlesLocal[division.title]}
                </Text>
                <br />
                {division.types.map((type, typeIndex) => (
                  <>
                    {typePermission(type) ? (
                      <div
                        className={[styles.row, styles.settingsType].join(' ')}
                        key={type.field}
                      >
                        <Text
                          color="#000000"
                          size="s14"
                          className={styles.snuggledToLeft}
                        >
                          {language === 'es' ? notifTextLocal[type.name] : type.name}
                        </Text>
                        {/*  // POSTPONE: Email settings
                <Checkbox
                  name="email"
                  checked={getCheckboxValue(divisionIndex, typeIndex).email}
                  onChange={() =>
                    handleChange({
                      divisionIndex,
                      typeIndex,
                      fieldName: type.field,
                      target: 'email',
                    })
                  }
                />
                */}
                        <Checkbox
                          isContractorsStyle
                          name="push"
                          checked={getCheckboxValue(divisionIndex, typeIndex).push}
                          onChange={() =>
                            handleChange({
                              divisionIndex,
                              typeIndex,
                              fieldName: type.field,
                              target: 'push',
                            })
                          }
                        />
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
            ) : null}
          </>
        ))}
        {hasFormChanged ? (
          <div className={styles.footer}>
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                size="small"
                height={buttonHeight}
                disabled={loading || updateLoading}
                handleClick={submit}
              >
                {t('saveChangesButton', 'Save Changes')}
              </Button>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                color="white"
                size="small"
                height={buttonHeight}
                disabled={loading || updateLoading}
                handleClick={cancel}
              >
                {t('cancelButton', 'Cancel')}
              </Button>
            </div>
          </div>
        ) : null}
      </WrapperBlock>
    </div>
  );
};

export default SettingsPage;
