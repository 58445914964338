import { useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import auth from 'utils/auth';
import { SIGNOUT_MUTATION } from 'graphql/mutations/auth';

const LogoutPage = () => {
  const [signOutUser] = useMutation(SIGNOUT_MUTATION);
  const client = useApolloClient();
  useEffect(() => {
    const handleSubmit = async () => {
      try {
        const token = auth.getToken();
        auth.removeToken();
        localStorage.setItem('logged', 'false');
        await client.clearStore();
        signOutUser({
          variables: { token },
          optimisticResponse: {
            signOutUser: {
              message: 'OK!',
              __typename: 'SuccessMessage',
            },
          },
        });
        window.location.replace('/sign-in');
      } catch (e) {
        window.location.replace('/sign-in');
      }
    };

    handleSubmit();
  }, [signOutUser, client]);

  return null;
};

export default LogoutPage;
