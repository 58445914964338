const colorStatus = {
  in_progress: 'Green',
  rollover: 'Yellow',
  reschedule_requested: 'Grey',
  new: 'Green',
  pending: 'Yellow',
  canceled: 'Red',
  completed: 'Green',
  scheduled: 'Grey',
  requested: 'Yellow',
  awarded: 'Grey',
};

export default (status) => colorStatus[status?.toLowerCase().replace(/-|_|\s/g, '_')];
