import client from 'graphql/client';

import { DOWNLOAD_FILES_DESCRIPTION } from 'graphql/queries/file';

export const deleteFileRoofProject = ({ parentId, fileId }) => {
  const { downloadFilesDescription } = client.cache.readQuery({
    query: DOWNLOAD_FILES_DESCRIPTION,
    variables: {
      data: parentId,
    },
  });

  const newFiles = downloadFilesDescription.filter(
    ({ contentDocumentId }) => contentDocumentId !== fileId
  );

  client.cache.writeQuery({
    query: DOWNLOAD_FILES_DESCRIPTION,
    variables: {
      data: parentId,
    },
    data: {
      downloadFilesDescription: newFiles,
    },
  });
};
