import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

const useErrorDocuments = (errors, touched) => {
  const { myCertification } = errors;

  useEffect(() => {
    if (myCertification?.message && !isEmpty(touched)) {
      if (myCertification?.message?.includes('provided')) {
        window.scrollTo({
          top: myCertification?.message?.includes('Other') ? 850 : 350,
          behavior: 'smooth',
        });
      }
      if (myCertification?.message?.includes('unique')) {
        window.scrollTo({
          top: myCertification?.message?.includes('not') ? 350 : 850,
          behavior: 'smooth',
        });
      }
    }
  }, [errors, touched]);
};
export default useErrorDocuments;
