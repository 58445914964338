import client from 'graphql/client';

import { MY_APPLICATION } from 'graphql/queries/application';

export const updateApplicationCache = (application) => {
  const app = client.cache.readQuery({
    query: MY_APPLICATION,
  });

  const filteredData = app.qualification.filter(({ sfId }) =>
    application?.createApplication?.qualification.every((item) => item.sfId !== sfId)
  );

  client.cache.writeData({
    query: MY_APPLICATION,
    data: {
      qualification: [...filteredData, ...application.createApplication.qualification],
      application: { ...application.createApplication.application },
    },
  });
};
export const updateQualificationCache = (qualification) => {
  const app = client.cache.readQuery({
    query: MY_APPLICATION,
  });

  client.cache.writeData({
    query: MY_APPLICATION,
    data: {
      qualification: qualification.deleteQualification,
      application: { ...app.application },
    },
  });
};
