import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import LangMenuModal from 'components/shared/modals/LangMenuModal';
import LanguageContext from 'context/LanguageContext';
import useVisibleComponent from 'services/hooks/useComponentVisible';
import Icon from 'components/shared/IconSVG';
import { useDetLang } from 'services/localization';
import { spainIcon, usaIcon, chevronIcon } from 'assets/icons';
import styles from './style.module.css';
import Text from '../../../components/shared/Text';

const MobileSignedOutHeader = ({ children }) => {
  const { language } = useContext(LanguageContext);
  const {
    ref: langIconRef,
    isComponentVisible: langPopupVisible,
    handleClick: setLangPopupVisible,
  } = useVisibleComponent(false);
  return (
    <div>
      <div className={styles.bg}>
        <Text size="s19" color="#F1C400" lineHeight="h35" weight="wb">
          RoofClaim.com
        </Text>
        <div ref={langIconRef} className={styles.langContainer}>
          <div
            className={styles.langWrapper}
            onClick={() => {
              setLangPopupVisible(!langPopupVisible);
            }}
          >
            <Icon src={useDetLang(spainIcon, usaIcon)} height="20px" width="20px" />
            <div className={styles.langArrow}>
              <Text weight="wb" size="s14" lineHeight="h23" className={styles.langText}>
                {language.toUpperCase()}
              </Text>
              <Icon
                src={chevronIcon}
                height="20px"
                width="20px"
                className={[
                  styles.langIcon,
                  !langPopupVisible ? styles.langIconClose : styles.langIconOpen,
                ].join(' ')}
              />
            </div>
          </div>
          <LangMenuModal
            isShow={langPopupVisible}
            handleClose={setLangPopupVisible}
            isRegistration
          />
        </div>
      </div>
      {children}
    </div>
  );
};

MobileSignedOutHeader.propTypes = {
  children: PropTypes.node,
};

MobileSignedOutHeader.defaultProps = {
  children: null,
};

export default MobileSignedOutHeader;
