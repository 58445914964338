import { isMyCertificationTabFilled } from 'utils/application/isMyCertificationTabFilled';

export const isSectionFilled = (myCertification, type) => {
  const { percentWithType } = isMyCertificationTabFilled(myCertification, [
    '__typename',
    'sfId',
    'otherIssuer',
    'type',
  ]);

  return percentWithType
    .filter((item) => item.type === type)
    .every(({ percent }) => percent === 1);
};
