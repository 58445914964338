import { fromObjectToArray } from 'utils/application/restructureData/restructureCertificationData';

export const isUniquePolicyNumber = (myCertification = {}, types) => {
  const arrayOfPolicyNumbers = fromObjectToArray(myCertification)
    .filter((item) => types.includes(item.type))
    .filter(({ files }) => files.length)
    .map(({ policyNumber }) => policyNumber);
  const uniquePolicyNumbers = [...new Set(arrayOfPolicyNumbers)];
  return { arrayOfPolicyNumbers, uniquePolicyNumbers };
};
