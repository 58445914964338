import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_JOBS } from 'graphql/queries/jobs';
import { CASE_COUNT } from 'graphql/queries/jobsCount';
import Dashboard from 'components/Dashboard';
import Spinner from 'components/shared/Spinner';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import WithError from 'services/HOCs/withError';

const DashboardPage = () => {
  const { data, loading, error } = useQuery(GET_JOBS);
  const { data: count = {} } = useQuery(CASE_COUNT);

  if (loading)
    return (
      <LayoutWithSidebar>
        <Spinner />
      </LayoutWithSidebar>
    );

  const { caseCount } = count;
  return (
    <WithError isShow={!!error}>
      <LayoutWithSidebar>
        <Dashboard
          jobs={data?.requestedJobs}
          caseCount={caseCount?.warrantyCasesCount}
          notPaidJobsCount={caseCount?.notPaidJobsCount}
          upcomingJobsCount={caseCount?.upcomingJobsCount}
        />
      </LayoutWithSidebar>
    </WithError>
  );
};

// DashboardPage.propTypes = {};

// DashboardPage.defaultProps = {};
export default DashboardPage;
