import React from 'react';
import PropTypes from 'prop-types';
import ReadView from './ReadView';
import FormView from './FormView';

const PlywoodSection = ({ isDeliveredPlywood, isDisable, ...props }) => {
  if (isDeliveredPlywood)
    return (
      <fieldset disabled>
        <ReadView {...props} />
      </fieldset>
    );

  return (
    <fieldset disabled={isDisable}>
      <FormView {...props} isDisable={isDisable} />
    </fieldset>
  );
};

PlywoodSection.propTypes = {
  isDeliveredPlywood: PropTypes.bool,
  isDisable: PropTypes.bool
};

PlywoodSection.defaultProps = {
  isDeliveredPlywood: false,
  isDisable: false
};

export default PlywoodSection;
