import gql from 'graphql-tag';

export const CHAT_TRANSCRIPTS = gql`
  query chatTranscripts($contactId: String!) {
    chatTranscripts(contactId: $contactId) {
      body
      createdAt
      status
      sfId
    }
  }
`;
