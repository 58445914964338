import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

export default class DonutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ initial: false });
    }, 10);
  }

  componentWillUnmount() {
    const { selected } = this.state;
    if (selected) {
      document.removeEventListener('click', this.onBlurClick);
    }
  }

  onBlurClick = () => {
    this.setState({
      selected: false,
    });
    document.removeEventListener('click', this.onBlurClick);
  };

  getRadius() {
    const { strokeWidth } = this.props;
    return this.getCenter() - strokeWidth / 2;
  }

  getCenter() {
    const { size } = this.props;
    return size / 2;
  }

  getTrackStyle() {
    const { strokeWidth } = this.props;
    return {
      strokeWidth: strokeWidth - 4,
    };
  }

  getCircumferecne = () => 2 * Math.PI * this.getRadius();

  getStrokeValue = (item) => {
    const { initial } = this.state;
    const value = () => (initial ? 0 : item.value);
    const circumference = 2 * Math.PI * this.getRadius();
    const fullValue = this.getFullValue();
    return (value / fullValue) * circumference;
  };

  getIndicatorStyle = (item) => {
    const { initial } = this.state;
    const { strokeWidth } = this.props;
    const circumference = this.getCircumferecne();
    const fullValue = this.getFullValue();
    const value = initial ? 0 : item.value;
    const strokeval = (value / fullValue) * circumference;
    const dashval = `${strokeval} ${circumference}`;
    return {
      strokeWidth,
      strokeDasharray: dashval,
      stroke: item.color,
    };
  };

  getFullValue = () => {
    const { series } = this.props;
    return series.reduce((acc, item) => acc + item.value, 0);
  };

  getRotation = (value) => (value / this.getFullValue()) * 360 - 90;

  handleCircleClick = (item) => {
    const { selected } = this.state;
    if (!selected) {
      this.setState({
        selected: item.name,
      });
      document.addEventListener('click', this.onBlurClick);
    }
  };

  render() {
    const { series, text, className, chartWidth, chartHeight } = this.props;

    const { selected } = this.state;
    const radius = this.getRadius();
    const center = this.getCenter();
    let valAccumulate = 0;
    return (
      <div className={[styles.chartWrapper, className].join(' ')}>
        <svg className={styles.chart} width={chartWidth} height={chartHeight}>
          <circle
            r={radius}
            cx={center}
            cy={center}
            className={styles.chartTrack}
            style={this.getTrackStyle()}
          />
          {series.map((item, index) => {
            const currentAccumValue = valAccumulate;
            valAccumulate += item.value;
            if (selected && item.name !== selected) {
              return null;
            }
            return (
              <circle
                key={item.name + index}
                r={radius}
                cx={center}
                cy={center}
                className={styles.chartIndicator}
                transform={`rotate(${this.getRotation(
                  currentAccumValue
                )} ${center},${center})`}
                style={this.getIndicatorStyle(item)}
                onClick={() => {
                  this.handleCircleClick(item);
                }}
              />
            );
          })}
        </svg>
        {text && <div className={styles.chartText}>{text}</div>}
      </div>
    );
  }
}

DonutChart.propTypes = {
  series: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  strokeWidth: PropTypes.number,
  size: PropTypes.number.isRequired,
  text: PropTypes.node,
  className: PropTypes.string,
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
};

DonutChart.defaultProps = {
  series: [],
  strokeWidth: 10,
  text: '',
  className: '',
  chartWidth: 280,
  chartHeight: 280,
};
