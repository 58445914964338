import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import UploadFileComponent from 'components/MyApplication/UploadApplicationFiles';

const W9Documents = ({
  myCertification: { w9 },
  handleChange,
  qualificationSfId,
  isOnBoarding,
  deleteUploadedFiles,
}) => {
  const { t } = useTranslation();
  return (
    <WrapperBlock
      tag="h2"
      title="W-9"
      isOnBoarding={isOnBoarding}
      isHasTooltip="true"
      toolTipText={t('myApplication.thirdTab.w_9.p_1', 'W-9 for current year')}
      isChecked={w9.docs_0.files.length > 0}
    >
      <UploadFileComponent
        accept=".jpeg, .png, .jpg, .pdf, .gif, .docx, .doc"
        maxCountDocument={5}
        uploadFiles={w9.docs_0.files}
        buttonText={t('uploadButton', 'Upload file(s)')}
        handleChange={(event) => handleChange(`myCertification.w9.docs_0`, event)}
        name="files"
        parentId={qualificationSfId}
        deleteUploadedFiles={deleteUploadedFiles}
      />
    </WrapperBlock>
  );
};
W9Documents.propTypes = {
  myCertification: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  deleteUploadedFiles: PropTypes.func.isRequired,
  qualificationSfId: PropTypes.array,
  isOnBoarding: PropTypes.bool,
};
W9Documents.defaultProps = {
  qualificationSfId: [],
  isOnBoarding: false,
};

export default W9Documents;
