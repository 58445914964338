import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import Modal from '../../../components/shared/modals/FormModal';
import FormHeader from '../../../components/Form/FormHeader';
import { FormContent } from '../../../components/shared/Form';
import formStyles from '../../../components/Auth/style.module.css';
import Button from '../../../components/shared/Button';
import { GET_MEMBERS } from '../../../graphql/queries/members';

const DeleteMemberModal = ({
  isShow,
  handleClose,
  data,
  handleSubmit: handleSubmitDelete,
  loading,
  resetSelectedEntry,
}) => {
  const _handleSubmit = async (values) => {
    await handleSubmitDelete({
      variables: { pgId: values.pgId },
      update: (proxy) => {
        const { members } = proxy.readQuery({ query: GET_MEMBERS });

        const updatedMemberId = members.findIndex((m) => m.pgId === values.pgId);
        const updatedMembers = [
          ...members.slice(0, updatedMemberId),
          ...members.slice(updatedMemberId + 1),
        ];

        proxy.writeQuery({
          query: GET_MEMBERS,
          data: { members: updatedMembers },
        });
        handleClose();
        resetSelectedEntry();
      },
    });
  };
  const { t } = useTranslation();
  return (
    <Formik initialValues={data} onSubmit={_handleSubmit}>
      {({ values, handleSubmit }) => {
        return (
          <form>
            <Modal
              isShow={isShow}
              className={styles.modalWrapper}
              title={t('members.delete.title', 'Delete Contact')}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            >
              <FormHeader />
              <FormContent>
                <div className={formStyles.formFooter}>
                  {`${t('members.delete.question', 'Are you sure you want to delete')} ${
                    values.firstName
                  } ${values.lastName}?`}
                </div>
                <div className={styles.deleteFormButtons}>
                  <Button
                    size="medium"
                    handleClick={handleSubmit}
                    disabled={loading}
                    className={styles.deleteFormButton}
                  >
                    {t('members.delete.deleteButton', 'Delete')}
                  </Button>
                  <Button
                    size="medium"
                    color="white"
                    handleClick={() => {
                      resetSelectedEntry();
                      handleClose();
                    }}
                    className={styles.deleteFormButton}
                  >
                    {t('cancelButton', 'Cancel')}
                  </Button>
                </div>
              </FormContent>
            </Modal>
          </form>
        );
      }}
    </Formik>
  );
};

export default DeleteMemberModal;

DeleteMemberModal.propTypes = {
  data: PropTypes.shape({
    pgId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  resetSelectedEntry: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isShow: PropTypes.bool,
  loading: PropTypes.bool,
};

DeleteMemberModal.defaultProps = {
  isShow: false,
  loading: true,
};
