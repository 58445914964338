import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Spinner from 'components/shared/Spinner';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import WithError from './withError';

export default (WrappedComponent, wrapperProps) => {
  return (props) => {
    const { loading, data, error } = useQuery(wrapperProps.query, {
      variables: wrapperProps.variables || {},
    });

    if (loading)
      return (
        <LayoutWithSidebar>
          <Spinner />
        </LayoutWithSidebar>
      );

    return (
      <WithError isShow={!!error}>
        <WrappedComponent {...props} data={data || {}} />
      </WithError>
    );
  };
};
