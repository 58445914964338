import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useCaseTypeLocal, useCaseStatusLocal } from 'localization';
import Text from 'components/shared/Text';
import { formatDate } from 'utils/dateFormatters';
import { withBracketsNumber } from 'utils/phoneNumberFormatter';
import { shortAddress } from 'utils/shortAddress';
import { caseStatus } from 'enums';
import styles from './styles.module.css';

const WarrantyTableCard = ({
  caseNumber,
  account,
  dateOfInstall,
  contact,
  type,
  status,
  handleShowDetails,
}) => {
  const { t } = useTranslation();
  const caseTypeLocal = useCaseTypeLocal();
  const caseStatusLocal = useCaseStatusLocal();
  return (
    <div className={[styles.projectCardWrapper].join(' ')}>
      <div onClick={handleShowDetails}>
        <div className={styles.projectCardHeader}>
          <Text color="#000" size="s16" lineHeight="h20" weight="wb" tag="p">
            {caseNumber}
          </Text>
        </div>
        <div>
          {dateOfInstall && (
            <div>
              <Text color="#767676" tag="p" size="s14" lineHeight="h18">
                {t('warranty.mapCard.date', 'Repair Date:')} {formatDate(dateOfInstall)}
              </Text>
            </div>
          )}
          <div>
            <Text color="#767676" tag="p" size="s14" lineHeight="h18">
              {t('warranty.mapCard.address', 'Address')}: {shortAddress(account.address)}
            </Text>
          </div>
          <div>
            <Text color="#000" tag="p" size="s14" lineHeight="h18">
              {t('warranty.mapCard.contact', 'Contact:')} {contact.name},{' '}
              {withBracketsNumber(contact.phone)}
            </Text>
          </div>
          {type && type !== '' && (
            <div>
              <Text color="#000" tag="p" size="s14" lineHeight="h18">
                {t('warranty.mapCard.type', 'Case type:')} {caseTypeLocal[type]}
              </Text>
            </div>
          )}
          <div>
            <span
              className={[
                styles.circle,
                status === caseStatus.new ? styles.circleGreen : styles.circleYellow,
              ].join(' ')}
            />
            {caseStatusLocal[status]}
          </div>
        </div>
      </div>
    </div>
  );
};

WarrantyTableCard.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  account: PropTypes.object.isRequired,
  dateOfInstall: PropTypes.string.isRequired,
  contact: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  handleShowDetails: PropTypes.func.isRequired,
};

WarrantyTableCard.defaultProps = {};

export default WarrantyTableCard;
