import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { RESCHEDULE_JOB } from 'graphql/mutations/opportunity';
import { Formik } from 'formik';
import Field from 'components/shared/Field';
import { FormColumn } from 'components/shared/Form';
import { ModalFooterButtons } from 'components/shared/modals/FormModal';
import TextArea from 'components/shared/TextArea';
import { updateCurrentJobList } from 'graphql/resolvers/opportunity/cache';
import { rescheduleJobSchema as validationSchema } from 'validation/jobs/rescheduleJob';
import styles from '../style.module.css';
import { useTopicOptions } from './reasonOptions';

const RescheduleJobModal = ({ handleClose, id }) => {
  const [rescheduleJob, { loading }] = useMutation(RESCHEDULE_JOB);
  const { t } = useTranslation();
  const topicOptions = useTopicOptions();

  const handleSubmitForm = (values) => {
    rescheduleJob({
      variables: {
        comment: values.comment,
        topic: values.topic,
        id,
      },
      update: (_, { data: { rescheduleWorkOrder } }) => {
        updateCurrentJobList(
          id,
          { status: rescheduleWorkOrder.status },
          { isAwarded: true }
        );
        handleClose();
      },
    });
  };

  return (
    <Formik
      initialValues={{
        topic: '',
        comment: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {({ values, handleSubmit, handleChange, errors, touched }) => (
        <fieldset disabled={loading}>
          <FormColumn>
            <Field
              type="select"
              label={t('rescheduleModal.selectLabel', 'Select a topic')}
              name="topic"
              value={values.topic}
              onChange={handleChange}
              isError={touched.topic && Boolean(errors.topic)}
              options={topicOptions}
            />
          </FormColumn>
          <FormColumn>
            <div className={styles.wrapperTextarea}>
              <TextArea
                name="comment"
                placeholder={t('messagePlaceholder', 'Your message...')}
                label={t('messageLabel', 'Add a comment')}
                value={values.comment}
                handleChange={handleChange}
                isError={touched.comment && Boolean(errors.comment)}
              />
            </div>
          </FormColumn>

          <ModalFooterButtons handleClose={handleClose} handleSubmit={handleSubmit} />
        </fieldset>
      )}
    </Formik>
  );
};

RescheduleJobModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

RescheduleJobModal.defaultProps = {
  id: '',
};

export default RescheduleJobModal;
