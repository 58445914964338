import { array, boolean, number, object, string } from 'yup';
import { fromObjectToArray } from 'utils/application/restructureData/restructureCertificationData';
import { isUniquePolicyNumber } from 'utils/application/uniquePolicyNumbers';
import { qualificationTypes } from 'enums';
import { emailRegex } from '../emailRegex';

export const submitValidationSchema = object().shape({
  myAccount: object().shape({
    companyName: string().required(),
    companyNameDBA: string(),
    organization: string().required(),
    ownerFirstName: string().required().trim(),
    ownerLastName: string().required().trim(),
    companyAddress: string().required(),
    city: string().required(),
    zipCode: string()
      .required()
      .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/, {
        message: 'Please enter valid zipcode',
        excludeEmptyString: true,
      }),
    state: string().required(),
    suiteNumber: string().trim(),
    companyEmailAddress: string().required().matches(emailRegex).trim(),
    companyPhoneNumber: string()
      .required()
      .matches(/^\d{10,10}$/, {
        message: 'Please enter valid phone number',
        excludeEmptyString: true,
      }),
    taxID: string()
      .matches(/^[0-9]{9}$/, 'Must be exactly 9 digits')
      .required()
      .trim(),
    registrationState: string().required(),
    yearCompanyStarted: string().required(),
    dunn: string(),
  }),
  myApplication: object()
    .shape({
      restorationVSRetail: number().required(),
      residentialVSCommercial: number().required(),
      shingle: number().required(),
      metal: number().required(),
      tile: number().required(),
      modifiedBitumenResidential: number().required(),
      definedWorkAreas: object()
        .shape({
          states: array().min(0).required(),
          counties: array().min(0),
          cities: array().min(0),
        })
        .test(
          'dwa',
          'It should be required to select at least one state',
          (dwa) => Object.values(dwa).flat().length > 0
        ),
      builtUpMetal: number().required(),
      modifiedBitumenCommercial: number().required(),
      thermoset: number().required(),
      thermoplastic: number().required(),
      serviceOffered: string(),
      numberCrews: number().required(),
      numberLaborers: number().required(),
      crewAveragePerDay: number().required(),
      onsiteManagementStructure: string().required(),
      onsiteManagementDays: string(),
      hasDumpTrailer: boolean().required(),
      staffedPullPermits: boolean().required(),
      preferredDistributor: string().required(),
      preferredManufacturer1: string().required(),
      preferredManufacturer2: string(),
      preferredManufacturer3: string(),
      preferredManufacturerOther: string()
    })
    .test('sum1', 'Total should be 100', (parent) => {
      const { shingle, metal, tile, modifiedBitumenResidential } = parent;
      const sum = shingle + metal + tile + modifiedBitumenResidential;
      return sum === 100 || sum === 0;
    })
    .test('sum2', 'Total should be 100', (parent) => {
      const {
        builtUpMetal,
        modifiedBitumenCommercial,
        thermoset,
        thermoplastic,
      } = parent;
      const sum = builtUpMetal + modifiedBitumenCommercial + thermoset + thermoplastic;
      return sum === 100 || sum === 0;
    }),
  myCertification: object()
    .test(
      'checkNotLicenseNumber',
      { message: 'Each not license number should be unique' },
      (parent) => {
        const { arrayOfPolicyNumbers, uniquePolicyNumbers } = isUniquePolicyNumber(
          parent,
          [qualificationTypes.gl, qualificationTypes.wc]
        );

        return arrayOfPolicyNumbers.length === uniquePolicyNumbers.length;
      }
    )
    .test(
      'checkLicenseNumber',
      { message: 'Each license number should be unique' },
      (parent) => {
        const { arrayOfPolicyNumbers, uniquePolicyNumbers } = isUniquePolicyNumber(
          parent,
          [qualificationTypes.lc]
        );

        return arrayOfPolicyNumbers.length === uniquePolicyNumbers.length;
      }
    ),
});

export const saveDraftValidationSchema = object().shape({
  myAccount: object().shape({
    companyName: string(),
    companyNameDBA: string(),
    organization: string(),
    ownerFirstName: string().trim(),
    ownerLastName: string().trim(),
    companyAddress: string(),
    city: string(),
    zipCode: string().matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/, {
      message: 'Please enter valid zipcode',
      excludeEmptyString: true,
    }),
    state: string(),
    suiteNumber: string(),
    companyEmailAddress: string().matches(emailRegex).trim(),
    companyPhoneNumber: string().matches(/^\d{10,10}$/, {
      message: 'Please enter valid phone number',
      excludeEmptyString: true,
    }),
    taxID: string()
      .matches(/^[0-9]{9}$/, 'Must be exactly 9 digits')
      .trim(),
    registrationState: string(),
    yearCompanyStarted: string().nullable(),
    dunn: string(),
  }),
  myApplication: object()
    .shape({
      restorationVSRetail: number(),
      residentialVSCommercial: number(),

      definedWorkAreas: object().shape({
        states: array().min(0),
        counties: array().min(0),
        cities: array().min(0),
      }),

      shingle: number(),
      metal: number(),
      tile: number(),
      modifiedBitumenResidential: number(),

      builtUpMetal: number(),
      modifiedBitumenCommercial: number(),
      thermoset: number(),
      thermoplastic: number(),

      serviceOffered: string(),
      numberCrews: number(),
      numberLaborers: number(),
      crewAveragePerDay: number(),
      onsiteManagementStructure: string(),
      onsiteManagementDays: string(),
      hasDumpTrailer: boolean().nullable(),
      staffedPullPermits: boolean().nullable(),
    })
    .test('sum1', 'Total should be 100', (parent) => {
      const { shingle, metal, tile, modifiedBitumenResidential } = parent;
      const sum = shingle + metal + tile + modifiedBitumenResidential;
      return sum === 100 || sum === 0;
    })
    .test('sum2', 'Total should be 100', (parent) => {
      const {
        builtUpMetal,
        modifiedBitumenCommercial,
        thermoset,
        thermoplastic,
      } = parent;
      const sum = builtUpMetal + modifiedBitumenCommercial + thermoset + thermoplastic;
      return sum === 100 || sum === 0;
    }),
  myCertification: object()
    .test('checkNumber', { message: 'Policy number should be provided' }, (parent) => {
      const arrayOfBool = fromObjectToArray(parent).map((item) => {
        if (item.type === qualificationTypes.w9) return true;
        if (item.type === qualificationTypes.lc) return true;
        return !(item.files.length && !item.policyNumber);
      });
      return !arrayOfBool.includes(false);
    })
    .test('checkOther', { message: 'Other issue should be provided' }, (parent) => {
      const arrayOfBool = fromObjectToArray(parent).map((item) => {
        if (
          item.type === qualificationTypes.lc &&
          item.carrier === 'Other' &&
          !item.otherIssuer
        ) {
          return false;
        }
        return !(
          item.type === qualificationTypes.lc &&
          item.files.length &&
          !item.policyNumber
        );
      });
      return !arrayOfBool.includes(false);
    })
    .test(
      'checkNotLicenseNumber',
      { message: 'Each not license number should be unique' },
      (parent) => {
        const { arrayOfPolicyNumbers, uniquePolicyNumbers } = isUniquePolicyNumber(
          parent,
          [qualificationTypes.gl, qualificationTypes.wc]
        );

        return arrayOfPolicyNumbers.length === uniquePolicyNumbers.length;
      }
    )
    .test(
      'checkLicenseNumber',
      { message: 'Each license number should be unique' },
      (parent) => {
        const { arrayOfPolicyNumbers, uniquePolicyNumbers } = isUniquePolicyNumber(
          parent,
          [qualificationTypes.lc]
        );

        return arrayOfPolicyNumbers.length === uniquePolicyNumbers.length;
      }
    ),
});
