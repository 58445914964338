import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import Datepicker from 'components/shared/Datepicker';
import WrapperBlock from 'components/shared/WrapperBlock';
import LanguageContext from 'context/LanguageContext';
import {
  FormColumn as Column,
  FormColumn as Col,
  FormRow as Row,
} from 'components/shared/Form';
import InputMask from 'react-input-mask';
import Field from 'components/shared/Field';
import { useOrganizations, roles, states } from 'enums';
import useError from 'services/hooks/useErrors/useErrorForms';
import { isAllowAction } from 'services/permissions';
import AuthRoleContext from 'context/AuthRoleContext';
import styles from '../style.module.css';

const Details = ({
  values: { organization },
  errors,
  touched,
  handleChange,
  isReadOnlyInput,
}) => {
  const userRole = useContext(AuthRoleContext);

  useError(errors, touched, 'companyDetails');
  const organizations = useOrganizations();

  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  return (
    <div className={styles.projectTabWrapper}>
      <div className={styles.projectFormBanner}>
        <WrapperBlock
          title={t('companyProfile.details.headerTitle', 'Organization')}
          tag="h2"
          isForm
          isContractorsStyle
        >
          <div className={styles.projectFormContent}>
            <Row>
              <Col>
                <Field
                  name="companyName"
                  label={t('companyProfile.details.legalLabel', 'Company Name (Legal)')}
                  type="text"
                  value={organization.companyName}
                  onChange={handleChange}
                  isError={
                    touched.organization?.companyName &&
                    Boolean(errors.organization?.companyName)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
              <Col>
                <Field
                  name="companyNameDBA"
                  label={t('companyProfile.details.DBAlLabel', 'Company Name (DBA)')}
                  type="text"
                  value={organization.companyNameDBA}
                  onChange={handleChange}
                  isError={
                    touched.organization?.companyNameDBA &&
                    Boolean(errors.organization?.companyNameDBA)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="organization"
                  label={t('companyProfile.details.typeLabel', 'Company Type:')}
                  type="select"
                  options={organizations}
                  value={organization.organization}
                  isError={
                    touched.organization?.organization &&
                    Boolean(errors.organization?.organization)
                  }
                  onChange={handleChange}
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
            </Row>
            {isAllowAction([roles.admin, roles.finance], userRole) && (
              <Row>
                <Column>
                  <Field
                    label={t(
                      'companyProfile.details.firstLabel',
                      'Registered Agent First Name'
                    )}
                    type="text"
                    inputMode="text"
                    name="ownerFirstName"
                    value={organization.ownerFirstName}
                    onChange={handleChange}
                    isError={
                      touched.organization?.ownerFirstName &&
                      Boolean(errors.organization?.ownerFirstName)
                    }
                    isReadOnly={isReadOnlyInput}
                  />
                </Column>
                <Column>
                  <Field
                    label={t(
                      'companyProfile.details.lastLabel',
                      'Registered Agent Last Name'
                    )}
                    type="text"
                    inputMode="text"
                    name="ownerLastName"
                    value={organization.ownerLastName}
                    onChange={handleChange}
                    isError={
                      touched.organization?.ownerLastName &&
                      Boolean(errors.organization?.ownerLastName)
                    }
                    isReadOnly={isReadOnlyInput}
                  />
                </Column>
              </Row>
            )}

            <Row>
              <Column>
                <Field
                  label={t('companyProfile.details.addressLabel', 'Company Address')}
                  type="text"
                  inputMode="text"
                  name="address"
                  value={organization.address}
                  onChange={handleChange}
                  isError={
                    touched.organization?.address && Boolean(errors.organization?.address)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Column>
              <Column>
                <Field
                  label={t('companyProfile.details.cityLabel', 'City')}
                  type="text"
                  inputMode="text"
                  name="city"
                  value={organization.city}
                  onChange={handleChange}
                  isError={
                    touched.organization?.city && Boolean(errors.organization?.city)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Field
                  label={t('companyProfile.details.zipLabel', 'Zip Code')}
                  type="text"
                  inputMode="text"
                  name="zipCode"
                  value={organization.zipCode}
                  onChange={handleChange}
                  isError={
                    touched.organization?.zipCode && Boolean(errors.organization?.zipCode)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Column>
              <Column>
                <Field
                  label={t('companyProfile.details.stateLabel', 'State')}
                  type="select"
                  name="state"
                  value={organization.state}
                  onChange={handleChange}
                  options={states}
                  isError={
                    touched.organization?.state &&
                    Boolean(errors.organization?.state) &&
                    organization.state
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Field
                  label={t('companyProfile.details.suiteLabel', 'Suite #')}
                  type="text"
                  name="suiteNumber"
                  value={organization.suiteNumber}
                  onChange={handleChange}
                  isError={
                    touched.organization?.suiteNumber &&
                    Boolean(errors.organization?.suiteNumber)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Column>
            </Row>
            <Row>
              <Col>
                <Field
                  label={t('companyProfile.details.emailLabel', 'Primary Email Address')}
                  type="email"
                  inputMode="email"
                  name="emailAddress"
                  value={organization.emailAddress}
                  onChange={handleChange}
                  isError={
                    touched.organization?.emailAddress &&
                    Boolean(errors.organization?.emailAddress)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
              <Col>
                <InputMask
                  mask="(999) 999-9999"
                  value={organization.companyPhoneNumber}
                  onChange={({ target }) => {
                    handleChange({
                      target: {
                        name: target.name,
                        value: target.value.replace(/\D/g, ''),
                      },
                    });
                  }}
                >
                  {() => (
                    <Field
                      label={t(
                        'companyProfile.details.phoneLabel',
                        'Company Phone Number'
                      )}
                      type="tel"
                      inputMode="tel"
                      autoComplete="tel"
                      name="companyPhoneNumber"
                      isError={
                        touched.organization?.companyPhoneNumber &&
                        Boolean(errors.organization?.companyPhoneNumber)
                      }
                      isReadOnly={isReadOnlyInput}
                    />
                  )}
                </InputMask>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className={styles.dateContainer}>
                  <label className={styles.label}>
                    {t('myApplication.thirdTab.license.dateLabel', 'Expiration Date')}
                  </label>
                  <Datepicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isMyApplication
                    value={organization.yearCompanyStarted}
                    locale={language}
                    name="yearCompanyStarted"
                    handleChange={(date) => {
                      handleChange({
                        target: {
                          value: moment(date).format('MM/DD/YYYY'),
                          name: 'yearCompanyStarted',
                        },
                      });
                    }}
                    customDatePickerClass={styles.datepickerInput}
                    isDisabled={isReadOnlyInput}
                  />
                </div>
              </Col>
              <Col>
                <Field
                  name="taxID"
                  label={t('companyProfile.details.taxLabel', 'Tax ID')}
                  type="text"
                  value={organization.taxID}
                  onChange={handleChange}
                  isError={
                    touched.organization?.taxID && Boolean(errors.organization?.taxID)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Field
                  label={t(
                    'companyProfile.details.businessLabel',
                    'Business Registration State'
                  )}
                  type="select"
                  name="registrationState"
                  value={organization.registrationState}
                  onChange={handleChange}
                  options={states}
                  isError={
                    touched.organization?.registrationState &&
                    Boolean(errors.organization?.registrationState)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
              <Col>
                <Field
                  name="dunnBradstreetNumber"
                  label={t('companyProfile.details.dunnLabel', 'Dunn & Bradstreet #')}
                  type="text"
                  value={organization.dunnBradstreetNumber}
                  onChange={handleChange}
                  isError={
                    touched.organization?.dunnBradstreetNumber &&
                    Boolean(errors.organization?.dunnBradstreetNumber)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="website"
                  label={t('companyProfile.details.websiteLabel', 'Website')}
                  type="text"
                  value={organization.website}
                  onChange={handleChange}
                  isError={
                    touched.organization?.website && Boolean(errors.organization?.website)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
              <Col>
                <Field
                  name="linkedin"
                  label="Linkedin"
                  type="text"
                  value={organization.linkedin}
                  onChange={handleChange}
                  isError={
                    touched.organization?.linkedin &&
                    Boolean(errors.organization?.linkedin)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="facebook"
                  label="Facebook"
                  type="text"
                  value={organization.facebook}
                  onChange={handleChange}
                  isError={
                    touched.organization?.facebook &&
                    Boolean(errors.organization?.facebook)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
              <Col>
                <Field
                  name="twitter"
                  label="Twitter"
                  type="text"
                  value={organization.twitter}
                  onChange={handleChange}
                  isError={
                    touched.organization?.twitter && Boolean(errors.organization?.twitter)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
            </Row>
          </div>
        </WrapperBlock>
      </div>
    </div>
  );
};
Details.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  isReadOnlyInput: PropTypes.bool,
};
Details.defaultProps = {
  isReadOnlyInput: false,
};

export default Details;
