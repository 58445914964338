import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TableMapViewLayout from 'layouts/TableMapView';
import { WARRANTY_OPPORTUNITIES } from 'graphql/queries/opportunities';
import { useQuery } from '@apollo/react-hooks';
import { GET_MEMBERS } from 'graphql/queries/members';
import Spinner from 'components/shared/Spinner';
import MobileContext from 'context/MobileContext';
import tableColumns from './columns';
import tableStyles from '../tableStyles.module.css';
import TableCardMobile from './TableCardMobile';

const WarrantyJobsTab = ({ history, userCurrentRole, ...props }) => {
  const isMobile = useContext(MobileContext);
  const { data: loadedData, loading, fetchMore } = useQuery(WARRANTY_OPPORTUNITIES, {
    variables: {
      offset: 0,
      limit: 100,
    },
  });
  const { data: membersList } = useQuery(GET_MEMBERS);

  const { t } = useTranslation();

  const locations = useMemo(
    () =>
      loadedData?.workOrders.map((object) => ({
        id: object.sfId,
        ...object.opportunity.location,
      })),
    [loadedData]
  );

  const columnTitles = {
    jobReference: t('jobs.warranty.table.refTitle', 'Job Reference'),
    installDate: t('jobs.warranty.table.dateTitle', 'Date of install'),
    address: t('jobs.warranty.table.addressTitle', 'Address'),
    name: t('jobs.warranty.table.nameTitle', 'Contact Name'),
    phone: t('jobs.warranty.table.phoneTitle', 'Contact Phone'),
    repair: t('jobs.warranty.table.repairTitle', '# Total Repair Cases'),
    open: t('jobs.warranty.table.openTitle', '# Open Cases'),
  };

  if (loading) return <Spinner absolute />;

  const handleShowDetails = ({ sfId }) => {
    history.push(`/jobs/warranty/${sfId}`);
  };

  const loadMore = () => {
    fetchMore({
      variables: {
        offset: loadedData?.workOrders.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({ }, prev, {
          workOrders: [...prev.workOrders, ...fetchMoreResult?.workOrders],
        });
      },
    });
  }

  return (
    <TableMapViewLayout
      {...props}
      onLoadMore={loadMore}
      searchValues={['reference', 'opportunity.account.address']}
      dataList={loadedData?.workOrders}
      mapOptions={{
        centerLocation: { lat: locations?.[0]?.lat, lng: locations?.[0]?.lng },
        zoomLevel: 10,
        locations,
      }}
      tableColumns={tableColumns({
        handleClickColumn: handleShowDetails,
        isMobile,
        columnTitles,
      })}
      searchPlaceholder={t(
        'jobs.warranty.searchPlaceholder',
        'Search by reference or address'
      )}
      emptyPageText={t(
        'jobs.warranty.emptyPage',
        'Currently, you do not have any warranty jobs.'
      )}
      tableOptions={{
        mobileTableRowClassName: [
          tableStyles.mobileTableRow,
          tableStyles.mobileTableRowVertical,
        ].join(' '),
        mobileContent: ({ data }, index) => {
          return (
            <TableCardMobile
              {...data}
              key={index}
              location={`${data.opportunity.account?.name}, ${data.opportunity.account?.shortAddress}`}
              warrantyCases={data.opportunity.warrantyCases}
              primaryContact={data.opportunity.contacts[0]}
              handleClick={() => handleShowDetails(data)}
              handleSelect={() => handleShowDetails(data)}
            />
          );
        },
      }}
      membersList={membersList?.members}
      userCurrentRole={userCurrentRole}
    >
      {({ handleSelectLocation, locations: selectedLocations, data }) => {
        return (
          <>
            {data.map((project, index) => (
              <TableCardMobile
                {...project}
                key={index}
                className={[
                  tableStyles.mobileTableRow,
                  tableStyles.mobileTableRowHorizontal,
                ].join(' ')}
                location={project.opportunity.account?.address}
                warrantyCases={project.opportunity.warrantyCases}
                handleClick={() => handleShowDetails(project)}
                handleSelect={(value) =>
                  handleSelectLocation(
                    {
                      id: project.sfId,
                      ...project.opportunity.location,
                    },
                    value
                  )
                }
                isSelected={selectedLocations.find(
                  ({ id, isSelected }) => id === project.sfId && isSelected
                )}
              />
            ))}
          </>
        );
      }}
    </TableMapViewLayout>
  );
};

WarrantyJobsTab.propTypes = {
  history: PropTypes.object.isRequired,
  userCurrentRole: PropTypes.string.isRequired,
  data: PropTypes.object,
};
WarrantyJobsTab.defaultProps = {
  data: {},
};
export default WarrantyJobsTab;
