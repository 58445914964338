import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const pages = (shortList, activePage, onChosenPage) => {
  if (shortList.length) {
    return (
      <div>
        {shortList.map((page) => (
          <span
            key={page}
            onClick={() => {
              onChosenPage(page);
            }}
            className={`${styles.pageNumber} ${page === activePage && styles.activePage}`}
          >
            {page}
          </span>
        ))}
      </div>
    );
  }

  return <></>;
};

const PageCounter = ({ activePage, pagesCount, gotoPage }) => {
  const stateLeftArrow = activePage !== 1 ? styles.able : styles.disable;
  const stateRightArrow = activePage !== pagesCount ? styles.able : styles.disable;

  const onPrevPage = () => {
    if (activePage > 1 && pagesCount !== 1) gotoPage(activePage - 1);
  };
  const onNextPage = () => {
    if (activePage < pagesCount) gotoPage(activePage + 1);
  };

  const { t } = useTranslation();
  console.log('pagesCount', pagesCount);

  const list = Array.apply(1, { length: pagesCount + 1 }).map(Number.call, Number);

  list.shift();

  let shortList;
  let listStart;
  let listEnd;

  if (pagesCount <= 11) {
    listStart = 1;
    listEnd = pagesCount - 1;
    shortList = list.slice(listStart, listEnd);
  } else if (activePage <= 10) {
    listStart = 1;
    listEnd = pagesCount - (pagesCount - 10);
    shortList = list.slice(listStart, listEnd);
  } else if (activePage > pagesCount - 10) {
    listStart = pagesCount - 10;
    listEnd = pagesCount - 1;
    shortList = list.slice(listStart, listEnd);
  } else {
    listStart = activePage - 5;
    listEnd = activePage + 4;
    shortList = list.slice(listStart, listEnd);
  }

  const shouldHideSecondDots = listEnd + 1 === pagesCount;
  const shouldHideFirstDots = listStart === 1;
  return (
    <div className={styles.container}>
      <span onClick={onPrevPage} className={stateLeftArrow}>
        {t('prevButton', 'Prev')}
      </span>

      <div
        className={`${styles.pageNumber} ${activePage === 1 && styles.activePage}`}
        onClick={() => {
          gotoPage(1);
        }}
      >
        1
      </div>

      {shouldHideFirstDots ? null : <span className={stateRightArrow}>...</span>}

      <div className={styles.pages}>{pages(shortList, activePage, gotoPage)}</div>

      {shouldHideSecondDots ? null : <span className={stateRightArrow}>...</span>}

      <div
        className={`${styles.pageNumber} ${
          activePage === pagesCount && styles.activePage
        }`}
        onClick={() => {
          gotoPage(pagesCount);
        }}
      >
        {pagesCount}
      </div>

      <span onClick={onNextPage} className={stateRightArrow}>
        {t('nextButton', 'Next')}
      </span>
    </div>
  );
};

PageCounter.propTypes = {
  activePage: PropTypes.number,
  pagesCount: PropTypes.number,
  gotoPage: PropTypes.func,
};

PageCounter.defaultProps = {
  activePage: null,
  pagesCount: null,
  gotoPage: () => {},
};

export default PageCounter;
