import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Formik } from 'formik';
import {
  FormContent,
  FormColumn as Col,
  FormRow as Row,
  FormFooter,
} from 'components/shared/Form';
import InputMask from 'react-input-mask';
import formStyles from 'components/Auth/style.module.css';
import { addMemberSchema } from 'validation/members';
import { useRolesOptionsLocal } from 'localization';
import Modal from '../../../components/shared/modals/FormModal';
import Field from '../../../components/shared/Field';
import FormHeader from '../../../components/Form/FormHeader';
import Button from '../../../components/shared/Button';
import { GET_MEMBERS } from '../../../graphql/queries/members';
import styles from './style.module.css';
import Text from '../../../components/shared/Text';

const AddMemberModal = ({
  membersEmails,
  isShow,
  handleClose,
  handleSubmit: handleSubmitAdd,
  loading,
}) => {
  const [hasNonEniqueEmailError, setHasNonEniqueEmailError] = useState(false);
  const _handleSubmit = async (values, { resetForm }) => {
    await handleSubmitAdd({
      variables: { member: values },
      update: (proxy, { data: { createMember: member } }) => {
        const { members } = proxy.readQuery({ query: GET_MEMBERS });
        const updatedMembers = [...members, member];

        proxy.writeQuery({
          query: GET_MEMBERS,
          data: { members: updatedMembers },
        });
        handleClose();
        resetForm();
      },
    });
  };

  const handleChangeEmail = (e, setFieldValue) => {
    setFieldValue('email', e.target.value);
    setHasNonEniqueEmailError(membersEmails.includes(e.target.value));
  };
  const { t } = useTranslation();
  const rolesOptionsLocal = useRolesOptionsLocal();
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role: '',
      }}
      validationSchema={addMemberSchema}
      validateOnMount
      onSubmit={_handleSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        setFieldError,
        resetForm,
      }) => {
        const handleCloseForm = () => {
          handleClose();
          resetForm();
        };
        return (
          <form>
            <Modal
              className={styles.modalWrapper}
              isShow={isShow}
              title={t('members.add.title', 'Add New Contact')}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            >
              <FormHeader />
              <FormContent>
                <Row>
                  <Col>
                    <Field
                      type="text"
                      label={t('members.add.firstLabel', 'First Name')}
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isError={touched.firstName && Boolean(errors.firstName)}
                    />
                  </Col>

                  <Col>
                    <Field
                      type="text"
                      label={t('members.add.lastLabel', 'Last Name')}
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isError={touched.lastName && Boolean(errors.lastName)}
                    />
                  </Col>
                </Row>

                <Col>
                  <Field
                    label={t('members.add.emailLabel', 'Email Address')}
                    type="email"
                    inputMode="email"
                    autoComplete="email"
                    name="email"
                    value={values.email}
                    isError={touched.email && Boolean(errors.email)}
                    onBlur={handleBlur}
                    onChange={(e) => handleChangeEmail(e, setFieldValue)}
                  />
                  {hasNonEniqueEmailError ? (
                    <Text size="s12" lineHeight="h14" color="#CF4B4B">
                      {t('members.add.emailError', ' This email is already in use.')}
                    </Text>
                  ) : null}
                </Col>

                <Col>
                  <InputMask
                    mask="(999) 999-9999"
                    value={values.phone}
                    onChange={(e) => {
                      setFieldValue('phone', e.target.value.replace(/\D/g, ''));
                    }}
                    onBlur={handleBlur}
                  >
                    {() => (
                      <Field
                        label={t('members.add.mobileLabel', 'Mobile Number')}
                        type="tel"
                        inputMode="tel"
                        autoComplete="tel"
                        name="phone"
                        isError={touched.phone && Boolean(errors.phone)}
                      />
                    )}
                  </InputMask>
                </Col>
                <Col>
                  <Field
                    label={t('members.add.roleLabel', 'Role')}
                    type="select"
                    inputMode="search"
                    name="role"
                    value={values.role}
                    isError={touched.role && Boolean(errors.role)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={rolesOptionsLocal}
                  />
                </Col>
              </FormContent>
              <FormFooter>
                <div className={formStyles.formFooter}>
                  <Button
                    className={styles.button}
                    size="medium"
                    handleClick={handleSubmit}
                    disabled={Boolean(Object.keys(errors)?.length) || loading}
                  >
                    {t('members.add.createButton', 'Create Contact')}
                  </Button>
                  <Button size="medium" color="white" handleClick={handleCloseForm}>
                    {t('cancelButton', 'Cancel')}
                  </Button>
                </div>
              </FormFooter>
            </Modal>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddMemberModal;

AddMemberModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isShow: PropTypes.bool,
  loading: PropTypes.bool,
  membersEmails: PropTypes.arrayOf(PropTypes.string),
};

AddMemberModal.defaultProps = {
  isShow: false,
  loading: true,
  membersEmails: [],
};
