import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { object, array, string, number } from 'yup';
import Text from 'components/shared/Text';
import WrapperBlock from 'components/shared/WrapperBlock';
import Textarea from 'components/shared/TextArea';
import UploadFileArea from 'components/shared/UploadFile/container';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import { FormColumn, FormRow } from 'components/shared/Form';
import { toBase64UploadedFiles } from 'utils/toBase64File';
import styles from '../style.module.css';
import LeaveNoteButton from '../LeaveNoteButton';

const PlanksSectionFormView = ({ isDisable, noteText, handleSubmit: handleConfirm }) => {
  const [isShowComment, setIsShowComment] = useState(false);

  const handleSubmitPlywood = async ({ files, quantity, comment }) => {
    const convertedFiles = await toBase64UploadedFiles(files);
    handleConfirm({ files: convertedFiles, quantity, comment });
  };
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        files: [],
        isConfirmed: false,
        quantity: null,
        comment: '',
      }}
      validationSchema={object().shape({
        comment: string().trim(),
        quantity: number().min(0).required(),
        files: array().when('quantity', {
          is: (value) => value > 0,
          then: array().required().min(1),
        }),
      })}
      onSubmit={handleSubmitPlywood}
    >
      {({ handleChange, values, touched, errors, handleSubmit }) => (
        <WrapperBlock
          title={t('jobs.awarded.details.activity.planks.formView.title', 'Planks')}
          classNameMainWrapper={styles.wrapper}
          rightComponent={
            <LeaveNoteButton
              isDisable={isDisable}
              handleClick={() => {
                if (isShowComment)
                  handleChange({ target: { value: '', name: 'comment' } });
                setIsShowComment(!isShowComment);
              }}
            >
              {noteText}
            </LeaveNoteButton>
          }
        >
          <div className={styles.sectionContent}>
            {isShowComment && (
              <FormColumn>
                <Textarea
                  value={values.comment}
                  handleChange={handleChange}
                  name="comment"
                  placeholder={t('notePlaceholder', 'Leave a note...')}
                  maxLength={255}
                />
              </FormColumn>
            )}
            <Text
              color="#000000"
              size="s14"
              tag="h2"
              lineHeight="h19"
              className={styles.textNote}
            >
              {t(
                'jobs.awarded.details.activity.planks.formView.textNote',
                'Please specify the quantity of planks used during this job.'
              )}
            </Text>
            <div>
              <FormRow>
                <FormColumn>
                  <Field
                    type="number"
                    name="quantity"
                    value={values.quantity}
                    min={0}
                    label={t(
                      'jobs.awarded.details.activity.planks.formView.quantityLabel',
                      'Quantity'
                    )}
                    onChange={handleChange}
                    isError={touched.quantity && Boolean(errors.quantity)}
                  />
                </FormColumn>
              </FormRow>

              {values.quantity > 0 && (
                <FormColumn>
                  <div className={styles.label}>
                    <Text color="#837F7D" tag="p">
                      {t(
                        'jobs.awarded.details.activity.planks.formView.upload',
                        'Upload pics before if necessary.'
                      )}
                    </Text>
                  </div>
                  <UploadFileArea
                    buttonText={t('uploadButton', 'Upload file(s)')}
                    accept=".jpeg, .png, .jpg"
                    name="files"
                    uploadFiles={values.files}
                    handleChange={handleChange}
                    maxCountDocument={values.quantity}
                    isInvalid={touched.files && Boolean(errors.files)}
                  />
                </FormColumn>
              )}

              <Button
                size="medium"
                handleClick={handleSubmit}
                className={styles.buttonMobile}
              >
                {t('confirmButton', 'Confirm')}
              </Button>
            </div>
          </div>
        </WrapperBlock>
      )}
    </Formik>
  );
};

PlanksSectionFormView.propTypes = {
  isDisable: PropTypes.bool,
  noteText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

PlanksSectionFormView.defaultProps = {
  isDisable: false,
};

export default PlanksSectionFormView;
