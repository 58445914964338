import gql from 'graphql-tag';

export const UPDATE_APPOINTMENT_TIME_MUTATION = gql`
  mutation UPDATE_APPOINTMENT_TIME_MUTATION($sfId: String!, $repairDateTime: Date!) {
    updateAppointmentTime(sfId: $sfId, repairDateTime: $repairDateTime) {
      repairDateTime
    }
  }
`;

export const UPDATE_STATUS_MUTATION = gql`
  mutation UPDATE_STATUS_MUTATION($sfId: String!) {
    updateStatus(sfId: $sfId) {
      status
    }
  }
`;
