import React from 'react';
import { useTranslation } from 'react-i18next';
import { useJobStatusLocal } from 'localization';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import calendarIcon from 'assets/icons/calendar.svg';
import alertIcon from 'assets/icons/alert.svg';
import { formatDate } from 'utils/dateFormatters';
import colorStatus from 'utils/statusColor';
import getJobStatus from 'utils/workOrderCurrentStatus';
import styles from './styles.module.css';
import pageStyles from '../../style.module.css';

const ProjectCard = ({
  location,
  reference,
  dateOfInstall,
  status,
  isSelected,
  handleSelect,
  handleClick,
}) => {
  const { t } = useTranslation();
  const jobStatusLocal = useJobStatusLocal();
  return (
    <div
      className={[
        styles.projectCardWrapper,
        styles.blockCenter,
        isSelected ? styles.projectCardWrapperSelected : '',
      ].join(' ')}
    >
      <div onClick={() => handleSelect(!isSelected)}>
        <div className={styles.projectCardHeader}>
          <Text color="#000" size="s17" weight="wb">
            {reference}
          </Text>
        </div>
        <div className={styles.projectCardBody}>
          <div>
            <Icon src={calendarIcon} width="12px" className={styles.calendarIcon} />
            <Text color="#767676">
              {' '}
              {t('jobs.awarded.mapCard.date', 'Date of install:')}{' '}
              {formatDate(dateOfInstall)}
            </Text>
          </div>
          <div className={styles.textOverflow}>
            <Text color="#767676">{location}</Text>
          </div>
        </div>
        <div className={styles.projectCardFooter}>
          <div className={styles.buttonWrapper}>
            <span
              className={[
                pageStyles.circle,
                pageStyles[`circle${colorStatus(status)}`],
              ].join(' ')}
            />
            <Text color="#000" size="s14" weight="wm">
              {jobStatusLocal[status]}
            </Text>
          </div>
          <div onClick={handleClick}>
            <Icon src={alertIcon} width="12px" className={styles.infoIcon} />
            <Text color="#000"> {t('jobs.awarded.mapCard.details', 'Details')}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  location: PropTypes.string,
  reference: PropTypes.string.isRequired,
  dateOfInstall: PropTypes.string,
  isSelected: PropTypes.bool,
  status: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  handleSelect: PropTypes.func,
};

ProjectCard.defaultProps = {
  dateOfInstall: '',
  isSelected: false,
  location: '',
  handleClick: () => {},
  handleSelect: () => {},
};

export default ProjectCard;
