import React from 'react';
import PropTypes from 'prop-types';
import ReadView from './ReadView';
import FormView from './FormView';

const PlanksSection = ({ isDeliveredPlanks, isDisable, ...props }) => {
  if (isDeliveredPlanks)
    return (
      <fieldset disabled>
        <ReadView {...props} />
      </fieldset>
    );

  return (
    <fieldset disabled={isDisable}>
      <FormView {...props} isDisable={isDisable} />
    </fieldset>
  );
};

PlanksSection.propTypes = {
  isDeliveredPlanks: PropTypes.bool,
  isDisable: PropTypes.bool,
};

PlanksSection.defaultProps = {
  isDeliveredPlanks: false,
  isDisable: false,
};

export default PlanksSection;
