import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import {
  topValueErrorsMyAccount,
  topValueErrorsCompanyDetails,
  topValueErrorsCompanyCoverage,
} from 'enums/errors/topValueErrors';

const errorsObject = {
  myAccount: topValueErrorsMyAccount,
  companyDetails: topValueErrorsCompanyDetails,
  companyCoverage: topValueErrorsCompanyCoverage,
};

const useErrorMyAccount = (errors, touched, page) => {
  useEffect(() => {
    if (!isEmpty(errors) && !isEmpty(touched)) {
      const errorsTop = sortBy(
        Object.values(errors).map((key) => {
          const values = Object.keys(key);

          return errorsObject[page][values[0]];
        }),
        'weight'
      );
      window.scrollTo({ top: errorsTop[0]?.top, behavior: 'smooth' });
    }
  });
};

export default useErrorMyAccount;
