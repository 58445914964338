import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import { rightIcon, calendarIcon } from 'assets/icons';
import styles from './styles.module.css';

const JobsCard = ({
  address,
  phoneNumber,
  totalSQs,
  product,
  installationDate,
  handlePush,
  permitNumber,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.cardContainer} onClick={handlePush}>
      <div className={styles.titleContainer}>
        <Text tag="h2" size="s14" weight="wb" lineHeight="h19" color="#000000">
          {t('dashboard.jobsCard.title', 'Roof Project')}
        </Text>
        <div className={styles.iconContainer}>
          <Icon src={rightIcon} className={styles.icon} />
        </div>
      </div>
      <div className={styles.contactInfo}>
        <Text tag="h2" size="s12" weight="wr" lineHeight="h14" color="#767676">
          {address}
        </Text>
        <Text tag="h2" size="s12" weight="wr" lineHeight="h14" color="#767676">
          {phoneNumber}
        </Text>
      </div>
      <div className={styles.productContainer}>
        {totalSQs && (
          <Text tag="h2" size="s12" weight="wr" lineHeight="h14" color="#767676">
            {t('dashboard.jobsCard.sqs', 'Total SQs:')} {totalSQs}
          </Text>
        )}
        {product && (
          <Text tag="h2" size="s12" weight="wr" lineHeight="h14" color="#767676">
            {t('dashboard.jobsCard.product', 'Replacement Product:')} {product}
          </Text>
        )}
      </div>
      {permitNumber && (
        <div className={styles.permitNumber}>
          <Text tag="h2" size="s14" weight="wr" lineHeight="h16" color="#000000">
            {t('dashboard.jobsCard.permitNumber', 'Permit #:')} {permitNumber}
          </Text>
        </div>
      )}
      <div className={styles.instDateContainer}>
        <Icon src={calendarIcon} className={styles.dateIcon} />
        <Text tag="h2" size="s14" weight="wr" lineHeight="h16" color="#000000">
          {t('dashboard.jobsCard.installation', 'Roof Installation:')} {installationDate}
        </Text>
      </div>
    </div>
  );
};
JobsCard.propTypes = {
  address: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  product: PropTypes.string,
  installationDate: PropTypes.string.isRequired,
  totalSQs: PropTypes.number.isRequired,
  permitNumber: PropTypes.string,
  handlePush: PropTypes.func.isRequired,
};
JobsCard.defaultProps = {
  permitNumber: '',
  phoneNumber: '',
  product: '',
};
export default JobsCard;
