import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import IconSVG from 'components/shared/IconSVG';
import { editIcon, deleteIcon } from 'assets/icons';
import { useRolesLocal, useUserStatusLocal } from 'localization';
import styles from './style.module.css';

const TableMobile = ({ name, role, phone, email, status, handleEdit, handleDelete }) => {
  const rolesOptionsLocal = useRolesLocal();
  const userStatusLocal = useUserStatusLocal();
  return (
    <div className={styles.wrapper}>
      <div className={styles.memberBlock}>
        <div className={styles.leftBlock}>
          <Text
            weight="wb"
            lineHeight="h19"
            size="s14"
            color="#000000"
            tag="p"
            className={styles.text}
          >
            {name}
          </Text>
          <Text
            lineHeight="h19"
            size="s16"
            color="#000000"
            tag="p"
            className={[styles.text, styles.email].join(' ')}
          >
            {email}
          </Text>
          <Text
            lineHeight="h14"
            size="s14"
            color="#949494"
            tag="p"
            className={styles.text}
          >
            {rolesOptionsLocal[role]}
          </Text>
        </div>
        <div className={styles.rightBlock}>
          {status && (
            <Text
              lineHeight="h16"
              size="s14"
              color="#000000"
              tag="p"
              className={[
                styles.text,
                styles.status,
                status === 'Active' ? styles.statusActive : '',
              ].join(' ')}
            >
              {userStatusLocal[status]}
            </Text>
          )}

          <Text
            lineHeight="h14"
            size="s14"
            color="#949494"
            tag="p"
            className={styles.text}
          >
            {phone}
          </Text>
          <div className={styles.actionWrapper}>
            <IconSVG src={editIcon} handleClick={handleEdit} />
            <IconSVG src={deleteIcon} handleClick={handleDelete} />
          </div>
        </div>
      </div>
    </div>
  );
};
TableMobile.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
TableMobile.defaultProps = {};
export default TableMobile;
