import gql from 'graphql-tag';

import { Opportunity } from '../../fragments';

export const GET_JOBS = gql`
  query getJobs {
    requestedJobs {
      ...JobFragment
    }
  }
  ${Opportunity.fragments.jobEntry}
`;
