import gql from 'graphql-tag';

export const CLAIM_OPPORTUNITY = gql`
  mutation CLAIM_OPPORTUNITY($id: String!) {
    claimOpportunity(id: $id) {
      message
    }
  }
`;

export const ASSIGN_CREW_JOB = gql`
  mutation ASSIGN_CREW_JOB($contactIds: [String!]!, $id: String!) {
    assignContactsToWork(contactIds: $contactIds, workOrderId: $id) {
      name
      sfId
    }
  }
`;

export const CANCEL_JOB = gql`
  mutation CHANGE_STATUS_JOB($comment: String!, $id: String!) {
    cancelWorkOrder(comment: $comment, workOrderId: $id) {
      message
    }
  }
`;

export const RESCHEDULE_JOB = gql`
  mutation CHANGE_STATUS_JOB($comment: String!, $id: String!, $topic: String!) {
    rescheduleWorkOrder(comment: $comment, workOrderId: $id, topic: $topic) {
      status
    }
  }
`;

export const CHECK_IN_MATERIALS = gql`
  mutation confirmMaterials(
    $comment: String
    $uploadIds: [String!]
    $jobId: String!
    $files: [UploadedFile]
  ) {
    confirmMaterials(comment: $comment, workOrderId: $jobId) {
      sfId
      deliveredMaterialsComment
      status
    }
    uploadFiles(files: $files, parentId: $uploadIds, type: "materialFile") {
      name
      contentDocumentId
      contentType
      description
    }
  }
`;

export const CONFIRM_PLANKS_JOB = gql`
  mutation confirmPlanks(
    $comment: String
    $uploadIds: [String!]
    $jobId: String!
    $files: [UploadedFile]
    $quantity: Float!
  ) {
    confirmPlanks(comment: $comment, workOrderId: $jobId, quantity: $quantity) {
      sfId
      deliveredPlanksComment
    }
    uploadFiles(files: $files, parentId: $uploadIds, type: "plankFile") {
      name
      contentDocumentId
      contentType
      description
    }
  }
`;

export const CONFIRM_PLYWOOD_JOB = gql`
  mutation confirmPlywood(
    $comment: String
    $uploadIds: [String!]
    $jobId: String!
    $files: [UploadedFile]
    $quantity: Float!
  ) {
    confirmPlywood(comment: $comment, workOrderId: $jobId, quantity: $quantity) {
      sfId
      plywoodComment
    }
    uploadFiles(files: $files, parentId: $uploadIds, type: "plywoodFile") {
      name
      contentDocumentId
      contentType
      description
    }
  }
`;

export const CONFIRM_INSPECTION = gql`
  mutation confirmInspection($files: [UploadedFile!]!, $jobId: [String!], $type: String!) {
    confirmInspection(workOrderId: $jobId) {
      approvedInspectionUpload
    }
    uploadFiles(files: $files, parentId: $jobId, type: $type) {
      contentDocumentId
      name
      description
      contentType
    }
  }
`;

export const CONFIRM_PERMIT = gql`
  mutation confirmPermit($files: [UploadedFile!]!, $jobId: [String!], $type: String!) {
    confirmPermit(workOrderId: $jobId) {
      isPermitUpload
    }
    uploadFiles(files: $files, parentId: $jobId, type: $type) {
      contentDocumentId
      name
      description
      contentType
    }
  }
`;
export const CONFIRM_WORKFLOW_JOB = gql`
  mutation confirmWorkflow(
    $comment: String
    $uploadIds: [String!]
    $jobId: String!
    $status: String!
    $files: [UploadedFile]
  ) {
    workOrderStatus(comment: $comment, workOrderId: $jobId, status: $status) {
      status
      commentRollover
    }
    uploadFiles(files: $files, parentId: $uploadIds, type: "after") {
      name
      contentDocumentId
      contentType
      description
    }
  }
`;
