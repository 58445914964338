import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Text from 'components/shared/Text';
import { attachmentTypes } from 'enums';
import UploadFileArea from 'components/shared/UploadFile/container';
import styles from '../style.module.css';

const ReadViewPlanks = ({ files }) => {
  const { t } = useTranslation();
  const permitFiles = files.filter(
    ({ description }) => description === attachmentTypes.permitFile
  );
  return (
    <>
      <div className={styles.label}>
        <Text color="#837F7D" tag="p">
          {t('jobs.awarded.details.activity.planks.readView.uploaded', 'Uploaded pics')}
        </Text>
      </div>
      <UploadFileArea
        buttonText={t('uploadButton', 'Upload file(s)')}
        name="files"
        uploadFiles={permitFiles}
        handleChange={() => {}}
        maxCountDocument={5}
      />
    </>
  );
};

ReadViewPlanks.propTypes = {
  files: PropTypes.array,
};

ReadViewPlanks.defaultProps = {
  files: [],
};

export default ReadViewPlanks;
