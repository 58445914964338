import moment from 'moment';

const dateFormatDatabase = 'YYYY-MM-DD';

const daysFromNow = (date) => {
  const then = moment(date, dateFormatDatabase).startOf('day');
  const now = moment().startOf('day');

  return moment(then).diff(now, 'days');
};

export const diffHours = (hours) =>
  (new Date().getTime() - new Date().setHours(hours, 0, 0, 0)) / 3600000;

export default daysFromNow;
