import gql from 'graphql-tag';

const FullApplic = gql`
  fragment FullApplic on Application {
    companyName
    companyNameDBA
    organization
    ownerLastName
    ownerFirstName
    address
    ownerEmail
    companyPhoneNumber
    taxID
    registrationState
    yearCompanyStarted
    dunBradstreet
    suiteNumber

    residentialVSCommercial
    restorationVSRetail

    shingle
    tile
    metal
    modifiedBitumenResidential

    builtUpMetal
    modifiedBitumenCommercial
    thermoset
    thermoplastic

    definedWorkAreas {
      states {
        id
        name
        shortname
      }
      counties {
        id
        name
        state
      }
      cities {
        id
        name
        county
        state
      }
    }

    serviceOffered

    numberCrews
    numberLaborers
    crewAveragePerDay
    onsiteManagementStructure
    structure

    preferredDistributor
    preferredManufacturer1
    preferredManufacturer2
    preferredManufacturer3
    preferredManufacturerOther

    staffedPullPermits
    hasDumpTrailer

    contractorSfId
    contractorPgId
    accountPgId
    accountSfId
    sfId
    pgId

    messageApplicant
    applicationSubmittedAt
    phoneInterviewAt
    siteInspectionDate
    status
    seeDenied
  }
`;

export const Application = {
  fragments: {
    FullApplic,
  },
};
