import { parse, stringify } from 'query-string';

export const parseQuery = (query) => {
  return parse(query);
};

export const stringifyQuery = (object) => {
  const queryString = stringify(object, { skipNull: true, skipEmptyString: true });
  return queryString ? `?${queryString}` : '';
};
