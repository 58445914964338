import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import WrapperBlock from 'components/shared/WrapperBlock';
import MessagesTab from 'components/common/MessagesTab';
import MobileTabs from 'components/shared/Tabs/Mobile';
import DesktopTabs from 'components/shared/Tabs/Desktop';
import SubHeader from 'components/shared/NavigationSubHeader';
import { useDetLang } from 'services/localization';
import MobileContext from '../../context/MobileContext';
import useTabIndex from '../../services/hooks/useTabIndex';
import LayoutWithSidebar from '../../layouts/LayoutWithSidebar';
import DescriptionSection from './DescriptionSection';
import InformationsSection from './InformationsSection';
import Button from '../../components/shared/Button';
import Document from './Documents';
import { isCompletedWarrantyCase } from '../../utils/isCompletedWarrantyCase';
import styles from './style.module.css';

const WarrantyDetailsPage = ({
  warrantyCase = {},
  coords,
  handleUpdateTime,
  handleUpdateStatus,
}) => {
  const isMobile = useContext(MobileContext);
  const { currentTabIndex, handleClick: setCurrentTabIndex } = useTabIndex(0);

  const { t } = useTranslation();
  const buttonHeight = useDetLang('local', '');
  const tabList = [
    {
      title: t('warranty.details.detailsTitle', 'Details'),
      children: (
        <div className={[styles.tabContentWrapper, styles.mainContent].join(' ')}>
          <div>
            <WrapperBlock title={t('warranty.details.description.title', 'Description')}>
              <DescriptionSection
                warrantyCase={warrantyCase}
                handleUpdateTime={handleUpdateTime}
              />
            </WrapperBlock>
            <WrapperBlock title={t('warranty.details.info.title', 'Information')}>
              <InformationsSection warrantyCase={warrantyCase} coords={coords} />
            </WrapperBlock>
          </div>
          {!isCompletedWarrantyCase(warrantyCase.status) && (
            <div className={styles.completeButtonWrapper}>
              <Button
                className={styles.completeButton}
                height={buttonHeight}
                handleClick={() => {
                  handleUpdateStatus(warrantyCase.sfId);
                }}
              >
                {t('warranty.details.completedButton', ' Mark as Completed')}
              </Button>
            </div>
          )}
        </div>
      ),
    },
    {
      title: t('warranty.details.documentsTitle', 'Documents'),
      children: (
        <div className={[styles.tabContentWrapper, styles.mainContent].join(' ')}>
          <WrapperBlock
            title={t('warranty.details.documents.documents.title', 'Documents')}
          >
            <Document caseSfId={warrantyCase.sfId} />
          </WrapperBlock>
        </div>
      ),
    },
    {
      title: t('warranty.details.messagesTitle', 'Messages'),
      children: <MessagesTab parentId={warrantyCase.sfId} type="case" />,
    },
  ];

  return (
    <LayoutWithSidebar>
      <div className={styles.container}>
        <SubHeader
          items={[
            {
              title: t('warranty.details.title', 'Warranty Case'),
              link: '/warranty',
            },
            {
              title: warrantyCase.caseNumber,
              link: null,
            },
          ]}
        />
        {isMobile ? (
          <MobileTabs
            tabList={tabList}
            currentTabIndex={currentTabIndex}
            handleClick={setCurrentTabIndex}
          />
        ) : (
          <DesktopTabs
            tabList={tabList}
            currentTabIndex={currentTabIndex}
            handleClick={setCurrentTabIndex}
          />
        )}
      </div>
    </LayoutWithSidebar>
  );
};

WarrantyDetailsPage.propTypes = {
  warrantyCase: PropTypes.object.isRequired,
  coords: PropTypes.object.isRequired,
  handleUpdateTime: PropTypes.func.isRequired,
  handleUpdateStatus: PropTypes.func.isRequired,
};
WarrantyDetailsPage.defaultProps = {};

export default WarrantyDetailsPage;
