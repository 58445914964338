import { getCookie } from './cookies';

function getToken() {
  return getCookie('access_token');
}

function decodeToken(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
}

function setToken(token, keepLogged) {
  const isMobileApp = localStorage.getItem('isMobile');

  document.cookie = `access_token=${token}; path=/;${
    keepLogged && !isMobileApp
      ? ` max-age=${process.env.REACT_APP_COOKIE_TIME_TO_LIVE}`
      : ''
  }; ${isMobileApp ? 'expires=Tue, 19 Jan 2038 03:14:07 GMT' : ''}`;
}

function removeToken() {
  document.cookie = `access_token=; path=/; max-age=0`;
}

export default {
  setToken,
  removeToken,
  getToken,
  decodeToken,
};
