import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useJobStatusLocal } from 'localization';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import calendarIcon from 'assets/icons/calendar.svg';
import alertIcon from 'assets/icons/alert.svg';
import { formatDate } from 'utils/dateFormatters';
import colorStatus from 'utils/statusColor';
import styles from './styles.module.css';
import pageStyles from '../../style.module.css';

const ProjectCard = ({
  location,
  reference,
  dateOfInstall,
  status,
  isSelected,
  isDisabled,
  handleClick,
  handleShowDetails,
  handleClaim,
}) => {
  const { t } = useTranslation();
  const jobStatusLocal = useJobStatusLocal();
  return (
    <div
      className={[
        styles.projectCardWrapper,
        isSelected ? styles.projectCardWrapperSelected : '',
      ].join(' ')}
    >
      <div onClick={handleClick}>
        <div className={styles.projectCardHeader}>
          <Text color="#000" size="s16" weight={isSelected ? 'wb' : 'wm'}>
            {t('jobs.available.mapCard.title', 'Roof Project')}
          </Text>
          <div>
            <span
              className={[
                pageStyles.circle,
                pageStyles[`circle${colorStatus(status)}`],
              ].join(' ')}
            />
            <Text color="#000" size="s14" weight={isSelected ? 'wb' : 'wm'}>
              {jobStatusLocal[status]}
            </Text>
          </div>
        </div>
        <div>
          <div>
            <Text color="#767676">
              {t('jobs.available.mapCard.ref', 'Ref:')} {reference}
            </Text>
          </div>
          <div>
            <Text color="#767676">
              {' '}
              {t('jobs.available.mapCard.location', 'Location:')} {location}
            </Text>
          </div>
          <div>
            <Icon src={calendarIcon} width="12px" className={styles.calendarIcon} />
            <Text color="#767676">
              {' '}
              {t('jobs.available.mapCard.date', 'Date of install:')}{' '}
              {formatDate(dateOfInstall)}
            </Text>
          </div>
        </div>
      </div>

      <div className={styles.projectCardFooter}>
        <button
          className={[styles.button, styles.buttonWrapper].join(' ')}
          type="button"
          disabled={isDisabled}
          onClick={handleClaim}
        >
          {isDisabled
            ? t('jobs.available.mapCard.claimed', 'Claimed')
            : t('jobs.available.mapCard.claim', 'Claim')}
        </button>
        <div onClick={handleShowDetails}>
          <Icon src={alertIcon} width="12px" className={styles.infoIcon} />
          <Text color="#000"> {t('jobs.available.mapCard.details', 'Details')}</Text>
        </div>
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  location: PropTypes.string,
  reference: PropTypes.string.isRequired,
  dateOfInstall: PropTypes.string,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  status: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  handleShowDetails: PropTypes.func,
  handleClaim: PropTypes.func,
};

ProjectCard.defaultProps = {
  dateOfInstall: '',
  isSelected: false,
  isDisabled: false,
  location: '',
  handleClick: () => {},
  handleShowDetails: () => {},
  handleClaim: () => {},
};

export default ProjectCard;
