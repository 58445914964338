import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReadView from './ReadView';
import FormView from './FormView';

const InspectSection = ({ approvedInspectionUpload, ...props }) => {

  if (approvedInspectionUpload)
    return (
      <fieldset disabled>
        <ReadView {...props} />
      </fieldset>
    );

  return (
    <fieldset>
      <FormView {...props} />
    </fieldset>
  );
};

InspectSection.propTypes = {
  approvedInspectionUpload: PropTypes.bool,
};

InspectSection.defaultProps = {
  approvedInspectionUpload: true,
};

export default InspectSection;
