import { applicationStatus } from 'enums';

export function isDisabled(status) {
  const disableInputsStatuses = [
    applicationStatus.approved,
    applicationStatus.denied,
    applicationStatus.pendingRR,
  ];
  return disableInputsStatuses.includes(status);
}
