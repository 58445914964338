import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  fbIcon,
  instagramIcon,
  linkedinIcon,
  twitterIcon,
  youtubeIcon,
  chevronIcon,
  appStoreIcon,
  playStoreIcon,
} from 'assets/icons';

import logoRoof from 'assets/logos/logo_roofclaim.png';
import Icon from 'components/shared/IconSVG';
import Text from 'components/shared/Text';
import styles from './style.module.css';

const Footer = () => {
  const { t } = useTranslation();
  const usefulLinks = [
    {
      name: t('footer.aboutUsLink', 'About Us'),
      url: 'https://www.roofclaim.com/about-us/',
      target: '_blank',
    },

    {
      name: t('footer.referralLink', 'Referral Rewards'),
      url: 'https://www.roofclaim.com/referral-program/',
      target: '_blank',
    },
  ];

  const usefulNavLinks = [
    { name: t('footer.faqLink', 'FAQs'), url: '/faq' },

    {
      name: t('footer.contactUsLink', 'Contact Us'),
      url: '/contact-us',
    },
  ];

  const mediaLinks = [
    {
      icon: fbIcon,
      url: 'https://www.facebook.com/RoofClaimUS/',
    },
    {
      icon: twitterIcon,
      url: 'https://twitter.com/RoofclaimUS',
    },
    {
      icon: youtubeIcon,
      url: 'https://www.youtube.com/channel/UCbIWrNbRjbWFm0GFNN2f01Q',
    },
    {
      icon: linkedinIcon,
      url: 'https://www.linkedin.com/company/roofclaim-com/',
    },
    {
      icon: instagramIcon,
      url: 'https://www.instagram.com/roofclaimus/',
    },
  ];
  const googlePlayUrl = process.env.REACT_APP_GOOGLE_PLAY_APP_URL;
  const appStoreUrl = process.env.REACT_APP_APP_STORE_URL;

  return (
    <div className={styles.container}>
      <Icon src={logoRoof} height="25px" width="223px" className={styles.logo} />
      <div
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
        className={styles.anchor}
      >
        <Icon
          src={chevronIcon}
          height="20px"
          width="20px"
          className={styles.anchorIcon}
        />
      </div>
      <div className={styles.linksContainer}>
        {(appStoreUrl || googlePlayUrl) && (
          <div className={styles.storeButtonContainer}>
            <Text tag="h2" size="s14" weight="wb" lineHeight="h16" color="#FFFFFF">
              {t('footer.download', 'Download our app!')}
            </Text>
            <div className={styles.buttonsGrid}>
              {appStoreUrl && (
                <a
                  className={styles.appStoreLink}
                  href={appStoreUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Icon
                    src={appStoreIcon}
                    height="40px"
                    width="135px"
                    className={styles.appStoreIcon}
                  />
                </a>
              )}

              {googlePlayUrl && (
                <a
                  className={styles.appStoreLink}
                  href={googlePlayUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Icon
                    src={playStoreIcon}
                    height="40px"
                    width="135px"
                    className={styles.playStoreIcon}
                  />
                </a>
              )}
            </div>
          </div>
        )}

        <div className={styles.usefulLinksContainer}>
          <Text
            tag="h2"
            size="s14"
            weight="wb"
            lineHeight="h16"
            color="#FFFFFF"
            className={styles.linksTitle}
          >
            {t('footer.useful', ' Useful Links')}
          </Text>
          {usefulLinks.map(({ url, name, target }) => (
            <a href={url} key={name} target={target} rel="noreferrer noopener">
              <Text
                tag="h2"
                size="s14"
                weight="wr"
                lineHeight="h16"
                color="#FFFFFF"
                className={styles.usefulLink}
              >
                {name}
              </Text>
            </a>
          ))}
          {usefulNavLinks.map(({ url, name }) => (
            <NavLink to={url} key={name}>
              <Text
                tag="h2"
                size="s14"
                weight="wr"
                lineHeight="h16"
                color="#FFFFFF"
                className={styles.usefulLink}
              >
                {name}
              </Text>
            </NavLink>
          ))}
        </div>

        <div className={styles.mediaLinksContainer}>
          <Text
            tag="h2"
            size="s14"
            weight="wb"
            lineHeight="h16"
            color="#FFFFFF"
            className={styles.linksTitle}
          >
            {t('footer.follow', ' Follow Us')}
          </Text>
          <div className={styles.mediaIconContainer}>
            {mediaLinks.map(({ url, icon }) => (
              <a href={url} key={url} target="_blank" rel="noreferrer noopener">
                <Icon
                  src={icon}
                  height="20px"
                  width="20px"
                  className={styles.mediaLink}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
      <Text
        tag="h2"
        size="s16"
        weight="wr"
        lineHeight="h21"
        color="#837F7D"
        className={styles.bottomText}
      >
        <a
          className={styles.privacyLink}
          href="https://www.roofclaim.com/privacy-policy/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span> </span>
          {t('footer.policy', ' Privacy Policy')}
        </a>
        <span> </span>|<span> </span>
        <a
          className={styles.privacyLink}
          href="https://www.roofclaim.com/terms-and-conditions/"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('footer.terms', 'Terms and Conditions')}
        </a>
      </Text>
    </div>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
