import { useState } from 'react';

const useTabIndex = (tabIndex = 0) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(tabIndex);

  return {
    currentTabIndex,
    handleClick: setCurrentTabIndex,
  };
};

export default useTabIndex;
