import gql from 'graphql-tag';

export const GET_FAQS = gql`
  query getFAQs {
    getFAQs {
      title
      details
      category
    }
  }
`;
