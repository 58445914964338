import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppConfigContext from 'context/AppConfigContext';
import Text from 'components/shared/Text';
import useError from 'services/hooks/useErrors/useErrorDocuments';
import { useAppConfigLocal } from 'localization';
import Documents from '../../Documents';
import styles from './styles.module.css';

const DocumentsTab = ({
  loadingAttachments,
  handleChange,
  qualificationSfId,
  errors,
  touched,
  ...formikProps
}) => {
  const config = useContext(AppConfigContext);

  useError(errors, touched);
  const appConfigLocal = useAppConfigLocal();
  return [
    <div className={styles.textWrapper}>
      <Text color="#292929" size="s16" lineHeight="h19" weight="wm">
        {appConfigLocal[config.textPermit]}
      </Text>
    </div>,
    <Documents
      {...formikProps}
      errors={errors}
      touched={touched}
      handleChange={handleChange}
      qualificationSfId={qualificationSfId}
      loadingAttachments={loadingAttachments}
    />,
  ];
};
DocumentsTab.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  qualificationSfId: PropTypes.array.isRequired,
  loadingAttachments: PropTypes.bool.isRequired,
};
export default DocumentsTab;
