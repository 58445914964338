import React, { useState, useContext } from 'react';
import ForgotPasswordForm from 'components/Auth/ForgotPassword';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import FormWrapper from 'components/Auth/FormWrapper';
import ConfirmEmailComponent from 'components/Auth/ConfirmEmail';
import { REQUEST_RESET_MUTATION } from 'graphql/mutations/auth';
import MobileSignedOutHeader from 'layouts/Mobile/MobileSignedOutHeader';
import { emailValidationSchema } from 'validation/signUp';
import { useHistory } from 'react-router-dom';
import AppConfigContext from 'context/AppConfigContext';
import styles from './style.module.css';

const ForgotPassword = () => {
  const history = useHistory();
  const [requestReset, { loading: isLoading }] = useMutation(REQUEST_RESET_MUTATION);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [email, setEmail] = useState('');
  const config = useContext(AppConfigContext);

  const handleSubmit = async (values) => {
    try {
      setEmail(values.email);
      await requestReset({
        variables: { email: values.email },
        update: (cache, { data }) => {
          setIsSendEmail(true);
        },
      });
      // eslint-disable-next-line no-empty
    } catch (e) {
      history.push('/sign-in');
    }
  };

  return (
    <MobileSignedOutHeader>
      <main>
        <div
          className={styles.contentPage}
          style={{ backgroundImage: `url(${config?.signInImageUrl})` }}
        >
          {!isSendEmail ? (
            <Formik
              initialValues={{ email: '' }}
              validateOnMount
              validationSchema={emailValidationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <fieldset disabled={isLoading}>
                  <ForgotPasswordForm
                    {...props}
                    contactUsLink={`mailto:${process.env.REACT_APP_ROOFCALIM_EMAIL}`}
                  />
                </fieldset>
              )}
            </Formik>
          ) : (
            <FormWrapper>
              <form>
                <ConfirmEmailComponent email={email} />
              </form>
            </FormWrapper>
          )}
        </div>
      </main>
    </MobileSignedOutHeader>
  );
};

export default ForgotPassword;
