import { qualificationTypes } from 'enums';
import { cleanNumber } from 'utils/phoneNumberFormatter';
import { restructureInitData } from 'utils/application/restructureData/restructureInitData';

export const initialValues = (data = {}, downloadFilesDescription) => {
  const { companyData } = data;
  const addressArray = companyData?.address.split(',');

  return {
    organization: {
      companyName: companyData?.companyName,
      companyNameDBA: companyData?.companyNameDBA,
      address: addressArray?.[0].trim(),
      city: addressArray?.[1].trim(),
      state: addressArray?.[2].trim(),
      zipCode: addressArray?.[3].trim(),
      suiteNumber: companyData?.suiteNumber || '',
      ownerFirstName: companyData?.ownerFirstName,
      ownerLastName: companyData?.ownerLastName,
      companyPhoneNumber: cleanNumber(companyData?.companyPhoneNumber),
      emailAddress: companyData?.ownerEmail,
      yearCompanyStarted: companyData?.yearCompanyStarted,
      taxID: companyData?.taxID || '',
      organization: companyData?.organization,
      registrationState: companyData?.registrationState,
      dunnBradstreetNumber: companyData?.dunnBradstreetNumber || '',
      website: companyData?.website || '',
      facebook: companyData?.facebook || '',
      linkedin: companyData?.linkedin || '',
      twitter: companyData?.twitter || '',
    },

    services: {
      restorationVSRetail: companyData?.restorationVSRetail,
      residentialVSCommercial: companyData?.residentialVSCommercial,
      shingle: companyData?.shingle,
      metal: companyData?.metal,
      tile: companyData?.tile,
      modifiedBitumenResidential: companyData?.modifiedBitumenResidential,
      builtUpMetal: companyData?.builtUpMetal,
      modifiedBitumenCommercial: companyData?.modifiedBitumenCommercial,
      thermoset: companyData?.thermoset,
      thermoplastic: companyData?.thermoplastic,
      serviceOffered: companyData?.serviceOffered || '',
    },

    coverage: {
      numberCrews: companyData?.numberCrews,
      numberLaborers: companyData?.numberLaborers,
      crewAveragePerDay: companyData?.crewAveragePerDay,
      hasDumpTrailer: companyData?.hasDumpTrailer,
      staffedPullPermits: companyData?.staffedPullPermits,
      onsiteManagementStructure: companyData?.onsiteManagementStructure,
      structure: companyData?.structure,
    },

    definedWorkAreas: {
      states: companyData?.definedWorkAreas.states,
      counties: companyData?.definedWorkAreas.counties,
      cities: companyData?.definedWorkAreas.cities,
    },

    myCertification: {
      workersCompensation: restructureInitData(
        data?.qualification,
        downloadFilesDescription,
        qualificationTypes.wc
      ),

      w9: restructureInitData(
        data?.qualification,
        downloadFilesDescription,
        qualificationTypes.w9
      ),

      general: restructureInitData(
        data?.qualification,
        downloadFilesDescription,
        qualificationTypes.gl
      ),

      license: restructureInitData(
        data?.qualification,
        downloadFilesDescription,
        qualificationTypes.lc
      ),

      auto: restructureInitData(
          data?.qualification,
          downloadFilesDescription,
          qualificationTypes.al
      ),
    },
  };
};
