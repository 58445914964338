export default [
  { id: 1, label: 'General contracting' },
  { id: 2, label: 'Siding' },
  { id: 3, label: 'Gutters' },
  { id: 4, label: 'Solar panels' },
  { id: 5, label: 'Screens' },
  { id: 6, label: 'Insulation' },
  { id: 7, label: 'HVAC' },
  { id: 8, label: 'Windows' },
  { id: 9, label: 'Drywall' },
  { id: 10, label: 'Fencing' },
];
