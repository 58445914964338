import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import queryString from 'query-string';
import CreatePassword from 'components/Auth/CreatePassword';
import FormWrapper from 'components/Auth/FormWrapper';
import { RESET_PASSWORD_MUTATION } from 'graphql/mutations/auth';
import { useCreatePasswordValidationSchema } from 'validation/signUp';
import MobileSignedOutHeader from 'layouts/Mobile/MobileSignedOutHeader';
import { useHistory } from 'react-router-dom';
import styles from '../ForgotPassword/style.module.css';
import { useFormatMessage } from '../../utils/graphQLErrorFormatters';
import { CHECK_RESET_TOKEN } from '../../graphql/queries/auth';
import Spinner from '../../components/shared/Spinner';
import { useAuth } from '../../services/hooks/useAuth';

const ResetPassword = () => {
  const history = useHistory();
  const { resetToken, email } = queryString.parse(history.location.search);

  const { isLoading: signInLoading, handleSubmit: signInHandleSubmit } = useAuth();
  const { formatMessage } = useFormatMessage();

  const { data, error, loading } = useQuery(CHECK_RESET_TOKEN, {
    variables: {
      token: resetToken,
    },
  });
  const [resetPassword, { loading: isLoading }] = useMutation(RESET_PASSWORD_MUTATION);
  const [networkError, setNetworkError] = useState(null);

  useEffect(() => {
    if (!resetToken) history.push('/sign-in');
  }, [resetToken]);

  useEffect(() => {
    if (error) history.push('/sign-in');
  }, [data, error]);

  const { t } = useTranslation();

  const handleSubmit = async (values) => {
    try {
      await resetPassword({
        variables: { ...values, resetToken },
        update: () => {
          signInHandleSubmit({
            email,
            password: values.password,
            reCAPTCHAToken: values.reCAPTCHAToken,
          });
        },
      });
    } catch (e) {
      if (formatMessage(e.message) === 'User is not exist')
        setNetworkError(t('createPassword.networkError', 'User is not exist'));
    }
  };
  const validationObject = useCreatePasswordValidationSchema();
  return (
    <MobileSignedOutHeader>
      <div className={styles.contentPage}>
        <FormWrapper>
          {loading || signInLoading ? (
            <Spinner />
          ) : (
            <Formik
              initialValues={{
                password: '',
                repeatedPassword: '',
              }}
              validateOnMount
              validationSchema={validationObject}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <fieldset disabled={isLoading}>
                  <CreatePassword {...formikProps} networkError={networkError} />
                </fieldset>
              )}
            </Formik>
          )}
        </FormWrapper>
      </div>
    </MobileSignedOutHeader>
  );
};

export default ResetPassword;
