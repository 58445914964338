import React from 'react';
import PropTypes from 'prop-types';
import { deleteFileRoofProject } from 'graphql/cache/project';
import UploadFileArea from 'components/shared/UploadFile';

const UploadFileContainer = ({
  deleteUploadedFiles,
  parentId,
  uploadFiles,
  handleChange,
  name,
  ...props
}) => {
  const handleUpload = (newFiles) => {
    const files = [uploadFiles, newFiles || []].flat();
    handleChange({ target: { name, value: files } });
  };

  const handleDeleteFile = (removedFile, fileIndex) => {
    const files = uploadFiles.filter((file, index) => index !== fileIndex);

    if (!removedFile.contentDocumentId) {
      handleChange({ target: { name, value: files } });
    } else {
      deleteUploadedFiles({
        variables: {
          fileId: removedFile.contentDocumentId,
        },
        update: (cache, { data: { deleteRelatedFile } }) => {
          if (!deleteRelatedFile) return null;
          handleChange({ target: { name, value: files } });
          return deleteFileRoofProject({
            fileId: removedFile.contentDocumentId,
            parentId,
          });
        },
      });
    }
  };

  return (
    <UploadFileArea
      {...props}
      uploadFiles={uploadFiles}
      handleUpload={handleUpload}
      handleDelete={handleDeleteFile}
    />
  );
};

UploadFileContainer.propTypes = {
  uploadFiles: PropTypes.array,
  name: PropTypes.string.isRequired,
  parentId: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  deleteUploadedFiles: PropTypes.func.isRequired,
};

UploadFileContainer.defaultProps = {
  uploadFiles: [],
  parentId: [],
};

export default UploadFileContainer;
