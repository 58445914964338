export default Object.freeze({
  cont: 'CONT-',
  full: 'FULL',
  abr: 'ABR',
  cust: 'CUST-',
  before: 'BEFORE-',
  after: 'AFTER-',
  permit: 'PERMIT-',
  inspect: 'INSPECT-'
});
