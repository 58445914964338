import client from 'graphql/client';
import { GET_CURRENT_USER } from '.';

export const updateContactListUser = (newUser) => {
  const newData = {
    ...newUser,
    account: {
      ...newUser.account,
    },
  };

  client.cache.writeQuery({
    query: GET_CURRENT_USER,

    data: {
      user: newData,
    },
  });
};
