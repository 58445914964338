import PropTypes from 'prop-types';
import React, {useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import debounce from 'lodash/debounce';
import {NavLink} from 'react-router-dom';
import InputMask from 'react-input-mask';
import {useLazyQuery, useQuery} from '@apollo/react-hooks';
import {
    GET_ADDRESS_DETAILS,
    GET_ADDRESS_PREDICTION,
    GET_ADDRESS_BY_COORDS,
} from 'graphql/queries/maps';
import MobileContext from 'context/MobileContext';
import LanguageContext from 'context/LanguageContext';
import Text from 'components/shared/Text';
import Field from 'components/shared/Field';
import Checkbox from 'components/shared/CheckBox';
import Button from 'components/shared/Button';
import Link from 'components/shared/Link';
import {
    FormColumn as Col,
    FormContent,
    FormFooter,
    FormRow as Row,
} from 'components/shared/Form';
import {states} from 'enums';
import AppConfigContext from 'context/AppConfigContext';
import useGeoposition from 'services/hooks/useGeoposition';
import {getLatLngStringFromCoords} from 'utils/getLatLngStringFromCoords';
import {useAuthErrors} from 'enums/errors/authErrors';
import {OAuthSignUpURIs} from 'enums/OAuthURIs';
import {useAppConfigLocal} from 'localization';
import FormHeader from '../../Form/FormHeader';
import SignUpButton from '../../SignUpButton';
import styles from '../style.module.css';
import buttonStyles from '../../shared/Button/style.module.css';

const FirstStepSignUp = ({
                             handleSubmit = () => {
                             },
                             values,
                             errors,
                             isLoading,
                             touched,
                             handleChange,
                             setFieldValue,
                             setFieldTouched,
                             userExistError,
                             addressExistError,
                             validateForm,
                             handleChangeAddress,
                             handleChangeEmail,
                             isOAuth,
                         }) => {
    const config = useContext(AppConfigContext);
    const {coords} = useGeoposition();
    const errorObject = useAuthErrors();

    const {data: addressByCoords, loading: addressAutoFillLoading} = useQuery(
        GET_ADDRESS_BY_COORDS,
        {
            variables: {latlng: getLatLngStringFromCoords(coords)},
            skip: !coords || !getLatLngStringFromCoords(coords),
        }
    );

    const [_getAddressPrediction, {data: addressPredictionData}] = useLazyQuery(
        GET_ADDRESS_PREDICTION
    );

    const getAddressPrediction = useCallback(
        debounce(
            ({variables: {input: value}}) =>
                _getAddressPrediction({variables: {input: value}}),
            500
        ),
        []
    );

    const setAddressFormValues = (placeID, name) => {
        setFieldValue('addressPlaceID', placeID ?? null);
        setFieldValue('address', name ?? null);
    };

    function fillFormFieldsByKeys(data) {
        if (data)
            Object.keys(data).map((el) => {
                if (data[el] && el !== '__typename') setFieldValue(el, data[el]);
                return el;
            });
    }

    const [getAddressDetails, {data: addressDetailsData}] = useLazyQuery(
        GET_ADDRESS_DETAILS,
        {
            onCompleted: () => {
                const data = addressDetailsData?.getAddressDetails;
                fillFormFieldsByKeys(data);
            },
        }
    );

    useEffect(() => {
        const data = addressByCoords?.getAddressByCoords;
        if (coords && data) {
            const {city, state, zipCode, formattedAddress, placeID, street, country} = data;
            fillFormFieldsByKeys({city, state, zipCode: String(zipCode), street, country});
            setAddressFormValues(placeID, formattedAddress);
        }
    }, [coords, addressByCoords?.getAddressByCoords]);

    useEffect(() => {
        validateForm();
    }, [values.addressPlaceID]);

    const isMobile = useContext(MobileContext);

    async function getOnChange(e) {
        const name = e.target.value;
        const placeID = addressPredictionData.getAddressPrediction?.predictions.find(
            (prediction) => prediction.description === name
        )?.place_id;
        setAddressFormValues(placeID, name);
        if (placeID) await getAddressDetails({variables: {placeID}});
        validateForm();
        setFieldTouched('address', true);
        handleChangeAddress();
    }

    const beforeMaskedValueChange = (newState) => {
        // eslint-disable-next-line prefer-const
        let {value, selection} = newState;
        if (value.endsWith('-')) value = value.slice(0, -1);
        return {
            value,
            selection,
        };
    };

    function getOnInputChange(value, action) {
        if (action.action !== 'input-blur' && action.action !== 'menu-close') {
            if (values.address) getAddressPrediction({variables: {input: value}});
            setAddressFormValues(null, value);
        }
        setFieldTouched('address', true);
        handleChangeAddress();
    }

    const goTo = (uri) => {
        window.location.href = uri;
    };

    const {language} = useContext(LanguageContext);

    function handleCreate() {
        setFieldValue('language', language);
        setTimeout(handleSubmit, 0);
    }

    const {t} = useTranslation();
    const appConfigLocal = useAppConfigLocal();
    return (
        <form>
            {isMobile && (
                <div className={styles.authFormHeaderMobile}>
                    <Text color="#00000D" weight="wb" size="s24">
                        {t('signUp.title.mobile', 'Sign Up')}
                    </Text>
                </div>
            )}
            {isOAuth ? null : (
                <FormHeader>
                    <SignUpButton icon="google" handleClick={() => goTo(OAuthSignUpURIs.google)}>
                        {t('signUp.body.oauth.google', ' Sign Up with Google')}
                    </SignUpButton>
                    <SignUpButton
                        icon="microsoft"
                        handleClick={() => goTo(OAuthSignUpURIs.microsoft)}
                    >
                        {t('signUp.body.oauth.microsoft', 'Sign Up with Microsoft')}
                    </SignUpButton>
                </FormHeader>
            )}
            <FormContent>
                <Col>
                    <Field
                        label={t('signUp.body.legalLabel', 'Company Name (Legal)')}
                        type="text"
                        inputMode="text"
                        autoComplete="off"
                        name="companyName"
                        value={values.companyName}
                        isError={touched.companyName && Boolean(errors.companyName)}
                        onChange={handleChange}
                    />
                </Col>
                <Col>
                    <Field
                        label={t('signUp.body.DBALabel', 'Company Name (DBA)')}
                        type="text"
                        inputMode="text"
                        autoComplete="off"
                        name="companyNameDBA"
                        value={values.companyNameDBA}
                        isError={touched.companyNameDBA && Boolean(errors.companyNameDBA)}
                        onChange={handleChange}
                    />
                </Col>
                <Row>
                    <Col>
                        <Field
                            label={t('signUp.body.firstNameLabel', 'First Name')}
                            type="text"
                            inputMode="text"
                            autoComplete="off"
                            name="firstName"
                            value={values.firstName}
                            isError={touched.firstName && Boolean(errors.firstName)}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col>
                        <Field
                            label={t('signUp.body.lastNameLabel', 'Last Name')}
                            type="text"
                            inputMode="text"
                            autoComplete="off"
                            name="lastName"
                            value={values.lastName}
                            isError={touched.lastName && Boolean(errors.lastName)}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Field
                            label={t('signUp.body.jobLabel', 'Job Title')}
                            type="text"
                            inputMode="text"
                            autoComplete="off"
                            isDisabled={isLoading}
                            name="jobTitle"
                            value={values.jobTitle}
                            isError={touched.jobTitle && Boolean(errors.jobTitle)}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col>
                        <InputMask
                            mask="+1 (999) 999-9999"
                            value={values.phoneNumber}
                            onChange={(e) => {
                                setFieldValue(
                                    'phoneNumber',
                                    e.target.value.replace('+', '').replace(/\D/g, '')
                                );
                            }}
                        >
                            {() => (
                                <Field
                                    label={t('signUp.body.phoneLabel', 'Phone Number')}
                                    type="tel"
                                    inputMode="tel"
                                    autoComplete="tel"
                                    name="phoneNumber"
                                    isError={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                />
                            )}
                        </InputMask>
                    </Col>
                </Row>
                <Col>
                    <Field
                        label={t('signUp.body.emailLabel', 'Email Address')}
                        type="email"
                        inputMode="email"
                        autoComplete="off"
                        name="email"
                        isDisabled={isOAuth}
                        value={values.email}
                        isError={
                            userExistError ||
                            (values.email && Boolean(errors.email)) ||
                            (touched.email && Boolean(errors.email))
                        }
                        onChange={handleChangeEmail}
                    />
                    {userExistError ? (
                        <Text size="s12" lineHeight="h14" color="#CF4B4B">
                            {t('signUp.body.error.email', 'This email is already in use.')}
                            <br/>
                            {t('signUp.body.error.member', 'Already a member?')}
                            <NavLink to="/sign-in">
                                <Text size="s12" lineHeight="h14" weight="wb" color="#CF4B4B">
                                    {' '}
                                    {t('signUp.body.error.login', ' Login')}
                                </Text>
                            </NavLink>
                        </Text>
                    ) : null}
                </Col>
                <Col>
                    <Field
                        autoComplete="off"
                        label={t('signUp.body.addressLabel', 'Address')}
                        name="address"
                        type="select"
                        isDisabled={isLoading || addressAutoFillLoading}
                        options={
                            addressPredictionData?.getAddressPrediction?.predictions.map((el) => ({
                                label: el.description,
                                value: el.description,
                            })) || []
                        }
                        onChange={getOnChange}
                        onInputChange={getOnInputChange}
                        icon={() => <div/>}
                        value={values.address ?? ''}
                        inputValue={values.address ?? ''}
                        isError={
                            addressExistError ||
                            (touched.address && Boolean(errors.address)) ||
                            (touched.addressPlaceID && Boolean(errors.addressPlaceID))
                        }
                    />
                    {addressExistError ? (
                        <Text size="s12" lineHeight="h14" color="#CF4B4B">
                            {errorObject.notUniqueAddress}
                        </Text>
                    ) : null}
                    {values.address &&
                    Boolean(errors.addressPlaceID) &&
                    Boolean(touched.address) ? (
                        <Text size="s12" lineHeight="h14" color="#CF4B4B">
                            {t('signUp.body.error.invalidAddress', 'Invalid address')}
                        </Text>
                    ) : null}
                </Col>
                {/* Mobile Columns */}
                {isMobile ? (
                    <>
                        <Row>
                            <Col>
                                <Field
                                    label={t('signUp.body.mobile.cityLabel', 'City')}
                                    inputMode="search"
                                    autoComplete="off"
                                    name="city"
                                    value={values.city}
                                    isError={touched.city && Boolean(errors.city)}
                                    onChange={handleChange}
                                />
                            </Col>

                            <Col>
                                <InputMask
                                    mask={values?.zipCode?.length >= 5 ? '99999-9999' : '99999'}
                                    maskChar={null}
                                    beforeMaskedValueChange={beforeMaskedValueChange}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'zipCode',
                                            e.target.value.replace('+1', '').replace(/\D/g, '')
                                        );
                                    }}
                                    value={values.zipCode}
                                >
                                    {() => (
                                        <Field
                                            label={t('signUp.body.mobile.zipLabel', 'Zip Code')}
                                            type="text"
                                            inputMode="numeric"
                                            autoComplete="off"
                                            name="zipCode"
                                            isError={touched.zipCode && Boolean(errors.zipCode)}
                                        />
                                    )}
                                </InputMask>
                            </Col>
                            <Col>
                                <Field
                                    label={t('signUp.body.mobile.stateLabel', 'State')}
                                    type="select"
                                    isDisabled={isLoading}
                                    inputMode="search"
                                    autoComplete="off"
                                    name="state"
                                    value={values.state}
                                    isError={touched.state && Boolean(errors.state)}
                                    onChange={handleChange}
                                    options={states}
                                />
                            </Col>
                            <Col>
                                <Field
                                    label={t('signUp.body.mobile.suiteLabel', 'Suite #')}
                                    type="text"
                                    isDisabled={isLoading}
                                    inputMode="search"
                                    autoComplete="off"
                                    name="suiteNumber"
                                    value={values.suiteNumber}
                                    isError={touched.suiteNumber && Boolean(errors.suiteNumber)}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                    </>
                ) : (
                    // Desktop Columns
                    <>
                        <Row>
                            <Col>
                                <Field
                                    label={t('signUp.body.cityLabel', 'City')}
                                    type="search"
                                    inputMode="search"
                                    autoComplete="off"
                                    name="city"
                                    value={values.city}
                                    isError={touched.city && Boolean(errors.city)}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col>
                                <InputMask
                                    mask={values?.zipCode?.length >= 5 ? '99999-9999' : '99999'}
                                    maskChar={null}
                                    beforeMaskedValueChange={beforeMaskedValueChange}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'zipCode',
                                            e.target.value.replace('+1', '').replace(/\D/g, '')
                                        );
                                    }}
                                    value={values.zipCode}
                                >
                                    {() => (
                                        <Field
                                            label={t('signUp.body.zipLabel', 'Zip Code')}
                                            type="text"
                                            inputMode="numeric"
                                            autoComplete="off"
                                            name="zipCode"
                                            isError={touched.zipCode && Boolean(errors.zipCode)}
                                        />
                                    )}
                                </InputMask>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field
                                    label={t('signUp.body.stateLabel', 'State')}
                                    type="select"
                                    isDisabled={isLoading}
                                    inputMode="search"
                                    autoComplete="off"
                                    name="state"
                                    value={values.state}
                                    isError={touched.state && Boolean(errors.state)}
                                    onChange={handleChange}
                                    options={states}
                                />
                            </Col>

                            <Col>
                                <Field
                                    label={t('signUp.body.suiteLabel', 'Suite #')}
                                    type="text"
                                    isDisabled={isLoading}
                                    inputMode="search"
                                    autoComplete="off"
                                    name="suiteNumber"
                                    value={values.suiteNumber}
                                    isError={touched.suiteNumber && Boolean(errors.suiteNumber)}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                <Col>
                    <Text size="s13" color="#837F7D" lineHeight="l16">
                        {t('Thanks for sign-up to Roofclaim.com.')}
                        <br/>{' '}
                        {t(
                            'By clicking “Create Account” you are agreeing to our'
                        )}{' '}
                        <Link
                            style={{color: '#837F7D'}}
                            href="https://roofclaim.com/pro-purchasing-terms-and-conditions/"
                            bold
                            size="s13"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('Terms of Service')}
                        </Link>{' '}
                        {/* eslint-disable-next-line max-len */}
                        {t('and authorize RoofClaim and its affiliates, using automated technology, to deliver marketing emails, calls and texts to the number and email address you provided. Consent is not a condition of purchase.')}{' '}
                        {t('See our')}{' '}
                        <Link
                            style={{color: '#837F7D'}}
                            href="https://roofclaim.com/privacy-policy/"
                            bold
                            size="s13"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('Privacy Policy')}
                        </Link>{' '}
                        {t('for more information.')}
                    </Text>
                </Col>
                <div style={{display: 'none'}}>
                    <Col>
                        <Checkbox
                            name="agreesWithTerms"
                            isContractorsStyle
                            value={values.agreesWithTerms}
                            text={
                                <>
                                    {t('signUp.footer.p_6', 'I agree with the')}{' '}
                                    <Link
                                        href="https://roofclaim.com/pro-purchasing-terms-and-conditions/"
                                        bold
                                        isUnderlined
                                        size="s13"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t('signUp.footer.p_7', 'Terms and Conditions')}
                                    </Link>
                                </>
                            }
                            isError={touched.agreesWithTerms && Boolean(errors.agreesWithTerms)}
                            required
                            onChange={() => {
                                handleChange({
                                    target: {name: 'agreesWithTerms', value: !values.agreesWithTerms},
                                });
                            }}
                        />
                    </Col>
                </div>

                <Col>
                    <Checkbox
                        name="contactPreferenceEmail"
                        isContractorsStyle
                        value={values.contactPreferenceEmail}
                        text={<> {t('signUp.footer.p_8', 'Please send me marketing emails')}.</>}
                        isError={
                            touched.contactPreferenceEmail && Boolean(errors.contactPreferenceEmail)
                        }
                        required
                        onChange={() => {
                            handleChange({
                                target: {
                                    name: 'contactPreferenceEmail',
                                    value: !values.contactPreferenceEmail,
                                },
                            });
                        }}
                    />
                </Col>
                <Col>
                    <Checkbox
                        name="contactPreferenceSms"
                        isContractorsStyle
                        value={values.contactPreferenceSms}
                        text={appConfigLocal[config?.textContactPreferenceSms]}
                        isError={touched.contactPreferenceSms && Boolean(errors.contactPreferenceSms)}
                        required
                        onChange={() => {
                            handleChange({
                                target: {
                                    name: 'contactPreferenceSms',
                                    value: !values.contactPreferenceSms,
                                },
                            });
                        }}
                    />
                </Col>
            </FormContent>
            <FormFooter>
                {isMobile ? (
                    <div className={styles.formFooter}>
                        <Button
                            size="medium"
                            handleClick={handleCreate}
                            disabled={isLoading}
                            className={buttonStyles.fullWidthMobile}
                        >
                            {t('signUp.footer.mobile.p_1', 'Create Account')}
                        </Button>
                        <br/>
                        <Text color="#00000D" weight="wm" size="s16">
                            {t('signUp.footer.mobile.p_2', 'Already have an account?')}
                            <Link href="/sign-in" bold size="medium">
                                {t('signUp.footer.mobile.p_3', 'Sign In')}
                            </Link>
                        </Text>
                    </div>
                ) : (
                    <Button size="medium" handleClick={handleCreate} disabled={isLoading}>
                        {t('signUp.footer.createButton', ' Create Account')}
                    </Button>
                )}
            </FormFooter>
        </form>
    );
};

FirstStepSignUp.defaultProps = {
    addressExistError: false,
    isLoading: false,
    userExistError: false,
    isOAuth: false,
};
FirstStepSignUp.propTypes = {
    addressExistError: PropTypes.bool,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    userExistError: PropTypes.bool,
    validateForm: PropTypes.func.isRequired,
    handleChangeAddress: PropTypes.func.isRequired,
    handleChangeEmail: PropTypes.func.isRequired,
    isOAuth: PropTypes.bool,
};

export default FirstStepSignUp;
