import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { object, string, array } from 'yup';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import Textarea from 'components/shared/TextArea';
import WrapperBlock from 'components/shared/WrapperBlock';
import useModal from 'services/hooks/useModal';
import { jobStatus } from 'enums';
import { FormColumn } from 'components/shared/Form';
import UploadFileArea from 'components/shared/UploadFile/container';
import { toBase64UploadedFiles } from 'utils/toBase64File';
import LeaveNoteButton from '../LeaveNoteButton';
import styles from '../style.module.css';
import InspectionModal from '../InspectionModal';

const FormViewCompleteSection = ({
  isDisable,
  noteText,
  handleSubmit: handleUpdateStatus,
  commentRollover,
  status,
}) => {
  const { isShow, handleClose, handleOpen } = useModal();
  const { isShow: isShowCommentArea, handleClick: handleClickCommentArea } = useModal(
    Boolean(commentRollover)
  );
  const handleSubmitStatus = async (values) => {
    const { files, comment, status: submitStatus } = values;
    const convertedFiles = await toBase64UploadedFiles(files);

    handleUpdateStatus({ files: convertedFiles, status: submitStatus, comment });
  };
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        status,
        comment: commentRollover,
        files: [],
      }}
      validationSchema={object().shape({
        status: string().required().oneOf([jobStatus.completed, jobStatus.rollover]),
        comment: string().trim().nullable(!isShowCommentArea).required(isShowCommentArea),
        /* Old Code */
        // files: array().when('quantity', {
        //   is: (value) => value > 0,
        //   then: array().required().min(1),
        // }),
        files: array().min(1, "Please select minimum 1 file")
      })}
      onSubmit={handleSubmitStatus}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => {
        const isCompleteStatus = values.status === jobStatus.completed;
        return (
          <WrapperBlock
            title={t('jobs.awarded.details.activity.status.formView.title', 'Status')}
            classNameMainWrapper={styles.wrapper}
            rightComponent={
              <LeaveNoteButton
                isDisable={isDisable}
                handleClick={() => {
                  if (isShowCommentArea)
                    handleChange({ target: { value: '', name: 'comment' } });
                  handleClickCommentArea();
                }}
              >
                {noteText}
              </LeaveNoteButton>
            }
          >
            <div className={styles.sectionContent}>
              {isShowCommentArea && (
                <FormColumn>
                  <Textarea
                    placeholder={t('notePlaceholder', 'Leave a note...')}
                    value={values.comment}
                    handleChange={handleChange}
                    name="comment"
                    isError={touched.comment && Boolean(errors.comment)}
                    maxLength={255}
                  />
                </FormColumn>
              )}

              <div className={styles.headerBlockSpace}>
                <div className={styles.fieldWrapper}>
                  <Field
                    type="select"
                    label={t(
                      'jobs.awarded.details.activity.status.formView.statusLabel',
                      'What is the job status?'
                    )}
                    value={values.status}
                    onChange={handleChange}
                    name="status"
                    options={[
                      {
                        label: t(
                          'jobs.awarded.details.activity.status.formView.completed',
                          'Completed'
                        ),
                        value: jobStatus.completed,
                      },
                      {
                        label: t(
                          'jobs.awarded.details.activity.status.formView.rollover',
                          'Rollover'
                        ),
                        value: jobStatus.rollover,
                      },
                    ]}
                    isError={touched.status && Boolean(errors.status)}
                  />
                </div>

                <Button
                  size="medium"
                  handleClick={(isCompleteStatus && values.files.length > 0) ? handleOpen : handleSubmit}
                  className={styles.buttonWrapper}
                >
                  {t('submitButton', 'Submit')}
                </Button>
              </div>
              {values.status === jobStatus.completed && (
                <FormColumn>
                  <UploadFileArea
                    buttonText={t('uploadButton', 'Upload file(s)')}
                    accept=".jpeg, .png, .jpg"
                    name="files"
                    uploadFiles={values.files}
                    handleChange={handleChange}
                    maxCountDocument={5}
                    isInvalid={touched.files && Boolean(errors.files)}
                    isError={touched.files && Boolean(errors.files)}
                  />
                  {(touched.files && Boolean(errors.files)) ? <p>{errors.files}</p> : ''}
                </FormColumn>
              )}
            </div>
            <InspectionModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              isShow={isShow}
            />
          </WrapperBlock>
        );
      }}
    </Formik>
  );
};

FormViewCompleteSection.propTypes = {
  isDisable: PropTypes.bool,
  noteText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  commentRollover: PropTypes.string,
  status: PropTypes.string.isRequired,
};

FormViewCompleteSection.defaultProps = {
  isDisable: false,
  commentRollover: '',
};

export default FormViewCompleteSection;
