import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { searchIcon } from 'assets/icons';
import Table from 'components/common/Table';
import SearchInput from 'components/shared/Input';
import filterData from 'utils/dataSearch';
import { paymentStatus } from 'enums';
import { useHistory } from 'react-router-dom';
import { parseQuery } from 'utils/parseQueryString';
import styles from '../../style.module.css';
import { useTableColumns } from '../pendingColumns';
import TableMobile from '../../Mobile/TableMobile';

const PaymentReceived = ({ jobs }) => {
  const history = useHistory();
  const [searchString, setSearchString] = useState('');

  const pendingJobs = jobs.filter(({ paymentStatus: payStatus }) =>
    [paymentStatus.NA, paymentStatus.notPaid].includes(payStatus)
  );

  const searchData = useMemo(
    () =>
      filterData({
        data: pendingJobs,
        searchString,
        searchValue: ['invoice.invoice', 'reference'],
      }),
    [searchString, jobs]
  );

  // Selected page index
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  useEffect(() => {
    const { search } = history.location;
    const query = parseQuery(search);
    if (query?.page) setCurrentPageIndex(query.page - 1);
  }, [currentPageIndex, history.location.search]);

  const { t } = useTranslation();
  const tableColumns = useTableColumns();
  return (
    <div className={styles.paymentTableWrapper}>
      <div className={styles.searchContainer}>
        <SearchInput
          rightIcon={searchIcon}
          placeholder={t('finance.search', 'Search by Invoice # or Job #')}
          name="searchValue"
          value={searchString}
          onChange={(event) => setSearchString(event.target.value)}
        />
      </div>
      {pendingJobs.length ? (
        <div className={styles.tableContainer}>
          <Table
            columns={tableColumns}
            data={searchData}
            pagination
            isSortDefault
            isFinance
            sortIndex={1}
            currentPageIndex={currentPageIndex}
            mobileContent={({ data }) => {
              return (
                <TableMobile
                  {...data}
                  invoiceId={data.invoice?.invoice}
                  address={data.opportunity.account.address}
                />
              );
            }}
          />
        </div>
      ) : (
        <span className={styles.emptyNote}>
          {' '}
          {t('finance.pending.emptyPage', "You haven't got any unpaid jobs")}
        </span>
      )}
    </div>
  );
};
PaymentReceived.propTypes = {
  jobs: PropTypes.array.isRequired,
};

PaymentReceived.defaultProps = {};
export default PaymentReceived;
