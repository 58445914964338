import gql from 'graphql-tag';

const FullQualification = gql`
  fragment FullQualification on Qualification {
    policyNumber
    expirationDate
    carrier
    otherIssuer
    type
    contractorSfId
    contractorPgId
    accountSfId
    accountPgId
    sfId
    pgId
  }
`;

export const Qualification = {
  fragments: {
    FullQualification,
  },
};
