import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableViewLayout from 'layouts/TableView';
import caseStatus from 'enums/caseStatus';
import Spinner from 'components/shared/Spinner';
import { parseQuery } from 'utils/parseQueryString';
import tableColumns from './columns';
import WarrantyTableCard from '../TableCardMobile';

const ActiveCasesTab = ({ history, cases = [], loading }) => {
  const activeCases = useMemo(
    () =>
      cases.filter((job) =>
        [caseStatus.new, caseStatus.in_progress].includes(job.status)
      ),
    [cases]
  );

  const handleShowDetails = ({ sfId }) => {
    history.push(`/warranty/${sfId}`);
  };

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  useEffect(() => {
    const { search } = history.location;
    const query = parseQuery(search);
    if (query?.page) setCurrentPageIndex(query.page - 1);
  }, [currentPageIndex, history.location.search]);

  const { t } = useTranslation();

  const columnTitles = {
    ref: t('warranty.table.refTitle', 'Reference Number'),
    date: t('warranty.table.dateTitle', 'Repair Date'),
    address: t('warranty.table.addressTitle', 'Address'),
    name: t('warranty.table.nameTitle', 'Contact Name'),
    phone: t('warranty.table.phoneTitle', 'Contact Phone'),
    type: t('warranty.table.typeTitle', 'Case Type'),
    status: t('warranty.table.statusTitle', 'Status'),
  };

  if (loading) return <Spinner absolute />;
  return (
    <TableViewLayout
      searchValues={[
        'caseNumber',
        'account.name',
        'dateOfInstall',
        'contact.name',
        'contact.phone',
        'type',
      ]}
      tableColumns={tableColumns({
        handleClickColumn: handleShowDetails,
        columnTitles,
      })}
      dataList={activeCases}
      emptyPageText={t(
        'warranty.active.emptyPage',
        'Currently, you do not have any active warranty cases.'
      )}
      searchPlaceholder={t('warranty.search', 'Search')}
      tableOptions={{
        currentPageIndex,
        mobileContent: (tableProps, index) => {
          return (
            <WarrantyTableCard
              {...tableProps.data}
              key={index}
              handleShowDetails={() => handleShowDetails(tableProps.data)}
            />
          );
        },
      }}
    />
  );
};

ActiveCasesTab.propTypes = {
  history: PropTypes.object.isRequired,
  cases: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};
ActiveCasesTab.defaultProps = {
  cases: [],
};

export default ActiveCasesTab;
