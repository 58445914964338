import { array, boolean, number, object, string } from 'yup';
import { fromObjectToArray } from 'utils/application/restructureData/restructureCertificationData';
import { isUniquePolicyNumber } from 'utils/application/uniquePolicyNumbers';
import { qualificationTypes } from 'enums';
import { emailRegex } from '../emailRegex';

export const companyProfileSchema = object().shape({
  organization: object().shape({
    companyName: string().required(),
    companyNameDBA: string().nullable(),
    organization: string().required(),
    ownerFirstName: string().trim().required(),
    ownerLastName: string().trim().required(),
    address: string().required(),
    suiteNumber: string().trim(),
    city: string().required(),
    emailAddress: string().matches(emailRegex).trim(),
    companyPhoneNumber: string().matches(/^\d{10,10}$/, {
      message: 'Please enter valid phone number',
      excludeEmptyString: true,
    }),
    yearCompanyStarted: string().required(),
    taxID: string().required(),
    registrationState: string().required(),
    dunnBradstreetNumber: string(),
    website: string(),
    facebook: string(),
    linkedin: string(),
    twitter: string(),
  }),
  services: object()
    .shape({
      restorationVSRetail: number().required(),
      residentialVSCommercial: number().required(),
      shingle: number().required(),
      metal: number().required(),
      tile: number().required(),
      modifiedBitumenResidential: number().required(),
      builtUpMetal: number().required(),
      modifiedBitumenCommercial: number().required(),
      thermoset: number().required(),
      thermoplastic: number().required(),
      serviceOffered: string(),
    })
    .test('sum1', 'Total should be 100', (parent) => {
      const { shingle, metal, tile, modifiedBitumenResidential } = parent;
      const sum = shingle + metal + tile + modifiedBitumenResidential;
      return sum === 100 || sum === 0;
    })
    .test('sum2', 'Total should be 100', (parent) => {
      const {
        builtUpMetal,
        modifiedBitumenCommercial,
        thermoset,
        thermoplastic,
      } = parent;
      const sum = builtUpMetal + modifiedBitumenCommercial + thermoset + thermoplastic;
      return sum === 100 || sum === 0;
    }),
  coverage: object().shape({
    numberCrews: number().required(),
    numberLaborers: number().required(),
    crewAveragePerDay: number().required(),
    onsiteManagementStructure: string().required(),
    structure: string(),
    // onsiteManagementDays: string(),
    hasDumpTrailer: boolean().required(),
    staffedPullPermits: boolean().required(),
  }),
  definedWorkAreas: object()
    .shape({
      states: array().min(0),
      counties: array().min(0),
      cities: array().min(0),
    })
    .test(
      'dwa',
      'It should be required to select at least one state',
      (dwa) => Object.values(dwa).flat().length > 0
    ),
  myCertification: object()
    .test('checkNumber', { message: 'Policy number should be provided' }, (parent) => {
      const arrayOfBool = fromObjectToArray(parent).map((item) => {
        if (item.type === qualificationTypes.w9) return true;
        if (item.type === qualificationTypes.lc) return true;
        return !(item.files.length && !item.policyNumber);
      });
      return !arrayOfBool.includes(false);
    })
    .test('checkOther', { message: 'Other issue should be provided' }, (parent) => {
      const arrayOfBool = fromObjectToArray(parent).map((item) => {
        if (
          item.type === qualificationTypes.lc &&
          item.carrier === 'Other' &&
          !item.otherIssuer
        ) {
          return false;
        }
        return !(
          item.type === qualificationTypes.lc &&
          item.files.length &&
          !item.policyNumber
        );
      });
      return !arrayOfBool.includes(false);
    })
    .test(
      'checkNotLicenseNumber',
      { message: 'Each not license number should be unique' },
      (parent) => {
        const { arrayOfPolicyNumbers, uniquePolicyNumbers } = isUniquePolicyNumber(
          parent,
          [qualificationTypes.gl, qualificationTypes.wc]
        );

        return arrayOfPolicyNumbers.length === uniquePolicyNumbers.length;
      }
    )
    .test(
      'checkLicenseNumber',
      { message: 'Each license number should be unique' },
      (parent) => {
        const { arrayOfPolicyNumbers, uniquePolicyNumbers } = isUniquePolicyNumber(
          parent,
          [qualificationTypes.lc]
        );

        return arrayOfPolicyNumbers.length === uniquePolicyNumbers.length;
      }
    ),
});
