import PropTypes from "prop-types";
import React from "react";
import styles from "./style.module.css";

const Switch = ({ isEnabled, handleChange }) => {
  return (
    <div className={styles.wrapper}>
      <input
        className={styles.switch}
        type="checkbox"
        checked={isEnabled}
      />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <label
        className={styles.label}
        onClick={handleChange}
      >
        <span className={styles.button} onClick={handleChange}/>
      </label>
    </div>
  );
};

export default Switch;

Switch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool
};

Switch.defaultProps = {
  isEnabled: false
};
