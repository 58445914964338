import { formatDate } from 'utils/dateFormatters';
import { withBracketsNumber } from 'utils/phoneNumberFormatter';
import { caseStatus } from 'enums';
import styles from '../style.module.css';

export default ({ handleClickColumn = () => {}, columnTitles }) => {
  const { jobReference, installDate, address, name, phone, repair, open } = columnTitles;

  const columns = [
    {
      Header: jobReference,
      accessor: 'reference',
      onClick: handleClickColumn,
      bold: true,
    },
    {
      Header: installDate,
      accessor: 'dateOfInstall',
      onClick: handleClickColumn,
      Cell: (row) => formatDate(row.value),
    },
    {
      Header: address,
      accessor: 'opportunity.account.address',
      onClick: handleClickColumn,
      isCutText: true,
    },
    {
      Header: name,
      accessor: 'opportunity.contacts[0].name',
      onClick: handleClickColumn,
    },
    {
      Header: phone,
      accessor: 'opportunity.contacts[0].phone',
      onClick: handleClickColumn,
      Cell: (row) => {
        return withBracketsNumber(row.value);
      },
    },
    {
      Header: repair,
      accessor: 'opportunity.warrantyCases',
      onClick: handleClickColumn,
      bold: true,
      textAlignHeader: 'center',
      textAlignCell: 'center',
      Cell: (row) => {
        return row.value?.length || 0;
      },
    },
    {
      Header: open,
      accessor: 'opportunity',
      onClick: handleClickColumn,
      className: styles.yellowText,
      bold: true,
      textAlignHeader: 'center',
      textAlignCell: 'center',
      Cell: (row) => {
        return (
          row.value.warrantyCases?.filter(({ status }) =>
            [caseStatus.inProgress, caseStatus.new].includes(status)
          ).length || 0
        );
      },
    },
  ];

  return columns;
};
