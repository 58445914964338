import gql from 'graphql-tag';
import { Case } from '../../fragments';

export const GET_WARRANTY_CASE = gql`
  query getWarrantyCase($sfId: String!) {
    getWarrantyCase(sfId: $sfId) {
      ...CaseFragment
    }
  }
  ${Case.fragments.case}
`;
export const GET_WARRANTY_CASES = gql`
  query getWarrantyCases {
    getWarrantyCases {
      ...CaseFragment
    }
  }
  ${Case.fragments.case}
`;
