import client from 'graphql/client';
import { GET_USER } from 'graphql/queries/auth';

export const updateUserLangCache = ({ language }) => {
  const { getUser } = client.cache.readQuery({
    query: GET_USER,
  });
  client.cache.writeData({
    query: GET_USER,
    data: {
      getUser: { ...getUser, language },
    },
  });
};
