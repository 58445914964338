import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useDownloadFiles from 'services/hooks/useDownloadFile';
import File from 'components/shared/LinkPDF/DownloadLink';
import Text from 'components/shared/Text';
import { FormColumn } from 'components/shared/Form';
import styles from '../style.module.css';

const ActivitySectionWarranty = ({
  deliveredMaterialsComment,
  plywoodComment,
  deliveredPlanksComment,
  deliveredPlanksQuantity,
  plywoodQuantity,
  materialFiles,
  plankFiles,
  plywoodFiles,
  commentRollover,
}) => {
  const { loading, handleDownload } = useDownloadFiles();

  const renderFiles = (files) =>
    files.length > 0 && (
      <FormColumn>
        <div className={styles.content}>
          {files.map((file, index) => {
            return (
              <File
                key={index}
                fileName={`${file.name}.${file.contentType}`}
                type={file.contentType}
                loading={loading}
                handleQueryClick={() => handleDownload(file.contentDocumentId)}
              >
                {file.name}
              </File>
            );
          })}
        </div>
      </FormColumn>
    );
  const { t } = useTranslation();
  return (
    <>
      {/* Materials */}
      <FormColumn>
        <Text color="#000" size="s16" weight="wm" tag="p">
          {t(
            'jobs.warranty.details.general.activity.materials',
            'Materials were checked in at 8 a.m.'
          )}
        </Text>
      </FormColumn>
      <div className={styles.middleSection}>
        {deliveredMaterialsComment && (
          <FormColumn>
            <Text color="#000" weight="wm">
              {t('jobs.warranty.details.general.activity.note', 'Note:')}{' '}
            </Text>
            <Text color="#837F7D">{deliveredMaterialsComment}</Text>
          </FormColumn>
        )}
      </div>
      {renderFiles(materialFiles)}
      <br />

      {/* Plywoods */}
      <FormColumn>
        <Text color="#000" size="s16" weight="wm" tag="p">
          {t(
            'jobs.warranty.details.general.activity.plywood',
            'Plywoods were confirmed at 1 p.m.'
          )}
        </Text>
      </FormColumn>
      <div className={styles.middleSection}>
        {plywoodComment && (
          <FormColumn>
            <Text color="#000" weight="wm">
              {t('jobs.warranty.details.general.activity.note', 'Note:')}{' '}
            </Text>
            <Text color="#837F7D">{plywoodComment}</Text>
          </FormColumn>
        )}
        <FormColumn>
          <Text color="rgb(104, 104, 104)" size="s14">
            {t('jobs.warranty.details.general.activity.quantity', 'Quantity:')}{' '}
          </Text>
          <Text color="#000">{plywoodQuantity}</Text>
        </FormColumn>
      </div>
      {renderFiles(plywoodFiles)}

      {/* Planks */}
      <FormColumn>
        <Text color="#000" size="s16" weight="wm" tag="p">
          {t(
            'jobs.warranty.details.general.activity.planks',
            'Planks were confirmed at 1 p.m.'
          )}
        </Text>
      </FormColumn>
      <div className={styles.middleSection}>
        {deliveredPlanksComment && (
          <FormColumn>
            <Text color="#000" weight="wm">
              {t('jobs.warranty.details.general.activity.note', 'Note:')}{' '}
            </Text>
            <Text color="#837F7D">{deliveredPlanksComment}</Text>
          </FormColumn>
        )}
        <FormColumn>
          <Text color="rgb(104, 104, 104)" size="s14">
            {t('jobs.warranty.details.general.activity.quantity', 'Quantity:')}{' '}
          </Text>
          <Text color="#000">{deliveredPlanksQuantity}</Text>
        </FormColumn>
      </div>
      {renderFiles(plankFiles)}
      <br />

      {/* Status */}
      <FormColumn>
        <Text color="#000" size="s16" weight="wm" tag="p">
          {t(
            'jobs.warranty.details.general.activity.status',
            'Status was marked as Completed at 8 p.m.'
          )}
        </Text>
      </FormColumn>
      <div className={styles.middleSection}>
        {commentRollover && (
          <FormColumn>
            <Text color="#000" weight="wm">
              {t('jobs.warranty.details.general.activity.note', 'Note:')}{' '}
            </Text>
            <Text color="#837F7D">{commentRollover}</Text>
          </FormColumn>
        )}
      </div>
    </>
  );
};

ActivitySectionWarranty.propTypes = {
  deliveredMaterialsComment: PropTypes.string,
  deliveredPlanksComment: PropTypes.string,
  plywoodComment: PropTypes.string,
  commentRollover: PropTypes.string,
  deliveredPlanksQuantity: PropTypes.number.isRequired,
  plywoodQuantity: PropTypes.number.isRequired,
  materialFiles: PropTypes.array.isRequired,
  plankFiles: PropTypes.array.isRequired,
  plywoodFiles: PropTypes.array.isRequired,
};

ActivitySectionWarranty.defaultProps = {
  deliveredMaterialsComment: '',
  commentRollover: '',
  deliveredPlanksComment: '',
  plywoodComment: '',
};

export default ActivitySectionWarranty;
