import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from 'graphql/cache';
import { MY_APPLICATION } from 'graphql/queries/application';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import MyApplicationContainer from 'containers/MyApplication';
import Spinner from 'components/shared/Spinner';

const MyApplicationPage = () => {
  const { data } = useQuery(GET_CURRENT_USER);
  const { data: app } = useQuery(MY_APPLICATION);

  if (!data || !app) {
    return (
      <LayoutWithSidebar>
        <Spinner />
      </LayoutWithSidebar>
    );
  }
  return (
    <LayoutWithSidebar>
      <MyApplicationContainer user={data?.user} app={app} />
    </LayoutWithSidebar>
  );
};

export default MyApplicationPage;
