import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import DesktopTabs from 'components/shared/Tabs/Desktop';
import MobileTabs from 'components/shared/Tabs/Mobile';
import MobileContext from 'context/MobileContext';
import useTabIndex from 'services/hooks/useTabIndex';
import { useQuery } from '@apollo/react-hooks';
import AccountDetailsTab from './AccountDetailsTab';
import SettingsTab from './SettingsTab';
import styles from '../pageStyles.module.css';
import ChatTranscripts from './ChatTranscripts';
import { GET_USER } from '../../graphql/queries/auth';

const MyAccountPage = () => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const { currentTabIndex, handleClick: setCurrentTabIndex } = useTabIndex(0);
  const { data, loading } = useQuery(GET_USER);

  const handleChangePathname = (index) => {
    setCurrentTabIndex(index);
  };

  const tabsList = [
    {
      title: t('myAccount.tabName.details', 'Account Details'),
      children: (
        <div className={styles.tabContentWrapper}>
          <AccountDetailsTab user={data?.getUser} loading={loading} />
        </div>
      ),
    },
    {
      title: t('myAccount.tabName.settings', 'Settings'),
      children: (
        <div className={styles.tabContentWrapper}>
          <SettingsTab />
        </div>
      ),
    },
    {
      title: t('myAccount.tabName.messages', 'Messages'),
      children: <ChatTranscripts user={data?.getUser} />,
    },
  ];

  return (
    <LayoutWithSidebar>
      <div className={styles.container}>
        {isMobile ? (
          <MobileTabs
            tabList={tabsList}
            currentTabIndex={currentTabIndex}
            handleClick={handleChangePathname}
            isMyAccount
          />
        ) : (
          <DesktopTabs
            tabList={tabsList}
            currentTabIndex={currentTabIndex}
            handleClick={handleChangePathname}
            isMyAccount
          />
        )}
      </div>
    </LayoutWithSidebar>
  );
};

MyAccountPage.propTypes = {};

export default MyAccountPage;
