import openSocket from 'socket.io-client';

const socket = openSocket(process.env.REACT_APP_API_URI, {
  transports: ['websocket'],
});

function isOnline(user) {
  socket.emit('online', user);
}

function getSocket() {
  return socket;
}

function closeSocketConnection() {
  return socket.disconnect();
}

export { isOnline, getSocket, closeSocketConnection };
