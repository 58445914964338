import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import AppConfigContext from 'context/AppConfigContext';
import MobileContext from '../../../../context/MobileContext';
import styles from './style.module.css';
import welcomeStyles from '../../style.module.css';

const LeadGreetingBlock = ({ username, handleSubmit }) => {
  const isMobile = useContext(MobileContext);
  const config = useContext(AppConfigContext);

  return (
    <div
      className={styles.blockWrapper}
      style={{ backgroundImage: `url(${config?.signInImageUrl})` }}
    >
      <Text
        tag="h2"
        size={isMobile ? 's19' : 's24'}
        lineHeight={isMobile ? 'h22' : 'h28'}
        weight="wb"
        color="#FFFFFF"
        className={styles.greetingTitle}
      >
        Hi Peter{username}!
      </Text>
      <Text
        tag="p"
        size={isMobile ? 's14' : 's19'}
        lineHeight={isMobile ? 'h20' : 'h30'}
        weight="wb"
        color="#FFFFFF"
        className={styles.greetingText}
      >
        Thank you for your interest in joining our network. Please create an account to
        begin your application.
      </Text>
      <Button
        size="larger"
        type="submit"
        handleClick={handleSubmit}
        className={welcomeStyles.welcomeButton}
      >
        Create Account
      </Button>
    </div>
  );
};

LeadGreetingBlock.propTypes = {
  username: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

LeadGreetingBlock.defaultProps = {
  username: '',
};

export default LeadGreetingBlock;
