import React, { useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { GET_WARRANTY_CASES } from 'graphql/queries/warrantyCases';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import DesktopTabs from 'components/shared/Tabs/Desktop';
import MobileTabs from 'components/shared/Tabs/Mobile';
import MobileContext from 'context/MobileContext';
import useTabIndex from 'services/hooks/useTabIndex';
import Spinner from 'components/shared/Spinner';
import ActiveCasesTab from './ActiveCasesTab';
import HistoryCasesTab from './HistoryTab';
import styles from '../pageStyles.module.css';

const tabIndexPaths = {
  history: 1,
};

const tabNames = ['', 'history'];

const WarrantyPage = ({ location, history, ...props }) => {
  const { data, loading } = useQuery(GET_WARRANTY_CASES);

  const tabName = location.hash.slice(1);
  const { currentTabIndex, handleClick: setCurrentTabIndex } = useTabIndex(
    tabIndexPaths[tabName] || 0
  );
  const isMobile = useContext(MobileContext);

  const handleChangePathname = (index) => {
    setCurrentTabIndex(index);
    const hashTab = tabNames[index];
    history.push([history.location.pathname, hashTab ? `#${hashTab}` : ''].join(''));
  };

  useEffect(() => {
    setCurrentTabIndex(tabIndexPaths[tabName] || 0);
    history.push([history.location.pathname, tabName ? `#${tabName}` : ''].join(''));
  }, [tabName, setCurrentTabIndex, history]);

  const { t } = useTranslation();

  const tabsList = [
    {
      title: t('warranty.activeTitle', 'Active cases'),
      children: (
        <ActiveCasesTab
          {...props}
          history={history}
          cases={data?.getWarrantyCases}
          loading={loading}
        />
      ),
    },
    {
      title: t('warranty.historyTitle', 'History'),
      children: (
        <HistoryCasesTab
          {...props}
          history={history}
          cases={data?.getWarrantyCases}
          loading={loading}
        />
      ),
    },
  ];

  return (
    <LayoutWithSidebar>
      {!data ? (
        <Spinner />
      ) : (
        <div className={styles.container}>
          {isMobile ? (
            <MobileTabs
              tabList={tabsList}
              currentTabIndex={currentTabIndex}
              handleClick={handleChangePathname}
            />
          ) : (
            <DesktopTabs
              tabList={tabsList}
              currentTabIndex={currentTabIndex}
              handleClick={handleChangePathname}
            />
          )}
        </div>
      )}
    </LayoutWithSidebar>
  );
};

WarrantyPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default WarrantyPage;
