import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const FormColumn = ({ children, fixHeight, className }) => (
  <div
    className={[styles.formColumn, fixHeight ? styles.formColumnFixHeight : '', className].join(' ')}
  >
    {children}
  </div>
);

const FormRow = ({ children, type = 'primary', className }) => (
  <div
    className={[
      styles.formRow,
      type === 'primary'
        ? styles.formRowPrimary
        : type === 'secondary'
        ? styles.formRowSecondary
        : styles.formRowSingle,
      className
    ].join(' ')}
  >
    {children}
  </div>
);

const FormFooter = ({ children, isLast }) => (
  <div className={[styles.formFooter, isLast ? styles.formFooterLast : ''].join(' ')}>
    {children}
  </div>
);

const FormContainer = ({ children }) => (
  <div className={styles.formContainer}>{children}</div>
);

const FormContent = ({ children }) => (
  <div className={styles.formContent}>{children}</div>
);

export { FormColumn, FormRow, FormFooter, FormContainer, FormContent };

FormColumn.propTypes = {
  children: PropTypes.node.isRequired,
  fixHeight: PropTypes.bool,
  className: PropTypes.string
};

FormColumn.defaultProps = {
  fixHeight: false,
  className: ''
};

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.string
};

FormRow.defaultProps = {
  type: 'primary',
  className: ''
};

FormFooter.propTypes = {
  children: PropTypes.node.isRequired,
  isLast: PropTypes.bool,
};

FormFooter.defaultProps = {
  isLast: false,
};

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

FormContent.propTypes = {
  children: PropTypes.node.isRequired,
};
