export function restructureInitData(qualifications, downloadFiles, type) {
  const qualificationsWithFiles = qualifications
    ?.filter((item) => item.type === type)
    .map((item) => ({
      ...item,
      files: downloadFiles.filter((file) => file?.parentSfId === item.sfId),
    }));
  if (!qualificationsWithFiles?.length)
    return {
      docs_0: {
        policyNumber: '',
        carrier: '',
        expirationDate: null,
        files: [],
        otherIssuer: '',
        type,
        sfId: '',
      },
    };
  const initValue = qualificationsWithFiles.map(
    (
      { accountSfId, accountPgId, contractorPgId, contractorSfId, pgId, ...rest },
      index
    ) => ({
      [`docs_${index}`]: { ...rest },
    })
  );
  let rez = {};
  initValue.forEach((item) => {
    rez = { ...rez, ...item };
  });
  return rez;
}
