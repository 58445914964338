import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TableViewLayout from 'layouts/TableView';
import Spinner from 'components/shared/Spinner';
import caseStatus from 'enums/caseStatus';
import tableColumns from './columns';
import WarrantyTableCard from '../TableCardMobile';

const HistoryCasesTab = ({ history, cases = [], loading }) => {
  const historyCases = useMemo(
    () => cases.filter((job) => job.status === caseStatus.completed),
    [cases]
  );
  const handleShowDetails = ({ sfId }) => {
    history.push(`/warranty/${sfId}`);
  };
  const { t } = useTranslation();

  const columnTitles = {
    ref: t('warranty.table.refTitle', 'Reference Number'),
    date: t('warranty.table.dateTitle', 'Repair Date'),
    address: t('warranty.table.addressTitle', 'Address'),
    name: t('warranty.table.nameTitle', 'Contact Name'),
    phone: t('warranty.table.phoneTitle', 'Contact Phone'),
    type: t('warranty.table.typeTitle', 'Case Type'),
  };

  if (loading) return <Spinner absolute />;

  return (
    <TableViewLayout
      searchValues={[
        'caseNumber',
        'account.name',
        'dateOfInstall',
        'contact.name',
        'contact.phone',
        'type',
      ]}
      dataList={historyCases}
      tableColumns={tableColumns({
        handleClickColumn: handleShowDetails,
        columnTitles,
      })}
      emptyPageText={t(
        'warranty.history.emptyPage',
        'Currently, you do not have any closed warranty cases.'
      )}
      searchPlaceholder={t('warranty.search', 'Search')}
      tableOptions={{
        mobileContent: (tableProps, index) => {
          return (
            <WarrantyTableCard
              {...tableProps.data}
              key={index}
              handleShowDetails={() => handleShowDetails(tableProps.data)}
            />
          );
        },
      }}
    />
  );
};

HistoryCasesTab.propTypes = {
  history: PropTypes.object.isRequired,
  cases: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};
HistoryCasesTab.defaultProps = {
  cases: [],
};
export default HistoryCasesTab;
