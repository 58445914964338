import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Field from 'components/shared/Field';
import { FormColumn as Col, FormRow as Row } from 'components/shared/Form';
import InputMask from 'react-input-mask';
import styles from './style.module.css';

const UserForm = ({ values = {}, handleChange, errors = {}, touched = {} }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col>
          <Field
            name="firstName"
            type="text"
            label={t('myAccount.details.editMode.firstLabel', 'First Name')}
            value={values.firstName}
            onChange={(e) => handleChange(e, 'firstName')}
            isError={touched?.firstName && Boolean(errors?.firstName)}
          />
        </Col>
        <Col>
          <Field
            name="lastName"
            type="text"
            label={t('myAccount.details.editMode.lastLabel', 'Last Name')}
            value={values.lastName}
            onChange={(e) => handleChange(e, 'lastName')}
            isError={touched?.lastName && Boolean(errors?.lastName)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name="email"
            type="email"
            label={t('myAccount.details.editMode.emailLabel', 'Email Address')}
            isDisabled
            value={values.email}
            onChange={(e) => handleChange(e, 'email')}
            isError={touched?.email && Boolean(errors?.email)}
          />
        </Col>
        <Col>
          <InputMask
            mask="(999) 999-9999"
            value={values.phoneNumber}
            onChange={(e) =>
              handleChange(
                {
                  target: {
                    name: e.target.name,
                    value: e.target.value.replace(/\D/g, ''),
                  },
                },
                'phoneNumber'
              )
            }
          >
            {() => (
              <Field
                name="phoneNumber"
                type="tel"
                label={t('myAccount.details.editMode.phoneLabel', 'Phone Number')}
                inputMode="tel"
                autoComplete="tel"
                isError={touched?.phoneNumber && Boolean(errors?.phoneNumber)}
              />
            )}
          </InputMask>
        </Col>
        <Col>
          <Field
            name="role"
            type="text"
            label={t('myAccount.details.editMode.roleLabel', 'Role')}
            value={values.contractorRole}
            isDisabled
          />
        </Col>
      </Row>
    </div>
  );
};

UserForm.propTypes = {
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.object,
  values: PropTypes.object,
};

UserForm.defaultProps = {
  errors: {},
  touched: {},
  values: {},
};

export default UserForm;
