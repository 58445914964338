import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { PAYMENT_DATA_DASHBOARD } from 'graphql/queries/payment';
import { useRole } from 'services/hooks/useRole';
import roles from 'enums/roles';
import HeaderBanner from './HeaderBanner';
// import RelatedArticles from './RelatedArticles';
import MonthPerformance from './MonthPerformance';
import styles from './styles.module.css';
import Jobs from './Jobs/AllJobs';
import Incomes from './Incomes';

const MainContent = ({ jobs, caseCount, notPaidJobsCount, upcomingJobsCount }) => {
  const { userRole, sfId } = useRole();
  const { data } = useQuery(PAYMENT_DATA_DASHBOARD);
  const completedPaidJobs = data?.paymentDataDashboard;
  return (
    <div className={styles.container}>
      <HeaderBanner />
      {userRole !== roles.crew ? (
        <MonthPerformance
          userRole={userRole}
          caseCount={caseCount}
          notPaidJobsCount={notPaidJobsCount}
          upcomingJobsCount={upcomingJobsCount}
        />
      ) : null}

      {userRole !== roles.superintendent ? (
        <Jobs jobs={jobs} userRole={userRole} sfId={sfId} />
      ) : null}

      {userRole === roles.admin || userRole === roles.finance ? (
        <div className={styles.incomeWrapper}>
          <Incomes completedPaidJobs={completedPaidJobs} />
        </div>
      ) : null}

      {/* <RelatedArticles /> */}
    </div>
  );
};

MainContent.propTypes = {
  jobs: PropTypes.array.isRequired,
  caseCount: PropTypes.number,
  notPaidJobsCount: PropTypes.number,
  upcomingJobsCount: PropTypes.number,
};
MainContent.defaultProps = {
  caseCount: 0,
  notPaidJobsCount: 0,
  upcomingJobsCount: 0,
};

export default MainContent;
