import client from 'graphql/client';
import {
  AVAILABLE_OPPORTUNITIES,
  AWARDED_OPPORTUNITIES,
} from 'graphql/queries/opportunities';
import { GET_ATTACHMENTS } from 'graphql/queries/file';

export const updateCurrentJobList = (id, data, options) => {
  const query = options?.isAwarded ? AWARDED_OPPORTUNITIES : AVAILABLE_OPPORTUNITIES;
  const { workOrders } = client.cache.readQuery({
    query,
  });

  const updatedJobList = workOrders.map((job) => {
    if (job.sfId === id) return { ...job, ...data };
    return job;
  });

  return client.cache.writeQuery({
    query,
    data: { workOrders: updatedJobList },
  });
};

export const updateFileListJob = (id, newFiles) => {
  const { files } = client.cache.readQuery({
    query: GET_ATTACHMENTS,
    variables: { contentDocumentId: id },
  });

  const updatedFiles = [files, newFiles].flat();

  return client.cache.writeQuery({
    query: GET_ATTACHMENTS,
    variables: { contentDocumentId: id },
    data: { files: updatedFiles },
  });
};
