import React, { useEffect } from 'react';
import { authCallback } from 'utils/authCallback';

const OAuthSuccess = () => {
  const params = new URL(document.location).searchParams;
  const token = params.get('token');

  useEffect(() => {
    if (token) {
      authCallback({
        token,
        isKeepLogged: true,
      });
      localStorage.setItem('logged', 'true');
    }
    else {
      window.location.replace('/sign-in')
    }
  }, [token]);
  return <></>;
};

export default OAuthSuccess;
