import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import styles from './styles.module.css';

const ProgressBar = ({
  progress = '0',
  width,
  height,
  fontSize,
  bgColor,
  borderRadius,
  className,
}) => {
  return (
    <div className={className}>
      <div className={styles.wrapper} style={{ width, height, borderRadius }}>
        <div
          className={styles.filler}
          style={{ width: `${progress}%`, backgroundColor: bgColor }}
        />
        <Text
          weight="wb"
          style={{ fontSize }}
          className={styles.progressLabel}
        >{`${progress}%`}</Text>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  bgColor: PropTypes.string,
  borderRadius: PropTypes.string,
  className: PropTypes.string,
};

ProgressBar.defaultProps = {
  width: '570px',
  height: '40px',
  fontSize: '24px',
  bgColor: '#f1c400',
  borderRadius: '50px',
  className: null,
};

export default ProgressBar;
