import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { GET_WARRANTY_CASE } from 'graphql/queries/warrantyCases';
import {
  UPDATE_APPOINTMENT_TIME_MUTATION,
  UPDATE_STATUS_MUTATION,
} from 'graphql/mutations/warrantyCases';
import Spinner from 'components/shared/Spinner';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import { WarrantyDetailsPage } from 'pages';
import { GET_LAT_LNG_FROM_ADDRESS } from '../../graphql/queries/maps';

const WarrantyDetailsPageContainer = (props) => {
  const { match } = props;
  const [address, setAddress] = useState(null);
  const [coords, setCoords] = useState({});
  const { data, loading } = useQuery(GET_WARRANTY_CASE, {
    variables: {
      sfId: match.params.id,
    },
    onCompleted: () => {
      if (data?.getWarrantyCase) {
        const {
          getWarrantyCase: { account },
        } = data;
        setAddress(
          `${account?.billingStreet}, ${account?.billingCity}, ${account?.billingState}`
        );
      }
    },
  });

  const [getLatLngFromAddressData, { data: latLngFromAddressData }] = useLazyQuery(
    GET_LAT_LNG_FROM_ADDRESS,
    {
      fetchPolicy: `network-only`,
      variables: {
        q: address,
      },
      onCompleted: () => {
        const location = latLngFromAddressData?.getLatLngFromAddress;
        if (location) {
          setCoords({
            lat: parseFloat(location.lat),
            lng: parseFloat(location.lng),
          });
        }
      },
    }
  );

  useEffect(() => {
    getLatLngFromAddressData(address);
  }, [address]);

  const [updateAppointmentTime, { loading: updateTimeLoading }] = useMutation(
    UPDATE_APPOINTMENT_TIME_MUTATION
  );

  const [updateStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_STATUS_MUTATION
  );

  const handleUpdateTime = async (sfId, repairDateTime) => {
    await updateAppointmentTime({
      variables: {
        sfId,
        repairDateTime,
      },
      update: (cache, { data: { updateAppointmentTime: appointmentTime } }) => {
        const { getWarrantyCase } = cache.readQuery({
          query: GET_WARRANTY_CASE,
          variables: {
            sfId,
            repairDateTime,
          },
        });

        getWarrantyCase.repairDateTime = appointmentTime.repairDateTime;

        cache.writeQuery({
          query: GET_WARRANTY_CASE,
          variables: {
            sfId,
            repairDateTime,
          },
          data: { getWarrantyCase },
        });
      },
    });
  };

  const handleUpdateStatus = async (sfId) => {
    await updateStatus({
      variables: {
        sfId,
      },
      update: (cache, { data: { updateStatus: result } }) => {
        const { getWarrantyCase } = cache.readQuery({
          query: GET_WARRANTY_CASE,
          variables: {
            sfId,
          },
        });

        getWarrantyCase.status = result.status;

        cache.writeQuery({
          query: GET_WARRANTY_CASE,
          variables: {
            sfId,
          },
          data: { getWarrantyCase },
        });
      },
    });
  };

  return loading || updateTimeLoading || updateStatusLoading ? (
    <LayoutWithSidebar>
      <Spinner />
    </LayoutWithSidebar>
  ) : (
    <WarrantyDetailsPage
      warrantyCase={data?.getWarrantyCase}
      coords={coords}
      handleUpdateStatus={handleUpdateStatus}
      handleUpdateTime={handleUpdateTime}
    />
  );
};
WarrantyDetailsPageContainer.propTypes = {
  match: PropTypes.object.isRequired,
};
export default WarrantyDetailsPageContainer;
