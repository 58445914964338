export const isTabFilled = (tabValuesObject, optionalFields = [], comparators = []) => {
  const tabEntries = Object.entries(tabValuesObject);
  const fieldNamesWithCustomComparator = comparators.map((c) => c.field);

  const onlyMandatoryValues = tabEntries.filter(
    (tabEntry) =>
      !optionalFields.includes(tabEntry[0]) &&
      !fieldNamesWithCustomComparator.includes(tabEntry[0])
  );

  const emptyFieldsWithCustomComparator = comparators.filter(
    (f) => !f.cb(tabValuesObject[f.field])
  );

  const emptyFields = onlyMandatoryValues.filter(([, value]) => {
    if (Array.isArray(value)) {
      return !value.length;
    }

    if (typeof value === 'object' && !(value instanceof Array) && value !== null) {
      return Object.values(value)?.some((el) => !el.length);
    }
    return value === '' || value === null;
  });

  return (
    (1 -
      (emptyFields.length + emptyFieldsWithCustomComparator.length) /
        onlyMandatoryValues.length) *
    100
  );
};
