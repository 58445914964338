import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRoofTypeLocal, useAppConfigLocal } from 'localization';
import LineText from 'components/common/LineText';
import GoogleMap from 'components/common/GoogleMap';
import { formatDate } from 'utils/dateFormatters';
import Text from 'components/shared/Text';
import AppConfigContext from 'context/AppConfigContext';
import styles from '../style.module.css';

const InformationSection = ({
  number,
  address,
  location,
  dateOfInstall,
  existingShingleType: roofType,
  awardAmount,
}) => {
  const centerLocation = location;
  const config = useContext(AppConfigContext);

  const { t } = useTranslation();
  const appConfigLocal = useAppConfigLocal();
  const roofTypeLocal = useRoofTypeLocal();
  return (
    <>
      <div className={[styles.headerBlockSpace, styles.mapBlock].join(' ')}>
        <div className={styles.smallContainer}>
          <LineText
            name={t('jobs.available.details.info.ref', 'Reference Number')}
            value={number}
            isFixed
          />
          <LineText
            name={t('jobs.available.details.info.location', 'Location')}
            value={address}
            isFixed
          />

          <LineText
            name={t('jobs.available.details.info.type', 'Roof Type')}
            value={(roofTypeLocal[roofType], roofType)}
            isFixed
          />

          <LineText
            name={t('jobs.available.details.info.amount', 'Award Amount')}
            value={awardAmount ? `$ ${Intl.NumberFormat().format(awardAmount)}` : null}
            isValueBold
            isFixed
          />

          <LineText
            name={t('jobs.available.details.info.date', 'Date of install')}
            value={formatDate(dateOfInstall)}
            isFixed
          />
        </div>
        <div className={styles.mapContainer}>
          <GoogleMap
            zones={[
              {
                center: centerLocation,
                radius: 5000,
              },
            ]}
            centerLocation={centerLocation}
            zoomLevel={11}
          />
        </div>
      </div>
      <Text color="#00000D" weight="wm" size="s16">
        {appConfigLocal[config.textPermit]}
      </Text>
    </>
  );
};

InformationSection.propTypes = {
  number: PropTypes.string,
  location: PropTypes.object,
  dateOfInstall: PropTypes.string,
  existingShingleType: PropTypes.string,
  awardAmount: PropTypes.number,
  address: PropTypes.string,
};

InformationSection.defaultProps = {
  number: '',
  location: {},
  dateOfInstall: '',
  existingShingleType: '',
  awardAmount: '',
  address: '',
};

export default InformationSection;
