import { useTranslation } from 'react-i18next';

export default function useOrganizations() {
  const { t } = useTranslation();
  return [
    {
      value: 'Sole proprietor',
      label: t('myApplication.firstTab.p_3', 'Sole proprietor'),
    },
    {
      value: 'Partnership',
      label: t('myApplication.firstTab.p_4', 'Partnership'),
    },
    {
      value: 'LLC',
      label: 'LLC - Limited Liability Company',
    },
    {
      value: 'S-Corp',
      label: 'S-Corp',
    },
    {
      value: 'C-Corp',
      label: 'C-Corp',
    },
  ];
}
