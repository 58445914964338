import gql from 'graphql-tag';

export const GET_MEMBERS = gql`
  query members {
    members {
      pgId
      firstName
      lastName
      email
      phone
      role
      status
      sfId
    }
  }
`;
