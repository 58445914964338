import gql from 'graphql-tag';

export const GET_ADDRESS_PREDICTION = gql`
  query getAddressPrediction($input: String!) {
    getAddressPrediction(input: $input) {
      status
      predictions {
        description
        place_id
      }
    }
  }
`;

export const GET_ADDRESS_BY_COORDS = gql`
  query getAddressByCoords($latlng: String!) {
    getAddressByCoords(latlng: $latlng) {
      city
      state
      zipCode
      formattedAddress
      placeID
      street
      country
    }
  }
`;

export const GET_ADDRESS_DETAILS = gql`
  query getAddressDetails($placeID: String!) {
    getAddressDetails(placeID: $placeID) {
      city
      state
      zipCode
      country
      street
      latitude
      longitude
    }
  }
`;

export const GET_LAT_LNG_FROM_ADDRESS = gql`
  query getLatLngFromAddress($q: String) {
    getLatLngFromAddress(q: $q) {
      lat
      lng
    }
  }
`;
