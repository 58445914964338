import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { roles, applicationStatus } from 'enums';
import { isAllowAction } from 'services/permissions';
import AppStatusContext from 'context/AppStatusContext';
import MobileContext from 'context/MobileContext';
import AuthRoleContext from 'context/AuthRoleContext';
import { useDefinedWorkArea } from 'services/hooks/useDefinedWorkArea';
import { setCurrentTab } from 'utils/companyProfile/setCurrentTab';
import { useDetLang } from 'services/localization';
import Details from './Details';
import Services from './Services';
import Coverage from './Coverage';
import DefinedWorkAreas from './DefinedWorkAreas';
import DocumentsTab from './DocumentsTab';
import MobileTabs from '../shared/Tabs/Mobile';
import Tabs from '../shared/Tabs/Desktop';
import Button from '../shared/Button';
import Text from '../shared/Text';
import styles from './style.module.css';

const CompanyProfile = ({
  handleCancel,
  handleSubmit,
  handleChange,
  qualificationSfId,
  loadingAttachments,
  errors,
  touched,
  blockHistory,
  isShowSaveChangesTooltip,
  ...formikProps
}) => {
  const isMobile = useContext(MobileContext);
  const userRole = useContext(AuthRoleContext);

  const [isReadOnlyInput] = useState(!isAllowAction([roles.admin], userRole));

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {
    setCurrentTab(errors, touched, setCurrentTabIndex);
  }, [errors, touched]);

  const { units, setUnits, catchmentUnits, setCatchmentUnits } = useDefinedWorkArea(
    formikProps.initialValues.definedWorkAreas
  );

  useEffect(() => {
    formikProps.setFieldValue('definedWorkAreas', catchmentUnits);
  }, [catchmentUnits]);

  const tabsProps = {
    errors,
    touched,
    blockHistory,
    ...formikProps,
  };

  const { t } = useTranslation();
  const buttonSize = useDetLang('medium', 'small');

  const tabList = [];
  if (isAllowAction([roles.admin, roles.finance, roles.superintendent], userRole)) {
    tabList.push({
      title: t('companyProfile.details.title', 'Details'),
      children: (
        <Details
          isReadOnlyInput={isReadOnlyInput}
          {...tabsProps}
          handleChange={(event) => handleChange('organization', event)}
        />
      ),
    });
  }
  if (isAllowAction([roles.admin, roles.finance], userRole)) {
    tabList.push(
      {
        title: t('companyProfile.services.title', 'Services'),
        children: <Services isReadOnlyInput={isReadOnlyInput} {...tabsProps} />,
      },
      {
        title: t('companyProfile.coverage.title', 'Coverage'),
        children: (
          <Coverage
            isReadOnlyInput={isReadOnlyInput}
            {...tabsProps}
            handleChange={(event) => handleChange('coverage', event)}
          />
        ),
      }
    );
  }
  if (isAllowAction([roles.admin, roles.finance, roles.superintendent], userRole)) {
    tabList.push({
      title: t('companyProfile.workArea.title', 'Defined Work Areas'),
      children: (
        <DefinedWorkAreas
          isReadOnlyInput={isReadOnlyInput}
          units={units}
          setUnits={setUnits}
          catchmentUnits={catchmentUnits}
          setCatchmentUnits={setCatchmentUnits}
          blockHistory={blockHistory}
        />
      ),
    });
  }
  if (isAllowAction([roles.admin, roles.finance], userRole)) {
    tabList.push({
      title: t('companyProfile.documents.title', 'Documents'),
      children: (
        <DocumentsTab
          {...tabsProps}
          handleChange={handleChange}
          qualificationSfId={qualificationSfId}
          loadingAttachments={loadingAttachments}
        />
      ),
    });
  }
  const { appStatus } = useContext(AppStatusContext);

  return (
    <div>
      {isMobile ? (
        <MobileTabs
          tabList={tabList}
          currentTabIndex={currentTabIndex}
          handleClick={setCurrentTabIndex}
          isContractorsStyle
        />
      ) : (
        <Tabs
          tabList={tabList}
          currentTabIndex={currentTabIndex}
          handleClick={setCurrentTabIndex}
        />
      )}
      {isAllowAction(roles.admin, userRole) && appStatus === applicationStatus.approved && (
        <section className={styles.buttonBlockWrapper}>
          <div className={styles.buttonBlockFlex}>
            <div className={`${styles.buttonBlock} ${styles.buttonBlockLeft}`}>
              <Button className={styles.cancelButton} handleClick={handleCancel}>
                {t('cancelButton', 'Cancel')}
              </Button>
            </div>
            <div className={`${styles.buttonBlock} ${styles.buttonBlockRight}`}>
              <Button
                handleClick={() => {
                  handleSubmit();
                }}
                className={[styles.submitButton, styles.btnTooltip].join(' ')}
                size={buttonSize}
              >
                {t('saveChangesButton', 'Save changes')}
              </Button>

              <Text
                color="#ffffff"
                className={[
                  styles.btnTooltipText,
                  isShowSaveChangesTooltip ? styles.btnTooltipTextActive : '',
                ].join(' ')}
              >
                {t('companyProfile.saved', 'Your changes have been saved')}
              </Text>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
CompanyProfile.propTypes = {
  values: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  blockHistory: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  qualificationSfId: PropTypes.array.isRequired,
  loadingAttachments: PropTypes.bool.isRequired,
  isShowSaveChangesTooltip: PropTypes.bool.isRequired,
};
export default CompanyProfile;
