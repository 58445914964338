import jobStatus from '../../enums/workOrderStatus';

export function isHideButton(status) {
  const finishStatus = [
    jobStatus.completed,
    jobStatus.reschedule_requested,
    jobStatus.closed,
    jobStatus.canceled,
  ];
  return finishStatus.includes(status);
}
