import gql from 'graphql-tag';
import { Opportunity } from '../../fragments';

export const GET_OPPORTUNITY = gql`
  query GetOpportunity($id: String!) {
    job(id: $id) @client
  }
`;

export const AVAILABLE_OPPORTUNITIES = gql`
  query {
    workOrders(type: "available") {
      number
      sfId
      status
      reference
      requestedAccountSfId
      description
      dateOfInstall
      amount

      opportunity {
        ...OpportunityFragment
      }
    }
  }
  ${Opportunity.fragments.entry}
`;

export const AWARDED_OPPORTUNITIES = gql`
  query {
    workOrders(type: "awarded") {
      ...JobFragment
    }
  }
  ${Opportunity.fragments.jobEntry}
`;

export const WARRANTY_OPPORTUNITIES = gql`
  query workOrders($offset: Int, $limit: Int) {
    workOrders(type: "warranty", offset: $offset, limit: $limit) {
      ...JobFragment
    }
  }
  ${Opportunity.fragments.jobEntry}
`;

export const CURRENT_JOB = (isAllowMembers) => {
  if (isAllowMembers)
    return gql`
      query currentAwardedJob($contentDocumentId: String!) {
        currentJob(id: $contentDocumentId) {
          ...JobFragment
        }
        files(contentDocumentId: $contentDocumentId) {
          contentDocumentId
          name
          description
          contentType
        }
        members {
          name
          sfId
          role
        }
      }
      ${Opportunity.fragments.jobEntry}
    `;

  return gql`
    query currentAwardedJob($contentDocumentId: String!) {
      currentJob(id: $contentDocumentId) {
        ...JobFragment
      }
      files(contentDocumentId: $contentDocumentId) {
        contentDocumentId
        name
        description
        contentType
      }
    }
    ${Opportunity.fragments.jobEntry}
  `;
};
