import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import DesktopTabs from 'components/shared/Tabs/Desktop';
import MobileTabs from 'components/shared/Tabs/Mobile';
import MobileContext from 'context/MobileContext';
import AuthRoleContext from 'context/AuthRoleContext';
import useTabIndex from 'services/hooks/useTabIndex';
import { parseQuery, stringifyQuery } from 'utils/parseQueryString';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from 'graphql/cache';
import Spinner from 'components/shared/Spinner';
import AvailableJobsTab from './AvailableJobsTab';
import AwardedJobsTab from './AwardedJobsTab';
import WarrantyJobsTab from './WarrantyJobsTab';
import styles from '../pageStyles.module.css';

const tabIndexPaths = {
  awarded: 1,
  warranty: 2,
};
const activeViews = {
  table: '',
  map: 'map',
};

const tabNames = ['', 'awarded', 'warranty'];

const JobListPage = ({ location, history, ...props }) => {
  const query = parseQuery(location.search);
  const { currentTabIndex, handleClick: setCurrentTabIndex } = useTabIndex(
    tabIndexPaths[query?.tab] || 0
  );
  const [currentTabView, setCurrentTabView] = useState(
    activeViews[query?.view] || activeViews.table
  );
  const isMobile = useContext(MobileContext);
  const userCurrentRole = useContext(AuthRoleContext);

  const handleChangePathname = ({ tabIndex, tabView }) => {
    const tabName = tabNames[tabIndex];
    history.push(
      [
        history.location.pathname,
        stringifyQuery({
          tab: tabName,
          view: tabView,
        }),
      ].join('')
    );
  };

  useEffect(() => {
    setCurrentTabIndex(tabIndexPaths[query.tab] || 0);
    setCurrentTabView(query.view || activeViews.table);
  }, [query.view, query.tab, setCurrentTabIndex]);

  const handleChangeTab = (index) => {
    handleChangePathname({ tabIndex: index, tabView: activeViews.table });
  };

  const tabProps = {
    ...props,
    history,
    currentView: currentTabView,
    currentTabIndex: query.page - 1,
    userCurrentRole: userCurrentRole || '',
    handleChangeView: (value) => {
      handleChangePathname({ tabIndex: currentTabIndex, tabView: activeViews[value] });
    },
  };

  const { t } = useTranslation();
  const tabsList = [
    {
      title: t('jobs.availableTitle', 'Available jobs'),
      children: <AvailableJobsTab {...tabProps} />,
    },
    {
      title: t('jobs.awardedTitle', 'Awarded jobs'),
      children: <AwardedJobsTab {...tabProps} />,
    },
    {
      title: t('jobs.warrantyTitle', 'Warranty jobs'),
      children: <WarrantyJobsTab {...tabProps} />,
    },
  ];
  const { data } = useQuery(GET_CURRENT_USER);
  return (
    <LayoutWithSidebar>
      {!data ? (
        <Spinner />
      ) : (
        <div className={styles.container}>
          {isMobile ? (
            <MobileTabs
              tabList={tabsList}
              currentTabIndex={currentTabIndex}
              handleClick={handleChangeTab}
            />
          ) : (
            <DesktopTabs
              tabList={tabsList}
              currentTabIndex={currentTabIndex}
              handleClick={handleChangeTab}
            />
          )}
        </div>
      )}
    </LayoutWithSidebar>
  );
};

JobListPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default JobListPage;
