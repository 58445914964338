import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import {
  FormContent,
  FormColumn as Col,
  FormRow as Row,
  FormFooter,
} from 'components/shared/Form';
import InputMask from 'react-input-mask';
import formStyles from 'components/Auth/style.module.css';
import Modal from 'components/shared/modals/FormModal';
import Field from 'components/shared/Field';
import FormHeader from 'components/Form/FormHeader';
import { roles } from 'enums';
import Button from 'components/shared/Button';
import { editMemberSchema } from 'validation/members';
import Icon from 'components/shared/IconSVG';
import Text from 'components/shared/Text';
import { warningIcon } from 'assets/icons';
import { useRolesOptionsLocal, useUserStatusLocal } from 'localization';
import styles from '../style.module.css';
import { getStatusNameByValue, getStatusValueByName } from '..';
import { GET_MEMBERS } from '../../../graphql/queries/members';
import stylesModal from './style.module.css';

const EditMemberModal = ({
  isShow,
  handleClose,
  data,
  handleSubmit: handleSubmitEdit,
  loading,
  resetSelectedEntry,
}) => {
  const _handleSubmit = async ({ sfId, ...payload }) => {
    await handleSubmitEdit({
      variables: { member: payload },
      update: (proxy, { data: { changeMember: member } }) => {
        const { members } = proxy.readQuery({ query: GET_MEMBERS });

        const updatedMemberId = members.findIndex((m) => m.pgId === payload.pgId);
        const updatedMembers = [
          ...members.slice(0, updatedMemberId),
          member,
          ...members.slice(updatedMemberId + 1),
        ];

        proxy.writeQuery({
          query: GET_MEMBERS,
          data: { members: updatedMembers },
        });
        handleClose();
        resetSelectedEntry();
      },
    });
  };
  const { t } = useTranslation();
  const rolesOptionsLocal = useRolesOptionsLocal();
  const userStatusLocal = useUserStatusLocal();
  return (
    <Formik
      initialValues={data}
      validationSchema={editMemberSchema}
      validateOnMount
      onSubmit={_handleSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        errors,
        touched,
        setFieldValue,
        dirty,
      }) => {
        return (
          <form>
            <Modal
              isShow={isShow}
              className={stylesModal.modalWrapper}
              title={t('members.edit.title', 'Edit Contact')}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            >
              <FormHeader>
                <div className={stylesModal.noteSection}>
                  <Icon
                    src={warningIcon}
                    height="18px"
                    width="18px"
                    className={stylesModal.noteIcon}
                  />
                  <Text
                    tag="p"
                    color="#9f9f9f"
                    className={stylesModal.noteMessage}
                    weight="wr"
                    size="s14"
                    lineHeight="h16"
                  >
                    {t(
                      'members.edit.pleaseContact',
                      'Please contact RoofClaim if you would like to update email address'
                    )}
                  </Text>
                </div>
              </FormHeader>
              <FormContent>
                <Row>
                  <Col>
                    <Field
                      type="text"
                      label={t('members.edit.firstLabel', 'First Name')}
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isError={touched.firstName && Boolean(errors.firstName)}
                    />
                  </Col>

                  <Col>
                    <Field
                      type="text"
                      label={t('members.edit.lastLabel', 'Last Name')}
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isError={touched.lastName && Boolean(errors.lastName)}
                    />
                  </Col>
                </Row>

                <Col>
                  <Field
                    label={t('members.edit.emailLabel', 'Email Address')}
                    type="email"
                    inputMode="email"
                    autoComplete="email"
                    name="email"
                    value={values.email}
                    isDisabled
                    isError={touched.email && Boolean(errors.email)}
                    onChange={handleChange}
                  />
                </Col>

                <Col>
                  <InputMask
                    mask="(999) 999-9999"
                    value={values.phone}
                    onChange={(e) => {
                      setFieldValue('phone', e.target.value.replace(/\D/g, ''));
                    }}
                  >
                    {() => (
                      <Field
                        label={t('members.edit.phoneLabel', 'Mobile Number')}
                        type="tel"
                        inputMode="tel"
                        autoComplete="tel"
                        name="phone"
                        isError={touched.phone && Boolean(errors.phone)}
                      />
                    )}
                  </InputMask>
                </Col>

                <Row>
                  <Col>
                    <Field
                      label={t('members.edit.roleLabel', 'Role')}
                      type="select"
                      inputMode="search"
                      name="role"
                      value={values.role}
                      isError={touched.role && Boolean(errors.role)}
                      onChange={handleChange}
                      options={rolesOptionsLocal}
                    />
                  </Col>
                  <Col>
                    <div className={styles.statusFormWrapper}>
                      <Field
                        label={t('members.edit.statusLabel', 'Status')}
                        type="switch"
                        name="status"
                        isEnabled={getStatusValueByName(values.status)}
                        handleChange={() => {
                          const currentStatusValue = getStatusValueByName(values.status);
                          const nextStatusName = getStatusNameByValue(
                            !currentStatusValue
                          );
                          setFieldValue('status', nextStatusName);
                        }}
                      />
                      {userStatusLocal[values.status]}
                    </div>
                  </Col>
                </Row>
              </FormContent>
              <FormFooter>
                <div className={formStyles.formFooter}>
                  <Button
                    className={stylesModal.button}
                    size="medium"
                    handleClick={handleSubmit}
                    disabled={Boolean(Object.keys(errors)?.length) || !dirty || loading}
                  >
                    {t('members.edit.saveButton', 'Save')}
                  </Button>
                  <Button
                    size="medium"
                    color="white"
                    handleClick={() => {
                      resetSelectedEntry();
                      handleClose();
                    }}
                  >
                    {t('cancelButton', 'Cancel')}
                  </Button>
                </div>
              </FormFooter>
            </Modal>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditMemberModal;

EditMemberModal.propTypes = {
  data: PropTypes.shape({
    pgId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  resetSelectedEntry: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isShow: PropTypes.bool,
  loading: PropTypes.bool,
};

EditMemberModal.defaultProps = {
  isShow: false,
  loading: true,
};
