import { useContext } from 'react';
import LanguageContext from 'context/LanguageContext';

export const getLocalKey = (translatedObject, key) => {
  const arr = Object.entries(translatedObject).filter((item) => item[1] === key);
  return arr[0][0];
};

export const useDetLang = (localValue, defValue) => {
  const { language } = useContext(LanguageContext);
  return language === 'es' ? localValue : defValue;
};
