import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import zxcvbn from 'zxcvbn';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import Link from 'components/shared/Link';
import { FormColumn, FormFooter } from 'components/shared/Form';
import Field from 'components/shared/Field';
import PasswordStrength from '../PasswordStrength';
import styles from './style.module.css';
import { useReCAPTCHA } from '../../../services/hooks/useReCAPTCHA';

const CreatePassword = ({
  handleSubmit = () => { },
  values,
  errors,
  handleChange,
  networkError,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const { handleSubmit: _handleSubmit, component: ReCAPTCHAComponent } = useReCAPTCHA(
    (token) => {
      setFieldValue('reCAPTCHAToken', token);
      handleSubmit(values);
    }
  );

  return (
    <form className={styles.wrapper}>
      {ReCAPTCHAComponent}
      <Text color="#00000D" weight="wb" size="s24" lineHeight="h22">
        {t('createPassword.title', 'Create Password')}
      </Text>
      <div className={styles.wrapperBody}>
        <div className={styles.wrapperContent}>
          <Text color="#292929" size="s14" lineHeight="h20" tag="p">
            {t(
              'createPassword.body.p_1',
              'Your password must be a minimum of 6 characters long. We suggest it contains at least one capital letter, one number and a special character.'
            )}
          </Text>
        </div>
        {networkError ? (
          <FormColumn>
            <Text size="s16" lineHeight="l14" color="#f44336">
              {networkError}
            </Text>
          </FormColumn>
        ) : null}
        <FormColumn>
          <Field
            type="password"
            label={t('createPassword.body.newLabel', 'Create New Password')}
            name="password"
            autoComplete="password"
            onChange={handleChange}
            value={values.password}
            isError={values.password && Boolean(errors.password)}
          />
        </FormColumn>
        <FormColumn>
          <Field
            errorMessage={errors.repeatedPassword}
            type="password"
            label={t('createPassword.body.repeatLabel', 'Repeat New Password')}
            name="repeatedPassword"
            autoComplete="password"
            value={values.repeatedPassword}
            isError={values.repeatedPassword && Boolean(errors.repeatedPassword)}
            onChange={handleChange}
          />
        </FormColumn>
        <FormColumn>
          {values.password.length > 0 ? (
            <PasswordStrength strength={zxcvbn(values.password)} />
          ) : null}
        </FormColumn>
      </div>
      <FormFooter>
        <div>
          <div className={styles.wrapperContent}>
            <Button
              size="medium"
              handleClick={_handleSubmit}
              disabled={Boolean(Object.keys(errors).length)}
            >
              {t('createPassword.footer.saveButton', 'Save')}
            </Button>
          </div>
          <div>
            <Text color="#000">
              {t('createPassword.footer.p_1', 'Already have an account?')}{' '}
            </Text>
            <Link href="/sign-in" color="secondary" bold>
              {t('createPassword.footer.p_2', 'Sign in')}
            </Link>
          </div>
        </div>
      </FormFooter>
    </form>
  );
};
CreatePassword.propTypes = {
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  networkError: PropTypes.string,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
CreatePassword.defaultProps = {
  handleSubmit: () => { },
  networkError: '',
};

export default CreatePassword;
