import React from 'react';
import ReCAPTCHA from 'components/Auth/ReCAPTCHA';

export function useReCAPTCHA(_handleSubmit) {
  const handleSubmit = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'signin' })
        .then((token) => {
          _handleSubmit(token);
        });
    });
  };

  return {
    component: <ReCAPTCHA />,
    handleSubmit,
  };
}
