import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import MobileContext from '../../context/MobileContext';
import styles from './style.module.css';

const BannerWithButton = ({ bgImage, handleSubmit }) => {
  const isMobile = useContext(MobileContext);
  const { t } = useTranslation();

  return (
    <div className={styles.bannerWrapper} style={{ backgroundImage: `url(${bgImage})` }}>
      <div className={styles.textWrapper}>
        <Text
          tag="p"
          size={isMobile ? 's16' : 's19'}
          lineHeight={isMobile ? 'h25' : 'h30'}
          weight="wb"
          color="#FFFFFF"
          className={styles.bannerText}
        >
          {t(
            'welcomePage.footer.text',
            'Our process centers on technology, speed, and convenience for the customer and the contractor. We stay abreast of new technologies to give your local business an edge in productivity, installation quality, and customer trust.'
          )}
        </Text>
        <Button
          size="larger"
          type="submit"
          handleClick={handleSubmit}
          className={styles.bannerButton}
        >
          {t('welcomePage.footer.startButton', 'Start My Application Now!')}
        </Button>
      </div>
    </div>
  );
};

BannerWithButton.propTypes = {
  bgImage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

BannerWithButton.defaultProps = {
  bgImage: '',
};

export default BannerWithButton;
