import moment from 'moment';

export const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export function monthIncome(jobs = [], monthValue, pickYear) {
  const monthJobs = jobs
    .map(({ paymentDate, amount }) => {
      return { paymentDate, amount };
    })
    .filter(({ paymentDate }) => moment(paymentDate).format('YYYY') === pickYear)
    .filter(({ paymentDate }) => moment(paymentDate).format('MMM') === monthValue);
  const monthInc = monthJobs.map(({ amount }) => amount).reduce((a, b) => a + b, 0);
  return monthInc / 1000;
}

export const incomeSum = (completedPaidJobs = [], pickYear) =>
  completedPaidJobs
    .map(({ amount, paymentDate }) => {
      return { amount, paymentDate };
    })
    .filter(({ paymentDate }) => moment(paymentDate).format('YYYY') === pickYear)
    .map(({ amount }) => amount)
    .reduce((a, b) => a + b, 0) / 1000;
