import gql from 'graphql-tag';
import { Qualification } from 'graphql/fragments/qualification.fragment';
import { CompanyData } from '../../fragments/companyProfile.fragment';

export const UPDATE_COMPANY_DATA_MUTATION = gql`
  mutation UPDATE_COMPANY_DATA_MUTATION($data: updateCompanyProfileData!) {
    updateCompanyData(data: $data) {
      companyData {
        ...FullCompanyData
      }
      qualification {
        ...FullQualification
      }
    }
  }
  ${CompanyData.fragments.FullCompanyData}
  ${Qualification.fragments.FullQualification}
`;
