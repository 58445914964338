import React from 'react';
import { useTranslation } from 'react-i18next';
import WideTabs from 'components/shared/WideTabs';
import useTabIndex from 'services/hooks/useTabIndex';
import PropTypes from 'prop-types';
import PaymentReceivedTable from './Tables/PaymentReceived';
import PendingPaymentTable from './Tables/PendingPayment';
import styles from '../style.module.css';

const PaymentsSection = ({ jobs }) => {
  const { currentTabIndex, handleClick } = useTabIndex(0);
  const { t } = useTranslation();
  const tabList = [
    {
      title: t('finance.received.title', 'Payment Received'),
      children: <PaymentReceivedTable jobs={jobs} />,
    },
    {
      title: t('finance.pending.title', 'Pending Payment'),
      children: <PendingPaymentTable jobs={jobs} />,
    },
  ];

  return (
    <section className={styles.wrapperBlock}>
      <WideTabs
        tabList={tabList}
        currentTabIndex={currentTabIndex}
        handleClick={handleClick}
      />
    </section>
  );
};
PaymentsSection.propTypes = {
  jobs: PropTypes.array.isRequired,
};

PaymentsSection.defaultProps = {};
export default PaymentsSection;
