import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SectionHeader from 'components/shared/SectionHeader';
import EstimatedOfServicesSection from 'components/common/EstimatedOfServicesSection';
import isError from 'services/hooks/useErrors/useErrorEstimation';
import styles from './styles.module.css';

const EstimateSection = ({
  values: { myApplication },
  setFieldValue,
  setTouched,
  errors,
  touched,
  residentialVSCommercial,
  blockHistory,
}) => {
  isError(errors, touched);
  const { t } = useTranslation();
  return (
    <div className={styles.section}>
      <SectionHeader
        text={t('myApplication.secondTab.estimate.title', 'Estimated % of Services')}
        className={styles.headerWrapper}
      />
      <div className={styles.chartWrapper}>
        <EstimatedOfServicesSection
          estimateData={myApplication}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
          type="myApplication"
          residentialVSCommercial={residentialVSCommercial}
          blockHistory={blockHistory}
        />
      </div>
    </div>
  );
};

EstimateSection.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  blockHistory: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  residentialVSCommercial: PropTypes.number.isRequired,
};

export default EstimateSection;
