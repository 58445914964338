import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { contactUsSchema } from 'validation/contactUs';
import { useMutation } from '@apollo/react-hooks';
import ContactUs from 'components/ContactUs/index';
import useModal from 'services/hooks/useIsShowComponent';
// eslint-disable-next-line import/no-named-as-default
import { cleanNumber, withBracketsNumber } from 'utils/phoneNumberFormatter';
import { CONTACT_US_MUTATION } from 'graphql/mutations/contactUs';

const ContactInfo = ({ user }) => {
  const { isShow: isOpenModal, handleClick } = useModal();

  const [contactUs, { loading }] = useMutation(CONTACT_US_MUTATION, {
    onCompleted: () => handleClick(),
  });

  const handleCloseModal = () => {
    handleClick();
  };

  const handleSubmit = (value, { resetForm }) => {
    contactUs({
      variables: {
        data: {
          fullName: value.fullName,
          email: value.email,
          phoneNumber: withBracketsNumber(value.phoneNumber),
          subject: value.subject,
          message: value.message,
        },
      },
    });
    resetForm();
  };

  const values = {
    fullName: `${user?.firstName || ''} ${user?.lastName || ''}`,
    email: user?.email,
    phoneNumber: cleanNumber(user?.mobilePhone || user?.phone),
    subject: '',
    message: '',
  };

  return (
    <Formik
      initialValues={values}
      validationSchema={contactUsSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        return (
          <ContactUs
            {...formikProps}
            loading={loading}
            handleCloseModal={handleCloseModal}
            isOpenModal={isOpenModal}
          />
        );
      }}
    </Formik>
  );
};
ContactInfo.propTypes = {
  user: PropTypes.object.isRequired,
};
ContactInfo.defaultProps = {};
export default ContactInfo;
