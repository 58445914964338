import { object, array, addMethod } from 'yup';
import groupBy from 'lodash/groupBy';
import userValidation from './user.validation';

addMethod(array, 'uniqueProperty', function testUniqueProperty(
  message,
  mapper = (a) => a
) {
  return this.test('unique', message, function testUnique(list) {
    const values = list.map(mapper);

    const grouped = groupBy(values);
    const group = Object.values(grouped).find((g) => g.length > 1);

    if (group) {
      const index = values.findIndex((value) => value === group[0]);
      return this.createError({ path: `${this.path}.[${index}].email`, message });
    }

    return true;
  });
});

export default object().shape({
  user: userValidation,
});
