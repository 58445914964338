import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import EmptyPageLayout from 'layouts/EmptyPage';
import Collapse from 'components/shared/Collapse';
import Text from 'components/shared/Text';
import Field from 'components/shared/Field';
import Icon from 'components/shared/IconSVG';
import { searchIcon } from 'assets/icons';
import { FormRow as Row, FormColumn as Col } from 'components/shared/Form';

import styles from './styles.module.css';

function FAQ({ faqList }) {
  const [inputValue, setInputValue] = useState('');

  const categoryList = [
    ...new Set(faqList?.map((item) => item.category).filter((item) => item !== null)),
  ];

  const handleChange = ({ target: { value } }) => {
    setInputValue(value);
  };
  const filteredFQAs = faqList?.filter(({ title }) =>
    title.toLowerCase().includes(inputValue.toLowerCase())
  );
  const { t } = useTranslation();
  return (
    <EmptyPageLayout title={t('faq.title', 'FAQ')}>
      <Row>
        <Col>
          <Field
            name="faqCategory"
            type="text"
            isSearchable
            value={inputValue}
            onChange={(e) => handleChange(e)}
          />
          <div className={styles.iconContainer}>
            <Icon
              src={searchIcon}
              height="18px"
              width="18px"
              className={styles.searchIcon}
            />
          </div>
        </Col>
      </Row>

      {!filteredFQAs?.length ? (
        <Text color="#000000" weight="wb" size="s19" tag="h2" lineHeight="h19">
          {t('faq.noResult', ' No results')}
        </Text>
      ) : null}

      {categoryList.map((category) => (
        <div className={styles.categoryContainer} key={category}>
          {filteredFQAs
            .filter((item) => item.category === category)
            .map(({ title, details }, index) => (
              <div className={styles.collapseContainer} key={title + index}>
                <Collapse title={title} isOpen={false} isBigContent>
                  <Text color="#000000" weight="wr" size="s14" tag="p" lineHeight="h18">
                    {/* eslint-disable-next-line react/no-danger */}
                    <span
                      dangerouslySetInnerHTML={{ __html: details }}
                      className={styles.description}
                    />
                  </Text>
                </Collapse>
              </div>
            ))}
          <Text
            color="#000000"
            weight="wb"
            size="s19"
            tag="h2"
            lineHeight="h19"
            key={category}
            className={styles.category}
          >
            {category}
          </Text>
        </div>
      ))}
    </EmptyPageLayout>
  );
}

FAQ.propTypes = {
  faqList: PropTypes.array.isRequired,
};

FAQ.defaultProps = {};

export default FAQ;
