import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import {
  CHECK_IN_MATERIALS,
  CONFIRM_PLANKS_JOB,
  CONFIRM_PLYWOOD_JOB,
  CONFIRM_WORKFLOW_JOB,
} from 'graphql/mutations/opportunity';
import {
  updateCurrentJobList,
  updateFileListJob,
} from 'graphql/resolvers/opportunity/cache';
import Spinner from 'components/shared/Spinner';
import countDiffDays from 'utils/countDaysBetweenDays';
import {attachmentTypes, roles} from 'enums';
import styles from '../../pageStyles.module.css';
import MessageSection from './MessageSection';
import MaterialsSection from './MaterialSection';
import PlywoodSection from './PlywoodSection';
import PlanksSection from './PlanksSection';
import CompleteSection from './CompleteSection';
import {CURRENT_JOB} from "../../../graphql/queries/opportunities";

const ActivityTab = ({ userCurrentRole, match, files, dateOfInstall, ...currentAwardedJob }) => {
  const { sfId: jobId, opportunity } = currentAwardedJob;
  const isAvailableActions = countDiffDays(dateOfInstall) <= 0;

  const [checkInMaterials, { loading: loadingCheckInMaterial }] = useMutation(
    CHECK_IN_MATERIALS, {
        refetchQueries: [
          { query: CURRENT_JOB(userCurrentRole !== roles.crew), variables: {
              contentDocumentId: match?.params?.id,
            }, }
        ]
      }
  );
  const [confirmPlanks, { loading: loadingConfirmPlanks }] = useMutation(
    CONFIRM_PLANKS_JOB, {
        refetchQueries: [
          { query: CURRENT_JOB(userCurrentRole !== roles.crew), variables: {
              contentDocumentId: match?.params?.id,
            }, }
        ]
      }
  );
  const [confirmPlywood, { loading: loadingConfirmPlywood }] = useMutation(
    CONFIRM_PLYWOOD_JOB, {
        refetchQueries: [
          { query: CURRENT_JOB(userCurrentRole !== roles.crew), variables: {
              contentDocumentId: match?.params?.id,
            }, }
        ]
      }
  );
  const [confirmWorkflow, { loading: loadingConfirmWorkflow }] = useMutation(
    CONFIRM_WORKFLOW_JOB, {
        refetchQueries: [
          { query: CURRENT_JOB(userCurrentRole !== roles.crew), variables: {
              contentDocumentId: match?.params?.id,
            }, }
        ]
      }
  );

  const isRequesting =
    loadingCheckInMaterial ||
    loadingConfirmPlanks ||
    loadingConfirmWorkflow ||
    loadingConfirmPlywood;

  const handleConfirmMaterials = (values) => {
    checkInMaterials({
      variables: {
        jobId,
        uploadIds: [jobId, opportunity.sfId],
        ...values,
      },
      update: (_, { data: { uploadFiles, confirmMaterials } }) => {
        updateCurrentJobList(
          jobId,
          {
            deliveredMaterialsComment: confirmMaterials.deliveredMaterialsComment,
            isDeliveredMaterials: true,
            status: confirmMaterials.status,
          },
          { isAwarded: true }
        );
        updateFileListJob(jobId, uploadFiles);
      },
    });
  };

  const handleConfirmPlanks = (values) => {
    confirmPlanks({
      variables: {
        jobId,
        uploadIds: [jobId, opportunity.sfId],
        ...values,
      },
      update: (_, { data: { uploadFiles } }) => {
        updateCurrentJobList(
          jobId,
          {
            deliveredPlanksComment: values.comment,
            deliveredPlanksQuantity: values.quantity,
            isDeliveredPlanks: true,
          },
          { isAwarded: true }
        );
        updateFileListJob(jobId, uploadFiles);
      },
    });
  };

  const handleConfirmPlywood = (values) => {
    confirmPlywood({
      variables: {
        jobId,
        uploadIds: [jobId, opportunity.sfId],
        ...values,
      },
      update: (_, { data: { uploadFiles } }) => {
        updateCurrentJobList(
          jobId,
          {
            plywoodComment: values.comment,
            plywoodQuantity: values.quantity,
            isDeliveredPlywood: true,
          },
          { isAwarded: true }
        );
        updateFileListJob(jobId, uploadFiles);
      },
    });
  };

  const handleConfirmWorkflow = (values) => {
    confirmWorkflow({
      variables: {
        jobId,
        uploadIds: [jobId, opportunity.sfId],
        ...values,
      },
      update: (_, { data: { uploadFiles, workOrderStatus } }) => {
        updateCurrentJobList(
          jobId,
          {
            commentRollover: workOrderStatus.commentRollover,
            status: workOrderStatus.status,
          },
          { isAwarded: true }
        );
        updateFileListJob(jobId, uploadFiles);
      },
    });
  };
  const { t } = useTranslation();

  if (isRequesting) return <Spinner absolute />;

  return (
    <div className={[styles.tabContentWrapper, styles.mainContent].join(' ')}>
      <MessageSection jobId={jobId} />

      <MaterialsSection
        {...currentAwardedJob}
        isDisable={!isAvailableActions}
        noteText={t(
          'jobs.awarded.details.activity.material.note',
          'Available after 7 a.m.'
        )}
        handleSubmit={handleConfirmMaterials}
        files={files.filter(
          ({ description }) => description === attachmentTypes.materialFile
        )}
      />

      <PlywoodSection
        {...currentAwardedJob}
        isDisable={!isAvailableActions}
        noteText={t(
          'jobs.awarded.details.activity.plywood.note',
          'Available after 1 p.m.'
        )}
        handleSubmit={handleConfirmPlywood}
        files={files.filter(
          ({ description }) => description === attachmentTypes.plywoodFile
        )}
      />
      <PlanksSection
        {...currentAwardedJob}
        isDisable={!isAvailableActions}
        noteText={t(
          'jobs.awarded.details.activity.plywood.note',
          'Available after 1 p.m.'
        )}
        handleSubmit={handleConfirmPlanks}
        files={files.filter(
          ({ description }) => description === attachmentTypes.plankFile
        )}
      />

      <fieldset disabled={!isAvailableActions}>
        <CompleteSection
          {...currentAwardedJob}
          isDisable={!isAvailableActions}
          noteText={t(
            'jobs.awarded.details.activity.status.note',
            'Available after 8 p.m.'
          )}
          handleSubmit={handleConfirmWorkflow}
          files={files.filter(({ description }) => description === attachmentTypes.after)}
        />
      </fieldset>
    </div>
  );
};

ActivityTab.propTypes = {
  jobId: PropTypes.string.isRequired,
  files: PropTypes.array,
  dateOfInstall: PropTypes.string.isRequired,
  currentAwardedJob: PropTypes.object.isRequired,
  userCurrentRole: PropTypes.string,
  match: PropTypes.object
};

ActivityTab.defaultProps = {
  files: [],
  userCurrentRole: '',
  match: {}
};

export default ActivityTab;
