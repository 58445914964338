import { toBase64UploadedFiles } from 'utils/toBase64File';

export const fromObjectToArray = (objectData) =>
  Object.values(objectData)
    .map((item) => Object.values(item))
    .flat();

export const restructureCertificationData = async (myCertification) => {
  const arrayOfData = fromObjectToArray(myCertification);
  return Promise.all(
    arrayOfData.map(async (data) => ({
      ...data,
      files: await toBase64UploadedFiles(
        data.files.filter((item) => !item?.contentDocumentId)
      ),
    }))
  );
};

export const checkIsNewFilesUpload = (myCertification) => {
  const arrayOfData = fromObjectToArray(myCertification);

  return arrayOfData.filter(({ files }) => files.some((file) => !file.contentDocumentId))
    .length;
};
