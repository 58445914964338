export const topValueErrorsMyAccount = {
  zipCode: {
    top: '1000',
    weight: 1,
  },
  companyEmailAddress: {
    top: '1150',
    weight: 2,
  },
  companyPhoneNumber: {
    top: '1250',
    weight: 3,
  },
  taxID: {
    top: '1300',
    weight: 4,
  },
};

export const topValueErrorsCompanyDetails = {
  companyName: {
    top: '150',
    weight: 1,
  },
  companyNameDBA: {
    top: '200',
    weight: 2,
  },
  ownerFirstName: {
    top: '350',
    weight: 3,
  },
  ownerLastName: {
    top: '350',
    weight: 4,
  },
  address: {
    top: '420',
    weight: 5,
  },
  city: {
    top: '420',
    weight: 6,
  },
  suiteNumber: {
    top: '600',
    weight: 7,
  },
  companyPhoneNumber: {
    top: '800',
    weight: 8,
  },
  taxID: {
    top: '1000',
    weight: 9,
  },
};

export const topValueErrorsCompanyCoverage = {
  numberCrews: {
    top: '140',
    weight: 1,
  },
  numberLaborers: {
    top: '200',
    weight: 2,
  },
  crewAveragePerDay: {
    top: '250',
    weight: 3,
  },
  ownerLastName: {
    top: '350',
    weight: 4,
  },
  address: {
    top: '420',
    weight: 5,
  },
  city: {
    top: '420',
    weight: 6,
  },
  companyPhoneNumber: {
    top: '800',
    weight: 7,
  },
  taxID: {
    top: '1000',
    weight: 8,
  },
};

export const topValueErrorsMyApplication = {
  myApplication: {
    top: '900',
    weight: 1,
  },
};
