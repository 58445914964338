import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'components/shared/Text';
import Link from 'components/shared/Link';
import styles from '../style.module.css';

const SignUpHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.authFormHeader}>
      <Text color="#00000D" weight="wb" size="s24">
        {t('signUp.title.p_1', ' Sign Up')}
      </Text>
      <Text color="#00000D" weight="wm" size="s16">
        {t('signUp.title.p_2', ' Already have an account?')}
        <Link href="/sign-in" bold size="medium">
          {t('signUp.title.p_3', ' Sign In')}
        </Link>
      </Text>
    </div>
  );
};

export default SignUpHeader;
