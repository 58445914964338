import gql from 'graphql-tag';
import { Auth } from '../../fragments/auth.fragment';

export const CHANGE_LANGUAGE = gql`
  mutation CHANGE_LANGUAGE($language: String!) {
    changeLanguage(language: $language) {
      language
    }
  }
`;
