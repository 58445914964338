import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import WrapperBlock from 'components/shared/WrapperBlock';
import EditMode from 'components/MyAccount/Account/Edit';
import ViewMode from 'components/MyAccount/Account/View';
import { editIcon, warningIcon } from 'assets/icons';
import Icon from 'components/shared/IconSVG';
import Spinner from 'components/shared/Spinner';
import Text from 'components/shared/Text';
import styles from './styles.module.css';

const AccountDetailsTab = ({ user, loading }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();
  if (loading) return <Spinner />;

  return (
    <WrapperBlock
      title={
        user?.account?.contacts?.length > 0
          ? t('myAccount.details.pluralTitle', 'Contacts')
          : t('myAccount.details.singleTitle', 'Contact')
      }
      isForm={isEditMode}
    >
      {isEditMode && (
        <div className={styles.noteSection}>
          <Icon
            src={warningIcon}
            height="18px"
            width="18px"
            className={styles.noteIcon}
          />
          <Text
            tag="p"
            color="#9f9f9f"
            className={styles.noteMessage}
            weight="wr"
            size="s14"
            lineHeight="h16"
          >
            {t(
              'myAccount.details.editMode.p_1',
              'Please contact RoofClaim if you would like to update email address'
            )}
          </Text>
        </div>
      )}
      {!isEditMode && (
        <div className={styles.editContainer} onClick={() => setIsEditMode(true)}>
          <Icon src={editIcon} height="12px" width="12px" className={styles.editIcon} />
          <Text
            tag="h2"
            size="s12"
            weight="wb"
            lineHeight="h13"
            color="000000"
            className={styles.editText}
          >
            {t('myAccount.details.viewMode.edit', 'Edit')}
          </Text>
        </div>
      )}

      {isEditMode ? (
        <EditMode user={user} handleCancel={() => setIsEditMode(false)} />
      ) : (
        <ViewMode user={user} />
      )}
    </WrapperBlock>
  );
};

AccountDetailsTab.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default AccountDetailsTab;
