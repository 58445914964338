import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { ACCOUNT_ACTIVATION_MUTATION } from 'graphql/mutations/auth';
import CreatePassword from 'components/Auth/CreatePassword';
import FormWrapper from 'components/Auth/FormWrapper';
import { Formik } from 'formik';
import { useCreatePasswordValidationSchema } from 'validation/signUp';
import MobileSignedOutHeader from 'layouts/Mobile/MobileSignedOutHeader';
import styles from '../ForgotPassword/style.module.css';
import { useAuth } from '../../services/hooks/useAuth';
import Spinner from '../../components/shared/Spinner';
import { CHECK_ACTIVATION_TOKEN } from '../../graphql/queries/auth';

const AccountActivationPage = () => {
  const history = useHistory();
  const { isLoading: signInLoading, handleSubmit: signInHandleSubmit } = useAuth();

  const [passwordActivation, { loading: isLoading }] = useMutation(
    ACCOUNT_ACTIVATION_MUTATION
  );
  const { activationToken, email } = queryString.parse(history.location.search);
  const { data, error, loading: checkTokenLoading } = useQuery(CHECK_ACTIVATION_TOKEN, {
    variables: {
      token: activationToken,
    },
  });

  useEffect(() => {
    if (error) history.push('/sign-in');
  }, [data, error, history]);

  useEffect(() => {
    if (!activationToken) history.push('/sign-in');
  }, [activationToken, history]);

  const handleSubmit = async (values) => {
    try {
      await passwordActivation({
        variables: {
          activationToken,
          password: values.password,
          repeatedPassword: values.repeatedPassword,
        },
        update: () => {
          signInHandleSubmit({
            email,
            password: values.password,
            reCAPTCHAToken: values.reCAPTCHAToken,
          });
        },
      });
    } catch (e) {
      // TODO: Error page
    }
  };
  const validationObject = useCreatePasswordValidationSchema();
  return (
    <MobileSignedOutHeader>
      <div className={styles.contentPage}>
        <FormWrapper>
          {signInLoading || checkTokenLoading ? (
            <Spinner />
          ) : (
            <Formik
              initialValues={{
                password: '',
                repeatedPassword: '',
              }}
              validateOnMount
              validationSchema={validationObject}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <fieldset disabled={isLoading}>
                  <CreatePassword {...formikProps} />
                </fieldset>
              )}
            </Formik>
          )}
        </FormWrapper>
      </div>
    </MobileSignedOutHeader>
  );
};

export default AccountActivationPage;
