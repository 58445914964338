import React from 'react';
import PropTypes from 'prop-types';
import IconSVG from '../IconSVG';
import styles from './style.module.css';

const ToggleViewIcons = ({ activeIcon, handleClick, items = [] }) => {
  return (
    <div className={styles.wrapper}>
      {items.map(({ name, icon }, index) => {
        const isActive = activeIcon === name;
        return (
          <div
            className={[styles.card, isActive ? styles.activeCard : ''].join(' ')}
            onClick={() => handleClick(name)}
            key={index}
          >
            <IconSVG src={icon} className={isActive ? styles.activeIcon : styles.icon} />
          </div>
        );
      })}
    </div>
  );
};

ToggleViewIcons.propTypes = {
  activeIcon: PropTypes.string,
  handleClick: PropTypes.func,
  items: PropTypes.array,
};

ToggleViewIcons.defaultProps = {
  activeIcon: 'th',
  handleClick: () => {},
  items: [],
};

export default ToggleViewIcons;
