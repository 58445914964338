import client from 'graphql/client';

import { GET_COMPANY_DATA } from 'graphql/queries/companyProfile';

export const updateCompanyCache = (companyData) => {
  const { getCompanyData } = client.cache.readQuery({
    query: GET_COMPANY_DATA,
  });

  const filteredData = getCompanyData.qualification.filter(({ sfId }) =>
    companyData?.updateCompanyData?.qualification.every((item) => item.sfId !== sfId)
  );

  client.cache.writeData({
    query: GET_COMPANY_DATA,
    data: {
      qualification: [...filteredData, ...companyData.updateCompanyData.qualification],
      companyData: { ...companyData.updateCompanyData.companyData },
    },
  });
};
export const updateQualificationCache = (qualification) => {
  const app = client.cache.readQuery({
    query: GET_COMPANY_DATA,
  });

  client.cache.writeData({
    query: GET_COMPANY_DATA,
    data: {
      qualification: qualification.deleteQualification,
      application: { ...app.application },
    },
  });
};
