import React from 'react';
import ContactUs from 'containers/ContactUs';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER } from 'graphql/queries/auth';
import Spinner from 'components/shared/Spinner';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import WithError from 'services/HOCs/withError';

const ContactUsPage = () => {
  const { data, loading, error } = useQuery(GET_USER);
  if (loading)
    return (
      <LayoutWithSidebar>
        <Spinner />
      </LayoutWithSidebar>
    );
  return (
    <WithError isShow={!!error}>
      <LayoutWithSidebar>
        <ContactUs user={data?.getUser} />
      </LayoutWithSidebar>
    </WithError>
  );
};

export default ContactUsPage;
