import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRoofTypeLocal, useLoseTypeLocal } from 'localization';
import LineText from 'components/common/LineText';
import Text from 'components/shared/Text';
import useLoadAttachments from 'services/hooks/loadAttachments';
import File from 'components/shared/LinkPDF/DownloadLink';
import Spinner from 'components/shared/Spinner';
import filesPrefix from 'enums/filesPrefix';
import styles from '../style.module.css';

const DescriptionSection = ({
  totalSqs,
  existingShingleType,
  typeOfLoss,
  roofPitch,
  valley,
  ridgeVents4,
  numberPipeJacks,
  gooseneckDryerVents4,
  description,
  jobSfId,
}) => {
  const {
    files: jobFiles,
    loading: isLoading,
    handleDownload,
    loadingDownload,
  } = useLoadAttachments({
    contentDocumentId: jobSfId,
  });
  const filteredFiles = jobFiles.filter(({ name }) =>
    name.includes(`${filesPrefix.abr}`)
  );

  const { t } = useTranslation();
  const roofTypeLocal = useRoofTypeLocal();
  const loseTypeLocal = useLoseTypeLocal();
  return (
    <div className={styles.headerBlockSpace}>
      <div className={styles.bigContentContainer}>
        <LineText
          name={t('jobs.available.details.details.sqs', 'Total SQs')}
          value={totalSqs}
        />
        <LineText
          name={t('jobs.available.details.details.type', 'Existing Shingle Type')}
          value={roofTypeLocal[existingShingleType]}
        />
        <LineText
          name={t('jobs.available.details.details.loss', 'Type of Loss')}
          value={loseTypeLocal[typeOfLoss]}
        />
        <LineText
          name={t('jobs.available.details.details.pitch', 'Roof Pitch')}
          value={roofPitch}
        />
        <LineText
          name={t('jobs.available.details.details.valley', '# of valley')}
          value={valley}
        />
        <LineText
          name={t('jobs.available.details.details.ridge', "# of Ridge Vents 4'")}
          value={ridgeVents4}
        />
        <LineText
          name={t(
            'jobs.available.details.details.gooseneck',
            "# Gooseneck Dryer Vents - 4''"
          )}
          value={gooseneckDryerVents4}
        />
        <LineText
          name={t('jobs.available.details.details.pipe', '# Pipe Jacks')}
          value={numberPipeJacks}
        />
      </div>
      <div className={styles.container}>
        {description && (
          <>
            <Text size="s14" color="#686868" tag="p">
              {t('jobs.available.details.details.description', 'Description')}
            </Text>
            <Text color="#000" size="s14">
              {description}
            </Text>
          </>
        )}
        {isLoading ? (
          <Spinner color="white" />
        ) : (
          <div className={styles.content}>
            {filteredFiles.map((file, index) => {
              return (
                <div key={index}>
                  <File
                    fileName={`${file.name}.${file.contentType}`}
                    type={file.contentType}
                    loading={loadingDownload}
                    handleQueryClick={() => handleDownload(file.contentDocumentId)}
                  >
                    {file.name}
                  </File>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

DescriptionSection.propTypes = {
  totalSqs: PropTypes.number,
  existingShingleType: PropTypes.string,
  typeOfLoss: PropTypes.string,
  roofPitch: PropTypes.string,
  valley: PropTypes.number,
  ridgeVents4: PropTypes.number,
  numberPipeJacks: PropTypes.number,
  gooseneckDryerVents4: PropTypes.number,
  description: PropTypes.string,

  jobSfId: PropTypes.string.isRequired,
};

DescriptionSection.defaultProps = {
  totalSqs: '',
  existingShingleType: '',
  typeOfLoss: '',
  roofPitch: '',
  valley: '',
  ridgeVents4: '',
  numberPipeJacks: '',
  gooseneckDryerVents4: '',
  description: '',
};

export default DescriptionSection;
