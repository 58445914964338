import React, { useContext } from 'react';
import SignUpForm from 'containers/SignUp/SignUpContainer';
import AppConfigContext from 'context/AppConfigContext';
import MobileSignedOutHeader from 'layouts/Mobile/MobileSignedOutHeader';
import styles from './style.module.css';

const SignUpPage = () => {
  const config = useContext(AppConfigContext);
  return (
    <MobileSignedOutHeader>
      <div
        className={styles.contentPage}
        style={{ backgroundImage: `url(${config?.signUpImageUrl})` }}
      >
        <SignUpForm />
      </div>
    </MobileSignedOutHeader>
  );
};

SignUpPage.propTypes = {};

export default SignUpPage;
