import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/shared/Link';
import Text from 'components/shared/Text';
import { rightColored } from 'assets/icons';
import MobileContext from 'context/MobileContext';
import AppConfigContext from 'context/AppConfigContext';
import Button from 'components/shared/Button';
import Icon from 'components/shared/IconSVG';
import Popup from 'components/Auth/FormWrapper';
import MobileSignedOutHeader from '../../layouts/Mobile/MobileSignedOutHeader';
import styles from './style.module.css';

const ChangedEmailPage = ({ history }) => {
  const isMobile = useContext(MobileContext);
  const config = useContext(AppConfigContext);
  return (
    <MobileSignedOutHeader>
      <div className={styles.contentPage}
        style={{ backgroundImage: `url(${config?.signInImageUrl})` }}
      >
        <Popup>
          <form className={styles.wrapper}>
            <div className={styles.wrapperBody}>
              <div className={styles.wrapperContent}>
                <Text color="#292929" size="s14" lineHeight="h20" tag="p">
                  Check the email account associated with your user name to confirm your
                  email
                </Text>
              </div>
              <div className={styles.wrapperContent}>
                <Text color="#292929" size="s14" lineHeight="h20" tag="p">
                  Remember to look in your spam folder, where automated messages sometimes
                  filter.
                </Text>
              </div>
              <Text color="#292929" size="s14" lineHeight="h20" tag="p">
                If you still can't log in, contact your administrator.
              </Text>
            </div>

            <div className={styles.wrapperContent}>
              {isMobile ? (
                <div>
                  <Button size="large" handleClick={() => history.push('/sign-in')}>
                    Back to login
                  </Button>
                  <br />
                  <br />
                  <div>
                    <Link
                      href={`mailto:${process.env.REACT_APP_ROOFCALIM_EMAIL}`}
                      size="s14"
                      bold
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              ) : (
                <Link href="/sign-in" size="s16" bold>
                  <Icon
                    src={rightColored}
                    width="15px"
                    height="auto"
                    className={styles.back}
                  />
                  Back to login
                </Link>
              )}
            </div>
          </form>
        </Popup>
      </div>
    </MobileSignedOutHeader>
  );
};

ChangedEmailPage.propTypes = {
  history: PropTypes.func.isRequired,
};

ChangedEmailPage.defaultProps = {};

export default ChangedEmailPage;
