import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_RELATED_FILE } from 'graphql/mutations/files';
import W9Documents from './W9';
import WorkersCompensation from './workersCompensation';
import GeneralLiability from './generalLiability';
import License from './license';
import styles from './style.module.css';
import AutoLiability from "./autoLiability";

const Documents = ({
  values: { myCertification },
  errors = {},
  touched = {},
  className,
  handleChange,
  appStatus,
  qualificationSfId,
  loadingAttachments,
  loadCreateApp,
  isOnBoarding,
}) => {
  const [deleteUploadedFiles, { loading }] = useMutation(DELETE_RELATED_FILE);

  const documentProps = {
    myCertification,
    errors: errors.myCertification,
    touched: touched.myCertification,
    qualificationSfId,
    isOnBoarding,
    deleteUploadedFiles,
    handleChange,
    appStatus,
    loadCreateApp,
  };

  return (
    <fieldset disabled={loading || loadingAttachments}>
      <div className={[styles.projectTabWrapper, className].join(' ')}>
        <div className={styles.projectFormBanner}>
          <W9Documents {...documentProps} />

          <WorkersCompensation {...documentProps} />

          <GeneralLiability {...documentProps} />

          <AutoLiability {...documentProps} />

          <License {...documentProps} />
        </div>
      </div>
    </fieldset>
  );
};
Documents.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  qualificationSfId: PropTypes.array,
  loadingAttachments: PropTypes.bool.isRequired,
  loadCreateApp: PropTypes.bool.isRequired,
  appStatus: PropTypes.string.isRequired,
  isOnBoarding: PropTypes.bool,
};
Documents.defaultProps = {
  errors: {},
  touched: {},
  qualificationSfId: [],
  className: '',
  isOnBoarding: false,
};

export default Documents;
