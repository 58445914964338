import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import Text from 'components/shared/Text';
import MobileContext from 'context/MobileContext';
import Field from 'components/shared/Field';
import { useYearsForIncomes } from 'utils/yearsOptions';
import Chart from './Chart';
import { incomeSum } from './Chart/chartData';
import SeeAll from '../SeeAllComponent';
import styles from './styles.module.css';

const Incomes = ({ completedPaidJobs }) => {
  const { t } = useTranslation();

  const yearsForIncomes = useYearsForIncomes();
  const isMobile = useContext(MobileContext);
  const history = useHistory();

  const [datePicker, setDatePicker] = useState(
    `Jan ${new Date().getUTCFullYear()} - Dec ${new Date().getUTCFullYear()}`
  );

  const pickYear = datePicker.split('-')[0].trim().split(' ')[1];

  const incomePeriod = [
    {
      income: incomeSum(completedPaidJobs, pickYear),
      color: '#F1C400',
      title: t('dashboard.income.selectedPeriod', 'Selected period'),
    },
    {
      income: incomeSum(completedPaidJobs, String(pickYear - 1)),
      color: '#5162D3',
      title: t('dashboard.income.previousPeriod', 'Previous period'),
    },
  ];
  return (
    <div className={styles.incomeWrapper}>
      <WrapperBlock
        title={t('dashboard.income.title', 'Income')}
        rightComponent={<SeeAll handleClick={() => history.push('/financials')} />}
      >
        <div className={styles.dateContainer}>
          <div className={styles.incomeContainer}>
            {incomePeriod.map(({ income, color, title }, index) => (
              <div style={{ marginRight: '20px' }} key={index + title}>
                <Text
                  tag="h2"
                  size={isMobile ? 's15' : 's17'}
                  weight="wb"
                  lineHeight="h21"
                  color="#000000"
                  className={styles.income}
                >
                  ${income.toFixed(1).replace('.', ',')}00
                </Text>
                <div className={styles.incomeTitle}>
                  <span className={styles.circle} style={{ backgroundColor: color }} />
                  <Text tag="h2" size="s14" weight="wr" lineHeight="h16" color="#000000">
                    {title}
                  </Text>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.datePickerContainer}>
            <Field
              type="select"
              options={yearsForIncomes}
              value={datePicker}
              onChange={(e) => setDatePicker(e.target.value)}
            />
          </div>
        </div>
        <Chart jobs={completedPaidJobs} pickYear={pickYear} />
      </WrapperBlock>
    </div>
  );
};
Incomes.propTypes = {
  completedPaidJobs: PropTypes.array,
};
Incomes.defaultProps = {
  completedPaidJobs: [],
};
export default Incomes;
