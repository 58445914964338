import { fromObjectToArray } from 'utils/application/restructureData/restructureCertificationData';
import { qualificationTypes, certificationFields } from 'enums';

export const mandatoryAndEmptyArrays = (item, optionalFields) => {
  const arrayOfArrays = Object.entries(item);
  let onlyMandatoryValues = [];

  if (item.type === qualificationTypes.w9) {
    onlyMandatoryValues = arrayOfArrays.filter((it) => it[0] === 'files');
  } else if (item.type === qualificationTypes.lc && item.carrier !== 'Other') {
    onlyMandatoryValues = arrayOfArrays.filter((it) =>
      [
        certificationFields.policyNumber,
        certificationFields.carrier,
        certificationFields.expirationDate,
      ].includes(it[0])
    );
  } else if (item.type === qualificationTypes.lc && item.carrier === 'Other') {
    onlyMandatoryValues = arrayOfArrays.filter((it) =>
      [
        certificationFields.policyNumber,
        certificationFields.carrier,
        certificationFields.otherIssuer,
        certificationFields.expirationDate,
      ].includes(it[0])
    );
  } else {
    onlyMandatoryValues = arrayOfArrays.filter((it) => !optionalFields.includes(it[0]));
  }

  const emptyFields = onlyMandatoryValues.filter((arr) => {
    if (Array.isArray(arr[1])) {
      return !arr[1].length;
    }
    return !arr[1];
  });
  return { onlyMandatoryValues, emptyFields };
};

export const isMyCertificationTabFilled = (tabValuesObject, optionalFields = []) => {
  const arrayOfObjects = fromObjectToArray(tabValuesObject);

  const arrayOfPercents = arrayOfObjects.map((item) => {
    const { onlyMandatoryValues, emptyFields } = mandatoryAndEmptyArrays(
      item,
      optionalFields
    );

    return (onlyMandatoryValues.length - emptyFields.length) / onlyMandatoryValues.length;
  });

  const percentWithType = arrayOfObjects.map((item) => {
    const { onlyMandatoryValues, emptyFields } = mandatoryAndEmptyArrays(
      item,
      optionalFields
    );

    return {
      percent:
        (onlyMandatoryValues.length - emptyFields.length) / onlyMandatoryValues.length,
      type: item.type,
    };
  });

  const generalPercent =
    (arrayOfPercents.reduce((a, b) => a + b) / arrayOfPercents.length) * 100;

  return { generalPercent, percentWithType };
};
