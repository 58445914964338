import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Checkbox from 'components/shared/CheckBox';
import styles from '../styles.module.css';

const FirstScreen = ({ confirmFirstScreen, setConfirmFirstScreen }) => {
  const { t } = useTranslation();

  return (
    <>
      <Text tag="p" color="#000000" weight="wb" size="s14" lineHeight="h20">
        {t(
          'inspectionModal.body.firstScreen.p_1',
          'All drip-edge are properly fastened and lapped, corners are tight and symmetrically balanced, valley cuts are correct and 2” off center, and they are sealed at the intersection of wall and house.'
        )}
      </Text>
      <p />
      <Text tag="p" color="#000000" size="s14" lineHeight="h20" weight="wb">
        {t(
          'inspectionModal.body.firstScreen.p_2',
          'Flashing at sidewalls are accurately installed, cleanly attached, and are uniformed throughout the property.'
        )}
      </Text>
      <p />
      <Text tag="p" color="#000000" size="s14" lineHeight="h20" weight="wb">
        {t(
          'inspectionModal.body.firstScreen.p_3',
          'Pipe jack boots are accurately installed, painted, and have no exposed fasteners. Furthermore, the chimney is properly flashed and counter flashed according to substrate.'
        )}
      </Text>
      <p />
      <Text tag="p" color="#000000" size="s14" lineHeight="h20" weight="wb">
        {t(
          'inspectionModal.body.firstScreen.p_4',
          'Dryer vent screen and rain diverters are correctly replaced, and ridge vents are correctly attached and tarred with the correct size fasteners.'
        )}
      </Text>
      <div className={styles.confirmContainer}>
        <Checkbox
          isContractorsStyle
          value={confirmFirstScreen}
          textSize="big"
          text={t(
            'inspectionModal.confirm',
            'I confirm the above statements are true and accurate.'
          )}
          onChange={() => setConfirmFirstScreen(!confirmFirstScreen)}
        />
      </div>
    </>
  );
};

FirstScreen.propTypes = {
  setConfirmFirstScreen: PropTypes.func.isRequired,
  confirmFirstScreen: PropTypes.bool.isRequired,
};

export default FirstScreen;
