import isEmpty from 'lodash/isEmpty';

export function setCurrentTab(errors, touched, setCurrentTabIndex) {
  if (!isEmpty(errors) && !isEmpty(touched)) {
    switch (Object.keys(errors)[0]) {
      case 'organization':
        setCurrentTabIndex(0);
        break;
      case 'services':
        setCurrentTabIndex(1);
        break;
      case 'coverage':
        setCurrentTabIndex(2);
        break;
      case 'definedWorkAreas':
        setCurrentTabIndex(3);
        break;
      case 'myCertification':
        setCurrentTabIndex(4);
        break;
      default:
        setCurrentTabIndex(0);
    }
  }
}
