import React from 'react';
import PropTypes from 'prop-types';
import useIsShowComponent from 'services/hooks/useIsShowComponent';
import arrowDown from 'assets/icons/arrowDown.svg';
import Text from 'components/shared/Text';
import styles from './style.module.css';
import IconSVG from '../IconSVG';

const Collapse = ({ title, children, icon, isOpen = true, isBigContent }) => {
  const { isShow: isOpened, handleClick } = useIsShowComponent(isOpen);

  return (
    <section
      className={[
        styles.collapseWrapper,
        isOpened ? styles.collapseWrapperOpened : styles.collapseWrapperClosed,
      ].join(' ')}
    >
      <div className={styles.collapseHeader}>
        <div>
          <div className={styles.collapseBlockTitle}>
            <div onClick={handleClick}>
              <Text color="#000" weight="wb" size="s17">
                {title}
              </Text>
            </div>

            {icon && <div className={styles.wrapperBlockIcon}>{icon}</div>}
          </div>
          {isOpened && <div className={styles.collapseTitleLine} />}
        </div>

        <div className={styles.collapseDropIconWrapper} onClick={handleClick}>
          <IconSVG
            height="14px"
            width="14px"
            src={arrowDown}
            className={[
              isOpened ? styles.collapseOpenIcon : '',
              styles.collapseDropIcon,
            ].join(' ')}
          />
        </div>
      </div>

      {isOpened && (
        <main
          className={isBigContent ? styles.collapseMainFormBig : styles.collapseMainForm}
        >
          {children}
        </main>
      )}
    </section>
  );
};

Collapse.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  isOpen: PropTypes.bool,
  isBigContent: PropTypes.bool,
};

Collapse.defaultProps = {
  icon: null,
  isOpen: true,
  isBigContent: false,
};

export default Collapse;
