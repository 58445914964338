import gql from 'graphql-tag';

export const GET_STATES = gql`
  query getStates {
    getStates {
      id
      name
      shortname
    }
  }
`;

export const GET_COUNTIES_BY_STATE = gql`
  query getCountiesByState($states: [String]) {
    getCountiesByState(states: $states) {
      id
      name
      state
    }
  }
`;

export const GET_CITIES_BY_COUNTY = gql`
  query getCitiesByCounty($states: [String], $counties: [String]) {
    getCitiesByCounty(states: $states, counties: $counties) {
      id
      name
      state
      county
    }
  }
`;

export const GET_STATES_BOUNDARIES = gql`
  query getStatesBoundaries($states: [StateInput]) {
    getStatesBoundaries(states: $states) {
      name
      shortname
      geojson {
        lat
        lng
      }
    }
  }
`;

export const GET_COUNTIES_BOUNDARIES = gql`
  query getCountiesBoundaries($counties: [CountyInput]) {
    getCountiesBoundaries(counties: $counties) {
      name
      state
      geojson {
        lat
        lng
      }
    }
  }
`;

export const GET_CITIES_BOUNDARIES = gql`
  query getCitiesBoundaries($cities: [CityInput]) {
    getCitiesBoundaries(cities: $cities) {
      name
      state
      geojson {
        lat
        lng
      }
    }
  }
`;
