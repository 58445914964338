import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import Text from 'components/shared/Text';
import EmptyPageLayout from 'layouts/EmptyPage';
import Spinner from 'components/shared/Spinner';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import ContactUsDesktop from './Background';
import ContactFrom from './ContactForm';
import styles from './styles.module.css';

function ContactUs({
  handleSubmit,
  values,
  handleChange,
  errors,
  touched,
  loading,
  handleCloseModal,
  isOpenModal,
}) {
  const isMobile = useContext(MobileContext);
  const { t } = useTranslation();
  return (
    <EmptyPageLayout title={t('contactUs.header.title', 'Contact Us')}>
      {!isMobile && (
        <ContactUsDesktop
          contactUsPage
          titleText={t('contactUs.body.title', 'Contact Us')}
          bodyText={t(
            'contactUs.body.p_1',
            'Our team is here to help you with your roof projects. You can reach us anytime by phone, email or chat.'
          )}
        />
      )}
      <AcknowledgmentModal
        title={t('acknowledgmentModal.contactUs.title', 'Message Sent')}
        description={t(
          'acknowledgmentModal.contactUs.description',
          'Thank you, your message has been sent. One of our staff will be getting back to you shortly.'
        )}
        handleClick={handleCloseModal}
        handleClose={handleCloseModal}
        isShow={isOpenModal}
        buttonText={t('acknowledgmentModal.contactUs.ok', 'OK')}
      />
      <form className={styles.projectFormWrapper} onSubmit={handleSubmit}>
        {loading ? <Spinner /> : null}
        {!loading ? (
          <>
            <Text
              color="#000000"
              weight="wb"
              size="s19"
              tag="h2"
              lineHeight="h19"
              className={styles.title}
            >
              {t('contactUs.form.title', 'Send a message')}
            </Text>
            <ContactFrom
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              handleSubmit={handleSubmit}
            />
          </>
        ) : null}
      </form>
    </EmptyPageLayout>
  );
}

ContactUs.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  loading: PropTypes.bool,
  isOpenModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};

ContactUs.defaultProps = {
  touched: {},
  errors: {},
  loading: false,
  isOpenModal: false,
  handleCloseModal: () => {},
};

export default ContactUs;
