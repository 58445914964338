import isEmpty from 'lodash/isEmpty';

export function setCurrentTab(errors, touched, setCurrentTabIndex) {
  if (!isEmpty(errors) && !isEmpty(touched)) {
    switch (Object.keys(errors)[0]) {
      case 'myAccount':
        setCurrentTabIndex(0);
        break;
      case 'myApplication':
        setCurrentTabIndex(1);
        break;
      case 'myCertification':
        setCurrentTabIndex(2);
        break;
      default:
        setCurrentTabIndex(0);
    }
  }
}
