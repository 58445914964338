import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { object, array, bool, string } from 'yup';
import Textarea from 'components/shared/TextArea';
import Text from 'components/shared/Text';
import Checkbox from 'components/shared/CheckBox';
import UploadFileArea from 'components/shared/UploadFile/container';
import { toBase64UploadedFiles } from 'utils/toBase64File';
import WrapperBlock from 'components/shared/WrapperBlock';
import Button from 'components/shared/Button';
import { FormColumn } from 'components/shared/Form';
import LeaveNoteButton from '../LeaveNoteButton';
import styles from '../style.module.css';

const FormViewMaterialSection = ({
  isDisable,
  noteText,
  handleSubmit: handleConfirm,
}) => {
  const [isShowCommentMaterials, setIsShowCommentMaterials] = useState(false);

  const handleSubmitMaterials = async ({ files, comment }) => {
    const convertedFiles = await toBase64UploadedFiles(files);
    handleConfirm({ files: convertedFiles, comment });
  };
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        files: [],
        isConfirmed: false,
        comment: '',
      }}
      validationSchema={object().shape({
        files: array().min(0),
        isConfirmed: bool().required().equals([true]),
        comment: string().trim().required(isShowCommentMaterials),
      })}
      onSubmit={handleSubmitMaterials}
    >
      {({ handleChange, values, touched, errors, handleSubmit }) => {
        return (
          <WrapperBlock
            title={t(
              'jobs.awarded.details.activity.materials.formView.title',
              'Materials & Other'
            )}
            classNameMainWrapper={styles.wrapper}
            rightComponent={
              <LeaveNoteButton
                isDisable={isDisable}
                handleClick={() => {
                  if (isShowCommentMaterials)
                    handleChange({ target: { value: '', name: 'comment' } });
                  setIsShowCommentMaterials(!isShowCommentMaterials);
                }}
              >
                {noteText}
              </LeaveNoteButton>
            }
          >
            <div className={styles.sectionContent}>
              {isShowCommentMaterials && (
                <FormColumn>
                  <Textarea
                    placeholder={t('notePlaceholder', 'Leave a note...')}
                    value={values.comment}
                    handleChange={handleChange}
                    name="comment"
                    isError={touched.comment && Boolean(errors.comment)}
                    maxLength={255}
                  />
                </FormColumn>
              )}

              <Checkbox
                isContractorsStyle
                text={t(
                  'jobs.awarded.details.activity.materials.formView.agree',
                  'I agree that all materials have been delivered and the job description matches the project details.'
                )}
                textSize="large"
                checked={values.isConfirmed}
                isError={touched.isConfirmed && Boolean(errors.isConfirmed)}
                onChange={() =>
                  handleChange({
                    target: { name: 'isConfirmed', value: !values.isConfirmed },
                  })
                }
              />
              <br />
              <div>
                <div className={styles.label}>
                  <Text color="#837F7D" tag="p">
                    {t(
                      'jobs.awarded.details.activity.materials.formView.upload',
                      'Upload pics before if necessary.'
                    )}
                  </Text>
                </div>
                <UploadFileArea
                  buttonText={t('uploadButton', 'Upload file(s)')}
                  accept=".jpeg, .png, .jpg"
                  name="files"
                  uploadFiles={values.files}
                  handleChange={handleChange}
                  maxCountDocument={5}
                />
                <div className={styles.footerSection}>
                  <Button
                    size="medium"
                    handleClick={handleSubmit}
                    className={styles.buttonMobile}
                  >
                    {t('checkInButton', 'Check in')}
                  </Button>
                </div>
              </div>
            </div>
          </WrapperBlock>
        );
      }}
    </Formik>
  );
};

FormViewMaterialSection.propTypes = {
  isDisable: PropTypes.bool,
  noteText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

FormViewMaterialSection.defaultProps = {
  isDisable: false,
};

export default FormViewMaterialSection;
