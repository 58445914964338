import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LineText from 'components/common/LineText';
import GoogleMap from 'components/common/GoogleMap';
import { formatDate } from 'utils/dateFormatters';
import { withBracketsNumber } from 'utils/phoneNumberFormatter';
import isNumber from 'lodash/isNumber';
import styles from '../style.module.css';

const InformationSection = ({
  address,
  location,
  dateOfInstall,
  gateCode,
  contacts,
  awardAmount,
}) => {
  const centerLocation = location;
  const { t } = useTranslation();
  return (
    <div className={[styles.headerBlockSpace, styles.mapBlock].join(' ')}>
      <div className={styles.smallContainer}>
        <LineText
          name={t('jobs.awarded.details.general.info.address', 'Address')}
          value={address}
          isFixed
        />
        <LineText
          name={t('jobs.awarded.details.general.info.code', 'Gate Code')}
          value={gateCode}
          isFixed
        />
        {contacts?.map((contact, index) => {
          return (
            <div key={index}>
              <LineText
                name={`${t('jobs.awarded.details.general.info.contact', 'Contact')} ${
                  index + 1
                }`}
                value={contact.name}
                isFixed
              />
              <LineText name="" value={withBracketsNumber(contact.phone)} isFixed />
              <LineText name="" value={contact.contactType} isFixed />
            </div>
          );
        })}
        <br />
        <LineText
          name={t('jobs.awarded.details.general.info.date', 'Date of install')}
          value={formatDate(dateOfInstall)}
          isFixed
        />
        <LineText
          name={t('jobs.awarded.details.general.info.amount', 'Award Amount')}
          value={
            isNumber(awardAmount) ? `$ ${Intl.NumberFormat().format(awardAmount)}` : null
          }
          isValueBold
          isFixed
        />
      </div>
      <div className={styles.mapContainer}>
        <GoogleMap
          locations={[{ ...centerLocation }]}
          centerLocation={centerLocation}
          zoomLevel={14}
        />
      </div>
    </div>
  );
};

InformationSection.propTypes = {
  location: PropTypes.object,
  dateOfInstall: PropTypes.string,
  gateCode: PropTypes.string,
  contacts: PropTypes.array.isRequired,
  address: PropTypes.string.isRequired,
  awardAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InformationSection.defaultProps = {
  location: {},
  dateOfInstall: '',
  gateCode: '',
  awardAmount: '',
};

export default InformationSection;
