import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormColumn } from 'components/shared/Form';
import Modal, { ModalFooterButtons } from 'components/shared/modals/FormModal';
import Text from 'components/shared/Text';
import Checkbox from 'components/shared/CheckBox';
import { useRolesLocal } from 'localization';
import styles from './style.module.css';

const ModalContent = ({ handleClose, contacts, assignedContacts, handleSave }) => {
  const [selectedContactList, setSelectedContactList] = useState(assignedContacts);
  const [isError, serIsError] = useState(false);

  const handleSubmit = () => {
    if (!selectedContactList.length) return serIsError(true);
    return handleSave(selectedContactList);
  };

  const handleSelectContact = ({ sfId }) => {
    const contactList = [...selectedContactList];
    if (!contactList.includes(sfId)) {
      contactList.push(sfId);
      return setSelectedContactList(contactList);
    }
    return setSelectedContactList(contactList.filter((id) => id !== sfId));
  };
  const { t } = useTranslation();
  const localRoles = useRolesLocal();
  return (
    <>
      <FormColumn>
        <Text color="#000" size="s16">
          {t('assignCrewModal.p_1', 'Assign the Crew members for this job:')}
        </Text>
      </FormColumn>
      <FormColumn>
        <div
          className={[
            styles.wrapper,
            isError ? styles.wrapperRed : styles.wrapperBlack,
          ].join(' ')}
        >
          {contacts.map((contact, index) => {
            return (
              <div className={styles.lineWrapper} key={index}>
                <div className={styles.wrapperCheckbox}>
                  <Checkbox
                    isContractorsStyle
                    checked={selectedContactList.includes(contact.sfId)}
                    onChange={() => handleSelectContact(contact)}
                  />
                </div>
                <Text color="#000" size="s16">
                  {contact.name} ({localRoles[contact.role]})
                </Text>
              </div>
            );
          })}
        </div>
      </FormColumn>
      <ModalFooterButtons handleClose={handleClose} handleSubmit={handleSubmit} />
    </>
  );
};

ModalContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired,
  assignedContacts: PropTypes.array,
};

ModalContent.defaultProps = {
  assignedContacts: [],
};

const AssignCrewModal = ({ isShow, ...props }) => {
  const { t } = useTranslation();
  return (
    <Modal isShow={isShow} title={t('assignCrewModal.title', 'Assign Crew')}>
      <ModalContent {...props} />
    </Modal>
  );
};

AssignCrewModal.propTypes = {
  isShow: PropTypes.bool,
};

AssignCrewModal.defaultProps = {
  isShow: false,
};

export default AssignCrewModal;
