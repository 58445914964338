import React from 'react';
import { GoogleMap, Polygon, Circle } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const PolygonMap = ({
  containerStyle,
  center,
  coordinates,
  options,
  zoom,
  radius,
  mapWrapperClass,
}) => {
  return (
    <div className={mapWrapperClass}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
        {coordinates.map((coordinate, i) =>
          coordinate[0].length === 1 ? (
            <Circle
              key={i}
              center={coordinate[0][0]}
              radius={radius}
              options={options}
              visible
            />
          ) : (
            <Polygon key={i} paths={coordinate} options={options} />
          )
        )}
      </GoogleMap>
    </div>
  );
};
PolygonMap.propTypes = {
  containerStyle: PropTypes.object,
  center: PropTypes.object,
  coordinates: PropTypes.object.isRequired,
  options: PropTypes.object,
  zoom: PropTypes.number,
  radius: PropTypes.number,
  mapWrapperClass: PropTypes.string,
};
PolygonMap.defaultProps = {
  containerStyle: {
    width: '100%',
    height: '450px',
  },
  center: {
    lat: 32.1656221,
    lng: -82.9000751,
  },
  options: {
    strokeColor: '#F1C400',
    strokeOpacity: 1,
    strokeWeight: 4,
    fillColor: 'rgba(241, 196, 0, 0.5)',
    fillOpacity: 0.5,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  },
  zoom: 5,
  radius: 10000,
  mapWrapperClass: '',
};

export default PolygonMap;
