import React from 'react';
import { formatDate } from 'utils/dateFormatters';
import Button from 'components/shared/Button';
import { jobStatus } from 'enums';
import { useJobStatusLocal } from 'localization';
import styles from '../style.module.css';

const colorCircle = {
  new: styles.circleGreen,
  requested: styles.circleYellow,
};

export default ({
  handleClickClaim = () => {},
  isAllowViewAward = false,
  isAllowClaim = true,
  handleClickColumn = () => {},
  columnTitles,
}) => {
  const {
    jobReference,
    installDate,
    location,
    awardAmount,
    stat,
    action,
    actionText,
    actionTextClaimed,
  } = columnTitles;

  const columns = [
    {
      Header: jobReference,
      accessor: 'reference',
      className: styles.tableFirstCell,
      onClick: handleClickColumn,
      Cell: (row) => {
        return (
          <span className={[styles.firstCell, styles.firstCellSecondary].join(' ')}>
            {row.value}
          </span>
        );
      },
      bold: true,
    },
    {
      Header: installDate,
      accessor: 'dateOfInstall',
      onClick: handleClickColumn,
      Cell: (row) => formatDate(row.value),
    },
    {
      Header: location,
      accessor: 'opportunity.account.shortAddress',
      onClick: handleClickColumn,
    },
    {
      Header: awardAmount,
      accessor: 'amount',
      onClick: handleClickColumn,
      textAlignCell: 'center',
      textAlignHeader: 'center',
      Cell: (row) => (row.value ? `$ ${row.value}` : ''),
    },
    {
      Header: stat,
      accessor: 'status',
      onClick: handleClickColumn,
      Cell: (row) => {
        const status = jobStatus[row.value] || row.value;
        return (
          <div>
            <span
              className={[
                styles.circle,
                colorCircle[status?.toLowerCase().replace('-', '').split(' ')[0]] || '',
              ].join(' ')}
            />
            {useJobStatusLocal()[status]}
          </div>
        );
      },
    },
    {
      Header: action,
      accessor: '',
      Cell: (row) => {
        const { status } = row.row.original;
        const isDisabled = status === jobStatus.requested;
        return (
          <Button
            textSize="small"
            size="smallest"
            disabled={isDisabled}
            handleClick={() => handleClickClaim(row.row.original)}
          >
            {isDisabled ? actionTextClaimed : actionText}
          </Button>
        );
      },
      textAlignCell: 'center',
      textAlignHeader: 'center',
    },
  ];

  if (!isAllowViewAward) delete columns[3];
  if (!isAllowClaim) delete columns[5];
  return columns;
};
