import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const WideTabs = ({ tabList = [], currentTabIndex = 0, handleClick }) => {
  return (
    <Tabs className={styles.tabs} selectedIndex={currentTabIndex} onSelect={() => {}}>
      <TabList className={styles.tabList}>
        {tabList.map(({ title: tabTitle, isDisabled }, index) => (
          <Tab
            key={`${index}tab`}
            className={styles.tab}
            selectedClassName={styles.selectedTab}
            disabled={isDisabled}
            onClick={() => handleClick(index)}
          >
            {tabTitle}
          </Tab>
        ))}
      </TabList>
      {tabList.map(({ children }, index) => (
        <TabPanel
          key={`${index}tabPanel`}
          className={styles.tabPanel}
          selectedClassName={styles.selectedTabPanel}
        >
          {children}
        </TabPanel>
      ))}
    </Tabs>
  );
};

WideTabs.propTypes = {
  tabList: PropTypes.array.isRequired,
  currentTabIndex: PropTypes.number,
  handleClick: PropTypes.func,
};

WideTabs.defaultProps = {
  currentTabIndex: 0,
  handleClick: () => {},
};

export default WideTabs;
