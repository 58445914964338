export default Object.freeze({
  new: 'New',
  requested: 'Requested',
  in_progress: 'In Progress',
  completed: 'Completed',
  rollover: 'Rollover',
  closed: 'Closed',
  canceled: 'Canceled',
  scheduled: 'Scheduled',
  reschedule_requested: 'Reschedule Requested',
  awarded: 'Scheduled',
  warranty: 'Warranty',
});
