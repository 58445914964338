export const circleOptions = {
  strokeOpacity: 1,
  fillOpacity: 0.5,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

export const defaultCircleColors = {
  strokeColor: '#f3c306',
  strokeWeight: 2,
  fillColor: '#f3c306',
};
