import React from 'react';
import PropTypes from 'prop-types';
import styles from '../style.module.css';
import SortIcon from '../sortIcon';
import { StyledTable, StyledCell, StyledHeaderCell, StyledRow } from '../TableStyles';

const DesktopTable = ({
  getTableProps,
  headerGroups,
  tableRows,
  prepareRow,
  tableFixed,
  hasRowClick,
  onRowClick,
  isFinance,
}) => {
  return (
    <StyledTable {...getTableProps()} tableFixed={tableFixed}>
      <thead className={styles.tableHeader}>
        {headerGroups.map((headerGroup) => (
          <tr className={styles.tableHeaderRow} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <StyledHeaderCell
                textAlign={column.textAlignHeader || 'left'}
                bold={column.isSorted}
                {...column.getHeaderProps({
                  ...column.getSortByToggleProps(),
                })}
              >
                {column.isHideHeader ? '' : column.render('Header')}
                <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
              </StyledHeaderCell>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {tableRows.map(
          (row) =>
            prepareRow(row) || (
              <StyledRow
                {...row.getRowProps()}
                onClick={() => (hasRowClick ? onRowClick(row.original) : null)}
                isFinance={isFinance}
              >
                {row.cells.map((cell) => {
                  return (
                    <StyledCell
                      {...cell.getCellProps()}
                      bold={cell.column.bold}
                      textAlign={cell.column.textAlignCell || 'left'}
                      isCutText={cell.column.isCutText}
                      className={cell.column.className}
                      onClick={() =>
                        cell.column.onClick ? cell.column.onClick(row.original) : null
                      }
                    >
                      {cell.render('Cell')}
                    </StyledCell>
                  );
                })}
              </StyledRow>
            )
        )}
      </tbody>
    </StyledTable>
  );
};

DesktopTable.propTypes = {
  getTableProps: PropTypes.func.isRequired,
  headerGroups: PropTypes.array.isRequired,
  tableRows: PropTypes.array.isRequired,
  prepareRow: PropTypes.func.isRequired,
  tableFixed: PropTypes.bool,
  onRowClick: PropTypes.func,
  hasRowClick: PropTypes.bool,
  isFinance: PropTypes.bool,
};

DesktopTable.defaultProps = {
  tableFixed: false,
  hasRowClick: false,
  isFinance: false,
  onRowClick: () => {},
};

export default DesktopTable;
