import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/shared/modals/SunnyModal';
import HeaderContent from './HeaderContent';
import MainContent from './MainContent';

const InspectionModal = ({ isShow, handleClose, handleSubmit }) => {
  if (!isShow) return null;
  return (
    <Modal
      isSmallSize
      headerContent={<HeaderContent />}
      mainContent={<MainContent handleSubmit={handleSubmit} handleClose={handleClose} />}
    />
  );
};

InspectionModal.propTypes = {
  isShow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default InspectionModal;
