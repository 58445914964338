import gql from 'graphql-tag';

const FullCompanyData = gql`
  fragment FullCompanyData on CompanyData {
    companyName
    companyNameDBA
    address
    suiteNumber
    ownerLastName
    ownerFirstName
    companyPhoneNumber
    ownerEmail
    yearCompanyStarted
    taxID
    organization
    registrationState
    dunnBradstreetNumber
    website
    facebook
    linkedin
    twitter

    residentialVSCommercial
    restorationVSRetail
    shingle
    metal
    tile
    modifiedBitumenResidential
    builtUpMetal
    modifiedBitumenCommercial
    thermoset
    thermoplastic
    serviceOffered

    definedWorkAreas {
      states {
        id
        name
        shortname
      }
      counties {
        id
        name
        state
      }
      cities {
        id
        name
        county
        state
      }
    }

    numberCrews
    numberLaborers
    crewAveragePerDay
    hasDumpTrailer
    staffedPullPermits
    onsiteManagementStructure
    structure

    contractorSfId
    contractorPgId
    accountPgId
    accountSfId
    sfId
    pgId
  }
`;

export const CompanyData = {
  fragments: {
    FullCompanyData,
  },
};
