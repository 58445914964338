import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Spinner from 'components/shared/Spinner';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import { GET_COMPANY_DATA } from '../../graphql/queries/companyProfile';
import CompanyProfileContainer from '../../containers/CompanyProfile';

const CompanyProfilePage = () => {
  const { data, loading } = useQuery(GET_COMPANY_DATA);

  return (
    <LayoutWithSidebar>
      {loading ? <Spinner /> : <CompanyProfileContainer data={data?.getCompanyData} />}
    </LayoutWithSidebar>
  );
};

export default CompanyProfilePage;
