import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import { FormColumn as Col, FormRow as Row } from 'components/shared/Form';
import Field from 'components/shared/Field';
import Text from 'components/shared/Text';
import Checkbox from 'components/shared/CheckBox';
import {
  useOnsiteManagementDays,
  useOnsiteManagementStructure,
} from 'enums/application/onsiteManagement';
import useError from 'services/hooks/useErrors/useErrorForms';
import styles from '../style.module.css';

const Coverage = ({
  values: { coverage },
  errors,
  touched,
  handleChange,
  setFieldValue,
  isReadOnlyInput,
}) => {
  useError(errors, touched, 'companyCoverage');
  const onsiteManagementDays = useOnsiteManagementDays();
  const onsiteManagementStructure = useOnsiteManagementStructure();

  const { t } = useTranslation();
  return (
    <div className={styles.projectTabWrapper}>
      <div className={styles.projectFormBanner}>
        <WrapperBlock
          title={t('companyProfile.coverage.headerTitle', 'Coverage & Crew')}
          isForm
          isContractorsStyle
        >
          <div className={styles.projectFormContent}>
            <Row>
              <Col>
                <Field
                  name="numberCrews"
                  label={t('companyProfile.coverage.crewLabel', '# Crews')}
                  type="text"
                  value={coverage.numberCrews}
                  onChange={handleChange}
                  isError={
                    touched.coverage?.numberCrews && Boolean(errors.coverage?.numberCrews)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
              <Col>
                <Field
                  name="numberLaborers"
                  label={t(
                    'companyProfile.coverage.laborersLabel',
                    '# of Laborers Per Crew'
                  )}
                  type="text"
                  value={coverage.numberLaborers}
                  onChange={handleChange}
                  isError={
                    touched.coverage?.numberLaborers &&
                    Boolean(errors.coverage?.numberLaborers)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="crewAveragePerDay"
                  label={t(
                    'companyProfile.coverage.sqsLabel',
                    'Crew average # sqs per day'
                  )}
                  type="text"
                  value={coverage.crewAveragePerDay}
                  onChange={handleChange}
                  isError={
                    touched.coverage?.crewAveragePerDay &&
                    Boolean(errors.coverage?.crewAveragePerDay)
                  }
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.checkboxQuestionWrapper}>
                  <div className={styles.questionWrapper}>
                    <Text color="#000" size="s14" weight="wm">
                      {t(
                        'companyProfile.coverage.dumpLabel',
                        'Do you have a dump trailer?'
                      )}
                    </Text>
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      isContractorsStyle
                      value="true"
                      text={t('companyProfile.coverage.yes', 'Yes')}
                      name="hasDumpTrailer"
                      checked={coverage.hasDumpTrailer === true}
                      isError={
                        touched.coverage?.hasDumpTrailer &&
                        Boolean(errors.coverage?.hasDumpTrailer)
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('coverage.hasDumpTrailer', true);
                      }}
                      isReadOnlyInput={isReadOnlyInput}
                    />
                    <Checkbox
                      isContractorsStyle
                      value="false"
                      text={t('companyProfile.coverage.no', 'No')}
                      name="hasDumpTrailer"
                      checked={coverage.hasDumpTrailer === false}
                      isError={
                        touched.coverage?.hasDumpTrailer &&
                        Boolean(errors.coverage?.hasDumpTrailer)
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('coverage.hasDumpTrailer', false);
                      }}
                      isReadOnlyInput={isReadOnlyInput}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.checkboxQuestionWrapper}>
                  <div className={styles.questionWrapper}>
                    <Text color="#000" size="s14" weight="wm">
                      {t(
                        'companyProfile.coverage.permits',
                        'Are you staffed to pull permits?'
                      )}
                    </Text>
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      isContractorsStyle
                      value="true"
                      text={t('companyProfile.coverage.yes', 'Yes')}
                      name="staffedPullPermits"
                      checked={coverage.staffedPullPermits}
                      isError={
                        touched.coverage?.staffedPullPermits &&
                        Boolean(errors.coverage?.staffedPullPermits)
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('coverage.staffedPullPermits', true);
                      }}
                      isReadOnlyInput={isReadOnlyInput}
                    />
                    <Checkbox
                      isContractorsStyle
                      value="false"
                      text={t('companyProfile.coverage.no', 'No')}
                      name="staffedPullPermits"
                      checked={coverage.staffedPullPermits === false}
                      isError={
                        touched.coverage?.staffedPullPermits &&
                        Boolean(errors.coverage?.staffedPullPermits)
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('coverage.staffedPullPermits', false);
                      }}
                      isReadOnlyInput={isReadOnlyInput}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={styles.secondLastBlock}>
                <Field
                  name="onsiteManagementStructure"
                  label={t(
                    'companyProfile.coverage.describeLabel',
                    'Describe onsite management structure:'
                  )}
                  type="select"
                  options={onsiteManagementStructure}
                  value={coverage.onsiteManagementStructure}
                  isError={
                    touched.coverage?.onsiteManagementStructure &&
                    Boolean(errors.coverage?.onsiteManagementStructure)
                  }
                  onChange={handleChange}
                  isReadOnly={isReadOnlyInput}
                />
              </Col>
              {coverage.onsiteManagementStructure ===
              onsiteManagementStructure[1].value ? (
                <Col className={styles.lastBlock}>
                  <Field
                    name="structure"
                    label=""
                    type="select"
                    options={onsiteManagementDays}
                    value={coverage.structure}
                    isError={
                      touched.coverage?.structure && Boolean(errors.coverage?.structure)
                    }
                    onChange={handleChange}
                    isReadOnly={isReadOnlyInput}
                  />
                </Col>
              ) : null}
            </Row>
          </div>
        </WrapperBlock>
      </div>
    </div>
  );
};
Coverage.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  isReadOnlyInput: PropTypes.bool,
};
Coverage.defaultProps = {
  isReadOnlyInput: false,
};

export default Coverage;
