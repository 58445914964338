import PropTypes from "prop-types";
import React from "react";
import Text from "components/shared/Text";
import styles from "./style.module.css";

const LegendItem = ({ color, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.indicator} style={{ backgroundColor: color }}/>
      <Text size="s14" lineHeight="l16" color="#989CA4">{children}</Text>
    </div>
  );
};

export default LegendItem;

LegendItem.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
