import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from 'components/shared/Button';
import editIcon from 'assets/icons/edit.svg';
import deleteIcon from 'assets/icons/delete.svg';
import TableView from 'layouts/TableView';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import useModal from 'services/hooks/useModal';
import IconSVG from 'components/shared/IconSVG';
import { ADD_NEW_MEMBER, DELETE_MEMBER, EDIT_MEMBER } from 'graphql/mutations/members';
import { GET_MEMBERS } from 'graphql/queries/members';
import Spinner from 'components/shared/Spinner';
import AuthRoleContext from 'context/AuthRoleContext';
import roles from 'enums/roles';
import Text from 'components/shared/Text';
import AppStatusContext from 'context/AppStatusContext';
import { applicationStatus } from 'enums';
import { useHistory } from 'react-router-dom';
import WithError from 'services/HOCs/withError';
import { useRolesLocal, useUserStatusLocal } from 'localization';
import { useDetLang } from 'services/localization';
import DeleteMemberModal from './Modals/DeleteMemberModal';
import EditMemberModal from './Modals/EditMemberModal';
import AddMemberModal from './Modals/AddMemberModal';
import TableMobile from './Mobile/TableMobile';
import styles from './style.module.css';
import { parseQuery } from '../../utils/parseQueryString';

const memberStatuses = {
  Active: true,
  Disabled: false,
};

const statusesColors = {
  Active: '#6C9961',
  Disabled: '#979797',
};

export function getStatusValueByName(status) {
  return memberStatuses[status];
}

export function getStatusNameByValue(value) {
  return Object.keys(memberStatuses).find((status) => memberStatuses[status] === value);
}

export const useGeneralTableColumns = () => {
  const { t } = useTranslation();
  return [
    {
      Header: t('members.table.name', 'Name'),
      accessor: (el) => `${el.firstName} ${el.lastName}`,
      Cell: (row) => {
        const { lastName, firstName } = row.row.original;
        return `${firstName} ${lastName}`;
      },
    },
    {
      Header: t('members.table.role', 'Role'),
      accessor: 'role',
      Cell: (row) => {
        const { role } = row.row.original;

        return useRolesLocal()[role];
      },
    },
    {
      Header: t('members.table.phone', 'Phone Number'),
      accessor: 'phone',
    },
  ];
};

const useGetTableColumnsForAdmin = ({ handleDelete, handleEdit, setSelectedEntryId }) => {
  const { t } = useTranslation();
  return [
    ...useGeneralTableColumns(),
    {
      Header: t('members.table.email', 'Email'),
      accessor: 'email',
    },
    {
      Header: t('members.table.status', 'Status'),
      accessor: 'status',
      Cell: (row) => {
        const { status } = row.row.original;
        return (
          <div className={styles.statusContainer}>
            <div
              className={styles.indicator}
              style={{ backgroundColor: statusesColors[status] }}
            />
            {useUserStatusLocal()[status]}
          </div>
        );
      },
    },
    {
      Header: t('members.table.action', 'Action'),
      accessor: '',
      Cell: (row) => {
        const _setSelectedEntryId = () => setSelectedEntryId(row.row.original.pgId);

        const _handleEdit = () => {
          _setSelectedEntryId();
          handleEdit();
        };

        const _handleDelete = () => {
          _setSelectedEntryId();
          handleDelete();
        };

        return (
          <div className={styles.switchContainer}>
            <IconSVG src={editIcon} handleClick={_handleEdit} />
            <IconSVG src={deleteIcon} handleClick={_handleDelete} />
          </div>
        );
      },
      isHideHeader: true,
    },
  ];
};

const useGetTableColumnsForRegularUser = () => {
  const { t } = useTranslation();
  return [
    ...useGeneralTableColumns(),
    {
      Header: t('members.table.status', 'Status'),
      accessor: 'status',
      Cell: (row) => {
        const { status } = row.row.original;
        return (
          <div className={styles.statusContainer}>
            <div
              className={styles.indicator}
              style={{ backgroundColor: statusesColors[status] }}
            />
            {useUserStatusLocal()[status]}
          </div>
        );
      },
    },
  ];
};

const AddContactButton = ({ handleClick }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.buttonContainer}>
      <Button
        handleClick={handleClick}
        textSize="small"
        size="medium"
        height={useDetLang('local', '')}
      >
        {t('members.addNewButton', 'Add New Member')}
      </Button>
    </div>
  );
};

const MembersPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const isAdmin = useContext(AuthRoleContext) === roles.admin;

  const [selectedEntryId, setSelectedEntryId] = useState(null);
  const resetSelectedEntry = () => setSelectedEntryId(null);

  // Selected page index
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  useEffect(() => {
    const { search } = history.location;
    const query = parseQuery(search);
    if (query?.page) setCurrentPageIndex(query.page - 1);
  }, [currentPageIndex, history.location.search]);

  const { data, loading } = useQuery(GET_MEMBERS);
  const [members, setMembers] = useState(data?.members);

  useEffect(() => {
    if (!loading) {
      setMembers(
        data?.members.map((member) => ({
          ...member,
          phone: `(${member.phone.substr(0, 3)}) ${member.phone.substr(
            3,
            3
          )}-${member.phone.substr(6, 4)}`,
        }))
      );
    }
  }, [data, loading]);

  const [handleAdd, { loading: isAddLoading, error }] = useMutation(ADD_NEW_MEMBER);
  const [handleEdit, { loading: isEditLoading }] = useMutation(EDIT_MEMBER);
  const [handleDelete, { loading: isDeleteLoading }] = useMutation(DELETE_MEMBER);

  const { isShow: isShowAdd, handleClick: handleClickAdd } = useModal();
  const { isShow: isShowEdit, handleClick: handleClickEdit } = useModal();
  const { isShow: isShowDelete, handleClick: handleClickDelete } = useModal();

  const getSelectedEntry = () => {
    if (data?.members) return data.members.find((el) => el.pgId === selectedEntryId);
    return null;
  };

  const selectedEntry = getSelectedEntry();

  const columnsForAdmin = useGetTableColumnsForAdmin({
    handleEdit: handleClickEdit,
    handleDelete: handleClickDelete,
    setSelectedEntryId,
  });

  const columnsForRegularUser = useGetTableColumnsForRegularUser();
  const _tableColumns = isAdmin ? columnsForAdmin : columnsForRegularUser;

  const emptyPageContent = (
    <div>
      <Text
        lineHeight="h22"
        size="s19"
        color="#000000"
        tag="p"
        className={styles.emptyContentText}
      >
        {t('members.emptyPage', 'There are no other team members yet!')}
      </Text>
      {isAdmin && <AddContactButton handleClick={handleClickAdd} />}
    </div>
  );
  const { appStatus } = useContext(AppStatusContext);

  return (
    <LayoutWithSidebar>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {appStatus === applicationStatus.approved ? (
            <div className={styles.pageWrapper}>
              <TableView
                searchPlaceholder={t('members.search', 'Search by name or address')}
                searchValues={[
                  'firstName',
                  'lastName',
                  'phone',
                  'email',
                  'role',
                  'status',
                ]}
                tableColumns={_tableColumns}
                dataList={members || []}
                emptyPageText={emptyPageContent}
                buttons={
                  isAdmin ? <AddContactButton handleClick={handleClickAdd} /> : <></>
                }
                tableOptions={{
                  currentPageIndex,
                  mobileContent: (tableItemProps) => {
                    return (
                      <TableMobile
                        {...tableItemProps.data}
                        name={`${tableItemProps.data.firstName} ${tableItemProps.data.lastName}`}
                        phone={tableItemProps.data.phone}
                        email={tableItemProps.data.email}
                        role={tableItemProps.data.role}
                        status={tableItemProps.data.status}
                        handleEdit={() => {
                          setSelectedEntryId(tableItemProps.data.pgId);
                          handleClickEdit();
                        }}
                        handleDelete={() => {
                          setSelectedEntryId(tableItemProps.data.pgId);
                          handleClickDelete();
                        }}
                      />
                    );
                  },
                }}
                classNameHeader={styles.headerWrapper}
                classNameWrapper={styles.wrapper}
              />
              {isAdmin ? (
                <WithError
                  isShow={!!error}
                  message={`Error: ${
                    error?.message.split('Error:')[1] || error?.message.split(':')[2]
                  }`}
                >
                  <AddMemberModal
                    membersEmails={members?.map((member) => member.email) ?? []}
                    isShow={isShowAdd}
                    handleClose={handleClickAdd}
                    handleSubmit={handleAdd}
                    loading={isAddLoading}
                  />
                </WithError>
              ) : null}

              {isAdmin && selectedEntry ? (
                <>
                  <EditMemberModal
                    isShow={isShowEdit}
                    handleClose={handleClickEdit}
                    data={selectedEntry}
                    handleSubmit={handleEdit}
                    loading={isEditLoading}
                    resetSelectedEntry={resetSelectedEntry}
                  />
                  <DeleteMemberModal
                    isShow={isShowDelete}
                    handleClose={handleClickDelete}
                    data={selectedEntry}
                    handleSubmit={handleDelete}
                    loading={isDeleteLoading}
                    resetSelectedEntry={resetSelectedEntry}
                  />
                </>
              ) : null}
            </div>
          ) : (
            <div className={styles.textWrapper}>
              <Text color="#000000" size="s16" lineHeight="h20" weight="wr">
                {t(
                  'notAvailablePage',
                  'This page will be available only when application is approved'
                )}
              </Text>
            </div>
          )}
        </>
      )}
    </LayoutWithSidebar>
  );
};

export default MembersPage;

AddContactButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};
