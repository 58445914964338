import { passwordWarning } from 'localization';
import { useDetLang } from 'services/localization';

export const useWarningLocalization = (warning) => {
  const {
    aaa,
    common,
    top,
    similar,
    sequences,
    straight,
    short,
    dates,
    years,
  } = passwordWarning;

  const aaaError = useDetLang(aaa, warning);
  const commonError = useDetLang(common, warning);
  const topError = useDetLang(top, warning);
  const similarError = useDetLang(similar, warning);
  const sequencesError = useDetLang(sequences, warning);
  const straightError = useDetLang(straight, warning);
  const shortError = useDetLang(short, warning);
  const datesError = useDetLang(dates, warning);
  const yearsError = useDetLang(years, warning);

  if (warning.includes('"aaa"')) return aaaError;
  if (warning.includes('very common')) return commonError;
  if (warning.includes('top-100')) return topError;
  if (warning.includes('commonly used')) return similarError;
  if (warning.includes('6543')) return sequencesError;
  if (warning.includes('Straight')) return straightError;
  if (warning.includes('Short')) return shortError;
  if (warning.includes('Dates')) return datesError;
  if (warning.includes('Recent')) return yearsError;
  return commonError;
};
