import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import { Redirect } from 'react-router-dom';
import { AVAILABLE_OPPORTUNITIES } from 'graphql/queries/opportunities';
import { CLAIM_OPPORTUNITY } from 'graphql/mutations/opportunity';
import { updateCurrentJobList } from 'graphql/resolvers/opportunity/cache';
import { useMutation } from '@apollo/react-hooks';
import SubHeader from 'components/shared/NavigationSubHeader';
import WrapperBlock from 'components/shared/WrapperBlock';
import PropTypes from 'prop-types';
import AuthRoleContext from 'context/AuthRoleContext';
import Button from 'components/shared/Button';
import withLoadOpportunities from 'services/HOCs/withLoadData';
import { jobStatus, roles } from 'enums';
import { isAllowAction } from 'services/permissions';
import { useDetLang } from 'services/localization';
import DescriptionSection from './DescriptionSection';
import InformationSection from './InformationSection';
import styles from '../pageStyles.module.css';

const AvailableJobDetailsPage = ({ data: loadedData, ...props }) => {
  const { match } = props;
  const [claimOpportunity, { loading: loadingClaim }] = useMutation(CLAIM_OPPORTUNITY);
  const userRole = useContext(AuthRoleContext);

  const { t } = useTranslation();
  const buttonHeight = useDetLang('base', 'small');
  const buttonWidth = useDetLang('local', 'small');

  const job = loadedData?.workOrders.find(({ sfId }) => sfId === match.params.id);

  if (!job) return <Redirect to="/jobs" />;

  const isDisableClaim = job.status === jobStatus.requested;

  const handleClaim = () => {
    claimOpportunity({
      variables: {
        id: job.sfId,
      },
      update: () => {
        updateCurrentJobList(job.sfId, { status: jobStatus.requested });
      },
    });
  };

  return (
    <LayoutWithSidebar>
      <div className={styles.container}>
        <SubHeader
          items={[
            {
              title: t('jobs.available.details.header.title', 'Available Jobs'),
              link: '/jobs',
            },
            {
              title: job.reference,
              link: null,
            },
          ]}
          rightComponent={
            isAllowAction([roles.admin, roles.finance], userRole) && (
              <Button
                textSize="small"
                size={buttonWidth}
                height={buttonHeight}
                handleClick={handleClaim}
                disabled={loadingClaim || isDisableClaim}
              >
                {isDisableClaim
                  ? t('jobs.available.details.header.claimed', 'Claimed')
                  : t('jobs.available.details.header.claim', 'Claim this job')}
              </Button>
            )
          }
        />
        <div className={[styles.tabContentWrapper, styles.mainContent].join(' ')}>
          <WrapperBlock
            title={t('jobs.available.details.info.title', 'Information')}
            classNameMainWrapper={styles.wrapper}
          >
            <InformationSection
              {...job.opportunity}
              number={job.reference}
              address={job.opportunity?.account?.shortAddress}
              location={job.opportunity?.location}
              dateOfInstall={job?.dateOfInstall}
              awardAmount={
                isAllowAction([roles.admin, roles.finance], userRole) ? job.amount : ''
              }
            />
          </WrapperBlock>
          <WrapperBlock
            title={t('jobs.available.details.details.title', 'Details')}
            classNameMainWrapper={styles.wrapper}
          >
            <DescriptionSection
              {...job.opportunity}
              description={job.description}
              jobSfId={job.sfId}
            />
          </WrapperBlock>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

AvailableJobDetailsPage.propTypes = {
  match: PropTypes.object.isRequired,
  data: PropTypes.object,
};

AvailableJobDetailsPage.defaultProps = {
  data: {
    workOrders: [],
  },
};

export default withLoadOpportunities(AvailableJobDetailsPage, {
  isCurrentProject: true,
  query: AVAILABLE_OPPORTUNITIES,
});
