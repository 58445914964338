import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRoofTypeLocal, useLoseTypeLocal } from 'localization';
import LineText from 'components/common/LineText';
import Text from 'components/shared/Text';
import File from 'components/shared/LinkPDF/DownloadLink';
import Spinner from 'components/shared/Spinner';
import useLoadAttachments from 'services/hooks/loadAttachments';
import filesPrefix from 'enums/filesPrefix';
import styles from '../style.module.css';

const DescriptionSection = ({
  opportunity = {},
  description,
  files = [],
  openCaseSfId,
  warrantyJobs,
}) => {
  const {
    totalSqs,
    product,
    existingShingleType,
    typeOfLoss,
    roofPitch,
    sfId: opportunitySfId,
  } = opportunity;

  const {
    files: serviceFiles,
    loading: isLoadingFiles,
    handleDownload: handleDownloadFile,
    loadingDownload: isLoadingDownload,
  } = useLoadAttachments({
    contentDocumentId: warrantyJobs ? openCaseSfId : opportunitySfId,
    entityName: warrantyJobs ? null : 'Service',
  });

  const filteredFiles = files?.filter(({ name }) => name.includes(`${filesPrefix.full}`));
  const { t } = useTranslation();
  const roofTypeLocal = useRoofTypeLocal();
  const loseTypeLocal = useLoseTypeLocal();
  return (
    <div className={styles.headerBlockSpace}>
      <div className={styles.bigContentContainer}>
        <LineText
          name={t('jobs.awarded.details.general.details.sqs', 'Total SQs')}
          value={totalSqs}
        />
        <LineText
          name={t(
            'jobs.awarded.details.general.details.product',
            'Replacement Roof Product'
          )}
          value={product?.name}
        />
        <LineText
          name={t('jobs.awarded.details.general.details.type', 'Existing Shingle Type')}
          value={roofTypeLocal[existingShingleType]}
        />
        <LineText
          name={t('jobs.awarded.details.general.details.lose', 'Type of Loss')}
          value={loseTypeLocal[typeOfLoss]}
        />
        <LineText
          name={t('jobs.awarded.details.general.details.pitch', 'Roof Pitch')}
          value={roofPitch}
        />
      </div>
      <div className={styles.container}>
        {description && (
          <>
            <Text size="s14" color="#686868" tag="p">
              {t('jobs.awarded.details.general.details.description', 'Description')}
            </Text>
            <Text color="#000" size="s14">
              {description}
            </Text>
          </>
        )}

        {isLoadingFiles ? (
          <Spinner color="white" />
        ) : (
          <div className={styles.content}>
            {[...serviceFiles, ...filteredFiles].map((file, index) => {
              return (
                <div key={index}>
                  <File
                    fileName={`${file.name}.${file.contentType}`}
                    type={file.contentType}
                    loading={isLoadingDownload}
                    handleQueryClick={() => handleDownloadFile(file.contentDocumentId)}
                  >
                    {file.name}
                  </File>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

DescriptionSection.propTypes = {
  opportunity: PropTypes.object.isRequired,
  description: PropTypes.string,
  openCaseSfId: PropTypes.string,
  warrantyJobs: PropTypes.bool,
  files: PropTypes.array.isRequired,
};

DescriptionSection.defaultProps = {
  description: '',
  openCaseSfId: '',
  warrantyJobs: false,
};

export default DescriptionSection;
