import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import Text from 'components/shared/Text';
import styles from './style.module.css';
import Tooltip from '../../common/Tooltip';

function WrapperBlock({
  children,
  title,
  isForm,
  icon,
  rightComponent,
  className,
  classNameMainWrapper,
  isContractorsStyle,
  tag,
  isOnBoarding,
  isChecked,
  isHasTooltip,
  isHideSeparateLine,
  toolTipText,
}) {
  const isMobile = useContext(MobileContext);
  // const isHideSeparateLine = true;
  const classNameBlockRight = [styles.wrapperBlockRight, className].join(' ');

  const [isVisible, setIsVisible] = useState(true);

  const handleToggleBlock = () => {
    setIsVisible(!isVisible);
  };

  return (
    <section
      className={[
        styles.wrapperBlock,
        isOnBoarding
          ? isChecked
            ? styles.wrapperCheckBlockChecked
            : styles.wrapperCheckBlock
          : '',
      ].join(' ')}
    >
      {isOnBoarding && (
        <span className={[styles.checkbox, isChecked ? styles.checked : ''].join(' ')} />
      )}
      <div className={isOnBoarding ? styles.titleBlock : ''}>
        <div className={styles.wrapperBlockJustify}>
          <div>
            <div className={styles.wrapperBlockTitle}>
              <Text
                color="#000"
                weight="wb"
                className={styles.title}
                size={
                  title === 'Defined Work Areas'
                    ? isMobile
                      ? 's20'
                      : 's24'
                    : isMobile
                    ? 's16'
                    : 's19'
                }
                lineHeight="h14"
                tag={tag}
              >
                {title}
              </Text>
              {icon && <div className={styles.wrapperBlockIcon}>{icon}</div>}
              {isHasTooltip && <Tooltip text={toolTipText} />}
            </div>
            {!isHideSeparateLine && (
              <div
                className={[
                  styles.wrapperBlockLine,
                  isContractorsStyle ? styles.wrapperBlockLineContractor : '',
                ].join(' ')}
              />
            )}
          </div>
          {rightComponent !== null && (
            <div className={classNameBlockRight}>{rightComponent}</div>
          )}
        </div>
      </div>
      {isOnBoarding && isMobile && (
        <span
          onClick={handleToggleBlock}
          className={[
            styles.toggleButton,
            isVisible ? styles.toggleButtonRotate : '',
          ].join(' ')}
        />
      )}
      <main
        className={[
          isForm ? styles.wrapperBlockMainForm : styles.wrapperBlockMain,
          isContractorsStyle ? styles.wrapperBlockMainContractors : '',
          isOnBoarding ? styles.wrapperBlockMainOnBoarding : '',
          isVisible ? '' : styles.hidden,
          classNameMainWrapper,
        ].join(' ')}
      >
        {children}
      </main>
    </section>
  );
}

WrapperBlock.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  isForm: PropTypes.bool,
  rightComponent: PropTypes.node,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  classNameMainWrapper: PropTypes.string,
  isContractorsStyle: PropTypes.bool,
  tag: PropTypes.string,
  isOnBoarding: PropTypes.bool,
  isChecked: PropTypes.bool,
  isHasTooltip: PropTypes.bool,
  isHideSeparateLine: PropTypes.bool,
  toolTipText: PropTypes.string,
};

WrapperBlock.defaultProps = {
  className: '',
  classNameMainWrapper: '',
  icon: null,
  isForm: false,
  rightComponent: null,
  isContractorsStyle: false,
  tag: 'span',
  isOnBoarding: false,
  isChecked: false,
  isHasTooltip: false,
  isHideSeparateLine: false,
  toolTipText: '',
};

export default WrapperBlock;
