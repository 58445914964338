import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

export const StyledTable = ({ children, tableFixed, ...props }) => (
  <table
    {...props}
    className={[styles.table, tableFixed ? styles.tableFixed : ''].join(' ')}
  >
    {children}
  </table>
);

export const StyledCell = ({
  children,
  bold,
  textAlign,
  isCutText,
  className,
  ...props
}) => {
  return (
    <td
      {...props}
      className={[
        styles.tableCell,
        bold ? styles.textBold : '',
        styles[textAlign],
        isCutText ? styles.tableCellCut : '',
        className,
      ].join(' ')}
    >
      {children}
    </td>
  );
};

StyledCell.propTypes = {
  children: PropTypes.node.isRequired,
  bold: PropTypes.bool,
  textAlign: PropTypes.string,
  isCutText: PropTypes.bool,
  className: PropTypes.string,
};
StyledCell.defaultProps = {
  bold: false,
  isCutText: false,
  textAlign: 'left',
  className: '',
};

export const StyledHeaderCell = ({ children, bold, textAlign, ...props }) => {
  return (
    <th
      {...props}
      className={[
        styles.tableHeaderCell,
        styles[textAlign],
        bold ? styles.textBold : styles.text,
      ].join(' ')}
    >
      {children}
    </th>
  );
};

StyledHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
  bold: PropTypes.bool,
  textAlign: PropTypes.string,
};

StyledHeaderCell.defaultProps = {
  bold: false,
  textAlign: 'left',
};

export const StyledRow = ({ children, isFinance, ...props }) => {
  return (
    <tr {...props} className={isFinance ? styles.tableRowFinance : styles.tableRow}>
      {children}
    </tr>
  );
};

StyledTable.propTypes = {
  children: PropTypes.node.isRequired,
  tableFixed: PropTypes.bool,
};

StyledTable.defaultProps = {
  tableFixed: false,
};

StyledRow.propTypes = {
  children: PropTypes.node.isRequired,
  isFinance: PropTypes.bool,
};
StyledRow.defaultProps = {
  isFinance: false,
};
