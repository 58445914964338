import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Text from 'components/shared/Text';
import { MESSAGES } from 'graphql/queries/messages';
import Spinner from 'components/shared/Spinner';
import { formatDateTime } from 'utils/dateFormatters';
import styles from './style.module.css';

const MessagesTab = ({ parentId, type = 'workOrder' }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(MESSAGES, {
    variables: {
      parentId,
      type,
    },
  });

  if (loading)
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );

  if (!data?.messages?.length)
    return (
      <div className={styles.mainContent}>
        {t(
          'messagePage.noMessageText',
          'Currently, you have not received any messages from RoofClaim.'
        )}
      </div>
    );

  return (
    <div className={styles.mainContent}>
      {data?.messages?.map((message, sectionIndex) => {
        return (
          <div className={styles.messageWrapper} key={sectionIndex}>
            <div className={styles.messageTitle}>
              <Text color="#000000" weight="wb" size="s16">
                {message.type}
              </Text>{' '}
              <Text color="#000000" weight="wb" size="s14">
                {formatDateTime(message.createdDate)}
              </Text>
              <div className={styles.wrapperBlockLine} />
            </div>
            <div>
              {message.body && (
                <Text color="#000000" weight="wr" size="s14" tag="p" lineHeight="h18">
                  <span
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{
                      __html: message.body
                        .replace('CONT-', '')
                        .replace('CONT -', '')
                        .replace('CONT', ''),
                    }}
                  />
                </Text>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

MessagesTab.propTypes = {
  parentId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

MessagesTab.defaultProps = {
  type: 'workOrder',
};

export default MessagesTab;
