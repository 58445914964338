import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import 'react-rangeslider/lib/index.css';
import pageStyles from '../../style.module.css';
import RangeSlider from '../../../common/RangeSlider';

const Details = ({
  restorationVSRetail,
  residentialVSCommercial,
  setFieldValue,
  setTouched,
  setResidentialVSCommercial,
  isReadOnlyInput,
  blockHistory,
}) => {
  const { t } = useTranslation();
  return (
    <WrapperBlock
      title={t('companyProfile.services.details.title', 'Details')}
      tag="h2"
      isHasTooltip
      toolTipText={t(
        'companyProfile.services.details.ratio',
        'Ratio of services. The total of each percentage bar should always be 100%'
      )}
      isContractorsStyle
    >
      <div className={pageStyles.projectFormContent}>
        <RangeSlider
          objectName="services"
          restorationVSRetail={restorationVSRetail}
          residentialVSCommercial={residentialVSCommercial}
          setResidentialVSCommercial={setResidentialVSCommercial}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
          isReadOnly={isReadOnlyInput}
          blockHistory={blockHistory}
        />
      </div>
    </WrapperBlock>
  );
};
Details.propTypes = {
  restorationVSRetail: PropTypes.number.isRequired,
  residentialVSCommercial: PropTypes.number.isRequired,
  setResidentialVSCommercial: PropTypes.func.isRequired,
  blockHistory: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  isReadOnlyInput: PropTypes.bool,
};
Details.defaultProps = {
  isReadOnlyInput: false,
};

export default Details;
