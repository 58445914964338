import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AppStatusContext from 'context/AppStatusContext';
import Text from 'components/shared/Text';
import { applicationStatus } from 'enums';
import styles from './style.module.css';
import IconSVG from '../../IconSVG';
import arrayIcon from './arrayIcon.svg';

const MobileTabsComponent = ({
  tabList = [],
  currentTabIndex = 0,
  handleClick,
  isContractorsStyle,
  projectPgId,
  projectStage,
  isMyAccount,
}) => {
  const [isShowTabList, setIsShowTabList] = useState(false);
  const history = useHistory();
  const { appStatus } = useContext(AppStatusContext);
  const { t } = useTranslation();
  return (
    <Tabs className={styles.tabs} selectedIndex={currentTabIndex} onSelect={() => {}}>
      <div
        className={styles.headerMobileTabs}
        onClick={() => setIsShowTabList(!isShowTabList)}
      >
        <Text color="#000" weight="wb" size="s16">
          {tabList[currentTabIndex].title}
        </Text>
        <IconSVG
          src={arrayIcon}
          width="15px"
          height="15px"
          className={isShowTabList ? styles.iconUp : ''}
        />
      </div>
      <TabList
        className={[
          styles.tabList,
          isShowTabList ? styles.tabListVisible : styles.tabListHidden,
        ].join(' ')}
      >
        {tabList.map(({ title: tabTitle, isDisabled }, index) => {
          return (
            <Tab
              key={`${index}tab`}
              className={styles.tab}
              selectedClassName={styles.selectedTab}
              disabled={isDisabled}
              disabledClassName={styles.disabledTab}
              onClick={() => {
                if (isDisabled) return null;
                if (
                  tabTitle === 'Roof Project' &&
                  history.location.pathname.includes('roof-project') &&
                  projectPgId
                ) {
                  history.push(`/my-projects/roofs/${projectPgId}`);
                  handleClick(index);
                  return setIsShowTabList(false);
                }
                handleClick(index);
                return setIsShowTabList(false);
              }}
            >
              {tabTitle}
            </Tab>
          );
        })}
      </TabList>

      {appStatus === applicationStatus.approved || isMyAccount ? (
        <>
          {tabList.map(({ children }, index) => (
            <TabPanel
              key={`${index}tabPanel`}
              className={styles.tabPanel}
              selectedClassName={[
                styles.selectedTabPanel,
                isContractorsStyle ? styles.selectedTabPanelContractor : '',
              ].join(' ')}
            >
              {children}
            </TabPanel>
          ))}
        </>
      ) : (
        <div className={styles.textWrapper}>
          <Text color="#000000" size="s16" lineHeight="h20" weight="wr">
            {t(
              'notAvailablePage',
              'This page will be available only when application is approved'
            )}
          </Text>
        </div>
      )}
    </Tabs>
  );
};

MobileTabsComponent.propTypes = {
  tabList: PropTypes.array.isRequired,
  currentTabIndex: PropTypes.number,
  handleClick: PropTypes.func,
  isContractorsStyle: PropTypes.bool,
  projectPgId: PropTypes.string,
  projectStage: PropTypes.string,
  isMyAccount: PropTypes.bool,
};

MobileTabsComponent.defaultProps = {
  currentTabIndex: 0,
  handleClick: () => {},
  isContractorsStyle: false,
  projectPgId: '',
  projectStage: '',
  isMyAccount: false,
};

export default MobileTabsComponent;
