import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import EstimatedOfServicesSection from 'components/common/EstimatedOfServicesSection';

const EstimatesOfServices = ({
  isReadOnlyInput,
  services,
  residentialVSCommercial,
  ...formikProps
}) => {
  const { t } = useTranslation();
  return (
    <WrapperBlock
      title={t('myApplication.secondTab.estimate.title', 'Estimates % of Services')}
      tag="h2"
      isContractorsStyle
    >
      <EstimatedOfServicesSection
        estimateData={services}
        {...formikProps}
        isReadOnlyInput={isReadOnlyInput}
        type="services"
        residentialVSCommercial={residentialVSCommercial}
      />
    </WrapperBlock>
  );
};
EstimatesOfServices.propTypes = {
  services: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  residentialVSCommercial: PropTypes.number.isRequired,
  isReadOnlyInput: PropTypes.bool,
};
EstimatesOfServices.defaultProps = {
  isReadOnlyInput: false,
};

export default EstimatesOfServices;
