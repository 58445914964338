import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import Text from '../Text';

const TitlePage = ({ children }) => {
  return (
    <div>
      <Text color="#000" weight="wb" size="s19">
        {children}
      </Text>
      <div className={styles.titlePageLine} />
    </div>
  );
};

TitlePage.propTypes = {
  children: PropTypes.string.isRequired,
};

export default TitlePage;
