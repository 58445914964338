import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useLoadAttachments from 'services/hooks/loadAttachments';
import Text from 'components/shared/Text';
import Spinner from 'components/shared/Spinner';
import File from 'components/shared/LinkPDF/DownloadLink';
import styles from './style.module.css';

const DocContainer = ({ caseSfId }) => {
  const { files, loading, handleDownload, loadingDownload } = useLoadAttachments({
    contentDocumentId: caseSfId,
  });
  const { t } = useTranslation();
  return (
    <>
      {files?.length ? (
        <div className={styles.documentFormContainer}>
          {files?.map((file) => {
            return (
              <div className={styles.documentWrapper} key={file.contentDocumentId}>
                <File
                  fileName={`${file.name}.${file.contentType}`}
                  type={file.contentType}
                  loading={loadingDownload}
                  handleQueryClick={() => handleDownload(file.contentDocumentId)}
                >
                  {file.name}
                </File>
              </div>
            );
          })}
        </div>
      ) : null}
      {!files?.length && !loading ? (
        <Text
          color="#000"
          weight="wr"
          size="s16"
          tag="h2"
          lineHeight="h18"
          className={styles.title}
        >
          {t('warranty.details.documents.documents.emptyPage', ' No files yet')}
        </Text>
      ) : null}
      {loading ? <Spinner /> : null}
    </>
  );
};

DocContainer.propTypes = {
  caseSfId: PropTypes.string.isRequired,
};

export default DocContainer;
