import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './style.module.css';
import chevronIcon from './arrow_chevron_right.svg';
import Icon from '../IconSVG';
import Text from '../Text';

const SubHeader = ({ items, rightComponent }) => {
  return (
    <div className={styles.subHeaderWrapper}>
      <div className={styles.subHeaderTitles}>
        {items.map(({ title, link }, index) => {
          return (
            <div className={styles.subHeaderItem} key={index}>
              {index !== 0 && index <= items.length - 1 && (
                <Icon src={chevronIcon} height="14px" className={styles.chevronIcon} />
              )}
              {link ? (
                <div className={styles.subHeaderTitleStep}>
                  <Link to={link}>
                    <Text color="#837F7D" weight="wr" size="s16" tag="p">
                      {title}
                    </Text>
                  </Link>
                </div>
              ) : (
                <Text color="#000" weight="wb" size="s15" tag="p">
                  {title}
                </Text>
              )}
            </div>
          );
        })}
      </div>
      {rightComponent && (
        <div className={styles.subHeaderRightPart}>{rightComponent}</div>
      )}
    </div>
  );
};

SubHeader.propTypes = {
  items: PropTypes.array.isRequired,
  rightComponent: PropTypes.node,
};

SubHeader.defaultProps = {
  rightComponent: null,
};

export default SubHeader;
