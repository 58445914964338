import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    user @client {
      pgId
      sfId
      accountPgId
      accountSfId
      firstName
      lastName
      name
      contractorStatus
      contractorRole
      contactType
      phone
      mobilePhone
      email
      alternateEmail
      isFirstLogin
      language
      account {
        phone
        email
        pgId
        parentId
        name
        companyType
        billingCity
        companyName
        companyNameDBA
        billingLatitude
        billingLongitude
        billingPostalCode
        billingState
        billingCountry
        billingGeoCodeAccuracy
        billingStreet
        type
        loanNumber
        address
        suiteNumber
        gateCode
        website
        facebook
        linkedin
        twitter
        createdAt

        contacts {
          pgId
          sfId
          firstName
          lastName
          phone
          mobilePhone
          email
          alternateEmail
          contactPreferenceEmail
          contactPreferenceSms
          contactPreferencePhone
          language
        }
        application {
          contractorSfId
          contractorPgId
          accountPgId
          accountSfId
          sfId
          pgId
          status
        }
      }
    }
  }
`;
