import React, { useContext } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Pagination from 'components/shared/TablePagination/container';
import { parseQuery, stringifyQuery } from 'utils/parseQueryString';
import MobileContext from 'context/MobileContext';
import styles from './style.module.css';
import DesktopTable from './Desktop';
import MobileTable from './Mobile';

const Table = ({
  columns,
  data,
  hasRowClick,
  onRowClick,
  isSortDefault,
  tableFixed,
  sortIndex = 0,
  pagination,
  currentPageIndex = 0,
  mobileContent,
  isFinance,
  onLoadMore,
  ...props
}) => {
  const history = useHistory();
  const isMobile = useContext(MobileContext);
  const pageSize = 10;
  const activePageIndex =
    currentPageIndex > 0 && data.length / pageSize >= currentPageIndex
      ? currentPageIndex
      : 0;

  const initialState = isSortDefault
    ? {
        sortBy: [
          {
            id: columns[sortIndex].accessor,
            desc: true,
          },
        ],
      }
    : {};
  const handleChangePathname = (selectedPageIndex) => {
    const { search, pathname } = history.location;
    const query = parseQuery(search);
    history.push(
      [pathname, stringifyQuery({ ...query, page: selectedPageIndex + 1 })].join('')
    );
  };

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        ...initialState,
        pageSize,
        pageIndex: activePageIndex,
      },
    },
    useSortBy,
    usePagination
  );
  const tableRows = pagination ? page : rows;

  const handleChangePage = (selectedPageIndex) => {
    handleChangePathname(selectedPageIndex);
    gotoPage(selectedPageIndex);
  };

  return (
    <>
      {mobileContent && isMobile ? (
        <MobileTable
          {...props}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          tableRows={tableRows}
          prepareRow={prepareRow}
          tableFixed={tableFixed}
          hasRowClick={hasRowClick}
          onRowClick={onRowClick}
        >
          {(mobileTableProps) => mobileContent(mobileTableProps)}
        </MobileTable>
      ) : (
        <DesktopTable
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          tableRows={tableRows}
          prepareRow={prepareRow}
          tableFixed={tableFixed}
          hasRowClick={hasRowClick}
          onRowClick={onRowClick}
          isFinance={isFinance}
        />
      )}

      {pagination && (
        <div className={styles.paginationWrapper}>
          <Pagination
            gotoPage={handleChangePage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            itemsCount={rows.length}
            onLoadMore={onLoadMore}
          />
        </div>
      )}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  onRowClick: PropTypes.func,
  hasRowClick: PropTypes.bool,
  isSortDefault: PropTypes.bool,
  sortIndex: PropTypes.number,
  tableFixed: PropTypes.bool,
  pagination: PropTypes.bool,
  isFinance: PropTypes.bool,
  currentPageIndex: PropTypes.number,
  mobileContent: PropTypes.func,
  onLoadMore: PropTypes.func,
};

Table.defaultProps = {
  columns: [],
  data: [],
  onRowClick: () => false,
  isSortDefault: false,
  sortIndex: 0,
  tableFixed: false,
  hasRowClick: false,
  pagination: false,
  isFinance: false,
  currentPageIndex: 0,
  mobileContent: null,
  onLoadMore: () => {}
};

export default Table;
