import React, { useContext } from 'react';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import { CURRENT_JOB } from 'graphql/queries/opportunities';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Spinner from 'components/shared/Spinner';
import WarrantyJobDetailsPage from 'pages/WarrantyJobDetailsPage';
import AuthRoleContext from 'context/AuthRoleContext';
import { roles } from 'enums';

const WarrantyJobDetailsPageContainer = (props) => {
  const { match } = props;
  const userCurrentRole = useContext(AuthRoleContext);
  const { loading, data } = useQuery(CURRENT_JOB(userCurrentRole !== roles.crew), {
    variables: {
      contentDocumentId: match.params.id,
    },
  });

  if (loading)
    return (
      <LayoutWithSidebar>
        <Spinner />
      </LayoutWithSidebar>
    );

  return (
    <WarrantyJobDetailsPage
      {...props}
      job={data.currentJob}
      files={data?.files || []}
      members={data?.members || []}
      userCurrentRole={userCurrentRole || ''}
    />
  );
};

WarrantyJobDetailsPageContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

WarrantyJobDetailsPageContainer.defaultProps = {};

export default WarrantyJobDetailsPageContainer;
