import { monthLocal } from 'localization';
import { useDetLang } from 'services/localization';

const now = new Date().getUTCFullYear();
export const years = Array(now - (now - 20))
  .fill('')
  .map((v, idx) => now - idx)
  .map((el) => {
    return { value: el, label: el };
  });

export const useYearsForIncomes = () => {
  const decLocal = useDetLang(monthLocal[11], 'Dec');
  const janLocal = useDetLang(monthLocal[0], 'Jan');
  return Array(now - (now - 5))
    .fill('')
    .map((v, idx) => now - idx)
    .map((el) => {
      return {
        value: `Jan ${el} - Dec ${el}`,
        label: `${janLocal} ${el} - ${decLocal} ${el}`,
      };
    });
};
