import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import Text from '../shared/Text';
import Icon from "../shared/IconSVG";
import {appStoreIcon, playStoreIcon} from "../../assets/icons";

const CompactFooter = () => {
  const { t } = useTranslation();
  const googlePlayUrl = process.env.REACT_APP_GOOGLE_PLAY_APP_URL;
  const appStoreUrl = process.env.REACT_APP_APP_STORE_URL;
  return (
    <footer className={styles.footerWrapper}>
      {(appStoreUrl || googlePlayUrl) && (
          <div className={styles.storeButtonContainer}>
            <Text tag="h2" size="s14" weight="wb" lineHeight="h16" color="#FFFFFF">
              {t('footer.download', 'Download our app!')}
            </Text>
            <div className={styles.buttonsGrid}>
              {appStoreUrl && (
                  <a
                      className={styles.appStoreLink}
                      href={appStoreUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                  >
                    <Icon
                        src={appStoreIcon}
                        height="40px"
                        width="135px"
                        className={styles.appStoreIcon}
                    />
                  </a>
              )}

              {googlePlayUrl && (
                  <a
                      className={styles.appStoreLink}
                      href={googlePlayUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                  >
                    <Icon
                        src={playStoreIcon}
                        height="40px"
                        width="135px"
                        className={styles.playStoreIcon}
                    />
                  </a>
              )}
            </div>
          </div>
      )}
      <div>
        <Text
          tag="p"
          size="s14"
          weight="wr"
          lineHeight="h17"
          color="#FFFFFF"
          className={styles.bottomText}
        >
          {t(
            'welcomePage.footer.terms',
            '© Copyright 2013 - 2020 RoofClaim.com. All Rights Reserved.'
          )}
          <a
            className={styles.privacyLink}
            href="https://www.roofclaim.com/privacy-policy/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <span> </span>
            Privacy Policy.
          </a>
          <a
            className={styles.privacyLink}
            href="https://www.roofclaim.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <span> </span>
            Site Map.
          </a>
          <a
            className={styles.privacyLink}
            href="https://www.roofclaim.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <span> </span>
            Contact Us.
          </a>
        </Text>
      </div>
    </footer>
  );
};

export default CompactFooter;
