import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/dateFormatters';
import LineText from 'components/common/LineText';
import Text from 'components/shared/Text';
import MobileContext from 'context/MobileContext';
import TimePicker from 'components/shared/Datepicker/TimePicker';
import Button from 'components/shared/Button';
import Datepicker from 'components/shared/Datepicker';
import { isCompletedWarrantyCase } from 'utils/isCompletedWarrantyCase';
import { useCaseSubjectLocal, useCaseTypeLocal } from 'localization';
import { useDetLang } from 'services/localization';
import styles from '../style.module.css';

const DescriptionSection = ({ warrantyCase, handleUpdateTime }) => {
  const [repairDate, setRepairDate] = useState(formatDate(warrantyCase.repairDateTime));
  const [repairTime, setRepairTime] = useState(warrantyCase.repairDateTime);

  const createRepairDateTime = () => {
    const date = new Date(repairDate);
    const time = new Date(repairTime);
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    return date;
  };
  const isMobile = useContext(MobileContext);

  const { t } = useTranslation();
  const caseSubjectLocal = useCaseSubjectLocal();
  const caseTypeLocal = useCaseTypeLocal();
  const buttonWidth = useDetLang('small', 'smallest');
  return (
    <div className={styles.wrapper}>
      <div>
        <LineText
          name={t('warranty.details.description.subject', 'Subject')}
          value={caseSubjectLocal[warrantyCase.subject]}
          isFixed
        />
        <LineText
          name={t('warranty.details.description.date', 'Date Created')}
          value={formatDate(warrantyCase.createdAt)}
          isFixed
        />
        <LineText
          name={t('warranty.details.description.type', 'Type')}
          value={caseTypeLocal[warrantyCase.type]}
          isFixed
        />
        <LineText
          name={t('warranty.details.description.details', 'Details')}
          value={warrantyCase.description}
          isFixed
        />
        <div
          className={[styles.rowContainer, styles.rowContainerPickerWrapper].join(' ')}
        >
          <div>
            <Text color={isMobile ? '#000000' : '#686868'} size="s14" lineHeight="h20">
              {t('warranty.details.description.time', 'Appointment Time')}
            </Text>
          </div>
          <div className={styles.rowContainerPicker}>
            <div>
              <Datepicker
                isWarranty
                value={repairDate}
                name="repairDate"
                handleChange={(date) => {
                  setRepairDate(date);
                }}
                isDisabled={isCompletedWarrantyCase(warrantyCase.status)}
              />
            </div>
            <div className={styles.centerText}>
              <Text color="#686868" size="s14" weight="wm">
                {t('warranty.details.description.at', 'at')}
              </Text>
            </div>

            <div>
              <TimePicker
                value={repairTime ? new Date(repairTime) : null}
                name="repairTime"
                handleChange={(time) => {
                  setRepairTime(time);
                }}
                isDisabled={isCompletedWarrantyCase(warrantyCase.status)}
              />
            </div>
            {!isCompletedWarrantyCase(warrantyCase.status) && (
              <Button
                size={buttonWidth}
                handleClick={() => {
                  handleUpdateTime(warrantyCase.sfId, createRepairDateTime());
                }}
                color="primary"
                textSize="small"
              >
                {t('warranty.details.description.saveButton', 'Save Date')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DescriptionSection.propTypes = {
  warrantyCase: PropTypes.object.isRequired,
  handleUpdateTime: PropTypes.func.isRequired,
};
export default DescriptionSection;
