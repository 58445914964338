import React, { useEffect, useCallback, useContext } from 'react';
import queryString from 'query-string';
import AppConfigContext from 'context/AppConfigContext';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import {
  CONFIRM_EMAIL_FROM_NEW_EMAIL_MUTATION,
  CONFIRM_EMAIL_FROM_OLD_EMAIL_MUTATION,
} from 'graphql/mutations/auth';
import MobileSignedOutHeader from 'layouts/Mobile/MobileSignedOutHeader';
import auth from 'utils/auth';
import api from 'services/api';
import Spinner from 'components/shared/Spinner';
import Popup from 'components/Auth/FormWrapper';
import Text from 'components/shared/Text';
import styles from './styles.module.css';

const ConfirmEmailPage = () => {
  const config = useContext(AppConfigContext);
  const history = useHistory();
  const [confirmEmailFromNewEmail] = useMutation(CONFIRM_EMAIL_FROM_NEW_EMAIL_MUTATION);
  const [confirmEmailFromOldEmail] = useMutation(CONFIRM_EMAIL_FROM_OLD_EMAIL_MUTATION);
  const { activationToken, newEmail, oldEmail } = queryString.parse(
    history.location.search
  );

  const handleSubmitFromNewEmail = useCallback(async () => {
    try {
      await confirmEmailFromNewEmail({
        variables: {
          activationToken,
        },
        update: (
          cache,
          {
            data: {
              confirmEmailFromNewEmail: { token },
            },
          }
        ) => {
          auth.setToken(token);
          api.setAuthTokenToHeader(token);
          window.location.replace('/');
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [activationToken, confirmEmailFromNewEmail]);

  const handleSubmitFromOldEmail = useCallback(async () => {
    try {
      await confirmEmailFromOldEmail({
        variables: {
          newEmail,
          oldEmail,
        },
        update: (
          cache,
          {
            data: {
              confirmEmailFromOldEmail: { token },
            },
          }
        ) => {
          auth.setToken(token);
          api.setAuthTokenToHeader(token);
          window.location.replace('/');
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [newEmail, oldEmail, confirmEmailFromOldEmail]);

  useEffect(() => {
    if (activationToken) {
      handleSubmitFromNewEmail();
    }
    if (newEmail && oldEmail) {
      handleSubmitFromOldEmail();
    }
  }, [
    activationToken,
    newEmail,
    oldEmail,
    handleSubmitFromNewEmail,
    handleSubmitFromOldEmail,
  ]);

  return (
    <MobileSignedOutHeader>
      <div className={styles.contentPage}
        style={{ backgroundImage: `url(${config?.signInImageUrl})` }}
      >
        <Popup>
          <Text
            color="#000000"
            size="s17"
            lineHeight="h20"
            tag="h2"
            weight="wr"
            className={styles.emailConfirmText}
          >
            Please, wait a few seconds, you will be redirected to the home page with the
            new email address
          </Text>
          <Spinner color="white" />
        </Popup>
      </div>
    </MobileSignedOutHeader>
  );
};

export default ConfirmEmailPage;
