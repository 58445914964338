import { useDetLang } from 'services/localization';
import { useJobStatusLocal, useCaseStatusLocal } from 'localization';
import { formatDate } from 'utils/dateFormatters';

export const useNotifLocal = (
  text,
  jobReference,
  jobStatus,
  crewNames,
  dateOfInstall
) => {
  const claimed = `El estado de su trabajo reclamado ${jobReference} se ha actualizado a ${
    useJobStatusLocal()[jobStatus]
  }.`;

  const notClaimed = `
  Lo sentimos, el trabajo ${jobReference} no le ha sido adjudicado.`;

  const notForget = `
No olvide actualizar el estado del trabajo ${jobReference} a completado o transferido.`;

  const planks = `
  Sube las fotos de TABLAS, MATERIALES que se han entregado para el trabajo ${jobReference}.`;

  const checkIn = `
  No olvide registrarse esta mañana para el trabajo ${jobReference}.`;

  const notifForAdmin = `
El trabajo ${jobReference} se ha asignado a: ${crewNames}. La fecha de instalación ${
    formatDate(dateOfInstall) || 'aún no está establecida'
  }. `;

  const notifForCrew = `
¡Felicitaciones! Se le ha asignado el trabajo ${jobReference} La fecha de instalación  ${
    formatDate(dateOfInstall) || 'aún no está establecida'
  }. `;

  const scheduled = `
  El estado de su trabajo programado ${jobReference} se ha actualizado a ${
    useJobStatusLocal()[jobStatus]
  }.`;

  const caseUpdated = `
El estado del caso de garantía para JOB ${jobReference} se ha actualizado a ${
    useCaseStatusLocal()[jobStatus]
  }.`;

  const caseOpened = `Tenga en cuenta que se ha ABIERTO un nuevo caso de garantía para el TRABAJO ${jobReference} .`;

  const jobOpened = `Tenga en cuenta que se ha ABIERTO un nuevo caso de garantía para el TRABAJO ${jobReference} .`;

  const claimedChange = useDetLang(claimed, text);
  const claimedChangeReject = useDetLang(notClaimed, text);
  const updateStatus = useDetLang(notForget, text);
  const uploadPhoto = useDetLang(planks, text);
  const checkInJob = useDetLang(checkIn, text);
  const jobAssignedAdmin = useDetLang(notifForAdmin, text);
  const jobAssignedCrew = useDetLang(notifForCrew, text);
  const scheduledChange = useDetLang(scheduled, text);
  const warrantyUpdated = useDetLang(caseUpdated, text);
  const warrantyOpened = useDetLang(caseOpened, text);
  const newJob = useDetLang(jobOpened, text);

  if (text.includes('Your claimed')) return claimedChange;
  if (text.includes('Sorry')) return claimedChangeReject;
  if (text.includes('rollover')) return updateStatus;
  if (text.includes('PLANKS')) return uploadPhoto;
  if (text.includes('check-in')) return checkInJob;
  if (text.includes('Install')) return jobAssignedAdmin;
  if (text.includes('Congratulations')) return jobAssignedCrew;
  if (text.includes('scheduled')) return scheduledChange;
  if (text.includes('The Warranty')) return warrantyUpdated;
  if (text.includes('note')) return warrantyOpened;
  if (text.includes('claiming')) return newJob;

  return '';
};
