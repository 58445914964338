import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import notificationStatuses from 'enums/notificationStatuses';
import TitlePage from '../../TitlePage';
import styles from './style.module.css';
import Text from '../../Text';
import { useNotifLocal } from './notificationTextLocal';

const NotificationCard = ({
  createdAt,
  text,
  status,
  jobReference,
  jobStatus,
  crewNames,
  dateOfInstall,
}) => {
  const titlePropsEnum = {
    [notificationStatuses.notRead]: {
      weight: 'wb',
    },
    [notificationStatuses.read]: {},
  };

  return (
    <div className={styles.cardWrapper}>
      <Text {...titlePropsEnum[status]} color="#000000" lineHeight="h26" size="s14">
        {moment(createdAt).format('MM/DD/YYYY - HH:mmA')}
      </Text>
      <Text color="#00000D" lineHeight="h18" size="s14">
        {useNotifLocal(text, jobReference, jobStatus, crewNames, dateOfInstall)}
      </Text>
    </div>
  );
};

const NotificationsModal = ({ notifications, isShow }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: `${isShow ? 'block' : 'none'}` }}>
      <div className={[styles.wrapper, styles['slide-bottom']].join(' ')}>
        <TitlePage>{t('notifications.title', 'Notifications')}</TitlePage>
        <div className={styles.notificationsContainer}>
          {notifications.length ? (
            notifications.map(
              ({
                id,
                createdAt,
                text,
                status,
                jobReference,
                jobStatus,
                crewNames,
                dateOfInstall,
              }) => (
                <NotificationCard
                  key={id}
                  createdAt={createdAt}
                  text={text}
                  status={status}
                  jobReference={jobReference}
                  jobStatus={jobStatus}
                  crewNames={crewNames}
                  dateOfInstall={dateOfInstall}
                />
              )
            )
          ) : (
            <>
              <br />
              <Text size="s16" color="#000000">
                {t('notifications.emptyPage', 'No notifications yet')}
              </Text>
            </>
          )}
        </div>
      </div>
      <span className={[styles.triangle, styles['slide-bottom']].join(' ')} />
    </div>
  );
};

export default NotificationsModal;

NotificationsModal.propTypes = {
  isShow: PropTypes.bool,
  notifications: PropTypes.array,
};

NotificationsModal.defaultProps = {
  isShow: false,
  notifications: [],
};

NotificationCard.propTypes = {
  createdAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  text: PropTypes.string,
  jobReference: PropTypes.string,
  jobStatus: PropTypes.string,
  crewNames: PropTypes.string,
  dateOfInstall: PropTypes.string,
};

NotificationCard.defaultProps = {
  text: '',
  jobReference: '',
  jobStatus: '',
  crewNames: '',
  dateOfInstall: '',
};
