import React from 'react';
import { useTranslation } from 'react-i18next';
import { useJobStatusLocal } from 'localization';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { formatDate } from 'utils/dateFormatters';
import colorStatus from 'utils/statusColor';
import Button from 'components/shared/Button';
import { isHideButton } from 'utils/jobs/isHideButton';
import styles from '../../tableStyles.module.css';
import pageStyles from '../../style.module.css';

const AwardedTableCardMobile = ({
  location,
  reference,
  dateOfInstall,
  status,
  isSelected,
  isDisabled,
  handleClick,
  handleClaim,
}) => {
  const { t } = useTranslation();
  const jobStatusLocal = useJobStatusLocal();
  return (
    <>
      <div
        className={[
          styles.projectCardWrapper,
          isSelected ? styles.projectCardWrapperSelected : '',
        ].join(' ')}
        onClick={handleClick}
      >
        <div>
          <div className={styles.projectCardHeader}>
            <Text color="#000" size="s16" weight="wb">
              {reference}
            </Text>
            <div className={styles.statusWrapper}>
              <span
                className={[
                  pageStyles.circle,
                  pageStyles[`circle${colorStatus(status)}`],
                ].join(' ')}
              />
              <Text color="#000" size="s14" weight={isSelected ? 'wb' : 'wm'}>
                {jobStatusLocal[status]}
              </Text>
            </div>
          </div>

          <Text color="#000">
            {t('jobs.awarded.mapCard.mobile.date', 'Date of install:')}{' '}
            {dateOfInstall ? formatDate(dateOfInstall) : 'Not set yet'}
          </Text>
          <div className={styles.mobileTableRowContent}>
            <div className={styles.textOverflow}>
              <Text color="#767676">
                {' '}
                {t('jobs.awarded.mapCard.mobile.location', 'Location:')} {location}
              </Text>
            </div>
          </div>
        </div>
      </div>
      {!isHideButton(status) && (
        <div className={styles.projectCardFooter}>
          <Button
            height="small"
            textSize="small"
            color="secondary"
            disabled={isDisabled}
            handleClick={handleClaim}
          >
            {t('jobs.awarded.mapCard.mobile.reschedule', 'Reschedule')}
          </Button>
        </div>
      )}
    </>
  );
};

AwardedTableCardMobile.propTypes = {
  location: PropTypes.string,
  reference: PropTypes.string.isRequired,
  dateOfInstall: PropTypes.string,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  status: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  handleClaim: PropTypes.func,
};

AwardedTableCardMobile.defaultProps = {
  dateOfInstall: '',
  isSelected: false,
  isDisabled: false,
  location: '',
  handleClick: () => {},
  handleClaim: () => {},
};

export default AwardedTableCardMobile;
