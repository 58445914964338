import gql from 'graphql-tag';
import { Application } from 'graphql/fragments/application.fragment';
import { Qualification } from 'graphql/fragments/qualification.fragment';

export const MY_APPLICATION = gql`
  query myApplication {
    application {
      ...FullApplic
    }
    qualification {
      ...FullQualification
    }
  }
  ${Application.fragments.FullApplic}
  ${Qualification.fragments.FullQualification}
`;
