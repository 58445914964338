import React from 'react';
import PropTypes from 'prop-types';
import FileIcon from 'assets/icons/file.svg';
import DownloadIcon from 'assets/icons/download.svg';
import Icon from 'components/shared/IconSVG';
import truncate from 'lodash/truncate';
import filesPrefix from 'enums/filesPrefix';
import { GET_CURRENT_USER } from 'graphql/cache';
import { useQuery } from '@apollo/react-hooks';
import Text from '../Text';
import styles from './style.module.css';

const DownloadLink = ({
  href,
  fileName,
  children,
  handleQueryClick,
  loading,
  nextContentDocumentId,
  prevContentDocumentId,
  type = 'pdf',
}) => {
  const { data } = useQuery(GET_CURRENT_USER);
  const { firstName, lastName } = data?.user;
  const handleClick = () => {
    handleQueryClick();

    const sameId = nextContentDocumentId === prevContentDocumentId;

    if (!href || !sameId) return;
    const link = document.createElement('a');
    link.setAttribute('href', href);
    link.setAttribute('download', fileName);
    link.className = styles.downloadLink;
    link.click();
  };
  const className = loading ? styles.loadingStyle : null;
  return (
    <div
      className={[styles.fileWrapper, styles.fileWrapperRow, className].join(' ')}
      onClick={handleClick}
    >
      <div className={styles.fileWrapperContent}>
        <div className={styles.linkWrapper}>
          <Icon src={FileIcon} height="24px" width="20px" className={styles.fileIcon} />
        </div>
        <div className={styles.linkContent}>
          <Text color="#000" weight="wb" size="s13" tag="p">
            {truncate(
              children
                .replace(`${filesPrefix.full}`, '')
                .replace(`${filesPrefix.before}`, '')
                .replace(`${filesPrefix.after}`, '')
                .replace(`${filesPrefix.abr}`, '')
                .replace(`${filesPrefix.cont}`, '')
                .replace(`${firstName}${lastName}-`, ''),
              { length: 22 }
            )}
          </Text>
          <span className={styles.greyText}>{type?.toUpperCase()}</span>
        </div>
      </div>

      <div>
        <Icon src={DownloadIcon} height="15px" width="15px" />
      </div>
    </div>
  );
};

DownloadLink.propTypes = {
  href: PropTypes.string,
  fileName: PropTypes.string,
  handleQueryClick: PropTypes.func,
  children: PropTypes.string.isRequired,
  nextContentDocumentId: PropTypes.string,
  prevContentDocumentId: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
};

DownloadLink.defaultProps = {
  fileName: '',
  loading: false,
  href: '',
  nextContentDocumentId: '',
  prevContentDocumentId: '',
  type: 'pdf',
  handleQueryClick: () => {},
};

export default DownloadLink;
