import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Textarea from 'components/shared/TextArea';
import WrapperBlock from 'components/shared/WrapperBlock';
import Button from 'components/shared/Button';
import { POST_MESSAGE } from 'graphql/mutations/message';
import { GET_CURRENT_USER } from 'graphql/cache';
import { useQuery, useMutation } from '@apollo/react-hooks';
import useModal from 'services/hooks/useIsShowComponent';
import { FormColumn } from 'components/shared/Form';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import Spinner from 'components/shared/Spinner';
import AuthRoleContext from 'context/AuthRoleContext';
import LeaveNoteButton from '../LeaveNoteButton';
import styles from '../style.module.css';

const MessageSection = ({ jobId }) => {
  const userRole = useContext(AuthRoleContext);
  const {
    data: { user = {} },
  } = useQuery(GET_CURRENT_USER);
  const { name, email, mobilePhone } = user;

  const [message, setMessage] = useState('');
  const [isShowTextField, setIsShowTextField] = useState(false);
  const { isShow: isOpenModal, handleClick } = useModal();

  const [postMessage, { loading }] = useMutation(POST_MESSAGE, {
    onCompleted: () => handleClick(),
  });
  const handleCloseModal = () => {
    handleClick();
  };
  const handleSubmit = () => {
    setMessage('');
    setIsShowTextField(false);

    postMessage({
      variables: {
        parentId: jobId,
        message: `CONT- ${message} 
- ${name}, ${userRole}, ${email}, ${mobilePhone}`,
      },
    });
  };

  const { t } = useTranslation();
  return (
    <WrapperBlock
      title={t('jobs.awarded.details.activity.message.title', 'Messages')}
      classNameMainWrapper={styles.wrapper}
      rightComponent={
        <LeaveNoteButton
          handleClick={() => {
            if (isShowTextField) setMessage('');
            setIsShowTextField(!isShowTextField);
          }}
        />
      }
    >
      <AcknowledgmentModal
        title={t('jobs.awarded.messageSendModal.title', 'Message Sent')}
        description={t(
          'jobs.awarded.messageSendModal.description',
          'Thank you, your message has been sent. One of our staff will be getting back to you shortly.'
        )}
        handleClick={handleCloseModal}
        handleClose={handleCloseModal}
        isShow={isOpenModal}
        buttonText={t('jobs.awarded.messageSendModal.ok', 'OK')}
      />
      <div className={styles.sectionContent}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {isShowTextField && (
              <>
                <FormColumn>
                  <Textarea
                    placeholder={t('notePlaceholder', 'Leave a note...')}
                    value={message}
                    handleChange={(e) => setMessage(e.target.value)}
                    name="comment"
                    maxLength={255}
                  />
                </FormColumn>

                <div className={styles.footerSection}>
                  <Button
                    size="medium"
                    handleClick={handleSubmit}
                    className={styles.buttonMobile}
                  >
                    {t(
                      'jobs.awarded.details.activity.message.sendButton',
                      'Send message'
                    )}
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </WrapperBlock>
  );
};

MessageSection.propTypes = {
  jobId: PropTypes.string.isRequired,
};

MessageSection.defaultProps = {};

export default MessageSection;
