import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormColumn as Col, FormRow as Row } from 'components/shared/Form';
import Slider from 'react-rangeslider';
import Text from 'components/shared/Text';
import 'react-rangeslider/lib/index.css';
import debounce from 'lodash/debounce';
import styles from './styles.module.css';

const RangeSlider = ({
  objectName,
  restorationVSRetail,
  residentialVSCommercial,
  setResidentialVSCommercial,
  setFieldValue,
  setTouched,
  isDisabled,
  isReadOnly,
  blockHistory,
}) => {
  const [restorationVSRetailValue, setRestorationVSRetailValue] = useState(
    restorationVSRetail
  );

  // Form value changing needs to be debounced because of significantly performance degradation
  const debounceFormChange = (func) => debounce(func, 200);

  const _handleChangeResidentialVSCommercialValue = useCallback(
    debounceFormChange((value) => {
      setFieldValue(`${objectName}.residentialVSCommercial`, value);
      setTouched({}, false);
      blockHistory();
    }),
    []
  );

  const _handleChangeRestorationVSRetailValue = useCallback(
    debounceFormChange((value) => {
      setFieldValue(`${objectName}.restorationVSRetail`, value);
      setTouched({}, false);
      blockHistory();
    }),
    []
  );

  const handleChangeRestorationVSRetailValue = (value) => {
    if (value !== restorationVSRetailValue) {
      setRestorationVSRetailValue(value);
      _handleChangeRestorationVSRetailValue(value);
    }
  };

  const handleChangeResidentialVSCommercialValue = (value) => {
    if (value !== residentialVSCommercial) {
      setResidentialVSCommercial(value);
      _handleChangeResidentialVSCommercialValue(value);
    }
  };
  const { t } = useTranslation();
  return (
    <Row>
      <Col>
        <Text
          weight="wm"
          tag="p"
          size="s14"
          color="#00000D"
          lineHeight="l20"
          className={styles.title}
        >
          {t('firstSlider.p_1', 'Roof Revenue (%)')}
        </Text>
        <div
          className={[
            styles.sliderWrapper,
            isDisabled ? styles.sliderDisabledWrapper : '',
            isReadOnly ? styles.sliderReadOnlyWrapper : '',
          ].join(' ')}
        >
          <Slider
            step={10}
            tooltip={false}
            value={restorationVSRetail}
            onChange={handleChangeRestorationVSRetailValue}
          />
        </div>
        <div className={styles.labelsWrapper}>
          <div className={`${styles.label} ${styles.labelLeft}`}>
            <span className={styles.marker} />
            <Text size="s14" color="#837F7D" lineHeight="l16">
              {t('firstSlider.p_2', 'Restoration:')}
            </Text>
            <Text
              size="s14"
              color="#353535"
              lineHeight="l16"
              weight="wb"
              className={styles.labelCount}
            >
              {restorationVSRetailValue}%
            </Text>
          </div>
          <div className={`${styles.label} ${styles.labelRight}`}>
            <span className={styles.marker} />
            <Text size="s14" color="#837F7D" lineHeight="l16">
              {t('firstSlider.p_3', 'Retail:')}
            </Text>
            <Text
              size="s14"
              color="#353535"
              lineHeight="l16"
              weight="wb"
              className={styles.labelCount}
            >
              {100 - restorationVSRetailValue}%
            </Text>
          </div>
        </div>
      </Col>

      <Col className={styles.lastBlock}>
        <Text
          weight="wm"
          tag="p"
          size="s14"
          color="#00000D"
          lineHeight="l20"
          className={styles.title}
        >
          {t('secondSlider.p_1', 'Property Type (%)')}
        </Text>
        <div
          className={[
            styles.sliderWrapper,
            isDisabled ? styles.sliderDisabledWrapper : '',
            isReadOnly ? styles.sliderReadOnlyWrapper : '',
          ].join(' ')}
        >
          <Slider
            step={10}
            tooltip={false}
            value={residentialVSCommercial}
            onChange={handleChangeResidentialVSCommercialValue}
          />
        </div>
        <div className={styles.labelsWrapper}>
          <div className={`${styles.label} ${styles.labelLeft}`}>
            <span className={styles.marker} />
            <Text size="s14" color="#837F7D" lineHeight="l16">
              {t('secondSlider.p_2', 'Residential:')}
            </Text>
            <Text
              size="s14"
              color="#353535"
              lineHeight="l16"
              weight="wb"
              className={styles.labelCount}
            >
              {residentialVSCommercial}%
            </Text>
          </div>
          <div className={`${styles.label} ${styles.labelRight}`}>
            <span className={styles.marker} />
            <Text size="s14" color="#837F7D" lineHeight="l16">
              {t('secondSlider.p_3', 'Commercial:')}
            </Text>
            <Text
              size="s14"
              color="#353535"
              lineHeight="l16"
              weight="wb"
              className={styles.labelCount}
            >
              {100 - residentialVSCommercial}%
            </Text>
          </div>
        </div>
      </Col>
    </Row>
  );
};
RangeSlider.propTypes = {
  objectName: PropTypes.string,
  restorationVSRetail: PropTypes.number.isRequired,
  residentialVSCommercial: PropTypes.number.isRequired,
  setResidentialVSCommercial: PropTypes.func.isRequired,
  blockHistory: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};
RangeSlider.defaultProps = {
  objectName: 'services',
  isDisabled: false,
  isReadOnly: false,
};

export default RangeSlider;
