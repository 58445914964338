import React from 'react';
import PropTypes from 'prop-types';
import googleIcon from 'assets/icons/google.png';
import microsoftIcon from 'assets/icons/microsoft.png';
import styles from './style.module.css';
import IconSVG from '../shared/IconSVG';

const icons = {
  google: googleIcon,
  microsoft: microsoftIcon,
};

const SignUpButton = ({ icon, children, handleClick, className, ...props }) => {
  const classNames = [styles.button, className].join(' ');

  return (
    <button {...props} onClick={handleClick} type="button" className={classNames}>
      <div className={styles.arrange}>
        <IconSVG
          src={icons[icon]}
          width="20px"
          height="20px"
          className={styles.mediaIcon}
        />
        {children}
      </div>
    </button>
  );
};

SignUpButton.propTypes = {
  handleClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  icon: PropTypes.string,
};

SignUpButton.defaultProps = {
  children: '',
  className: '',
  icon: 'google',
  handleClick: () => {},
};

export default SignUpButton;
