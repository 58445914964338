import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Tooltip from 'components/common/Tooltip';
import styles from './styles.module.css';

const SectionHeader = ({
  text,
  color,
  fontSize,
  lineSize,
  weight,
  className,
  isHasTooltip,
  toolTipText,
}) => {
  return (
    <div className={className}>
      <div className={styles.headerWrapper}>
        <Text
          tag="h2"
          color={color}
          className={styles.header}
          weight={weight}
          size={fontSize}
          lineHeight={lineSize}
        >
          {text}
        </Text>
        {isHasTooltip && <Tooltip text={toolTipText} />}
      </div>

      <div className={styles.spreadLine} />
    </div>
  );
};

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  lineSize: PropTypes.string,
  weight: PropTypes.string,
  className: PropTypes.string,
  isHasTooltip: PropTypes.bool,
  toolTipText: PropTypes.string,
};

SectionHeader.defaultProps = {
  color: '#000000',
  fontSize: 's24',
  lineSize: 'h28',
  weight: 'wb',
  className: '',
  isHasTooltip: false,
  toolTipText: '',
};

export default SectionHeader;
