import { Opportunity } from 'graphql/fragments';

const job = (launch, { id: jobId }, { cache, getCacheKey }) => {
  const id = getCacheKey({ __typename: 'Job', sfId: jobId });
  const fragment = Opportunity.fragments.jobEntry;
  const data = cache.readFragment({ fragment, id });
  return data;
};

export default { resolvers: {}, Query: { job } };
