import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import WrapperBlock from 'components/shared/WrapperBlock';
import { FormColumn as Col, FormRow as Row } from 'components/shared/Form';
import Text from 'components/shared/Text';
import { GET_LAT_LNG_FROM_ADDRESS } from 'graphql/queries/maps';
import {
  GET_CITIES_BY_COUNTY,
  GET_COUNTIES_BY_STATE,
  GET_STATES,
  GET_CITIES_BOUNDARIES,
  GET_COUNTIES_BOUNDARIES,
  GET_STATES_BOUNDARIES,
} from 'graphql/queries/geoUnits';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { FixedSizeList } from 'react-window';
import { components } from 'react-select';
import MobileContext from 'context/MobileContext';
import { states } from 'enums';
import { isDisabled } from 'utils/application/checkIsDisabled';
import isEqual from 'lodash/isEqual';
import Spinner from '../../shared/Spinner';
import Field from '../../shared/Field';
import PolygonMap from '../../GoogleMap/PolygonMap';
import styles from './style.module.css';
import pageStyles from '../style.module.css';
import * as Constants from './constants';
import Button from '../../shared/Button';

const VirtualizedList = ({ children }) => {
  let baseHeight = 300;
  const baseItemHeight = 40;

  if (children?.length > 0) {
    if (children.length < baseHeight / baseItemHeight)
      baseHeight = baseItemHeight * children.length;
  } else baseHeight = baseItemHeight;
  return (
    <FixedSizeList
      height={baseHeight}
      itemCount={children.length}
      itemSize={baseItemHeight}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  );
};

const VirtualizedOption = (props) => {
  /* eslint-disable */
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;
  return <components.Option {...props}>{props.children}</components.Option>;
  /* eslint-enable */
};

const virtualizedSelect = {
  MenuList: VirtualizedList,
  Option: VirtualizedOption,
};

const DefinedWorkAreas = ({
  className,
  appStatus,
  isReadOnlyInput,
  units,
  setUnits,
  catchmentUnits,
  setCatchmentUnits,
  blockHistory,
}) => {
  const isMobile = useContext(MobileContext);

  const [isMapVisible, setIsMapVisible] = useState(!isMobile);
  const [coords, setCoords] = useState(Constants.USA_MAP_CENTER);
  const [zoom, setZoom] = useState(Constants.ZOOM_FOR_COUNTRY);

  const handleMapViewButton = () => {
    if (isMapVisible === false) {
      setIsMapVisible(!isMapVisible);
    }
  };

  const findStateByShortname = (shortname) =>
    states.find((state) => state.value === shortname)?.label ?? '';

  const formatCountyLabel = (county) => {
    if (county?.name && county?.state)
      return `${county?.name}, ${findStateByShortname(county?.state)}`;
    return '';
  };

  const formatCityLabel = (city) => {
    if (city?.name && city?.county && city?.state)
      return `${city?.name}, ${city?.county}, ${findStateByShortname(city?.state)}`;
    return '';
  };

  // Administrative units for displaying on map
  const catchmentUnitsForMap = useMemo(() => {
    let statesForMap = catchmentUnits.states;
    let countiesForMap = catchmentUnits.counties;

    if (catchmentUnits.cities.length) {
      const countiesToExclude = catchmentUnits.cities.map((city) => ({
        county: city?.county,
        state: city?.state,
      }));
      countiesForMap = catchmentUnits.counties.filter(
        (c) => !countiesToExclude.find((x) => x.county === c.name && x.state === c.state)
      );
    }
    if (catchmentUnits.counties.length) {
      const statesToExclude = catchmentUnits.counties.map((county) => county?.state);
      statesForMap = catchmentUnits.states.filter(
        (s) => !statesToExclude.includes(s.shortname)
      );
    }

    return {
      ...catchmentUnits,
      states: statesForMap,
      counties: countiesForMap,
    };
  }, [catchmentUnits]);

  const { loading, data: statesData, error } = useQuery(GET_STATES);

  useEffect(() => {
    if (!loading && !error) setUnits({ type: 'states', payload: statesData?.getStates });
  }, [loading, units.states, error]);

  const [
    getCountiesByState,
    { data: countiesData, loading: countiesLoading },
  ] = useLazyQuery(GET_COUNTIES_BY_STATE, {
    fetchPolicy: `network-only`,
    onCompleted: () => {
      setUnits({ type: 'counties', payload: countiesData?.getCountiesByState });
    },
  });
  const [getCitiesByCounty, { data: citiesData, loading: citiesLoading }] = useLazyQuery(
    GET_CITIES_BY_COUNTY,
    {
      fetchPolicy: `network-only`,
      onCompleted: () => {
        setUnits({ type: 'cities', payload: citiesData?.getCitiesByCounty });
      },
    }
  );

  const [
    _updateCoords,
    { data: latLngFromAddressData, loading: coordsLoading },
  ] = useLazyQuery(GET_LAT_LNG_FROM_ADDRESS, {
    fetchPolicy: `network-only`,
    // skip: catchmentUnits.length === 0,
    onCompleted: () => {
      const location = latLngFromAddressData?.getLatLngFromAddress;
      if (location)
        setCoords({
          lat: parseFloat(location.lat),
          lng: parseFloat(location.lng),
        });
    },
  });

  const updateCoords = (q) => {
    if (q) _updateCoords({ variables: { q } });
  };

  useEffect(() => {
    if (catchmentUnits.states?.length)
      updateCoords(
        findStateByShortname(
          catchmentUnits.states[catchmentUnits.states.length - 1]?.shortname
        )
      );
  }, [catchmentUnits.states]);

  useEffect(() => {
    if (catchmentUnits.counties?.length)
      updateCoords(
        formatCountyLabel(catchmentUnits.counties[catchmentUnits.counties.length - 1])
      );
  }, [catchmentUnits.counties]);

  useEffect(() => {
    if (catchmentUnits.cities?.length)
      updateCoords(
        formatCityLabel(catchmentUnits.cities[catchmentUnits.cities.length - 1])
      );
  }, [catchmentUnits.cities]);

  const [
    getStatesBoundaries,
    { data: statesBoundaries, loading: statesBoundariesLoading },
  ] = useLazyQuery(GET_STATES_BOUNDARIES, {
    variables: { states: catchmentUnitsForMap.states },
    skip: catchmentUnitsForMap.states?.length === 0,
  });

  const [
    getCountiesBoundaries,
    { data: countiesBoundaries, loading: countiesBoundariesLoading },
  ] = useLazyQuery(GET_COUNTIES_BOUNDARIES, {
    variables: { counties: catchmentUnitsForMap.counties },
    skip: catchmentUnitsForMap.counties?.length === 0,
  });

  const [
    getCitiesBoundaries,
    { data: citiesBoundaries, loading: citiesBoundariesLoading },
  ] = useLazyQuery(GET_CITIES_BOUNDARIES, {
    variables: { cities: catchmentUnitsForMap.cities },
    skip: catchmentUnitsForMap.cities?.length === 0,
  });

  const path = useMemo(() => {
    let coordinates = [];
    if (statesBoundaries?.getStatesBoundaries)
      coordinates = coordinates.concat(statesBoundaries?.getStatesBoundaries);
    if (countiesBoundaries?.getCountiesBoundaries)
      coordinates = coordinates.concat(countiesBoundaries?.getCountiesBoundaries);
    if (citiesBoundaries?.getCitiesBoundaries)
      coordinates = coordinates.concat(citiesBoundaries?.getCitiesBoundaries);

    if (coordinates.length) {
      return coordinates.map(({ geojson }) =>
        geojson.map((el) =>
          el.map((x) => ({
            lng: parseFloat(x.lng),
            lat: parseFloat(x.lat),
          }))
        )
      );
    }
    return [];
  }, [statesBoundaries, countiesBoundaries, citiesBoundaries, catchmentUnits]);

  const boundariesLoading = useMemo(
    () => statesBoundariesLoading || countiesBoundariesLoading || citiesBoundariesLoading,
    [statesBoundariesLoading, countiesBoundariesLoading, citiesBoundariesLoading]
  );

  const unitsLoading = useMemo(
    () =>
      boundariesLoading || coordsLoading || loading || countiesLoading || citiesLoading,
    [loading, countiesLoading, citiesLoading, boundariesLoading, coordsLoading]
  );

  function clearMap(level) {
    if (level === Constants.LEVEL_FOR_CITY) {
      setCatchmentUnits({ type: 'cities', payload: [] });
    }

    if (level === Constants.LEVEL_FOR_COUNTY) {
      setUnits({ type: 'cities', payload: [] });

      setCatchmentUnits({ type: 'cities', payload: [] });
      setCatchmentUnits({ type: 'counties', payload: [] });
    }

    if (level === Constants.LEVEL_FOR_STATE) {
      setUnits({ type: 'cities', payload: [] });
      setUnits({ type: 'counties', payload: [] });
      setUnits({ type: 'states', payload: [] });

      setCatchmentUnits({ type: 'cities', payload: [] });
      setCatchmentUnits({ type: 'counties', payload: [] });
      setCatchmentUnits({ type: 'states', payload: [] });
    }
  }

  // Reactive units disposing when changing parent unit
  useEffect(() => {
    const allowedStates = catchmentUnits.states.map((state) => state.shortname);
    const disposedCounties = catchmentUnits.counties.filter((county) =>
      allowedStates.includes(county.state)
    );
    const disposedCities = catchmentUnits.cities.filter(
      (city) =>
        allowedStates.includes(city.state) &&
        catchmentUnits.counties.find(
          (county) => county.name === city.county && county.state === city.state
        )
    );

    if (!isEqual(catchmentUnits.counties, disposedCounties))
      setCatchmentUnits({ type: 'counties', payload: disposedCounties });
    if (!isEqual(catchmentUnits.cities, disposedCities))
      setCatchmentUnits({ type: 'cities', payload: disposedCities });
  }, [catchmentUnits.states]);

  useEffect(() => {
    const allowedStates = catchmentUnits.states.map((state) => state.shortname);
    const disposedCities = catchmentUnits.cities.filter(
      (city) =>
        allowedStates.includes(city.state) &&
        catchmentUnits.counties.find(
          (county) => county.name === city.county && county.state === city.state
        )
    );

    if (!isEqual(catchmentUnits.cities, disposedCities))
      setCatchmentUnits({ type: 'cities', payload: disposedCities });
  }, [catchmentUnits.counties]);

  // Boundaries/Subunits loading when unit change
  useEffect(() => {
    const _handleStateChange = async () => {
      if (catchmentUnits.states.length) {
        await Promise.all([
          isMapVisible ? getStatesBoundaries() : Promise.resolve(true),
          getCountiesByState({
            variables: { states: catchmentUnits.states.map((s) => s.shortname) },
          }),
        ]);
        setZoom(Constants.ZOOM_FOR_STATE);
      }
    };

    _handleStateChange();
  }, [catchmentUnits.states]);

  useEffect(() => {
    const _handleCountyChange = async () => {
      if (catchmentUnits.counties.length) {
        const selectedStatesNames = catchmentUnits.states.map((s) => s.shortname);
        const selectedCountiesNames = catchmentUnits.counties.map((v) => v.name);

        await Promise.all([
          isMapVisible ? getCountiesBoundaries() : Promise.resolve(true),
          getCitiesByCounty({
            variables: { states: selectedStatesNames, counties: selectedCountiesNames },
          }),
        ]);
        setZoom(Constants.ZOOM_FOR_COUNTY);
      }
    };

    _handleCountyChange();
  }, [catchmentUnits.counties]);

  useEffect(() => {
    const _handleCityChange = async () => {
      if (catchmentUnits.cities.length) {
        await Promise.all([isMapVisible ? getCitiesBoundaries() : Promise.resolve(true)]);
        setZoom(Constants.ZOOM_FOR_CITY);
      }
    };

    _handleCityChange();
  }, [catchmentUnits.cities]);

  // Handlers for units changing via dropdown
  function handleStateChange(e) {
    const selectedStates = e.target.value;
    blockHistory();
    if (selectedStates?.length > 0) {
      setCatchmentUnits({ type: 'states', payload: selectedStates });
    } else {
      clearMap(Constants.LEVEL_FOR_STATE);
      setZoom(Constants.ZOOM_FOR_COUNTRY);
    }
  }

  async function handleCountyChange(e) {
    const values = e.target.value;
    blockHistory();
    if (values && values.length > 0) {
      setCatchmentUnits({ type: 'counties', payload: values });
    } else {
      clearMap(Constants.LEVEL_FOR_COUNTY);
      setZoom(Constants.ZOOM_FOR_STATE);
    }
  }

  async function handleCityChange(e) {
    const values = e.target.value;
    blockHistory();
    if (values?.length > 0) {
      setCatchmentUnits({ type: 'cities', payload: values });
      await Promise.all([isMapVisible ? getCitiesBoundaries() : Promise.resolve(true)]);
      setZoom(Constants.ZOOM_FOR_CITY);
    } else {
      clearMap(Constants.LEVEL_FOR_CITY);
      setZoom(Constants.ZOOM_FOR_COUNTY);
    }
  }
  const { t } = useTranslation();
  return (
    <div className={[pageStyles.projectTabWrapper, className].join(' ')}>
      <div className={pageStyles.projectFormBanner}>
        <WrapperBlock
          title={t('workArea.title', 'Defined Work Areas')}
          tag="h2"
          isForm
          isContractorsStyle
        >
          {loading ? (
            <Spinner />
          ) : (
            <div className={pageStyles.projectFormContent}>
              <div className={styles.textWrapper}>
                <Text color="#000" size="s16" lineHeight="l19" weight="wr">
                  {t(
                    'workArea.body.p_1',
                    'Please select and define your work area. This should include anywhere that you are willing to travel should a Roof Project that you claim is confirmed for you. There is no limit to what you can select, but please keep in mind your licensing considerations (next step) and your ability to cover it.'
                  )}
                </Text>
              </div>
              <Row>
                <Col className={styles.column}>
                  <div className={styles.inputWrapper}>
                    <Field
                      isMulti
                      height="auto"
                      placeholder={t('workArea.body.statePlaceholder', 'State:')}
                      label=""
                      type="select"
                      inputMode="search"
                      components={units.states.length > 0 ? virtualizedSelect : null}
                      name="catchmentStates"
                      value={catchmentUnits.states}
                      getOptionValue={(option) => option.label}
                      onChange={handleStateChange}
                      isDisabled={unitsLoading || isDisabled(appStatus)}
                      options={
                        units.states.length > 0
                          ? units.states.map((el) => ({
                              label: el.name,
                              value: el,
                            }))
                          : []
                      }
                      isReadOnly={isReadOnlyInput}
                    />
                  </div>
                </Col>
                <Col className={styles.column}>
                  <div className={styles.inputWrapper}>
                    <Field
                      isMulti
                      height="auto"
                      placeholder={t('workArea.body.countyPlaceholder', 'County:')}
                      label=""
                      type="select"
                      isDisabled={unitsLoading || isDisabled(appStatus)}
                      components={units.counties.length > 0 ? virtualizedSelect : null}
                      inputMode="search"
                      name="catchmentCounties"
                      value={catchmentUnits.counties}
                      getOptionValue={(option) => option.label}
                      onChange={handleCountyChange}
                      options={
                        units.counties.length > 0
                          ? units.counties.map((el) => ({
                              label: formatCountyLabel(el),
                              value: el,
                            }))
                          : []
                      }
                      isReadOnly={isReadOnlyInput}
                    />
                  </div>
                </Col>
              </Row>

              <Col className={styles.column}>
                <div className={styles.inputWrapper}>
                  <Field
                    isMulti
                    placeholder={t('workArea.body.cityPlaceholder', 'City:')}
                    height="auto"
                    label=""
                    type="select"
                    isDisabled={unitsLoading || isDisabled(appStatus)}
                    inputMode="search"
                    components={units.cities.length > 0 ? virtualizedSelect : null}
                    name="catchmentCities"
                    value={catchmentUnits.cities}
                    getOptionValue={(option) => option.label}
                    onChange={handleCityChange}
                    options={
                      units.cities.length > 0
                        ? units.cities.map((el) => ({
                            label: formatCityLabel(el),
                            value: el,
                          }))
                        : []
                    }
                    isReadOnly={isReadOnlyInput}
                  />
                </div>
              </Col>
              <div className={isMobile ? '' : styles.map}>
                {unitsLoading ? (
                  <Spinner />
                ) : (
                  [
                    isMobile && !isReadOnlyInput && (
                      <Button
                        handleClick={handleMapViewButton}
                        size="medium"
                        className={styles.mapViewBtn}
                      >
                        {t('workArea.body.mapViewButton', 'Map view')}
                      </Button>
                    ),
                    <PolygonMap
                      containerStyle={{
                        width: '100%',
                        height: '450px',
                      }}
                      radius={2000}
                      center={coords}
                      coordinates={path}
                      zoom={zoom}
                      mapWrapperClass={
                        isMapVisible ? '' : isReadOnlyInput ? '' : styles.mapHidden
                      }
                    />,
                  ]
                )}
              </div>
            </div>
          )}
        </WrapperBlock>
      </div>
    </div>
  );
};

DefinedWorkAreas.propTypes = {
  className: PropTypes.string,
  appStatus: PropTypes.string,
  isReadOnlyInput: PropTypes.bool,
  units: PropTypes.shape({
    states: PropTypes.array,
    counties: PropTypes.array,
    cities: PropTypes.array,
  }),
  catchmentUnits: PropTypes.shape({
    states: PropTypes.array,
    counties: PropTypes.array,
    cities: PropTypes.array,
  }),
  setUnits: PropTypes.func.isRequired,
  setCatchmentUnits: PropTypes.func.isRequired,
  blockHistory: PropTypes.func.isRequired,
};
DefinedWorkAreas.defaultProps = {
  className: '',
  appStatus: 'Draft',
  isReadOnlyInput: false,
  units: {
    states: [],
    counties: [],
    cities: [],
  },
  catchmentUnits: {
    states: [],
    counties: [],
    cities: [],
  },
};

export default DefinedWorkAreas;

VirtualizedList.propTypes = {
  children: PropTypes.node.isRequired,
};
