import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  install,
  myaccount,
  guarantee,
  industrytraining,
  marketingopportunities,
} from 'assets/icons/welcome_page';

import Text from 'components/shared/Text';
import MobileContext from 'context/MobileContext';
import PresentationIcon from './PresentationIcon';
import styles from './style.module.css';

const NoLeadPresentationBlock = () => {
  const isMobile = useContext(MobileContext);
  const { t } = useTranslation();

  const cardsList = [
    {
      image: install,
      title: t('welcomePage.body.card_1.title', 'Install-Ready Jobs'),
      text: t(
        'welcomePage.body.card_1.text',
        'Unlike similar organizations, we don’t sell leads. We connect you with ready-to-install jobs in your area, so you can get to work and make money.'
      ),
    },
    {
      image: myaccount,
      title: t('welcomePage.body.card_2.title', 'Local Job Search'),
      text: t(
        'welcomePage.body.card_2.text',
        'Search our portal for jobs within any area, targeting your most profitable neighborhoods.'
      ),
    },
    {
      image: industrytraining,
      title: t('welcomePage.body.card_3.title', 'Reduced Overhead'),
      text: t(
        'welcomePage.body.card_3.text',
        'Hiring, training, and managing sales teams costs time and money. Join our nexus and focus on more profitable pursuits.'
      ),
    },
    {
      image: guarantee,
      title: t('welcomePage.body.card_4.title', 'Timely Payment'),
      text: t(
        'welcomePage.body.card_4.text',
        'We pay our contractors within hours of the customer accepting the job.'
      ),
    },
    {
      image: marketingopportunities,
      title: t('welcomePage.body.card_5.title', 'Automated Communications'),
      text: t(
        'welcomePage.body.card_5.text',
        'Streamline your customer communications and save hours on tedious administrative tasks.'
      ),
    },
  ];

  return (
    <div className={styles.blockWrapper}>
      <div className={styles.textWrapper}>
        <Text
          tag="h2"
          size={isMobile ? 's19' : 's24'}
          lineHeight={isMobile ? 'h22' : 'h28'}
          weight="wb"
          color="#F3C306"
          className={styles.presentationTitle}
        >
          {t('welcomePage.body.title', 'How RoofClaim.com Helps Contractors')}
        </Text>
        <Text
          tag="p"
          size={isMobile ? 's14' : 's19'}
          lineHeight={isMobile ? 'h20' : 'h30'}
          weight="wr"
          color="##00000D"
          className={styles.presentationText}
        >
          {t(
            'welcomePage.body.p_1',
            'In exchange for top-notch professionalism and customer service, we provide consistent growth opportunities. The bottom line? You take care of our customers, and we’ll take care of you!'
          )}
        </Text>
      </div>
      <div className={styles.presentationWrapper}>
        <Text
          tag="h3"
          size={isMobile ? 's19' : 's24'}
          lineHeight={isMobile ? 'h22' : 'h28'}
          weight="wb"
          color="#292929"
          className={styles.presentationTitle}
        >
          {t(
            'welcomePage.body.cardsTitle',
            'As a member of the RoofClaim.com Contractor Nexus, you’ll get:'
          )}
        </Text>
        <div className={styles.presentationIconsBlock}>
          {cardsList.map(({ image, title, text }, index) => (
            <PresentationIcon
              image={image}
              title={title}
              text={text}
              key={index + title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NoLeadPresentationBlock;
