import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'components/shared/modals/SunnyModal';
import Text from 'components/shared/Text';
import IconSVG from 'components/shared/IconSVG';
import Button from 'components/shared/Button';
import { quotationIcon } from 'assets/icons';
import styles from './styles.module.css';

const HeaderComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.headerWrapper}>
      <IconSVG
        src={quotationIcon}
        width="60px"
        height="60px"
        className={styles.headerIcon}
      />
      <Text
        className={styles.headerTitle}
        tag="h2"
        size="s24"
        lineHeight="h29"
        color="#000000"
        weight="wb"
      >
        {t('onBoardingModal.title.p_1', 'My Application')}
      </Text>
      <Text
        className={styles.headerText}
        size="s13"
        lineHeight="h16"
        tag="p"
        color="#292929"
      >
        {t(
          'onBoardingModal.title.p_2',
          'Please Read Carefully Before Completing the Application'
        )}
      </Text>
    </div>
  );
};

const MainContent = ({ onButtonClick }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.mainContentWrapper}>
      <Text tag="p" color="#000000" weight="wb" size="s14" lineHeight="h20">
        {t(
          'onBoardingModal.body.p_1',
          'The professionals in our RoofClaim.com Preferred Contractor Network (the “Network”) meet very strict criteria, and only firms that meet these criteria are eligible to participate in the Network. Our criteria and qualification screening are designed to restrict the Network to the most knowledgeable and capable construction professionals in the industry, those who are able to demonstrate, to the satisfaction of RoofClaim.com and/or its affiliated entities, a full range of skills required to deliver a quality project, an exemplary track record of projects, and sufficient skill and experience to carry out projects in an exceptional manner. The criteria to become a RoofClaim.com Preferred Contractor (“Contractor”) are:'
        )}
      </Text>
      <p />
      <Text tag="p" color="#000000" size="s14" lineHeight="h20">
        {t(
          'onBoardingModal.body.p_2',
          '- Your current business has been in operation under the same name at least 2 years.'
        )}
        <br />{' '}
        {t(
          'onBoardingModal.body.p_3',
          '- Hold general liability, worker’s compensation and automobile liability as stated in the insurance requirement section. Certificate of insurance is required  with this application.'
        )}
        <br />{' '}
        {t(
          'onBoardingModal.body.p_4',
          '- Hold licenses & other insurances as required by local, state or federal law.'
        )}
        <br />{' '}
        {t(
          'onBoardingModal.body.p_5',
          '- No bankruptcy (personal or business) filed during past 7 years.'
        )}
        {t(
          'onBoardingModal.body.p_6',
          ' - No adverse legal actions or judgments against your company during previous 5 years.'
        )}
        <br />{' '}
        {t(
          'onBoardingModal.body.p_7',
          '- Adhere to the Network terms and conditions, trademark and insurance requirements, which are incorporated by reference herein, and are set forth immediately following this Application.'
        )}
      </Text>
      <p />
      <Text tag="p" color="#000000" size="s14" lineHeight="h20">
        {t(
          'onBoardingModal.body.p_8',
          ' All the above criteria must be maintained on an ongoing basis or you will lose the right to participate in the Network. It is RoofClaim.com’s decision whether or not to designate an applicant as an RoofClaim.com Contractor, and that decision is made at the sole and unfettered judgment of RoofClaim.com and is not subject to challenge by an applicant or anyone else. By submitting this application, the applicant acknowledges its understanding of the criteria and selection process and waives any and all claims related to RoofClaim.com’s consideration of, or decision on, its Application.'
        )}
      </Text>
      <Button className={styles.button} handleClick={onButtonClick}>
        {t('onBoardingModal.body.p_9', 'I understand')}
      </Button>
    </div>
  );
};

const onBoardingModal = ({ isShow, onButtonClick }) => {
  if (!isShow) return null;
  return (
    <Modal
      headerContent={<HeaderComponent />}
      mainContent={<MainContent onButtonClick={onButtonClick} />}
    />
  );
};

onBoardingModal.propTypes = {
  isShow: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};
MainContent.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
};

export default onBoardingModal;
