import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { deleteIcon } from 'assets/icons';
import Icon, { secondaryIconProps } from 'components/shared/IconSVG';
import styles from './style.module.css';

const DeleteButton = ({ handleDeleteDocument, disabled }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.deleteButton}>
      <div
        onClick={disabled ? () => {} : handleDeleteDocument}
        className={styles.deleteButtonContent}
      >
        <Icon src={deleteIcon} {...secondaryIconProps} />
        <Text color={disabled ? '#837f7d' : '#000'} weight="wb" size="s12">
          {t('deleteDocumentButton', 'Delete document')}
        </Text>
      </div>
    </div>
  );
};
DeleteButton.propTypes = {
  handleDeleteDocument: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DeleteButton.defaultProps = {
  disabled: false,
};

export default DeleteButton;
