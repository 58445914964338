import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import { rightIcon } from 'assets/icons';
import styles from './styles.module.css';

const SeeAll = ({ handleClick }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container} onClick={handleClick}>
      <Text color="#000000" weight="wb" size="s14" tag="h2" lineHeight="h16">
        {t('seeButton', 'See All')}
      </Text>
      <Icon src={rightIcon} height="18px" width="18px" className={styles.arrayIcon} />
    </div>
  );
};

SeeAll.propTypes = {
  handleClick: PropTypes.func,
};
SeeAll.defaultProps = {
  handleClick: () => {},
};

export default SeeAll;
