import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import WrapperBlock from 'components/shared/WrapperBlock';
import Checkbox from 'components/shared/CheckBox';
import { initialServices } from 'enums';
import { useOtherServicesLocal } from 'localization';
import styles from './style.module.css';
import pageStyles from '../../style.module.css';

const OtherServicesOffered = ({
  serviceOffered,
  setFieldValue,
  isReadOnlyInput,
  blockHistory,
}) => {
  const initialValues = serviceOffered
    ? initialServices
        .filter(({ label }) => serviceOffered.split(';').includes(label))
        .map(({ id }) => id)
    : [];

  const handleList = ({ key }) => {
    const copyArr = [...initialValues];
    blockHistory();
    if (copyArr.includes(key)) {
      setFieldValue(
        'services.serviceOffered',
        copyArr
          .filter((item) => item !== key)
          .map((item) => initialServices[item - 1].label)
          .join(';')
      );
    } else {
      copyArr.push(key);
      setFieldValue(
        'services.serviceOffered',
        copyArr.map((item) => initialServices[item - 1].label).join(';')
      );
    }
  };
  const { t } = useTranslation();
  const otherServicesLocal = useOtherServicesLocal();
  return (
    <WrapperBlock
      title={t('companyProfile.services.other.title', 'Other services offered')}
      tag="h2"
      isContractorsStyle
    >
      <div className={pageStyles.projectFormContent}>
        <div className={styles.checkboxWrapper}>
          {otherServicesLocal.map((item, index) => (
            <Checkbox
              key={item.label + index}
              text={item.label}
              textSize="s14"
              checked={initialValues.includes(item.id)}
              onChange={() => handleList({ key: item.id })}
              isContractorsStyle
              isReadOnlyInput={isReadOnlyInput}
            />
          ))}
        </div>
      </div>
    </WrapperBlock>
  );
};
OtherServicesOffered.propTypes = {
  serviceOffered: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  blockHistory: PropTypes.func.isRequired,
  isReadOnlyInput: PropTypes.bool,
};
OtherServicesOffered.defaultProps = {
  isReadOnlyInput: false,
};

export default OtherServicesOffered;
