import React from 'react';
import PropTypes from 'prop-types';
import UploadFileArea from '.';

const UploadFileContainer = ({ uploadFiles, handleChange, name, ...props }) => {
  const handleUpload = (newFiles) => {
    const files = [uploadFiles, newFiles || []].flat();
    handleChange({ target: { name, value: files } });
  };

  const handleDeleteFile = (removedFile, fileIndex) => {
    const files = uploadFiles.filter((file, index) => index !== fileIndex);
    handleChange({ target: { name, value: files } });
  };

  return (
    <UploadFileArea
      {...props}
      uploadFiles={uploadFiles}
      handleUpload={handleUpload}
      handleDelete={handleDeleteFile}
    />
  );
};

UploadFileContainer.propTypes = {
  uploadFiles: PropTypes.array,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

UploadFileContainer.defaultProps = {
  uploadFiles: [],
};

export default UploadFileContainer;
