import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { formatDate } from 'utils/dateFormatters';
import { withBracketsNumber } from 'utils/phoneNumberFormatter';
import Spinner from 'components/shared/Spinner';
import LineText from 'components/common/LineText';
import GoogleMap from 'components/common/GoogleMap';
import styles from '../style.module.css';

const InformationsSection = ({ warrantyCase, coords }) => {
  const { t } = useTranslation();
  return (
    <div className={[styles.infoWrapper, styles.wrapper].join(' ')}>
      <div className={styles.infoContainer}>
        <LineText
          name={t('warranty.details.info.address', 'Address')}
          value={`${warrantyCase.account?.billingStreet}, ${warrantyCase.account?.billingCity}, ${warrantyCase.account?.billingState}`}
          isFixed
        />
        <LineText
          name={t('warranty.details.info.code', 'Gate Code')}
          value={warrantyCase.account?.gateCode}
          isFixed
        />
        <LineText
          name={t('warranty.details.info.contact', 'Contact')}
          value={`${warrantyCase.contact?.name}, ${withBracketsNumber(
            warrantyCase.contact?.phone
          )}`}
          isFixed
        />
        <LineText
          name={t('warranty.details.info.date', 'Date of Install')}
          value={formatDate(warrantyCase.dateOfInstall)}
          isFixed
          isValueBold
        />
        <LineText
          name={t('warranty.details.info.amount', 'Award Amount')}
          value={`$ ${Intl.NumberFormat().format(warrantyCase.totalAmount)}`}
          isFixed
          isNameBold
          isValueBold
        />
      </div>
      <div className={styles.mapContainer}>
        {isEmpty(coords) ? (
          <Spinner />
        ) : (
          <GoogleMap locations={[{ ...coords }]} centerLocation={coords} zoomLevel={14} />
        )}
      </div>
    </div>
  );
};
InformationsSection.propTypes = {
  warrantyCase: PropTypes.object.isRequired,
  coords: PropTypes.object.isRequired,
};
export default InformationsSection;
