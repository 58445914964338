import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import { useSignUpFirstStepValidationSchema } from 'validation/signUp';
import { SIGNUP_MUTATION } from 'graphql/mutations/auth';
import MobileContext from 'context/MobileContext';

import { useAuthErrors } from 'enums/errors/authErrors';
import { useFormatAuthMessage } from 'utils/graphQLErrorFormatters';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { FirstStepSignUp, SignUpHeader } from '../../components/Auth/SignUp';
import ConfirmEmail from '../../components/Auth/ConfirmEmail';
import FormWrapper from '../../components/Auth/FormWrapper';
import { OAuthSignInURIs } from '../../enums/OAuthURIs';

const SignUpForm = () => {
  const history = useHistory();
  const [signUpUser, { loading: isLoading, error }] = useMutation(SIGNUP_MUTATION);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isAddressError, setIsAddressError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [email, setEmail] = useState('');
  const isMobile = useContext(MobileContext);

  const errorObject = useAuthErrors();
  const { formatAuthMessage } = useFormatAuthMessage();

  const {
    email: emailFromQueryString,
    firstName: firstNameFromQueryString,
    lastName: lastNameFromQueryString,
    oauth,
    method,
  } = queryString.parse(history.location.search);
  const isOAuth = oauth === 'true';

  const handleSubmit = async (values) => {
    setEmail(values.email);
    await signUpUser({
      variables: {
        data: {
          ...values,
          phoneNumber: values.phoneNumber.slice(1),
        },
      },
      update: () => {
        if (!isOAuth) {
          setIsFirstStep(false);
        } else {
          window.location.replace(OAuthSignInURIs[method]);
        }
      },
    });
  };

  useEffect(() => {
    setIsAddressError(
      error && formatAuthMessage(error?.message) === errorObject.notUniqueAddress
    );
    setIsEmailError(error && formatAuthMessage(error?.message) === 'notUniqueEmail');
  }, [error]);
  const validationObject = useSignUpFirstStepValidationSchema();
  return (
    <FormWrapper header={isFirstStep && !isMobile ? <SignUpHeader /> : null}>
      {isFirstStep ? (
        <Formik
          initialValues={{
            companyName: '',
            companyNameDBA: '',
            firstName: firstNameFromQueryString ?? '',
            lastName: lastNameFromQueryString ?? '',
            jobTitle: '',
            phoneNumber: '',
            email: emailFromQueryString ?? '',
            address: '',
            city: '',
            zipCode: '',
            state: '',
            suiteNumber: '',
            addressPlaceID: '',
            latitude: '',
            longitude: '',
            agreesWithTerms: false,
            contactPreferenceEmail: false,
            contactPreferenceSms: false,
            isOAuth,
            language: '',
          }}
          validationSchema={validationObject}
          validateOnMount
          onSubmit={handleSubmit}
        >
          {(formikProps) => {
            const handleChangeAddress = () => {
              setIsAddressError(false);
            };

            const handleChangeEmail = (event) => {
              formikProps.handleChange(event);
              setIsEmailError(false);
            };
            return (
              <fieldset disabled={isLoading}>
                <FirstStepSignUp
                  isOAuth={isOAuth}
                  {...formikProps}
                  isLoading={isLoading}
                  isMobile={isMobile}
                  handleChangeAddress={handleChangeAddress}
                  handleChangeEmail={handleChangeEmail}
                  userExistError={isEmailError}
                  addressExistError={isAddressError}
                />
              </fieldset>
            );
          }}
        </Formik>
      ) : (
        <ConfirmEmail isCreatePassword email={email} />
      )}
    </FormWrapper>
  );
};

export default SignUpForm;
