import gql from 'graphql-tag';

export const ADD_NEW_MEMBER = gql`
  mutation ADD_NEW_MEMBER($member: AddMemberInput) {
    createMember(member: $member) {
      pgId
      sfId
      firstName
      lastName
      email
      phone
      role
      status
    }
  }
`;

export const EDIT_MEMBER = gql`
  mutation EDIT_MEMBER($member: ChangeMemberInput) {
    changeMember(member: $member) {
      pgId
      sfId
      firstName
      lastName
      email
      phone
      role
      status
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation DELETE_MEMBER($pgId: ID) {
    deleteMember(pgId: $pgId) {
      message
    }
  }
`;
