import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconSVG from 'components/shared/IconSVG';
import Text from 'components/shared/Text';
import { rightArrowIcon } from '../../../assets/icons';
import styles from './styles.module.css';

const NextPrevButton = ({
  handleBackClick,
  handleNextClick,
  currentTabIndex,
  activeTabIndex,
  tabListSize,
  className,
}) => {
  const activeIndex = activeTabIndex || currentTabIndex;
  const { t } = useTranslation();
  return (
    <div className={[styles.navigationWrapper, className].join(' ')}>
      <div
        className={[
          styles.buttonWrapper,
          styles.buttonBackWrapper,
          activeIndex === 0 ? styles.disabled : '',
        ].join(' ')}
        onClick={handleBackClick}
      >
        <IconSVG
          src={rightArrowIcon}
          className={[styles.backArrow, styles.buttonArrow].join(' ')}
        />
        <Text
          color="#000"
          weight="wb"
          size="s14"
          lineHeight="h19"
          className={styles.backButton}
        >
          {t('backButton', 'Back')}
        </Text>
      </div>

      <div
        className={[
          styles.buttonWrapper,
          styles.buttonNextWrapper,
          activeIndex === tabListSize - 1 ? styles.disabled : '',
        ].join(' ')}
        onClick={handleNextClick}
      >
        <Text
          color="#000"
          weight="wb"
          size="s14"
          lineHeight="h19"
          className={styles.nextButton}
        >
          {t('nextButton', 'Next')}
        </Text>
        <IconSVG src={rightArrowIcon} />
      </div>
    </div>
  );
};

NextPrevButton.propTypes = {
  handleNextClick: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  activeTabIndex: PropTypes.number,
  tabListSize: PropTypes.number.isRequired,
  className: PropTypes.string,
};
NextPrevButton.defaultProps = {
  activeTabIndex: null,
  className: '',
};
export default NextPrevButton;
