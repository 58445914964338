import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import {
  updateFileListJob,
  updateCurrentJobList,
} from 'graphql/resolvers/opportunity/cache';
import { CONFIRM_INSPECTION } from 'graphql/mutations/opportunity';
import UploadFileArea from 'components/shared/UploadFile/container';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/Spinner';
import Text from 'components/shared/Text';
import Checkbox from 'components/shared/CheckBox';
import { useAppConfigLocal } from 'localization';
import AppConfigContext from 'context/AppConfigContext';
import { toBase64UploadedFiles } from 'utils/toBase64File';
import styles from '../style.module.css';
import {CURRENT_JOB} from "../../../../graphql/queries/opportunities";
import {roles} from "../../../../enums";

const InspectSection = ( { sfId: jobId, ...props },) => {
  const { t } = useTranslation();
  const appConfigLocal = useAppConfigLocal();
  const config = useContext(AppConfigContext);

  const { match, userCurrentRole } = props;
  const [confirmInspection, { loading }] = useMutation(CONFIRM_INSPECTION, {
    refetchQueries: [
      { query: CURRENT_JOB(userCurrentRole !== roles.crew), variables: {
          contentDocumentId: match?.params?.id,
        }, }
    ]
  });
  const [filesForUpload, setFilesForUpload] = useState([]);
  const [inspectionNeed, setInspectionNeed] = useState(false);

  function handleFiles({ target }) {
    setFilesForUpload(target?.value);
  }

  const handleConfirmInspection = ({ files }) => {
    confirmInspection({
      variables: {
        jobId: [jobId],
        type: 'inspectFile',
        files,
      },
      update: (_, {data: {uploadFiles: newFiles}}) => {
        updateCurrentJobList(
            jobId,
            {
              approvedInspectionUpload: true,
            },
            {isAwarded: true}
        );
        updateFileListJob(jobId, newFiles);
      },
    });
  } ;

  const handleSubmitInspectionApproval = async () => {
    const convertedFiles = await toBase64UploadedFiles(filesForUpload);
    handleConfirmInspection({ files: convertedFiles });
  };

  if (loading) return <Spinner />;
  return (
    <>
      <div className={styles.confirmContainer}>
        <Checkbox
          isContractorsStyle
          value={inspectionNeed}
          textSize="big"
          text={appConfigLocal[config.textInspection]}
          onChange={() => setInspectionNeed(!inspectionNeed)}
        />
      </div>
      {inspectionNeed ? (
        <div className={styles.uploadContainer}>
          <div className={styles.label}>
            <Text color="#837F7D" tag="p">
              {t('Final Inspection Approval.')}
            </Text>
          </div>
          <UploadFileArea
            buttonText={t('uploadButton', 'Upload file(s)')}
            accept=".jpeg, .png, .jpg"
            name="files"
            uploadFiles={filesForUpload}
            handleChange={handleFiles}
            maxCountDocument={5}
          />
          <Button
            size="medium"
            handleClick={handleSubmitInspectionApproval}
            className={styles.confirmButton}
          >
            {t('confirmButton', 'Confirm')}
          </Button>
        </div>
      ) : null}
    </>
  );
};

InspectSection.propTypes = {
  sfId: PropTypes.string.isRequired,
  uploadSubmitted: PropTypes.func.isRequired,
  userCurrentRole: PropTypes.string,
  match: PropTypes.object
};

InspectSection.defaultProps = {
  userCurrentRole: '',
  match: {}
};

export default InspectSection;
