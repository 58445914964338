import React from 'react';
import PropTypes from 'prop-types';
import TablePagination from '.';

const ProductsPagination = ({ itemsCount, pageCount, gotoPage, pageIndex, onLoadMore }) => {
  const activePage = pageIndex + 1;


  const handleLoadMore = () => {
    onLoadMore();
  }

  const handleGoToPage = (pageValue) => {
    const newPage = pageValue ? Number(pageValue) - 1 : 0;
    gotoPage(newPage);
    if (Number(pageValue) === pageCount) {
      handleLoadMore();
    }
  };

  return (
    <TablePagination
      activePage={activePage}
      pagesCount={pageCount}
      itemsCount={itemsCount}
      gotoPage={handleGoToPage}
    />
  );
};

ProductsPagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number,
  pageCount: PropTypes.number,
  gotoPage: PropTypes.func,
  onLoadMore: PropTypes.func
};

ProductsPagination.defaultProps = {
  pageIndex: 0,
  pageCount: 0,
  gotoPage: () => {},
  onLoadMore: () => {}
};

export default ProductsPagination;
