export const assignEnvToWindow = (userData) => {
  window.REACT_APP_CHAT_INIT_FIRST_URL = process.env.REACT_APP_CHAT_INIT_FIRST_URL;
  window.REACT_APP_CHAT_INIT_SECOND_URL = process.env.REACT_APP_CHAT_INIT_SECOND_URL;
  window.REACT_APP_CHAT_INIT_KEY = process.env.REACT_APP_CHAT_INIT_KEY;
  window.REACT_APP_CHAT_INIT_BASE_CONTENT_URL =
    process.env.REACT_APP_CHAT_INIT_BASE_CONTENT_URL;
  window.REACT_APP_CHAT_INIT_BASE_URL = process.env.REACT_APP_CHAT_INIT_BASE_URL;
  window.REACT_APP_CHAT_SET_ATTRIBUTE = process.env.REACT_APP_CHAT_SET_ATTRIBUTE;
  window.USER_DATA = userData;
};
