import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import { withBracketsNumber } from 'utils/phoneNumberFormatter';
import daysFromNow from 'utils/countDaysBetweenDays';
import Text from 'components/shared/Text';
import { isFinishJob } from 'utils/isFinishJob';
import roles from 'enums/roles';
import jobStatus from 'enums/workOrderStatus';
import { formatDate } from 'utils/dateFormatters';
import styles from './styles.module.css';
import SeeAll from '../../SeeAllComponent';
import JobsCard from '../JobsCard';

const AllJobs = ({ jobs = [], sfId: contactSfId, userRole }) => {
  const history = useHistory();
  const { t } = useTranslation();

  function handlePush(path) {
    history.push(path);
  }
  let visibleJobs = jobs;

  if (userRole === roles.crew) {
    visibleJobs = jobs.filter(({ assignedContacts }) =>
      assignedContacts.some(({ sfId }) => sfId === contactSfId)
    );
  }

  const todayJobs = visibleJobs.filter(
    ({ dateOfInstall }) => daysFromNow(dateOfInstall) <= 0
  );
  const tomorrowJobs = visibleJobs.filter(
    ({ dateOfInstall }) => daysFromNow(dateOfInstall) === 1
  );

  return (
    <>
      <div className={styles.todayJobWrapper}>
        <WrapperBlock
          title={t('dashboard.todayJobsSection.title', "Today's jobs")}
          rightComponent={<SeeAll handleClick={() => handlePush('/jobs?tab=awarded')} />}
        >
          <div className={styles.jobsContainer}>
            {!todayJobs.length ? (
              <Text
                color="rgb(118, 118, 118)"
                weight="wb"
                size="s17"
                tag="h2"
                lineHeight="h17"
              >
                {t('dashboard.todayJobsSection.p_1', 'No jobs today')}
              </Text>
            ) : null}
            {todayJobs.map(({ opportunity, sfId, dateOfInstall, permitNumber }) => (
              <JobsCard
                key={sfId}
                address={opportunity.account?.address}
                phoneNumber={withBracketsNumber(opportunity.contacts[0]?.phone)}
                totalSQs={opportunity.totalSqs ?? ''}
                product={opportunity.existingShingleType}
                installationDate={formatDate(dateOfInstall)}
                permitNumber={permitNumber}
                handlePush={() => handlePush(`/jobs/awarded/${sfId}`)}
              />
            ))}
          </div>
        </WrapperBlock>
      </div>
      <div className={styles.tomorrowJobWrapper}>
        <WrapperBlock
          title={t('dashboard.tomorrowJobsSection.title', "Tomorrow's jobs")}
          rightComponent={<SeeAll handleClick={() => handlePush(`/jobs?tab=awarded`)} />}
        >
          <div className={styles.jobsContainer}>
            {!tomorrowJobs.length ? (
              <Text
                color="rgb(118, 118, 118)"
                weight="wb"
                size="s17"
                tag="h2"
                lineHeight="h17"
              >
                {t('dashboard.tomorrowJobsSection.p_1', 'No jobs tomorrow')}
              </Text>
            ) : null}
            {tomorrowJobs.map(({ opportunity, sfId, dateOfInstall, permitNumber }) => (
              <JobsCard
                key={sfId}
                address={opportunity?.account?.address}
                phoneNumber={withBracketsNumber(opportunity?.contacts[0].phone)}
                totalSQs={opportunity?.totalSqs ?? ''}
                product={opportunity?.existingShingleType}
                installationDate={formatDate(dateOfInstall)}
                permitNumber={permitNumber}
                handlePush={() => handlePush(`/jobs/awarded/${sfId}`)}
              />
            ))}
          </div>
        </WrapperBlock>
      </div>
    </>
  );
};
AllJobs.propTypes = {
  jobs: PropTypes.array.isRequired,
  userRole: PropTypes.string.isRequired,
  sfId: PropTypes.string.isRequired,
};
export default AllJobs;
