import React from 'react';
import { useHistory } from 'react-router-dom';
import WelcomeLayout from 'layouts/WelcomeLayout';
import WelcomeNoLead from 'containers/WelcomeNoLead/WelcomeNoLeadContainer';
import CompactFooter from 'components/CompactFooter';
import MobileSignedOutHeader from 'layouts/Mobile/MobileSignedOutHeader';

const WelcomeNoLeadPage = () => {
  const history = useHistory();
  return (
    <WelcomeLayout>
      <MobileSignedOutHeader>
        <WelcomeNoLead
          handleSubmit={() => history.push('/sign-up')}
          handleLoginSubmit={() => history.push('/sign-in')}
        />
        <CompactFooter handleSubmit={() => history.push('/sign-up')} />
      </MobileSignedOutHeader>
    </WelcomeLayout>
  );
};

export default WelcomeNoLeadPage;
