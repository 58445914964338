import { useEffect, useReducer } from 'react';

export function useDefinedWorkArea(initialData) {
  const initialUnits = {
    states: [],
    counties: [],
    cities: [],
  };

  function unitsReducer(state, action) {
    switch (action.type) {
      case 'states':
        return { ...state, states: action.payload };
      case 'counties':
        return { ...state, counties: action.payload };
      case 'cities':
        return { ...state, cities: action.payload };
      default:
        throw new Error();
    }
  }

  // List of selected administrative units
  const [catchmentUnits, setCatchmentUnits] = useReducer(unitsReducer, initialUnits);

  // Actual grouped list of administrative units
  const [units, setUnits] = useReducer(unitsReducer, initialUnits);

  useEffect(() => {
    if (initialData) {
      setCatchmentUnits({ type: 'states', payload: initialData.states });
      setCatchmentUnits({ type: 'counties', payload: initialData.counties });
      setCatchmentUnits({ type: 'cities', payload: initialData.cities });
    }
  }, [initialData]);

  return {
    units,
    setUnits,
    catchmentUnits,
    setCatchmentUnits,
  };
}
