import { formatDate } from 'utils/dateFormatters';
import { withBracketsNumber } from 'utils/phoneNumberFormatter';
import { useCaseTypeLocal, useCaseStatusLocal } from 'localization';
import { shortAddress } from 'utils/shortAddress';

export default ({ handleClickColumn = () => {}, columnTitles }) => {
  const { ref, date, address, name, phone, type } = columnTitles;
  return [
    {
      Header: ref,
      accessor: 'caseNumber',
      onClick: handleClickColumn,
      Cell: (row) => (row.value ? `${row.value}` : ''),
    },
    {
      Header: date,
      accessor: 'repairDateTime',
      onClick: handleClickColumn,
      Cell: (row) => formatDate(row.value),
    },
    {
      Header: address,
      accessor: 'account.address',
      onClick: handleClickColumn,
      Cell: (row) => shortAddress(row.value),
    },
    {
      Header: name,
      accessor: 'contact.name',
      onClick: handleClickColumn,
      Cell: (row) => (row.value ? `${row.value}` : ''),
    },
    {
      Header: phone,
      accessor: 'contact.phone',
      onClick: handleClickColumn,
      Cell: (row) => (row.value ? `${withBracketsNumber(row.value)}` : ''),
    },
    {
      Header: type,
      accessor: 'type',
      onClick: handleClickColumn,
      Cell: (row) => useCaseTypeLocal()[row.value] || '',
    },
  ];
};
