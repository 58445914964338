import { useTranslation } from 'react-i18next';

export const useAuthErrors = () => {
  const { t } = useTranslation();
  // Login
  return {
    invalidCredentials: t(
      'errors.emailPassword',
      'There was an error with your E-Mail/Password combination. Please try again.'
    ),

    // Register
    notUniqueEmail: t('errors.notUniqueEmail', 'notUniqueEmail'),
    notUniqueAddress: t(
      'errors.address',
      'Cannot create account for the selected address. Please get in touch with support to continue.'
    ),
  };
};
