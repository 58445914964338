import React from 'react';
import PropTypes from 'prop-types';
import ReadView from './ReadView';
import FormView from './FormView';

const PermitSection = ({ isPermitUpload, ...props }) => {
  if (isPermitUpload)
    return (
      <fieldset disabled>
        <ReadView {...props} />
      </fieldset>
    );

  return (
    <fieldset>
      <FormView {...props} />
    </fieldset>
  );
};

PermitSection.propTypes = {
  isPermitUpload: PropTypes.bool,
};

PermitSection.defaultProps = {
  isPermitUpload: false,
};

export default PermitSection;
