import React, { useState, useMemo } from 'react';
import { searchIcon } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import Table from 'components/common/Table';
import filterData from 'utils/dataSearch';
import PropTypes from 'prop-types';
import SearchInput from 'components/shared/Input';
import Text from 'components/shared/Text';
import styles from '../TableMapView/style.module.css';

const TableViewLayout = ({
  dataList,
  searchValues,
  tableColumns,
  tableOptions,
  emptyPageText,
  buttons,
  searchPlaceholder,
  classNameHeader,
  classNameWrapper,
}) => {
  const [searchString, setSearchString] = useState('');
  const { t } = useTranslation();

  const data = useMemo(
    () =>
      searchValues
        ? filterData({
            data: dataList,
            searchString,
            searchValue: searchValues,
          })
        : dataList,
    [searchString, dataList, searchValues]
  );

  if (!dataList.length)
    return <div className={styles.tabContentWrapper}>{emptyPageText}</div>;

  return (
    <div className={[styles.tabContentPagination, classNameWrapper].join(' ')}>
      <div className={[styles.block, styles.headerBlockSpace, classNameHeader].join(' ')}>
        <div className={styles.inputWrapper}>
          {searchValues && (
            <SearchInput
              rightIcon={searchIcon}
              placeholder={searchPlaceholder}
              name="searchValue"
              value={searchString}
              onChange={(event) => setSearchString(event.target.value)}
            />
          )}
        </div>
        {buttons}
      </div>

      {data.length > 0 ? (
        <div>
          <Table
            {...tableOptions}
            columns={tableColumns}
            data={data}
            isSortDefault
            sortIndex={1}
            pagination
          />
        </div>
      ) : (
        <div>
          <Text color="#000" size="s16">
            {t('emptySearch', 'There is no data that matches your search')}
          </Text>
        </div>
      )}
    </div>
  );
};

TableViewLayout.propTypes = {
  tableColumns: PropTypes.array.isRequired,
  dataList: PropTypes.array,
  searchValues: PropTypes.array,
  emptyPageText: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  tableOptions: PropTypes.object,
  buttons: PropTypes.node,
  classNameHeader: PropTypes.string,
  classNameWrapper: PropTypes.string,
};

TableViewLayout.defaultProps = {
  dataList: [],
  searchValues: null,
  tableOptions: {},
  buttons: null,
  classNameHeader: '',
  classNameWrapper: '',
};

export default TableViewLayout;
