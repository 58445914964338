import React from 'react';
import { Formik } from 'formik';
import { signInValidationSchema } from '../../validation/signIn';
import { useAuth } from '../../services/hooks/useAuth';
import SignIn from '../../components/Auth/SignIn';

const SignInForm = () => {
  const { isLoading, error, handleSubmit } = useAuth();

  return (
    <Formik
      initialValues={{ email: '', password: '', isKeepLogged: false }}
      validationSchema={signInValidationSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const handleChangeIsKeepLogged = () => {
          formikProps.setFieldValue('isKeepLogged', !formikProps.values.isKeepLogged);
        };
        return (
          <fieldset disabled={isLoading}>
            <SignIn
              {...formikProps}
              errorMessage={error}
              handleChangeIsKeepLogged={handleChangeIsKeepLogged}
            />
          </fieldset>
        );
      }}
    </Formik>
  );
};

export default SignInForm;
