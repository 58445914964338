import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/shared/Spinner';
import { updateContactListUser } from 'graphql/cache/user';
import { useMutation } from '@apollo/react-hooks';
import { CHANGE_USER_DATA } from 'graphql/mutations/auth';
import myAccountValidationSchema from 'validation/myAccount';
import Button from 'components/shared/Button';
import auth from 'utils/auth';
import api from 'services/api';
import WithError from 'services/HOCs/withError';
import { cleanNumber } from 'utils/phoneNumberFormatter';
import { useDetLang } from 'services/localization';
import UserForm from './UserForm';
import styles from './styles.module.css';

const EditMode = ({ user, handleCancel }) => {
  const [updateContacts, { loading, error }] = useMutation(CHANGE_USER_DATA);

  const initialValues = {
    user: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '',
      oldEmail: user?.email?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '',
      phoneNumber: user?.phone ? cleanNumber(user.phone) : '',
      contractorRole: user.contractorRole,
      isOAuth: user.isOAuth,
    },
  };

  const onSubmit = async (values) => {
    const userData = {
      firstName: values.user.firstName,
      lastName: values.user.lastName,
      newEmail: values.user.email,
      oldEmail: values.user.oldEmail,
      phone: values.user.phoneNumber,
      contractorRole: values.user.contractorRole,
    };

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    await updateContacts({
      variables: {
        userData,
      },

      update: (cache, { data: { changeUserData } }) => {
        updateContactListUser(changeUserData);

        if (changeUserData?.token) {
          auth.setToken(changeUserData?.token);
          api.setAuthTokenToHeader(changeUserData?.token);
          handleCancel();
        }
        if (!changeUserData?.token) {
          auth.removeToken();
          window.location.replace('/changeEmail');
        }
      },
    });
  };
  const { t } = useTranslation();
  const buttonHeight = useDetLang('local', '');
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={myAccountValidationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        setTouched,
        setFieldTouched,
        errors,
        touched,
        values,
        dirty,
        ...formikProps
      }) => {
        const handleUserChange = ({ target }) => {
          if (target.name === 'email') {
            setFieldTouched(`user.email`, true);
          } else {
            setFieldTouched(`user.${target.name}`, true);
          }

          handleChange({ target: { name: `user.${target.name}`, value: target.value } });
        };

        return (
          <>
            {loading ? (
              <Spinner />
            ) : (
              <WithError
                isShow={!!error}
                message={
                  error?.message.split('Error:')[1] || error?.message.split(':')[2]
                }
              >
                <form onSubmit={handleSubmit}>
                  <UserForm
                    {...formikProps}
                    values={{ ...values.user }}
                    errors={errors ? errors.user : {}}
                    touched={touched ? touched.user : {}}
                    handleChange={handleUserChange}
                  />

                  <Button
                    type="submit"
                    size="small"
                    height={buttonHeight}
                    disabled={Object.keys(errors).length || !dirty}
                    className={styles.buttonContainer}
                  >
                    {t('saveChangesButton', 'Save Changes')}
                  </Button>
                  <Button
                    color="white"
                    height={buttonHeight}
                    size="small"
                    handleClick={handleCancel}
                    className={styles.buttonContainer}
                  >
                    {t('cancelButton', 'Cancel')}
                  </Button>
                </form>
              </WithError>
            )}
          </>
        );
      }}
    </Formik>
  );
};

EditMode.propTypes = {
  user: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

EditMode.defaultProps = {};

export default EditMode;
