import { string, object } from 'yup';
import { emailRegex } from '../emailRegex';

const userValidation = object().shape(
  {
    firstName: string().required().trim(),
    lastName: string().required().trim(),
    email: string()
      .max(80, 'email should be a maximum of 80 characters long')
      .required('email is required')
      .trim()
      .matches(emailRegex),
    phoneNumber: string()
      .matches(/^\d{10,10}$/, {
        message: 'Please enter valid phone number',
        excludeEmptyString: true,
      }).required()
  },
);

export default userValidation;
