import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from 'components/shared/IconSVG';
import styles from './style.module.css';

function SidebarItem({ name, icon, path, isExact = true, setShowSidebar, badge }) {
  return (
    <NavLink exact={isExact} to={path} activeClassName={styles.active}>
      <div
        className={`${styles.menuItem} ${styles.menuItemActive}`}
        onClick={() => setShowSidebar(false)}
      >
        <div className={`${styles.line} ${styles.lineActive}`} />
        <Icon
          src={icon}
          height="18px"
          width="16px"
          className={
            name === 'Warranty'
              ? `${styles.icon} ${styles.iconActiveWarranty}`
              : `${styles.icon} ${styles.iconActive}`
          }
        />
        <span className={styles.itemName}>{name}</span>
        {badge? <div className={styles.badge}>{badge}</div> : null}
      </div>
    </NavLink>
  );
}
export default SidebarItem;

SidebarItem.propTypes = {
  badge: PropTypes.number,
  icon: PropTypes.string.isRequired,
  isExact: PropTypes.bool,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  setShowSidebar: PropTypes.func.isRequired
}

SidebarItem.defaultProps = {
  badge: 0,
  isExact: true
}

SidebarItem.defaultProps = {};
