import React from 'react';
import Text from 'components/shared/Text';
import PropTypes from 'prop-types';
import { applicationStatus } from 'enums';
import styles from './style.module.css';

const ScheduleBlock = ({ title, text, isColorPoint, isScheduled, status }) => {
  const classNameForColor = {
    [applicationStatus.draft]: styles.withGreyPoint,
    [applicationStatus.pendingRR]: styles.withYellowPoint,
    [applicationStatus.pendingCU]: styles.withYellowPoint,
    [applicationStatus.approved]: styles.withGreenPoint,
    [applicationStatus.denied]: styles.withRedPoint,
  };

  return (
    <div
      className={[
        styles.blockWrapper,
        isScheduled ? styles.blockWrapperWithTick : '',
      ].join(' ')}
    >
      <Text
        tag="p"
        className={styles.scheduleTitle}
        color="#292929"
        size="s16"
        lineHeight="h19"
      >
        {title}
      </Text>
      <Text
        className={
          isColorPoint ? [styles.withColorPoint, classNameForColor[status]].join(' ') : ``
        }
        tag="p"
        color="#00000D"
        size="s16"
        lineHeight="h19"
        weight="wb"
      >
        {text}
      </Text>
    </div>
  );
};
ScheduleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  status: PropTypes.string,
  isColorPoint: PropTypes.bool,
  isScheduled: PropTypes.bool,
};
ScheduleBlock.defaultProps = {
  isColorPoint: false,
  isScheduled: false,
  status: '',
};
export default ScheduleBlock;
