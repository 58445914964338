import { string, object, ref, boolean } from 'yup';
import { useTranslation } from 'react-i18next';
import { emailRegex } from '../emailRegex';

export const useSignUpFirstStepValidationSchema = () => {
  const { t } = useTranslation();
  return object().shape({
    companyName: string().required().trim(),
    companyNameDBA: string().trim(),
    firstName: string().required().trim(),
    lastName: string().required().trim(),
    jobTitle: string().required(),
    phoneNumber: string()
      .matches(/^\d{11,11}$/, {
        message: t('errors.validPhone', 'Please enter valid phone number'),
        excludeEmptyString: true,
      })
      .when(['mobilePhone'], {
        is: (mobilePhone) => !mobilePhone,
        then: string().required(),
      }),
    email: string().required().matches(emailRegex).trim(),
    address: string().required(),
    addressPlaceID: string().required(),
    city: string().required(),
    zipCode: string()
      .required()
      .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/, {
        message: t('errors.validZip', 'Please enter valid zipcode'),
        excludeEmptyString: true,
      }),
    state: string().required(),
    language: string().required(),
    suiteNumber: string(),
    latitude: string(),
    longitude: string(),
    agreesWithTerms: boolean(),
    contactPreferenceEmail: boolean(),
    contactPreferenceSms: boolean(),
  });
};

export const useCreatePasswordValidationSchema = () => {
  const { t } = useTranslation();
  return object().shape({
    password: string()
      .required(t('errors.passwordRequired', 'Password is required'))
      .matches(new RegExp('(?=.{6,})')),
    repeatedPassword: string()
      .required()
      .oneOf([ref('password'), null], t('errors.passwordMatch', 'Passwords must match')),
  });
};

export const emailValidationSchema = object().shape({
  email: string().required().matches(emailRegex).trim(),
});
