import gql from 'graphql-tag';

const FullDocument = gql`
  fragment FullDocument on Document {
    link
    pgId
    name
  }
`;

const FullRoofProject = gql`
  fragment FullRoofProject on Opportunity {
    pgId
    sfId
    homeOwnerId
    stageName
    status
    dateOfInstall
    adjusterStatus
    installationStatus
    claimNumber
    loanNumber
    policyNumber
    isRoofTarped
    existingShingleType
    satelliteDish
    deductibleAmount

    billingBalance
    billingDepositBalance
    billingInvoiceTotal
    insuranceCompanyId
    mortgageCompanyId
    replacementShingleId

    isInstallCompleted
    solarPanelsRemovalDate
    materialDeliveryDate
    warrantyVoidedDate

    isWarrantyVoided
    warrantyVoidedDate
    warrantyExpirationDate
    inspectionDate

    isContactorMarkedComplete
    isHOConfirmInstallationComplete
    isInsuranceApprovedClaim
    isHoaApproval
    contractSignedDate
    createdDate
    gateCode
    otherInsurance

    isIncludeMortgageCompany
    isGateCodeRequired
    delayMessage
    messageHomeowner
    isNetworkContractor
    adjusterMeetingDate
    reinspectionDate
    numberSolarPanels
    deductiblePaid

    service {
      pgId
      scheduledDate
    }

    account {
      pgId
      name
      address
      gateCode
      documents {
        name
        link
      }
    }
    insuranceCompany {
      sfId
      name
    }
    mortgageCompany {
      sfId
      name
    }
    product {
      family
      name
      sfId
      displayUrl
    }
    contractor {
      sfId
      firstName
      lastName
      email
      phone
      mobilePhone
      subsidiary
    }
  }
`;

const InsuranceClaimStage = gql`
  fragment InsuranceClaimStage on Opportunity {
    pgId
    sfId
    existingShingleType
    isRoofTarped
    mortgageCompanyId
    loanNumber
    insuranceCompanyId
    policyNumber
    claimNumber
    isInsuranceApprovedClaim
    stageName
    isIncludeMortgageCompany
    gateCode
    otherInsurance
    deductibleAmount
  }
`;

export const Projects = {
  fragments: {
    FullDocument,
    FullRoofProject,
    InsuranceClaimStage,
  },
};
