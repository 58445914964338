import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import Text from 'components/shared/Text';
import styles from './style.module.css';

const LineText = ({ name, value, isNameBold, isValueBold, isFixed }) => {
  const isMobile = useContext(MobileContext);

  if (!value) return null;
  return (
    <div
      className={[
        styles.rowContainer,
        isFixed ? styles.rowContainerFixed : styles.rowContainerBig,
      ].join(' ')}
    >
      <div className={styles.rowTitle}>
        <Text
          color={isMobile ? '#000000' : '#686868'}
          size="s14"
          lineHeight="h20"
          weight={isNameBold ? 'wb' : 'wr'}
        >
          {name}
        </Text>
      </div>

      <div className={styles.rowText}>
        {typeof value === 'string' || typeof value === 'number' ? (
          <Text
            color="#000000"
            size={isValueBold || isMobile ? 's13' : 's14'}
            lineHeight="h20"
            weight={isValueBold || isMobile ? 'wb' : 'wr'}
          >
            {value}
          </Text>
        ) : (
          value
        )}
      </div>
    </div>
  );
};

LineText.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isNameBold: PropTypes.bool,
  isValueBold: PropTypes.bool,
  isFixed: PropTypes.bool,
};

LineText.defaultProps = {
  value: '',
  name: '',
  isNameBold: false,
  isValueBold: false,
  isFixed: false,
};

export default LineText;
