import { useTranslation } from 'react-i18next';

export const useOnsiteManagementStructure = () => {
  const { t } = useTranslation();
  return [
    {
      label: t(
        'myApplication.secondTab.coverage.p_1',
        'Dedicated Superintendent per job'
      ),
      value: 'Dedicated Superintendent per job',
    },
    {
      label: t('myApplication.secondTab.coverage.p_2', 'Roving Superintendent'),
      value: 'Roving Superintendent',
    },
  ];
};

export const useOnsiteManagementDays = () => {
  const { t } = useTranslation();
  return [
    {
      label: t('myApplication.secondTab.coverage.p_3', '0-2 jobs/day'),
      value: '0-2 jobs/day',
    },
    {
      label: t('myApplication.secondTab.coverage.p_4', '3-5 jobs/day'),
      value: '3-5 jobs/day',
    },
    {
      label: t('myApplication.secondTab.coverage.p_5', '5+ jobs/day'),
      value: '5+ jobs/day',
    },
  ];
};
