import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import FormWrapper from 'components/Auth/FormWrapper';
import Text from 'components/shared/Text';
import Link from 'components/shared/Link';
import {
  FormContainer,
  FormContent,
  FormColumn as Col,
  FormFooter,
} from 'components/shared/Form';
import Field from 'components/shared/Field';
import Checkbox from 'components/shared/CheckBox';
import Button from 'components/shared/Button';
import buttonStyles from 'components/shared/Button/style.module.css';
import emailIcon from 'assets/icons/email.svg';
import { OAuthSignInURIs } from 'enums/OAuthURIs';
import { useReCAPTCHA } from 'services/hooks/useReCAPTCHA';
import SignUpButton from '../../SignUpButton';
import FormHeader from '../../Form/FormHeader';
import styles from '../style.module.css';

const SignIn = ({
  handleSubmit,
  values,
  errors,
  touched,
  errorMessage,
  handleChange,
  handleChangeIsKeepLogged,
  setFieldValue,
}) => {
  const isMobile = useContext(MobileContext);
  const { t } = useTranslation();

  const goTo = (uri) => {
    window.location.href = uri;
  };

  const { handleSubmit: _handleSubmit, component: ReCAPTCHAComponent } = useReCAPTCHA(
    (token) => {
      setFieldValue('reCAPTCHAToken', token);
      handleSubmit(values);
    }
  );

  return (
    <FormWrapper
      header={
        isMobile ? null : (
          <div className={styles.authFormHeader}>
            <Text color="#00000D" weight="wb" size="s24">
              {t('signIn.header.p_1', 'Sign In')}
            </Text>
            <Text color="#00000D" weight="wm" size="s16">
              {t('signIn.header.p_2', "Don't have an account?")}
              <Link href="/sign-up" bold size="medium">
                {t('signIn.header.p_3', 'Sign Up')}
              </Link>
            </Text>
          </div>
        )
      }
    >
      <form onSubmit={handleSubmit} name="sign-in">
        {ReCAPTCHAComponent}
        <FormContainer>
          {isMobile && (
            <div className={styles.authFormHeaderMobile}>
              <Text color="#00000D" weight="wb" size="s21">
                {t('signIn.header.mobile.p_1', 'Sign In')}
              </Text>
            </div>
          )}
          <FormHeader>
            <SignUpButton icon="google" handleClick={() => goTo(OAuthSignInURIs.google)}>
              {t('signIn.body.google', 'Sign In with Google')}
            </SignUpButton>
            <SignUpButton
              icon="microsoft"
              handleClick={() => goTo(OAuthSignInURIs.microsoft)}
            >
              {t('signIn.body.microsoft', 'Sign In with Microsoft')}
            </SignUpButton>
          </FormHeader>
          <FormContent>
            {errorMessage ? (
              <Col>
                <Text size="s16" lineHeight="l14" weight="ws" color="#f44336">
                  {errorMessage}
                </Text>
              </Col>
            ) : null}
            <Col>
              <Field
                name="email"
                label={t('signIn.body.emailLabel', 'Email Address')}
                type="text"
                inputMode="text"
                required
                value={values.email}
                autoComplete="on"
                rightIcon={emailIcon}
                onChange={handleChange}
                isError={touched.email && Boolean(errors.email)}
              />
            </Col>
            <Col>
              <Field
                name="password"
                label={t('signIn.body.passwordLabel', 'Password')}
                type="password"
                inputMode="text"
                required
                value={values.password}
                autoComplete="current-password"
                onChange={handleChange}
                isError={touched.password && Boolean(errors.password)}
              />
            </Col>

            <Col>
              <Text size="s13" color="#837F7D" lineHeight="l16">
                {t(
                    'By clicking “Log Me In” you are agreeing to our'
                )}{' '}
                <Link
                    style={{color: '#837F7D'}}
                    href="https://roofclaim.com/pro-purchasing-terms-and-conditions/"
                    bold
                    size="s13"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  {t('Terms of Service')}
                </Link>{' '}
                {/* eslint-disable-next-line max-len */}
                {t('and authorize RoofClaim and its affiliates, using automated technology, to deliver marketing emails, calls and texts to the number and email address you provided. Consent is not a condition of purchase.')}{' '}
                {t('See our')}{' '}
                <Link
                    style={{color: '#837F7D'}}
                    href="https://roofclaim.com/privacy-policy/"
                    bold
                    size="s13"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  {t('Privacy Policy')}
                </Link>{' '}
                {t('for more information.')}
              </Text>
            </Col>

            <Col>
              <div className={styles.authFormHeader}>
                <Checkbox
                  isContractorsStyle
                  text={t('signIn.body.checkbox', 'Remember me')}
                  name="isKeepLogged"
                  value={values.isKeepLogged}
                  textSize="large"
                  onChange={handleChangeIsKeepLogged}
                />
                <div className={styles.authFooterLink}>
                  <Link href="/forgot-password">
                    {t('signIn.body.forgotPass', 'Forgot Password?')}
                  </Link>
                </div>
              </div>
            </Col>
          </FormContent>

          <FormFooter>
            {isMobile ? (
              <div className={styles.formFooter}>
                <Button
                  size="medium"
                  type="submit"
                  handleClick={_handleSubmit}
                  disabled={Object.keys(errors)?.length}
                  className={buttonStyles.fullWidthMobile}
                >
                  {t('signIn.footer.mobile.p_1', 'Log Me In')}
                </Button>
                <br />
                <Text color="#00000D" weight="wm" size="s16">
                  {t('signIn.footer.mobile.p_2', "Don't have an account?")}
                  <Link href="/sign-up" bold size="medium">
                    {t('signIn.footer.mobile.p_3', 'Sign Up')}
                  </Link>
                </Text>
              </div>
            ) : (
              <Button
                size="medium"
                type="submit"
                handleClick={_handleSubmit}
                disabled={Object.keys(errors)?.length}
              >
                {t('signIn.footer.p_1', 'Log Me In')}
              </Button>
            )}
          </FormFooter>
        </FormContainer>
      </form>
    </FormWrapper>
  );
};

SignIn.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleChangeIsKeepLogged: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

SignIn.defaultProps = {
  errorMessage: '',
};

export default SignIn;
