import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OtherServicesOffered from './OtherServicesOffered';
import EstimatesOfServices from './EstimatesOfServices';
import Details from './Details';
import styles from '../style.module.css';

const Services = ({ values: { services }, isReadOnlyInput, ...formikProps }) => {
  const { residentialVSCommercial } = services;
  const [residentialVSCommercialValue, setResidentialVSCommercial] = useState(
    residentialVSCommercial
  );
  return (
    <div className={styles.projectTabWrapper}>
      <div
        className={[styles.projectFormBanner, styles.projectFormBannerMobileWithBorder]}
      >
        <Details
          {...services}
          {...formikProps}
          isReadOnlyInput={isReadOnlyInput}
          residentialVSCommercial={residentialVSCommercialValue}
          setResidentialVSCommercial={setResidentialVSCommercial}
        />
        <EstimatesOfServices
          services={services}
          {...formikProps}
          isReadOnlyInput={isReadOnlyInput}
          residentialVSCommercial={residentialVSCommercialValue}
        />
        <OtherServicesOffered
          {...services}
          {...formikProps}
          isReadOnlyInput={isReadOnlyInput}
        />
      </div>
    </div>
  );
};
Services.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isReadOnlyInput: PropTypes.bool,
};
Services.defaultProps = {
  isReadOnlyInput: false,
};

export default Services;
