import gql from 'graphql-tag';

const OpportunityFragment = gql`
  fragment OpportunityFragment on Opportunity {
    sfId
    stageName
    status
    dateOfInstall
    installationStatus
    existingShingleType
    deductibleAmount
    materialDeliveryDate
    createdDate
    totalSqs
    roofPitch
    typeOfLoss
    valley
    ridgeVents4
    numberPipeJacks
    gooseneckDryerVents4

    account {
      sfId
      shortAddress
    }
    location(isRadius: true) {
      lat
      lng
    }
  }
`;

const OpportunityContactsFragment = gql`
  fragment OpportunityContactsFragment on Opportunity {
    sfId
    stageName
    status
    dateOfInstall
    installationStatus
    existingShingleType
    deductibleAmount
    materialDeliveryDate
    createdDate
    totalSqs
    roofPitch
    typeOfLoss
    valley
    ridgeVents4
    numberPipeJacks
    gooseneckDryerVents4

    contacts {
      name
      phone
      email
    }
    account {
      sfId
      shortAddress
      address
    }
    location {
      lat
      lng
    }
    product {
      name
    }
  }
`;

const JobFragment = gql`
  fragment JobFragment on Job {
    sfId
    number
    reference
    status
    description
    dateOfInstall
    deliveredPlanksQuantity
    deliveredMaterialsComment
    deliveredPlanksComment
    isDeliveredMaterials
    isDeliveredPlanks
    commentRollover
    permitNumber
    permitRequired
    amount
    requestedAccountSfId
    paymentDate
    paymentDueDate
    paymentStatus
    plywoodComment
    plywoodQuantity
    isDeliveredPlywood
    isPermitUpload
    approvedInspectionUpload

    assignedContacts {
      name
      sfId
    }

    invoice {
      sfId
      invoice
    }
    opportunity {
      sfId
      pgId
      stageName
      status
      dateOfInstall
      installationStatus
      existingShingleType
      deductibleAmount
      materialDeliveryDate
      createdDate
      totalSqs
      roofPitch
      typeOfLoss
      valley
      ridgeVents4
      numberPipeJacks
      gooseneckDryerVents4
      warrantyCases {
        pgId
        sfId
        caseNumber
        createdAt
        status
        subject
        description
        type
        dateOfInstall
        totalAmount
        repairDateTime
        account {
          address
          billingStreet
          billingCity
          billingCountry
          billingState
          billingPostalCode
          billingLatitude
          billingLongitude
          gateCode
        }
        contact {
          name
          phone
        }
        workOrder {
          dateOfInstall
        }
      }
      contacts {
        name
        phone
        email
        sfId
        contactType
      }
      account {
        sfId
        shortAddress
        name
        address
        gateCode
      }
      location {
        lat
        lng
      }
      product {
        name
      }
    }
  }
`;

export const Opportunity = {
  fragments: {
    entry: OpportunityFragment,
    entryContacts: OpportunityContactsFragment,
    jobEntry: JobFragment,
  },
};
