import { useAuthErrors } from 'enums/errors/authErrors';
import { useDefaultErrorMessage } from '../enums/errors';

export const useFormatMessage = () => {
  const defaultErrorMessage = useDefaultErrorMessage();

  const formatMessage = (message) => {
    if (message) return message?.replace(/GraphQL error: /, '');
    return defaultErrorMessage;
  };
  return { formatMessage };
};

export const useFormatAuthMessage = () => {
  const errorObject = useAuthErrors();
  const defaultErrorMessage = useDefaultErrorMessage();
  const { formatMessage } = useFormatMessage();

  const formatAuthMessage = (message) => {
    if (message)
      return (
        errorObject?.[formatMessage(message)?.replace(/AuthenticationError: /, '')] ??
        defaultErrorMessage
      );
    return defaultErrorMessage;
  };
  return { formatAuthMessage };
};
