import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Tooltip = ({ text }) => {
  return (
    <div className={styles.tooltip}>
      <span className={styles.tooltipText}>{text}</span>
    </div>
  );
};
Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
};
export default Tooltip;
