import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import IconSVG from 'components/shared/IconSVG';
import styles from './style.module.css';

const PresentationIcon = ({ image, title, text }) => {
  return (
    <div className={styles.iconWrapper}>
      <div className={styles.block}>
        <div className={styles.icon}>
          <IconSVG src={image} height="50px" width="50px" />
        </div>
        <Text
          tag="p"
          size="s19"
          weight="wb"
          lineHeight="h22"
          color="#F3C306"
          className={styles.iconTitle}
        >
          {title}
        </Text>
        <Text
          tag="p"
          size="s16"
          weight="wr"
          lineHeight="h20"
          color="#292929"
          className={styles.iconText}
        >
          {text}
        </Text>
      </div>
    </div>
  );
};

PresentationIcon.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

PresentationIcon.defaultProps = {
  image: '',
  title: '',
  text: '',
};

export default PresentationIcon;
