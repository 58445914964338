import gql from 'graphql-tag';

export const GET_USER = gql`
  query getUser {
    getUser {
      pgId
      sfId
      accountPgId
      accountSfId
      firstName
      lastName
      name
      contractorStatus
      contractorRole
      contactType
      phone
      mobilePhone
      email
      alternateEmail
      isFirstLogin
      isOAuth
      language
      account {
        phone
        email
        pgId
        parentId
        name
        companyName
        companyNameDBA
        companyType
        billingCity
        billingLatitude
        billingLongitude
        billingPostalCode
        billingState
        billingCountry
        billingGeoCodeAccuracy
        billingStreet
        type
        loanNumber
        address
        gateCode
        website
        facebook
        linkedin
        twitter
        suiteNumber
        createdAt

        contacts {
          pgId
          sfId
          firstName
          lastName
          phone
          mobilePhone
          email
          alternateEmail
          contactPreferenceEmail
          contactPreferenceSms
          contactPreferencePhone
          language
        }
        application {
          contractorSfId
          contractorPgId
          accountPgId
          accountSfId
          sfId
          pgId
          status
        }
      }
    }
  }
`;

export const CHECK_RESET_TOKEN = gql`
  query checkResetTokenExistance($token: String!) {
    checkResetTokenExistance(token: $token) {
      message
    }
  }
`;

export const CHECK_ACTIVATION_TOKEN = gql`
  query checkActivationTokenExistance($token: String!) {
    checkActivationTokenExistance(token: $token) {
      message
    }
  }
`;
