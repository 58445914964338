import { cleanNumber } from 'utils/phoneNumberFormatter';
import { restructureInitData } from 'utils/application/restructureData/restructureInitData';
import { qualificationTypes } from 'enums';

export const initValues = (data = {}, user, downloadFilesDescription) => {
  let initialValues;

  const { application, qualification } = data;
  const addressArray = application?.address?.split(',');
  if (application) {
    initialValues = {
      myAccount: {
        companyName: application?.companyName || '',
        companyNameDBA: application?.companyNameDBA || '',
        organization: application?.organization || '',
        ownerFirstName: application?.ownerFirstName || '',
        ownerLastName: application?.ownerLastName || '',
        companyAddress: addressArray ? addressArray[0].trim() : '',
        city: addressArray ? addressArray[1].trim() : '',
        state: addressArray ? addressArray[2].trim() : '',
        zipCode: addressArray ? addressArray[3].trim() : '',
        companyEmailAddress: application?.ownerEmail || '',
        companyPhoneNumber: cleanNumber(application?.companyPhoneNumber) || '',
        taxID: application?.taxID || '',
        registrationState: application?.registrationState || '',
        yearCompanyStarted: application?.yearCompanyStarted || null,
        dunBradstreet: application?.dunBradstreet || '',
        suiteNumber: application?.suiteNumber || '',
      },
      myApplication: {
        restorationVSRetail:
          application?.restorationVSRetail === 0
            ? 0
            : application?.restorationVSRetail || 50,
        residentialVSCommercial:
          application?.residentialVSCommercial === 0
            ? 0
            : application?.residentialVSCommercial || 50,

        shingle: application?.shingle === 0 ? 0 : application?.shingle || 25,
        metal: application?.metal === 0 ? 0 : application?.metal || 25,
        tile: application?.tile === 0 ? 0 : application?.tile || 25,
        modifiedBitumenResidential:
          application?.modifiedBitumenResidential === 0
            ? 0
            : application?.modifiedBitumenResidential || 25,

        builtUpMetal:
          application?.builtUpMetal === 0 ? 0 : application?.builtUpMetal || 25,
        modifiedBitumenCommercial:
          application?.modifiedBitumenCommercial === 0
            ? 0
            : application?.modifiedBitumenCommercial || 25,
        thermoset: application?.thermoset === 0 ? 0 : application?.thermoset || 25,
        thermoplastic:
          application?.thermoplastic === 0 ? 0 : application?.thermoplastic || 25,

        serviceOffered: application?.serviceOffered || '',

        definedWorkAreas: {
          states: application?.definedWorkAreas?.states ?? [],
          counties: application?.definedWorkAreas?.counties ?? [],
          cities: application?.definedWorkAreas?.cities ?? [],
        },

        numberCrews: application?.numberCrews || '',
        numberLaborers: application?.numberLaborers || '',
        crewAveragePerDay: application?.crewAveragePerDay || '',
        onsiteManagementStructure: application?.onsiteManagementStructure || '',
        onsiteManagementDays: application?.structure || '',
        hasDumpTrailer:
          application?.hasDumpTrailer !== null
            ? application?.hasDumpTrailer !== 'No'
            : null,
        staffedPullPermits:
          application?.staffedPullPermits !== null
            ? application?.staffedPullPermits !== 'No'
            : null,
        preferredDistributor: application?.preferredDistributor || '',
        preferredManufacturer1: application?.preferredManufacturer1 || '',
        preferredManufacturer2: application?.preferredManufacturer2 || '',
        preferredManufacturer3: application?.preferredManufacturer3 || '',
        preferredManufacturerOther: application?.preferredManufacturerOther || '',
      },
      myCertification: {
        w9: restructureInitData(
          qualification,
          downloadFilesDescription,
          qualificationTypes.w9
        ),
        workersCompensation: restructureInitData(
          qualification,
          downloadFilesDescription,
          qualificationTypes.wc
        ),
        general: restructureInitData(
          qualification,
          downloadFilesDescription,
          qualificationTypes.gl
        ),

        license: restructureInitData(
          qualification,
          downloadFilesDescription,
          qualificationTypes.lc
        ),

        auto: restructureInitData(
            qualification,
            downloadFilesDescription,
            qualificationTypes.al
        )
      },
    };
  } else {
    initialValues = {
      myAccount: {
        companyName: user?.account?.companyName || '',
        companyNameDBA: user?.account?.companyNameDBA || '',
        organization: '',
        ownerFirstName: '',
        ownerLastName: '',
        companyAddress: user?.account?.billingStreet || '',
        city: user?.account?.billingCity || '',
        zipCode: user?.account?.billingPostalCode || '',
        state: user?.account?.billingState || '',
        suiteNumber: user?.account?.suiteNumber || '',
        companyEmailAddress: '',
        companyPhoneNumber: '',
        taxID: '',
        registrationState: '',
        yearCompanyStarted: '',
        dunBradstreet: '',
      },
      myApplication: {
        restorationVSRetail: 50,
        residentialVSCommercial: 50,

        shingle: 25,
        metal: 25,
        tile: 25,
        modifiedBitumenResidential: 25,

        builtUpMetal: 25,
        modifiedBitumenCommercial: 25,
        thermoset: 25,
        thermoplastic: 25,

        definedWorkAreas: {
          states: [],
          counties: [],
          cities: [],
        },

        serviceOffered: '',

        numberCrews: '',
        numberLaborers: '',
        crewAveragePerDay: '',
        onsiteManagementStructure: '',
        onsiteManagementDays: '',
        hasDumpTrailer: null,
        staffedPullPermits: null,
        preferredDistributor: '',
        preferredManufacturer1: '',
        preferredManufacturer2: '',
        preferredManufacturer3: '',
        preferredManufacturerOther: '',
      },
      myCertification: {
        w9: {
          docs_0: {
            policyNumber: '',
            carrier: '',
            expirationDate: null,
            files: [],
            otherIssuer: '',
            type: qualificationTypes.w9,
            sfId: '',
          },
        },
        workersCompensation: {
          docs_0: {
            policyNumber: '',
            carrier: '',
            expirationDate: null,
            files: [],
            otherIssuer: '',
            type: qualificationTypes.wc,
            sfId: '',
          },
        },

        general: {
          docs_0: {
            policyNumber: '',
            carrier: '',
            expirationDate: null,
            files: [],
            otherIssuer: '',
            type: qualificationTypes.gl,
            sfId: '',
          },
        },
        license: {
          docs_0: {
            policyNumber: '',
            carrier: '',
            expirationDate: null,
            files: [],
            otherIssuer: '',
            type: qualificationTypes.lc,
            sfId: '',
          },
        },
        auto: {
          docs_0: {
            policyNumber: '',
            carrier: '',
            expirationDate: null,
            files: [],
            otherIssuer: '',
            type: qualificationTypes.al,
            sfId: '',
          },
        }
      },
    };
  }
  return initialValues;
};
