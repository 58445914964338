import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { monthLocal } from 'localization';
import { useDetLang } from 'services/localization';
import { month, monthIncome } from './chartData';
import styles from './styles.module.css';

const Chart = ({ jobs, pickYear }) => {
  const { t } = useTranslation();

  const [chartData, setChartData] = useState({});
  const chartIncomes = (year) => month.map((item) => monthIncome(jobs, item, year));

  const monthLocalLabel = useDetLang(monthLocal, month);

  const chartSteps = () => {
    const mostIncomeMonth = Math.max(
      ...chartIncomes(pickYear),
      ...chartIncomes(String(pickYear - 1))
    );

    return {
      step: Math.ceil(mostIncomeMonth * 0.4),
      max: Math.ceil(mostIncomeMonth * 0.4) * 3,
    };
  };

  const chart = () => {
    setChartData({
      labels: monthLocalLabel,
      datasets: [
        {
          label: t('dashboard.income.currentYear', 'Current year'),
          data: chartIncomes(pickYear),
          backgroundColor: Array(12)
            .fill('')
            .map((it) => '#F1C400'),
        },
        {
          label: t('dashboard.income.previousYear', 'Previous year'),
          data: chartIncomes(String(pickYear - 1)),
          backgroundColor: Array(12)
            .fill('')
            .map((it) => '#5162D3'),
        },
      ],
    });
  };
  useEffect(() => {
    chart();
  }, [jobs]);
  return (
    <div className={styles.chartContainer}>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                barThickness: 10,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawBorder: false,
                },
                ticks: {
                  max: chartSteps().max || 15,
                  min: 0,
                  stepSize: chartSteps().step || 5,
                  callback(label, index, labels) {
                    switch (label) {
                      case 0:
                        return '$0';
                      case chartSteps().step:
                        return `$${chartSteps().step}K`;
                      case chartSteps().step * 2:
                        return `$${chartSteps().step * 2}K`;
                      case chartSteps().step * 3:
                        return `$${chartSteps().step * 3}K`;

                      default:
                        return '';
                    }
                  },
                },
              },
            ],
          },
        }}
      />
    </div>
  );
};
Chart.propTypes = {
  jobs: PropTypes.array.isRequired,
  pickYear: PropTypes.string.isRequired,
};
export default Chart;
