import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const colorStyles = {
  grey: [styles.loaderGrey, styles.loaderContentBig].join(' '),
  white: [styles.loaderWhite, styles.loaderContentSmall].join(' '),
  yellow: [styles.loaderYellow, styles.loaderContentBig].join(' '),
};

const colorWrapperStyles = {
  grey: styles.loaderWrapperGrey,
  white: styles.loaderWrapperWhite,
};

const Spinner = ({ color, absolute, confirmEmail }) => {
  return (
    <div
      className={[
        confirmEmail ? styles.confirmEmailWrapper : styles.loaderWrapper,
        colorWrapperStyles[color],
        absolute ? styles.loaderWrapperAbsolute : '',
      ].join(' ')}
    >
      <div className={[styles.loaderContent, colorStyles[color]].join(' ')} />
    </div>
  );
};
Spinner.propTypes = {
  confirmEmail: PropTypes.bool,
  absolute: PropTypes.bool,
  color: PropTypes.string,
};
Spinner.defaultProps = {
  confirmEmail: false,
  absolute: false,
  color: 'grey',
};
export default Spinner;
