import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Checkbox from 'components/shared/CheckBox';
import styles from '../styles.module.css';

const ThirdScreen = ({ confirmThirdScreen, setConfirmThirdScreen }) => {
  const { t } = useTranslation();

  return (
    <>
      <Text tag="p" color="#000000" weight="wb" size="s14" lineHeight="h20">
        {t(
          'inspectionModal.body.thirdScreen.p_1',
          'Roof and gutters are free and clear of debris. Gutters, shingles, and drip edge are free of tar. There is also no tar on driveways, walkways, or any of the vertical surfaces of the house. Furthermore, soffits are all intact and lying as they should, and the entire job site is clean.'
        )}
      </Text>
      <p />
      <Text tag="p" color="#000000" size="s14" lineHeight="h20" weight="wb">
        {t(
          'inspectionModal.body.thirdScreen.p_2',
          'All tools, extra materials, and trailers have been picked up and/or are scheduled for pick up.'
        )}
      </Text>
      <p />
      <Text tag="p" color="#000000" size="s14" lineHeight="h20" weight="wb">
        {t(
          'inspectionModal.body.thirdScreen.p_3',
          'If applicable, satellite dish(s) is/are reattached.'
        )}
      </Text>
      <p />
      <Text tag="p" color="#000000" size="s14" lineHeight="h20" weight="wb">
        {t(
          'inspectionModal.body.thirdScreen.p_4',
          'If damage has been done to the metal or wood fascia, or on any part of the property, I have reported it within this App by posting details and photos to the Chatter feature of this App accordingly.'
        )}
      </Text>
      <div className={styles.confirmContainer}>
        <Checkbox
          isContractorsStyle
          value={confirmThirdScreen}
          textSize="big"
          text={t(
            'inspectionModal.confirm',
            'I confirm the above statements are true and accurate.'
          )}
          onChange={() => setConfirmThirdScreen(!confirmThirdScreen)}
        />
      </div>
    </>
  );
};

ThirdScreen.propTypes = {
  setConfirmThirdScreen: PropTypes.func.isRequired,
  confirmThirdScreen: PropTypes.bool.isRequired,
};

export default ThirdScreen;
