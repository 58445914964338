import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import ChartSection from './ChartSection';
import styles from './styles.module.css';

const EstimatedOfServicesSection = ({
  estimateData,
  setFieldValue,
  setTouched,
  isReadOnlyInput,
  residentialVSCommercial,
  blockHistory,
  type,
}) => {
  const { t } = useTranslation();

  const {
    shingle,
    metal,
    tile,
    modifiedBitumenResidential,
    builtUpMetal,
    modifiedBitumenCommercial,
    thermoset,
    thermoplastic,
  } = estimateData;

  const initialResidentialShareValues = {
    shingle: {
      color: '#F1C400',
      value: shingle,
      name: t('estimate.body.p_1', 'Shingle'),
      invalid: false,
    },
    metal: {
      color: '#6C9961',
      value: metal,
      name: t('estimate.body.p_2', 'Metal'),
      invalid: false,
    },
    tile: {
      color: '#5162D3',
      value: tile,
      name: t('estimate.body.p_3', 'Tile'),
      invalid: false,
    },
    modifiedBitumenResidential: {
      color: '#CF4B4B',
      value: modifiedBitumenResidential,
      name: t('estimate.body.p_4', 'Modified Bitumen'),
      invalid: false,
    },
  };

  const initialCommercialShareValues = {
    builtUpMetal: {
      color: '#F1C400',
      value: builtUpMetal,
      name: t('estimate.body.p_5', 'Built-Up (BUR) Metal'),
      invalid: false,
    },
    modifiedBitumenCommercial: {
      color: '#6C9961',
      value: modifiedBitumenCommercial,
      name: t('estimate.body.p_6', 'Modified Bitumen'),
      invalid: false,
    },
    thermoset: {
      color: '#5162D3',
      value: thermoset,
      name: t('estimate.body.p_7', 'Thermoset (EPDM)'),
      invalid: false,
    },
    thermoplastic: {
      color: '#CF4B4B',
      value: thermoplastic,
      name: t('estimate.body.p_8', 'Thermoplastic (PVC & TPO)'),
      invalid: false,
    },
  };
  const onHandleChangeShare = ({ key, value }) => {
    setFieldValue(type, { ...estimateData, [key]: value });
    setTouched({}, false);
    blockHistory();
  };
  return (
    <div className={styles.chartsSection}>
      <div className={styles.sectionWrapper}>
        {residentialVSCommercial === 0 ? (
          <div className={styles.chartBackground} />
        ) : null}
        <ChartSection
          series={initialResidentialShareValues}
          onHandleChangeValue={onHandleChangeShare}
          residentialVSCommercial={residentialVSCommercial}
          type="Residential"
          header={
            <>
              <Text color="#000000" weight="wb">
                {t('estimate.body.p_9', 'Residential:')}
              </Text>
              <Text color="#000000" weight="wr" className={styles.headerTotal}>
                {t('estimate.body.p_10', '(Total must equal 100%)')}
              </Text>
            </>
          }
          additionalText={
            <div style={{ textAlign: 'center' }}>
              <Text color="#837F7D" size="s14" lineHeight="h16">
                {t('estimate.body.p_11', 'Residential')}
                <br />
                {t('estimate.body.p_12', 'share')}
              </Text>
            </div>
          }
          isReadOnlyInput={isReadOnlyInput || residentialVSCommercial === 0}
        />
      </div>
      <div className={styles.sectionWrapper}>
        {residentialVSCommercial === 100 ? (
          <div className={styles.chartBackground} />
        ) : null}
        <ChartSection
          series={initialCommercialShareValues}
          onHandleChangeValue={onHandleChangeShare}
          residentialVSCommercial={residentialVSCommercial}
          type="Commercial"
          header={
            <>
              <Text color="#000000" weight="wb">
                {t('estimate.body.p_13', 'Commercial:')}
              </Text>
              <Text color="#000000" weight="wr" className={styles.headerTotal}>
                {t('estimate.body.p_14', '(Total must equal 100%)')}
              </Text>{' '}
            </>
          }
          additionalText={
            <div style={{ textAlign: 'center' }}>
              <Text color="#837F7D" size="s14" lineHeight="h16">
                {t('estimate.body.p_15', 'Commercial')}
                <br />
                {t('estimate.body.p_16', 'share')}
              </Text>
            </div>
          }
          isReadOnlyInput={isReadOnlyInput}
        />
      </div>
    </div>
  );
};
EstimatedOfServicesSection.propTypes = {
  estimateData: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  blockHistory: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  residentialVSCommercial: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  isReadOnlyInput: PropTypes.bool,
};
EstimatedOfServicesSection.defaultProps = {
  isReadOnlyInput: false,
};
export default EstimatedOfServicesSection;
