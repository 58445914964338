import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { formatDate } from 'utils/dateFormatters';
import { shortAddress } from 'utils/shortAddress';
import { usePaymentStatusLocal } from 'localization';
import styles from './style.module.css';

const TableMobile = ({
  paymentDate,
  invoiceId,
  address,
  amount,
  paymentStatus: payStatus,
}) => {
  const { t } = useTranslation();
  const paymentStatusLocal = usePaymentStatusLocal();
  return (
    <div className={styles.wrapper}>
      <div className={styles.memberBlock}>
        <div className={styles.leftBlock}>
          <Text
            weight="wb"
            lineHeight="h20"
            size="s16"
            color="#000000"
            tag="p"
            className={styles.text}
          >
            {t('finance.mobile.cardTitle', 'Roof Project')}
          </Text>
          <Text
            lineHeight="h20"
            size="s16"
            color="#000000"
            tag="p"
            className={styles.text}
          >
            {invoiceId}
          </Text>
          <Text
            lineHeight="h20"
            size="s14"
            color="#949494"
            tag="p"
            className={styles.text}
          >
            {shortAddress(address)}
          </Text>
        </div>
        <div className={styles.rightBlock}>
          {payStatus && (
            <Text
              weight="wb"
              lineHeight="h20"
              size="s14"
              tag="p"
              className={[styles.text, styles.status].join(' ')}
            >
              {paymentStatusLocal[payStatus]}
            </Text>
          )}
          <Text
            lineHeight="h20"
            size="s14"
            color="#949494"
            tag="p"
            className={styles.text}
          >
            {formatDate(paymentDate)}
          </Text>
          <Text
            weight="wb"
            lineHeight="h20"
            size="s14"
            color="#00000D"
            tag="p"
            className={styles.text}
          >
            ${Intl.NumberFormat().format(amount)}
          </Text>
        </div>
      </div>
    </div>
  );
};
TableMobile.propTypes = {
  paymentDate: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  paymentStatus: PropTypes.string.isRequired,
};
TableMobile.defaultProps = {};
export default TableMobile;
