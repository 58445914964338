export default (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace('+1', '').replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export const phoneNumberFormatWithCode = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace('+1', '').replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return null;
};

export function cleanNumber(phoneNumber) {
  let match = false;
  if (phoneNumber?.length === 10) {
    match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  } else {
    const cleaned = `${phoneNumber}`.replace(/^(\+1|1)/g, '').replace(/\D/g, '');
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  }
  if (match) {
    return `${match[1]}${match[2]}${match[3]}`;
  }
  return null;
}
export function withBracketsNumber(phoneNumber) {
  let match = false;
  if (phoneNumber?.length === 10) {
    match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  } else {
    const cleaned = `${phoneNumber}`.replace(/^(\+1|1)/g, '').replace(/\D/g, '');
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  }
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}
