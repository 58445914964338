import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from './style.module.css';
import Text from '../../Text';

const AccountMenuModal = ({ isShow, handleClose }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: `${isShow ? 'block' : 'none'}` }}>
      <div className={[styles.wrapper, styles['slide-bottom']].join(' ')}>
        <NavLink
          exact
          to="/my-account"
          activeClassName={styles.activeLink}
          onClick={handleClose}
        >
          <Text
            color="#837f7d"
            weight="wr"
            size="s17"
            tag="h2"
            lineHeight="h21"
            className={styles.accountTitle}
          >
            {t('accountMenuModal.myAccount', 'My Account')}
          </Text>
        </NavLink>
        <div className={styles.separationLine} />
        <NavLink
          exact
          to="/logout"
          activeClassName={styles.activeLink}
          onClick={handleClose}
        >
          <Text
            color="#837f7d"
            weight="wr"
            size="s17"
            tag="h2"
            lineHeight="h21"
            className={styles.logoutTitle}
          >
            {t('accountMenuModal.logout', 'Logout')}
          </Text>
        </NavLink>
      </div>

      <span className={[styles.triangle, styles['slide-bottom']].join(' ')} />
    </div>
  );
};

AccountMenuModal.propTypes = {
  isShow: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

AccountMenuModal.defaultProps = {
  isShow: false,
};
export default AccountMenuModal;
