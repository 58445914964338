import React from 'react';
import { useTranslation } from 'react-i18next';
import { useJobStatusLocal } from 'localization';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { formatDate } from 'utils/dateFormatters';
import colorStatus from 'utils/statusColor';
import Button from 'components/shared/Button';
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import styles from '../../tableStyles.module.css';
import pageStyles from '../../style.module.css';

const AvailableTableCard = ({
  location,
  reference,
  dateOfInstall,
  awardAmount,
  status,
  isSelected,
  isDisabled,
  handleClick,
  handleClaim,
  handleShowDetails,
  isAllowClaim,
}) => {
  const { t } = useTranslation();
  const jobStatusLocal = useJobStatusLocal();
  return (
    <>
      <div className={[styles.projectCardWrapper].join(' ')} onClick={handleClick}>
        <div className={styles.projectCardHeader}>
          <div onClick={handleShowDetails} className={styles.mobileTableRowFlex}>
            <Text color="#000" size="s14" weight="wb">
              #{reference}
            </Text>
            <AlertIcon
              style={{ width: '14px', height: '14px' }}
              className={styles.infoIcon}
            />
          </div>
          <div className={styles.statusWrapper}>
            <span
              className={[
                pageStyles.circle,
                pageStyles[`circle${colorStatus(status)}`],
              ].join(' ')}
            />
            <Text color="#000" size="s14" weight={isSelected ? 'wb' : 'wm'}>
              {jobStatusLocal[status]}
            </Text>
          </div>
        </div>
        <div className={styles.mobileTableRowContent}>
          <div>
            <div>
              <Text color="#000">
                {t('jobs.available.mapCard.mobile.date', 'Date of install:')}{' '}
                {dateOfInstall ? formatDate(dateOfInstall) : 'Not set yet'}
              </Text>
            </div>
            <div>
              <Text color="#767676">
                {t('jobs.available.mapCard.mobile.location', 'Location:')} {location}
              </Text>
            </div>
            {awardAmount !== null && (
              <div>
                <Text color="#767676">
                  {t('jobs.available.mapCard.mobile.amount', 'Award Amount:')}{' '}
                  {awardAmount >= 0
                    ? `$ ${Intl.NumberFormat().format(awardAmount)}`
                    : null}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
      {isAllowClaim && (
        <div className={styles.projectCardFooter}>
          <Button
            height="small"
            textSize="small"
            disabled={isDisabled}
            handleClick={handleClaim}
          >
            {isDisabled
              ? t('jobs.available.mapCard.mobile.claimed', 'Claimed')
              : t('jobs.available.mapCard.mobile.claim', 'Claim')}
          </Button>
        </div>
      )}
    </>
  );
};

AvailableTableCard.propTypes = {
  location: PropTypes.string,
  reference: PropTypes.string.isRequired,
  dateOfInstall: PropTypes.string,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  status: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  handleClaim: PropTypes.func,
  handleShowDetails: PropTypes.func.isRequired,
  awardAmount: PropTypes.number,
  isAllowClaim: PropTypes.bool,
};

AvailableTableCard.defaultProps = {
  dateOfInstall: '',
  isSelected: false,
  isDisabled: false,
  location: '',
  handleClick: () => {},
  handleClaim: () => {},
  awardAmount: null,
  isAllowClaim: true,
};

export default AvailableTableCard;
