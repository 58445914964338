import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styles from './style.module.css';

const ErrorMessage = ({ children, handleClose, timeout }) => {

  useEffect(() => {
    const _timeout = setTimeout(() => {
      handleClose();
    }, timeout);

    return () => {
      clearTimeout(_timeout);
    };
  });

  return (
    <div className={[styles.wrapper, styles['slide-bottom']].join(' ')}>
      <div className={styles.message}>
        {children}
      </div>
      <div className={styles.closeButton} onClick={handleClose}/>
    </div>
  );
};

export default ErrorMessage;

ErrorMessage.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func,
  timeout: PropTypes.number,
};

ErrorMessage.defaultProps = {
  children: null,
  handleClose: null,
  timeout: null,
};
