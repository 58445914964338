import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

export const secondaryIconProps = {
  width: '15px',
  height: '15px',
  className: styles.secondaryIcon,
};

export const defaultIconProps = {
  className: styles.orangeIcon,
};

const IconSVG = ({ src, width, height = 'auto', className, handleClick }) => (
  <span onClick={handleClick} style={{ userSelect: 'none' }}>
    <img src={src} className={className} alt="icon" style={{ width, height }} />
  </span>
);

IconSVG.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
};

IconSVG.defaultProps = {
  className: '',
  handleClick: () => {},
  height: 'auto',
  width: 'auto',
};

export default IconSVG;
