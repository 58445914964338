export const ZOOM_FOR_COUNTRY = 4;
export const ZOOM_FOR_STATE = 7;
export const ZOOM_FOR_COUNTY = 9;
export const ZOOM_FOR_CITY = 12;
export const ZOOM_FOR_ZIP_CODE = 14;

export const LEVEL_FOR_STATE = 1;
export const LEVEL_FOR_COUNTY = 2;
export const LEVEL_FOR_CITY = 3;

export const USA_MAP_CENTER = {
  lat: 39.5,
  lng: -98.35,
};
