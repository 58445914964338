import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { topValueErrorsMyApplication } from 'enums/errors/topValueErrors';

const useErrorEstimation = (errors, touched) => {
  useEffect(() => {
    if (!isEmpty(errors) && !isEmpty(touched)) {
      const errorsTop = sortBy(
        Object.keys(errors).map((key) => {
          return topValueErrorsMyApplication[key];
        }),
        'weight'
      );
      window.scrollTo({ top: errorsTop[0]?.top, behavior: 'smooth' });
    }
  });
};

export default useErrorEstimation;
