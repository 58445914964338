import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

const useErrorCertifications = (myCertification, touched) => {
  useEffect(() => {
    if (myCertification?.message && !isEmpty(touched)) {
      if (myCertification?.message?.includes('provided')) {
        window.scrollTo({
          top: myCertification?.message?.includes('Other') ? 1300 : 700,
          behavior: 'smooth',
        });
      }
      if (myCertification?.message?.includes('unique')) {
        window.scrollTo({
          top: myCertification?.message?.includes('not') ? 700 : 1300,
          behavior: 'smooth',
        });
      }
    }
  });
};

export default useErrorCertifications;
