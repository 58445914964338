import gql from 'graphql-tag';
import { CompanyData } from 'graphql/fragments/companyProfile.fragment';
import { Qualification } from 'graphql/fragments/qualification.fragment';

export const GET_COMPANY_DATA = gql`
  query getCompanyData {
    getCompanyData {
      companyData {
        ...FullCompanyData
      }
      qualification {
        ...FullQualification
      }
    }
  }
  ${CompanyData.fragments.FullCompanyData}
  ${Qualification.fragments.FullQualification}
`;
