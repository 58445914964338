import { useTranslation } from 'react-i18next';

export const useTopicOptions = () => {
  const { t } = useTranslation();
  return [
    {
      label: t('rescheduleModal.selectTopic.weather', 'Weather'),
      value: 'Weather',
    },
    {
      label: t('rescheduleModal.selectTopic.another', 'Another Job Runover'),
      value: 'Another Job Runover',
    },
    {
      label: t('rescheduleModal.selectTopic.crew', 'Crew Issue'),
      value: 'Crew Issue',
    },
    {
      label: t('rescheduleModal.selectTopic.unresponsive', 'Homeowner Unresponsive'),
      value: 'Homeowner Unresponsive',
    },
    {
      label: t('rescheduleModal.selectTopic.access', 'Roof Access Issues'),
      value: 'Roof Access Issues',
    },
  ];
};
