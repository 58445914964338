import { formatDate } from 'utils/dateFormatters';
import { useTranslation } from 'react-i18next';
import { usePaymentStatusLocal } from 'localization';

export const useTableColumns = () => {
  const { t } = useTranslation();
  return [
    {
      Header: t('finance.pending.table.incomeNumb', 'Invoice #'),
      accessor: 'invoice.invoice',
    },
    {
      Header: t('finance.pending.table.jobReference', 'Job #'),
      accessor: 'opportunity.idCounter',
      Cell: (row) => `J-${row.value}`,
    },
    {
      Header: t('finance.pending.table.date', 'Payment due date'),
      accessor: 'paymentDueDate',
      Cell: (row) => formatDate(row.value),
    },

    {
      Header: t('finance.pending.table.status', 'Status'),
      accessor: 'paymentStatus',
      Cell: (row) => usePaymentStatusLocal()[row.value],
    },
    {
      Header: t('finance.pending.table.income', 'Income'),
      accessor: (el) => `$${Intl.NumberFormat().format(el.amount)}`,
    },
  ];
};
