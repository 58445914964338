import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TableMapViewLayout from 'layouts/TableMapView';
import { AWARDED_OPPORTUNITIES } from 'graphql/queries/opportunities';
import { GET_MEMBERS } from 'graphql/queries/members';
import Spinner from 'components/shared/Spinner';
import useModal from 'services/hooks/useModal';
import { roles } from 'enums';
import { isAllowAction } from 'services/permissions';
import tableColumns from './columns';
import ProjectCard from './ProjectCard';
import RescheduleJobModal from '../../AwardedJobDetailsPage/RescheduleJobModal';
import TableCardMobile from './TableCardMobile';
import tableStyles from '../tableStyles.module.css';

const AwardedJobsTab = ({ history, userCurrentRole, ...props }) => {
  const { data: membersList, loading: loadMembers } = useQuery(GET_MEMBERS);
  const [selectedJobId, setSelectedJobId] = useState('');
  const { isShow, handleClose, handleOpen } = useModal();
  const { data: loadedData, loading } = useQuery(AWARDED_OPPORTUNITIES);
  const isAllowActions = isAllowAction(
    [roles.admin, roles.finance, roles.superintendent],
    userCurrentRole
  );

  const locations = useMemo(
    () =>
      loadedData?.workOrders.map((object) => ({
        id: object.sfId,
        ...object.opportunity.location,
      })),
    [loadedData]
  );

  const handleShowDetails = ({ sfId }) => {
    history.push(`/jobs/awarded/${sfId}`);
  };

  const handleReschedule = ({ sfId }) => {
    setSelectedJobId(sfId);
    handleOpen();
  };
  const { t } = useTranslation();
  const columnTitles = {
    jobReference: t('jobs.awarded.table.refTitle', 'Job Reference'),
    installDate: t('jobs.awarded.table.dateTitle', 'Date of install'),
    location: t('jobs.awarded.table.locationTitle', 'Location'),
    permit: t('jobs.awarded.table.permitTitle', 'Permit #'),
    stat: t('jobs.awarded.table.statusTitle', 'Status'),
    action: t('jobs.awarded.table.actionTitle', 'Action'),
    actionText: t('jobs.awarded.table.actionText', 'Reschedule'),
    notRequired: t('jobs.awarded.table.notRequired', 'Not Required'),
  };

  if (loading || loadMembers) return <Spinner absolute />;

  return (
    <>
      <TableMapViewLayout
        {...props}
        searchValues={['reference', 'opportunity.account.address']}
        dataList={loadedData?.workOrders}
        tableColumns={tableColumns({
          handleReschedule,
          handleClickColumn: handleShowDetails,
          isAllowActions,
          columnTitles,
        })}
        mapOptions={{
          isIncludeZone: false,
          centerLocation: { lat: locations?.[0]?.lat, lng: locations?.[0]?.lng },
          zoomLevel: 9,
          locations,
          isShowMarker: true,
        }}
        searchPlaceholder={t(
          'jobs.awarded.searchPlaceholder',
          'Search by reference or address'
        )}
        emptyPageText={t(
          'jobs.awarded.emptyPage',
          'Please check back later for newly awarded jobs.'
        )}
        tableOptions={{
          mobileTableRowClassName: [
            tableStyles.mobileTableRow,
            tableStyles.mobileTableRowSecondary,
            tableStyles.mobileTableRowVertical,
          ].join(' '),
          mobileContent: ({ data }, index) => {
            return (
              <TableCardMobile
                {...data}
                key={index}
                location={`${data.opportunity.account?.name}, ${data.opportunity.account?.shortAddress}`}
                dateOfInstall={data.dateOfInstall}
                handleClick={() => handleShowDetails(data)}
                handleClaim={() => handleReschedule(data)}
              />
            );
          },
        }}
        membersList={membersList?.members}
        userCurrentRole={userCurrentRole}
      >
        {({ handleSelectLocation, locations: selectedLocations, data }) => {
          return (
            <>
              {data.map((project, index) => (
                <ProjectCard
                  {...project}
                  key={index}
                  location={project.opportunity.account?.address}
                  handleClick={() => handleShowDetails(project)}
                  handleSelect={(value) => {
                    handleSelectLocation(
                      {
                        id: project.sfId,
                        ...project.opportunity.location,
                      },
                      value
                    );
                  }}
                  isSelected={selectedLocations.find(
                    ({ id, isSelected }) => id === project.sfId && isSelected
                  )}
                />
              ))}
            </>
          );
        }}
      </TableMapViewLayout>
      <RescheduleJobModal isShow={isShow} handleClose={handleClose} id={selectedJobId} />
    </>
  );
};

AwardedJobsTab.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.object,
  userCurrentRole: PropTypes.string.isRequired,
};

AwardedJobsTab.defaultProps = {
  data: {},
};

export default AwardedJobsTab;
