import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Footer from 'components/shared/Footer';
import { GET_CURRENT_USER } from 'graphql/cache';
import Header from './Header';
import Sidebar from './Sidebar';
import styles from './style.module.css';

function LayoutWithSidebar({ children }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const { data: userData } = useQuery(GET_CURRENT_USER);
  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflowY = 'hidden';
    } else document.body.style.overflowY = 'auto';
  }, [showSidebar]);

  return (
    <div className={styles.templateWrapper}>
      <Header setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
      <div className={styles.containerMenu}>
        <Sidebar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          userData={userData}
        />
        {showSidebar && (
          <div className={styles.popupBackground} onClick={() => setShowSidebar(false)} />
        )}
        <div className={[styles.containerMain].join(' ')}>{children}</div>
      </div>

      <Footer />
    </div>
  );
}

LayoutWithSidebar.propTypes = {
  children: PropTypes.node,
};
LayoutWithSidebar.defaultProps = {
  children: null,
};
export default LayoutWithSidebar;
