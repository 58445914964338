import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const MobileTable = ({
  getTableProps,
  tableRows,
  prepareRow,
  hasRowClick,
  onRowClick,
  children,
  mobileTableRowClassName,
}) => {
  return (
    <div {...getTableProps()} className={styles.mobileTableWrapper}>
      {tableRows.map(
        (row) =>
          prepareRow(row) || (
            <div
              {...row.getRowProps()}
              onClick={() => (hasRowClick ? onRowClick(row.original) : null)}
              className={mobileTableRowClassName || styles.mobileTableRow}
            >
              {children({ data: row.original, ...row })}
            </div>
          )
      )}
    </div>
  );
};

MobileTable.propTypes = {
  getTableProps: PropTypes.func.isRequired,
  headerGroups: PropTypes.array.isRequired,
  tableRows: PropTypes.array.isRequired,
  prepareRow: PropTypes.func.isRequired,
  tableFixed: PropTypes.bool,
  onRowClick: PropTypes.func,
  hasRowClick: PropTypes.bool,
  children: PropTypes.func.isRequired,
  mobileTableRowClassName: PropTypes.string,
};

MobileTable.defaultProps = {
  tableFixed: false,
  hasRowClick: false,
  onRowClick: () => {},
  mobileTableRowClassName: '',
};

export default MobileTable;
