import ApolloClient from 'apollo-client';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import authUtils from 'utils/auth';
import typeDefs from './schemas';
import resolvers from './resolvers';

const middleWareLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.files) {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
    // eslint-disable-next-line no-param-reassign
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation);
});

function getOptions() {
  let headers = {};
  const token = authUtils.getToken();
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const cache = new InMemoryCache({
    dataIdFromObject: (object) =>
      object.pgId || object.sfId || defaultDataIdFromObject(object),
  });

  return {
    link: ApolloLink.from([
      middleWareLink,
      onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
          const unauthorizedError = graphQLErrors.find((e) =>
            e.message.endsWith('Unauthorized')
          );

          if (unauthorizedError) {
            window.location.replace('/logout');
          }
        }
      }),
      createUploadLink({
        uri: `${process.env.REACT_APP_API_URI}/graphql`,
        headers,
        credentials: 'include',
      }),
    ]),
    cache,
    typeDefs,
    resolvers,
    connectToDevTools: process.env.NODE_ENV === 'development',
  };
}

const client = new ApolloClient(getOptions());

export default client;
