export async function toBase64UploadedFiles(files) {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const base64Files = await Promise.all(
    files.map(async (item) => {
      return toBase64(item);
    })
  );
  const uploadedFiles = files.map(({ name }, index) => ({
    file: base64Files[index],
    name,
  }));
  return uploadedFiles;
}
