import gql from 'graphql-tag';

export const PAYMENT_DATA_DASHBOARD = gql`
  query getPaymentDataDashboard {
    paymentDataDashboard {
      paymentDate
      amount
    }
  }
`;
export const PAYMENT_DATA_FINANCE = gql`
  query getPaymentDataFinance {
    paymentDataFinance {
      paymentDate
      paymentDueDate
      amount
      paymentStatus
      invoice {
        sfId
        invoice
      }
      opportunity {
        idCounter
      }
    }
  }
`;
