import { isPolicyUnique } from 'utils/application/isPolicyUnique';

export const isError = (
  errors,
  files,
  policyNumber,
  touched,
  index,
  myCertification,
  type
) => {
  const checkPolicyFilled =
    errors.message?.includes('Policy') &&
    !!files.length &&
    !policyNumber &&
    touched[type]?.[`docs_${index}`]?.policyNumber;

  const checkUniquePolicy =
    isPolicyUnique(myCertification, policyNumber) &&
    touched[type]?.[`docs_${index}`]?.policyNumber &&
    policyNumber;

  return checkPolicyFilled || checkUniquePolicy;
};
