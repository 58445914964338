import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageContext from 'context/LanguageContext';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormColumn as Col } from 'components/shared/Form';
import Field from 'components/shared/Field';
import Datepicker from 'components/shared/Datepicker';
import { isDisabled } from 'utils/application/checkIsDisabled';
import { isError } from 'utils/application/isError';
import styles from './style.module.css';
import pageStyles from '../style.module.css';

const DocumentDescription = ({
  policyNumber,
  carrier,
  expirationDate,
  policyName,
  carrierName,
  expirationName,
  handleChange,
  errors,
  touched,
  status,
  index,
  files,
  type,
  myCertification,
}) => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  return (
    <div className={pageStyles.columnWrapper}>
      <Col>
        <Field
          name={policyName}
          label={
            isError(errors, files, policyNumber, touched, index, myCertification, type) &&
            errors.message?.includes('Each') ? (
              <span className={styles.errorLabel}>
                {t(
                  'myApplication.thirdTab.documents.policyError',
                  'Policy # should be unique'
                )}
              </span>
            ) : (
              t('myApplication.thirdTab.documents.policyLabel', 'Policy #')
            )
          }
          type="text"
          value={policyNumber}
          onChange={handleChange}
          isError={isError(
            errors,
            files,
            policyNumber,
            touched,
            index,
            myCertification,
            type
          )}
        />
      </Col>
      <Col>
        <label className={pageStyles.label}>
          {t('myApplication.thirdTab.documents.dateLabel', 'Expiration Date')}
        </label>
        <Datepicker
          value={expirationDate}
          name={expirationName}
          locale={language}
          handleChange={(date) => {
            handleChange({
              target: {
                value: moment(date).format('MM/DD/YYYY'),
                name: expirationName,
              },
            });
          }}
          customDatePickerClass={styles.datepickerInput}
          isDisabled={isDisabled(status)}
        />
      </Col>
      <Col>
        <Field
          label={t('myApplication.thirdTab.documents.carrierLabel', 'Carrier')}
          type="text"
          name={carrierName}
          value={carrier}
          onChange={handleChange}
          isDisabled={isDisabled(status)}
        />
      </Col>
    </div>
  );
};
DocumentDescription.propTypes = {
  policyNumber: PropTypes.string,
  carrier: PropTypes.string,
  expirationDate: PropTypes.string,
  policyName: PropTypes.string.isRequired,
  carrierName: PropTypes.string.isRequired,
  expirationName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  myCertification: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  status: PropTypes.string,
  files: PropTypes.array,
  index: PropTypes.number,
};
DocumentDescription.defaultProps = {
  errors: {},
  index: 0,
  touched: {},
  files: [],
  status: 'Draft',
  policyNumber: '',
  carrier: '',
  expirationDate: '',
};
export default DocumentDescription;
