import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_ATTACHMENTS, DOWNLOAD_FILES_DATA } from 'graphql/queries/file';

export default ({ contentDocumentId, entityName }) => {
  const { data, loading } = useQuery(GET_ATTACHMENTS, {
    variables: {
      contentDocumentId,
      entityName,
    },
  });

  const [
    downloadFilesData,
    { data: downloadedData, loading: loadingDownload },
  ] = useLazyQuery(DOWNLOAD_FILES_DATA, {
    onCompleted: () => {
      if (!downloadedData?.downloadFile) return null;
      const link = document.createElement('a');
      link.setAttribute(
        'href',
        `data:${downloadedData?.downloadFile?.type};base64,${downloadedData?.downloadFile?.base64String}`
      );
      link.setAttribute('download', downloadedData?.downloadFile?.name);
      return link.click();
    },
  });

  const handleDownloadFile = (id) => {
    return downloadFilesData({
      variables: {
        contentDocumentId: id,
      },
    });
  };
  return {
    loading,
    files: data?.files || [],
    handleDownload: handleDownloadFile,
    loadingDownload,
  };
};
