import { useMutation } from '@apollo/react-hooks';
import { useState } from 'react';
import { authCallback } from 'utils/authCallback';
import { SIGNIN_MUTATION } from '../../graphql/mutations/auth';
import { useFormatAuthMessage } from '../../utils/graphQLErrorFormatters';

export function useAuth() {
  const [signInUser] = useMutation(SIGNIN_MUTATION);

  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { formatAuthMessage } = useFormatAuthMessage();

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await signInUser({
        variables: {
          email: values.email,
          password: values.password,
          reCAPTCHAToken: values.reCAPTCHAToken,
        },
        update: (cache, { data }) => {
          localStorage.setItem('logged', 'true');
          authCallback({
            token: data.signInUser.token,
            isKeepLogged: values.isKeepLogged,
          });
        },
      });
    } catch (e) {
      setLoading(false);
      const errorMessage = formatAuthMessage(e?.message);
      setError(errorMessage);
    }
  };
  return { isLoading, error, handleSubmit };
}
