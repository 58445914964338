/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { withRouter } from 'react-router-dom';
import { Component } from 'react';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}
export default withRouter(ScrollToTop);
