import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { RemoveScroll } from 'react-remove-scroll';
import moment from 'moment';
import InputMask from 'react-input-mask';
import {
  FormColumn as Column,
  FormContent,
  FormRow as Row,
} from 'components/shared/Form';
import LanguageContext from 'context/LanguageContext';
import Datepicker from 'components/shared/Datepicker';
import Field from 'components/shared/Field';
import Text from 'components/shared/Text';
import IconSVG from 'components/shared/IconSVG';
import { warningIcon } from 'assets/icons';
import { useOrganizations, states } from 'enums';
import SectionHeader from 'components/shared/SectionHeader';
import MobileContext from 'context/MobileContext';
import { isDisabled } from 'utils/application/checkIsDisabled';
import isError from 'services/hooks/useErrors/useErrorForms';
import Spinner from 'components/shared/Spinner';
import styles from './styles.module.css';

const MyAccountPart = ({
  values: { myAccount },
  errors,
  touched,
  handleChange,
  loadCreateApp,
  appStatus,
}) => {
  const isMobile = useContext(MobileContext);
  isError(errors, touched, 'myAccount');

  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const organizations = useOrganizations();
  return (
    <div className={styles.formWrapper}>
      {loadCreateApp && (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      <SectionHeader text={t('myApplication.firstTab.title', 'Details')} />
      <div className={styles.warningSection}>
        <IconSVG src={warningIcon} height="18px" width="18px" />
        <Text
          tag="p"
          color="#CF4B4B"
          className={styles.errorMessage}
          weight="wb"
          size="s14"
          lineHeight="h16"
        >
          {t(
            'myApplication.firstTab.p_1',
            'Unless marked optional, all fields are mandatory to submit your application.'
          )}{' '}
        </Text>
      </div>
      <Text
        tag="p"
        color="#292929"
        size="s16"
        lineHeight="h19"
        className={styles.hintMessage}
      >
        {t(
          'myApplication.firstTab.p_2',
          'Update your profile and complete your registration:'
        )}
      </Text>

      <FormContent>
        <Row>
          <Column>
            <Field
              label={t('myApplication.firstTab.legalLabel', 'Company Name (Legal)')}
              type="text"
              inputMode="text"
              name="companyName"
              value={myAccount.companyName}
              isError={
                touched.myAccount?.companyName && Boolean(errors.myAccount?.companyName)
              }
              onChange={handleChange}
              isMarked={
                !errors.myAccount?.companyName &&
                myAccount.companyName &&
                !isDisabled(appStatus)
              }
            />
          </Column>
          <Column>
            <Field
              label={t('myApplication.firstTab.DBALabel', 'Company Name (DBA)')}
              type="text"
              inputMode="text"
              name="companyNameDBA"
              value={myAccount.companyNameDBA}
              isError={
                touched.myAccount?.companyNameDBA &&
                Boolean(errors.myAccount?.companyNameDBA)
              }
              onChange={handleChange}
              isMarked={
                !errors.myAccount?.companyNameDBA &&
                myAccount.companyNameDBA &&
                !isDisabled(appStatus)
              }
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Field
              label={t('myApplication.firstTab.typeLabel', 'Company Type')}
              type="select"
              name="organization"
              value={myAccount.organization}
              onChange={handleChange}
              options={organizations}
              isError={
                touched.myAccount?.organization && Boolean(errors.myAccount?.organization)
              }
              isDisabled={isDisabled(appStatus)}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Field
              label={t(
                'myApplication.firstTab.firstLabel',
                'Registered Agent First Name'
              )}
              type="text"
              inputMode="text"
              name="ownerFirstName"
              value={myAccount.ownerFirstName}
              onChange={handleChange}
              isError={
                touched.myAccount?.ownerFirstName &&
                Boolean(errors.myAccount?.ownerFirstName)
              }
              isMarked={
                !errors.myAccount?.ownerFirstName &&
                myAccount.ownerFirstName &&
                !isDisabled(appStatus)
              }
            />
          </Column>
          <Column>
            <Field
              label={t('myApplication.firstTab.lastLabel', 'Registered Agent Last Name')}
              type="text"
              inputMode="text"
              name="ownerLastName"
              value={myAccount.ownerLastName}
              onChange={handleChange}
              isError={
                touched.myAccount?.ownerLastName &&
                Boolean(errors.myAccount?.ownerLastName)
              }
              isMarked={
                !errors.myAccount?.ownerLastName &&
                myAccount.ownerLastName &&
                !isDisabled(appStatus)
              }
            />
          </Column>
        </Row>
        {!isMobile && (
          <Row>
            <br />
          </Row>
        )}
        <Row>
          <Column>
            <Field
              label={t('myApplication.firstTab.addressLabel', 'Company Address')}
              type="text"
              inputMode="text"
              name="companyAddress"
              value={myAccount.companyAddress}
              onChange={handleChange}
              isError={
                touched.myAccount?.companyAddress &&
                Boolean(errors.myAccount?.companyAddress)
              }
              isMarked={
                !errors.myAccount?.companyAddress &&
                myAccount.companyAddress &&
                !isDisabled(appStatus)
              }
            />
          </Column>
          <Column>
            <Field
              label={t('myApplication.firstTab.cityLabel', 'City')}
              type="text"
              inputMode="text"
              name="city"
              value={myAccount.city}
              onChange={handleChange}
              isError={touched.myAccount?.city && Boolean(errors.myAccount?.city)}
              isMarked={
                !errors.myAccount?.city && myAccount.city && !isDisabled(appStatus)
              }
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Field
              label={t('myApplication.firstTab.zipLabel', 'Zip Code')}
              type="text"
              inputMode="text"
              name="zipCode"
              value={myAccount.zipCode}
              onChange={handleChange}
              isError={touched.myAccount?.zipCode && Boolean(errors.myAccount?.zipCode)}
              isMarked={
                !errors.myAccount?.zipCode && myAccount.zipCode && !isDisabled(appStatus)
              }
            />
          </Column>
          <Column>
            <Field
              label={t('myApplication.firstTab.stateLabel', 'State')}
              type="select"
              name="state"
              value={myAccount.state}
              onChange={handleChange}
              options={states}
              isError={
                touched.myAccount?.state &&
                Boolean(errors.myAccount?.state) &&
                myAccount.state
              }
              isDisabled={isDisabled(appStatus)}
            />
          </Column>
          <Column>
            <Field
              label={t('myApplication.firstTab.suiteLabel', 'Suite # (Optional)')}
              type="text"
              name="suiteNumber"
              value={myAccount.suiteNumber}
              onChange={handleChange}
              isError={
                touched.myAccount?.suiteNumber && Boolean(errors.myAccount?.suiteNumber)
              }
              isDisabled={isDisabled(appStatus)}
            />
          </Column>
        </Row>

        <Text
          color="#292929"
          size="s14"
          lineHeight="h20"
          tag="p"
          className={styles.notes}
        >
          {t(
            'myApplication.firstTab.body.p_1',
            'RoofClaim.com will send all account related communications to the below email address, including application status, awarded jobs, notifications regarding crew, etc.'
          )}
        </Text>

        <Row>
          <Column>
            <Field
              label={t('myApplication.firstTab.primaryLabel', 'Primary Email Address')}
              type="email"
              inputMode="email"
              name="companyEmailAddress"
              value={myAccount.companyEmailAddress}
              onChange={handleChange}
              isError={
                touched.myAccount?.companyEmailAddress &&
                Boolean(errors.myAccount?.companyEmailAddress)
              }
              isMarked={
                !errors.myAccount?.companyEmailAddress &&
                myAccount.companyEmailAddress &&
                !isDisabled(appStatus)
              }
            />
          </Column>
          <Column>
            <InputMask
              mask="(999) 999-9999"
              value={myAccount.companyPhoneNumber}
              onChange={({ target }) => {
                handleChange({
                  target: {
                    name: target.name,
                    value: target.value.replace(/\D/g, ''),
                  },
                });
              }}
            >
              {() => (
                <Field
                  label={t('myApplication.firstTab.phoneLabel', 'Company Phone Number')}
                  type="tel"
                  inputMode="tel"
                  autoComplete="tel"
                  name="companyPhoneNumber"
                  isError={
                    touched.myAccount?.companyPhoneNumber &&
                    Boolean(errors.myAccount?.companyPhoneNumber)
                  }
                  isMarked={
                    myAccount.companyPhoneNumber &&
                    !errors.myAccount?.companyPhoneNumber &&
                    !isDisabled(appStatus)
                  }
                />
              )}
            </InputMask>
          </Column>
        </Row>
        <Row>
          <Column>
            <InputMask mask="999999999" value={myAccount.taxID} onChange={handleChange}>
              {() => (
                <Field
                  label={t('myApplication.firstTab.taxlLabel', 'Tax ID (TIN, 9 digits)')}
                  type="text"
                  name="taxID"
                  isError={touched.myAccount?.taxID && Boolean(errors.myAccount?.taxID)}
                  isMarked={
                    myAccount.taxID && !errors.myAccount?.taxID && !isDisabled(appStatus)
                  }
                />
              )}
            </InputMask>
          </Column>
        </Row>
        {!isMobile && (
          <Row>
            <br />
          </Row>
        )}

        <div className={styles.columnWrapper}>
          <Column>
            <Field
              label={t(
                'myApplication.firstTab.businessLabel',
                'Business Registration State'
              )}
              type="select"
              name="registrationState"
              value={myAccount.registrationState}
              onChange={handleChange}
              options={states}
              isError={
                touched.myAccount?.registrationState &&
                Boolean(errors.myAccount?.registrationState) &&
                myAccount.registrationState
              }
              isDisabled={isDisabled(appStatus)}
            />
          </Column>
          <Column>
            <div className={styles.dateContainer}>
              <label className={styles.label}>
                {t('myApplication.firstTab.yearLabel', 'Date Business Started')}
              </label>

              <Datepicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                isMyApplication
                name="yearCompanyStarted"
                value={myAccount.yearCompanyStarted}
                locale={language}
                handleChange={(date) => {
                  handleChange({
                    target: {
                      value: moment(date).format('MM/DD/YYYY'),
                      name: 'yearCompanyStarted',
                    },
                  });
                }}
                customDatePickerClass={styles.datepickerInput}
                isDisabled={isDisabled(appStatus)}
              />
            </div>
          </Column>
          <Column>
            <Field
              label={t(
                'myApplication.firstTab.dunnLabel',
                'Dunn & Bradstreet # (Optional)'
              )}
              type="text"
              inputMode="text"
              name="dunBradstreet"
              value={myAccount.dunBradstreet}
              onChange={handleChange}
            />
          </Column>
        </div>
      </FormContent>
    </div>
  );
};

MyAccountPart.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  appStatus: PropTypes.string.isRequired,
  loadCreateApp: PropTypes.bool.isRequired,
};

export default MyAccountPart;
