import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import CompanyProfile from 'components/CompanyProfile';
import { DOWNLOAD_FILES_DESCRIPTION } from 'graphql/queries/file';
import { UPDATE_COMPANY_DATA_MUTATION } from 'graphql/mutations/companyProfile';
import {
  restructureCertificationData,
  checkIsNewFilesUpload,
} from 'utils/application/restructureData/restructureCertificationData';
import { companyProfileSchema } from 'validation/companyProfile';
import { updateCompanyCache } from 'graphql/cache/companyProfile';
import Button from 'components/shared/Button';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import useModal from 'services/hooks/useIsShowComponent';
import { initialValues } from './initialValues';
import styles from './styles.module.css';

const CompanyProfileContainer = ({ data }) => {
  const {
    data: { downloadFilesDescription } = { downloadFilesDescription: [] },
    loading: loadingAttachments,
    refetch: refetchDocuments,
  } = useQuery(DOWNLOAD_FILES_DESCRIPTION, {
    variables: {
      data: data?.qualification?.map(({ sfId }) => sfId),
    },
  });

  const [updateCompanyData, { loading }] = useMutation(UPDATE_COMPANY_DATA_MUTATION);

  const history = useHistory();
  const [path, setPath] = useState('');

  const { isShow: isOpenSaveModal, handleClick: handleClickSaveModal } = useModal(false);

  function blockHistory() {
    history.block(({ pathname }) => {
      setPath(pathname);
      handleClickSaveModal();
      return false;
    });
  }

  function unBlockHistory() {
    history.block(() => {});
  }

  useEffect(() => {
    history.listen(() => {
      unBlockHistory();
    });
  }, [history]);

  const _handleSubmit = async ({
    organization,
    services,
    coverage,
    definedWorkAreas,
    myCertification,
  }) => {
    unBlockHistory();

    await updateCompanyData({
      variables: {
        data: {
          organization,
          services,
          coverage,
          definedWorkAreas,
          myCertification: await restructureCertificationData(myCertification),
        },
      },
      update: (cache, { data: companyData }) => {
        if (checkIsNewFilesUpload(myCertification)) {
          refetchDocuments();
        }
        updateCompanyCache(companyData);
      },
    });
  };
  const { t } = useTranslation();
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues(data, downloadFilesDescription)}
      validationSchema={companyProfileSchema}
      onSubmit={_handleSubmit}
    >
      {({ handleChange, ...formikProps }) => {
        const _handleChange = (name, { target }) => {
          handleChange({
            target: { name: `${name}.${target.name}`, value: target.value },
          });
          blockHistory();
          formikProps.setTouched({}, false);
        };
        return (
          <fieldset disabled={loading}>
            <CompanyProfile
              {...formikProps}
              handleChange={_handleChange}
              blockHistory={blockHistory}
              qualificationSfId={data?.qualification?.map((item) => item.sfId)}
              loadingAttachments={loadingAttachments}
              isShowSaveChangesTooltip={loading}
              handleCancel={() => {
                unBlockHistory();
                if (path === 'reload') {
                  history.go();
                } else {
                  history.go(0);
                }
              }}
            />
            <AcknowledgmentModal
              handleClose={() => {
                handleClickSaveModal();
              }}
              footer={
                <div className={styles.buttonContainer}>
                  <Button
                    size="medium"
                    handleClick={() => {
                      _handleSubmit(formikProps.values);
                      handleClickSaveModal();
                    }}
                  >
                    {t('acknowledgmentModal.yes', 'Yes')}
                  </Button>

                  <Button
                    size="medium"
                    color="white"
                    handleClick={() => {
                      unBlockHistory();
                      if (path === 'reload') {
                        history.go();
                      } else {
                        history.push(path);
                      }
                    }}
                    className={styles.noButton}
                  >
                    {t('acknowledgmentModal.no', 'No')}
                  </Button>
                </div>
              }
              title={t('acknowledgmentModal.title', 'What would you like to do?')}
              description={t(
                'acknowledgmentModal.companyProfile.description',
                'Do you want to save the modifications on the form?'
              )}
              isShow={isOpenSaveModal}
            />
          </fieldset>
        );
      }}
    </Formik>
  );
};
CompanyProfileContainer.propTypes = {
  data: PropTypes.object.isRequired,
};

CompanyProfileContainer.defaultProps = {};
export default CompanyProfileContainer;
