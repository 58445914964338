import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRolesLocal } from 'localization';
import MobileContext from 'context/MobileContext';
import Text from 'components/shared/Text';
import { withBracketsNumber } from 'utils/phoneNumberFormatter';
import styles from './styles.module.css';

const Row = ({ title, value }) => {
  const isMobile = useContext(MobileContext);

  return (
    <div className={styles.rowContainer}>
      <Text tag="h2" size="s14" weight="wr" lineHeight="h13" color="#686868">
        {title}
      </Text>
      <Text
        tag="h2"
        size={isMobile ? 's13' : 's14'}
        weight={isMobile ? 'wb' : 'wr'}
        lineHeight="h13"
        color="000000"
        className={styles.value}
      >
        {value}
      </Text>
    </div>
  );
};

Row.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

Row.defaultProps = {
  value: '',
};

const ViewMode = ({ user }) => {
  const { t } = useTranslation();
  const rolesLocal = useRolesLocal();

  const contactTitles = [
    {
      title: t('myAccount.details.viewMode.name', 'Full Name'),
      key: 'fullName',
      value: '',
    },
    {
      title: t('myAccount.details.viewMode.email', 'Email Address'),
      key: 'emailAddress',
      value: '',
    },
    {
      title: t('myAccount.details.viewMode.phone', 'Phone Number'),
      key: 'phoneNumber',
      value: '',
    },
    {
      title: t('myAccount.details.viewMode.role', 'Role'),
      key: 'contractorRole',
      value: '',
    },
  ];

  const contractorRole = rolesLocal[user.contractorRole];

  const { contact } = useMemo(() => {
    let contactResult = contactTitles;

    if (user) {
      const contactData = {
        fullName: `${user.firstName || ''} ${user.lastName || ''}`,
        emailAddress: `${user.email || ''}` || '',
        phoneNumber: withBracketsNumber(user.phone),
        contractorRole,
      };

      contactResult = contactTitles.map(({ title, key }) => ({
        title,
        key,
        value: contactData[key],
      }));

      return { contact: contactResult };
    }

    return { contact: contactResult };
  }, [user]);

  return (
    <div className={styles.wrapper}>
      {contact.map(({ title, key, value }) => (
        <Row title={title} key={key} value={value} />
      ))}
    </div>
  );
};

ViewMode.propTypes = {
  user: PropTypes.object.isRequired,
};

ViewMode.defaultProps = {};

export default ViewMode;
