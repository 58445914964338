import auth from './auth';
import api from '../services/api';

export function authCallback({ token, isKeepLogged }) {
  auth.setToken(token, isKeepLogged);
  api.setAuthTokenToHeader(token);
  // Do not use history (react-router) here!
  // replace on '/' in order to redirect to dashboard or my-application
  window.location.replace('/');
}
