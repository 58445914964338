import React, { useEffect } from 'react';

const ReCAPTCHA = () => {
  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  return <div className="g-recaptcha" data-action="signin" />;
};

export default ReCAPTCHA;
