import { useLazyQuery } from '@apollo/react-hooks';
import { DOWNLOAD_FILES_DATA } from 'graphql/queries/file';

export default () => {
  const [downloadFilesData, { data, loading }] = useLazyQuery(DOWNLOAD_FILES_DATA, {
    onCompleted: () => {
      if (!data?.downloadFile) return null;
      const link = document.createElement('a');
      link.setAttribute(
        'href',
        `data:${data?.downloadFile?.type};base64,${data?.downloadFile?.base64String}`
      );
      link.setAttribute('download', data?.downloadFile?.name);
      return link.click();
    },
  });

  const handleDownloadFile = (id) => {
    return downloadFilesData({
      variables: {
        contentDocumentId: id,
      },
    });
  };
  return {
    loading,
    handleDownload: handleDownloadFile,
  };
};
