import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import { useDetLang } from 'services/localization';
import FirstScreen from './firstScreen';
import SecondScreen from './secondScreen';
import ThirdScreen from './thirdScreen';
import styles from '../styles.module.css';

const MainContent = ({ handleClose, handleSubmit }) => {
  const { t } = useTranslation();
  const [confirmFirstScreen, setConfirmFirstScreen] = useState(false);
  const [confirmSecondScreen, setConfirmSecondScreen] = useState(false);
  const [confirmThirdScreen, setConfirmThirdScreen] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);

  const allConfirmed = [confirmFirstScreen, confirmSecondScreen, confirmThirdScreen];

  function openNextPage() {
    setPageNumber(pageNumber + 1);
  }
  function isNextButtonDisabled() {
    if (pageNumber === 1 && confirmFirstScreen) return false;
    if (pageNumber === 2 && confirmSecondScreen) return false;
    return true;
  }

  const buttonHeight = useDetLang('local', '');
  return (
    <div className={styles.mainContentWrapper}>
      {pageNumber === 1 ? (
        <FirstScreen
          confirmFirstScreen={confirmFirstScreen}
          setConfirmFirstScreen={setConfirmFirstScreen}
        />
      ) : null}
      {pageNumber === 2 ? (
        <SecondScreen
          confirmSecondScreen={confirmSecondScreen}
          setConfirmSecondScreen={setConfirmSecondScreen}
        />
      ) : null}
      {pageNumber === 3 ? (
        <ThirdScreen
          confirmThirdScreen={confirmThirdScreen}
          setConfirmThirdScreen={setConfirmThirdScreen}
        />
      ) : null}
      <div className={styles.buttonContainer}>
        {pageNumber !== 3 ? (
          <Button
            disabled={isNextButtonDisabled()}
            className={[styles.button, styles.confirmBut].join(' ')}
            handleClick={openNextPage}
            size="small"
            height={buttonHeight}
          >
            {t('nextPage', 'Next Page')}
          </Button>
        ) : null}
        {pageNumber === 3 ? (
          <Button
            disabled={allConfirmed.includes(false)}
            className={[styles.button, styles.confirmBut].join(' ')}
            handleClick={handleSubmit}
            size="small"
            height={buttonHeight}
          >
            {t('confirmButton', 'Confirm')}
          </Button>
        ) : null}
        <Button
          className={styles.button}
          handleClick={handleClose}
          size="small"
          height={buttonHeight}
        >
          {t('cancelButton', 'Cancel')}
        </Button>
      </div>
    </div>
  );
};

MainContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default MainContent;
