import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useJobStatusLocal } from 'localization';
import Text from 'components/shared/Text';
import WrapperBlock from 'components/shared/WrapperBlock';
import { FormColumn } from 'components/shared/Form';
import UploadFileArea from 'components/shared/UploadFile/container';
import styles from '../style.module.css';

const ReadViewCompleteSection = ({ files, status, commentRollover: comment }) => {
  const { t } = useTranslation();
  return (
    <WrapperBlock
      title={t('jobs.awarded.details.activity.status.readView.title', 'Status')}
      classNameMainWrapper={styles.wrapper}
    >
      <div className={styles.sectionContent}>
        <FormColumn>
          <Text color="#000" weight="wb">
            {t('jobs.awarded.details.activity.status.readView.status', 'Status:')}{' '}
          </Text>
          <Text color="#000">{useJobStatusLocal()[status]}</Text>
        </FormColumn>
        {comment && (
          <FormColumn>
            <Text color="#000" weight="wb">
              {t('jobs.awarded.details.activity.status.readView.note', 'Note:')}{' '}
            </Text>
            <Text color="#837F7D">{comment}</Text>
          </FormColumn>
        )}
        <UploadFileArea
          buttonText={t('uploadButton', 'Upload file(s)')}
          name="files"
          uploadFiles={files}
          handleChange={() => {}}
          maxCountDocument={5}
        />
      </div>
    </WrapperBlock>
  );
};

ReadViewCompleteSection.propTypes = {
  commentRollover: PropTypes.string,
  files: PropTypes.array,
  status: PropTypes.string.isRequired,
};

ReadViewCompleteSection.defaultProps = {
  commentRollover: '',
  files: [],
};

export default ReadViewCompleteSection;
