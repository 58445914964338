import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['cookie', 'localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_API_URI}/locales?lang={{lng}}`,
      init: {
        cache: 'default',
      },
    },
    fallbackLng: 'en',
    debug: false,
    whitelist: ['en', 'es'], // available languages for browser dector to pick from
    detection: langDetectorOptions,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
