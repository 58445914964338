import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGa from 'react-ga';
import { Switch, useHistory, withRouter } from 'react-router-dom';
import Platforms from 'platform-detect/os.mjs';
import { LoadScript } from '@react-google-maps/api';
import MobileContext from 'context/MobileContext';
import LanguageContext from 'context/LanguageContext';
import TabletContext from 'context/TabletContext';
import AuthRoleContext from 'context/AuthRoleContext';
import AppStatusContext from 'context/AppStatusContext';
import PrivateRoute from 'utils/privateRoute';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/react-hooks';
import authUtils from 'utils/auth';
import { isOnline } from 'utils/socket';
import AwardedJobDetailsPage from 'containers/Jobs/AwardedJobDetailsContainer';
import WarrantyJobDetailsPage from 'containers/Jobs/WarrantyJobDetailsPageContainer';
import WarrantyDetailsPage from 'containers/WarrantyDetailsPage/WarrantyDetailsPageContainer';
import { roles, applicationStatus } from 'enums';
import { isAllowAction } from 'services/permissions';
import { GET_APP_CONFIG_CONTRACTOR } from 'graphql/queries/appConfigContractor';
import JobsCountContext from 'context/JobsCountContext';
import { assignEnvToWindow } from 'utils/assignEnvToWindow';

import {
  AccountActivationPage,
  AvailableJobDetailsPage,
  AwardedJobListPage,
  ContactUsPage,
  DashboardPage,
  FAQsPage,
  FinancialsPage,
  ForgotPasswordPage,
  JobListPage,
  LogoutPage,
  MembersPage,
  MyAccountPage,
  MyApplicationPage,
  OAuthPage,
  ResetPasswordPage,
  SignInPage,
  SignUpPage,
  WarrantyPage,
  WelcomeNoLeadPage,
} from './pages';
import './index.css';
import { GET_USER } from './graphql/queries/auth';
import ScrollToTop from './services/HOCs/scrollToTop';
import CompanyProfilePage from './pages/CompanyProfilePage';
import AppConfigContext from './context/AppConfigContext';
import { JOBS_COUNT } from './graphql/queries/jobsCount';
import ChangedEmailPage from './pages/ChangedEmailPage';
import ConfirmEmailPage from './pages/ConfirmEmailPage';

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  const [isTablet, setIsTablet] = useState(window.innerWidth <= 991);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [userRole, setUserRole] = useState(null);

  const lang =
    document.cookie.locale || localStorage.getItem('locale') || window.navigator.language;
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(lang);

  const [appStatus, setAppStatus] = useState(null);

  const history = useHistory();
  const client = useApolloClient();

  const { data: dataConfig } = useQuery(GET_APP_CONFIG_CONTRACTOR);
  const [getJobsCount, { data: jobsCount }] = useLazyQuery(JOBS_COUNT);

  const applicationStatusContextValue = { appStatus, setAppStatus };
  const languageContextValue = { language, setLanguage };

  const handleResize = () => {
    setIsTablet(window.innerWidth <= 991);
    setIsMobile(window.innerWidth <= 767);
  };

  const [getUser, { data }] = useLazyQuery(GET_USER, {
    onCompleted: () => {
      setAppStatus(data.getUser.account.application?.status);
      if (data?.getUser) {
        client.writeData({
          data: { user: data.getUser },
        });
        i18n.changeLanguage(data.getUser.language || 'en');
        setLanguage(data.getUser.language || 'en');

        setUserRole(data.getUser.contractorRole);
        isOnline(data?.getUser?.pgId);
      }
    },
    onError: () => {
      history.push('/logout');

      setLoading(false);
    },
  });

  assignEnvToWindow(data?.getUser);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    ReactGa.initialize('UA-32872144-10');
    ReactGa.pageview(window.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    const token = authUtils.getToken();

    const setUser = async () => {
      const user = authUtils.decodeToken(token);

      setUserRole(user?.contractorRole);
      await getUser();
    };

    if (token) {
      setIsAuth(true);
      setUser();
      getJobsCount();
    }

    setLoading(false);
  }, [getUser]);

  const appStatusCondition = appStatus !== applicationStatus.approved || !appStatus;
  // Temporarily disabled
  // useEffect(() => {
  //   const { android, ios } = Platforms;
  //
  //   if (dataConfig?.appConfigContractor && document.referrer) {
  //     const { urlGooglePlay, urlAppStore } = dataConfig?.appConfigContractor;
  //     if (ios) {
  //       window.location.replace(urlAppStore);
  //     }
  //     if (android) {
  //       window.location.replace(urlGooglePlay);
  //     }
  //   }
  // }, [dataConfig]);

  return (
    <AppStatusContext.Provider value={applicationStatusContextValue}>
      <AuthRoleContext.Provider value={userRole}>
        <AppConfigContext.Provider value={dataConfig?.appConfigContractor}>
          <JobsCountContext.Provider value={jobsCount}>
            <TabletContext.Provider value={isTablet}>
              <MobileContext.Provider value={isMobile}>
                <LanguageContext.Provider value={languageContextValue}>
                  <LoadScript
                    id="script-loader"
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    language="en"
                    region="us"
                  >
                    <ScrollToTop>
                      <Switch>
                        {loading ? (
                          <></>
                        ) : (
                          <>
                            <PrivateRoute
                              allow={!isAuth}
                              path="/sign-in"
                              exact
                              component={SignInPage}
                            />
                            <PrivateRoute
                              allow={!isAuth}
                              path="/sign-up"
                              exact
                              component={SignUpPage}
                            />
                            <PrivateRoute
                              allow={!isAuth}
                              path="/forgot-password"
                              exact
                              component={ForgotPasswordPage}
                            />
                            <PrivateRoute
                              allow={!isAuth}
                              path="/confirm"
                              exact
                              component={AccountActivationPage}
                            />
                            <PrivateRoute
                              allow={isAuth}
                              path="/my-account"
                              exact
                              component={MyAccountPage}
                            />
                            <PrivateRoute
                              allow={!isAuth}
                              path="/create-password"
                              exact
                              component={ResetPasswordPage}
                            />
                            <PrivateRoute
                              allow={!isAuth && !isMobile}
                              path="/"
                              exact
                              component={WelcomeNoLeadPage}
                              redirectTo={
                                isMobile && !isAuth
                                  ? '/sign-in'
                                  : appStatusCondition
                                  ? '/my-application'
                                  : '/dashboard'
                              }
                            />
                            <PrivateRoute
                              allow={
                                (isAuth && appStatus !== applicationStatus.approved) ||
                                !appStatus
                              }
                              path="/my-application"
                              exact
                              component={MyApplicationPage}
                              redirectTo={appStatus ? '/dashboard' : '/sign-in'}
                            />
                            <PrivateRoute
                              allow={isAuth && appStatus === applicationStatus.approved}
                              path="/dashboard"
                              exact
                              component={DashboardPage}
                              redirectTo={appStatus ? '/my-application' : '/sign-in'}
                            />

                            <PrivateRoute
                              allow={
                                isAuth &&
                                isAllowAction(
                                  [roles.admin, roles.finance, roles.superintendent],
                                  userRole
                                )
                              }
                              path="/jobs"
                              redirectTo={isAuth ? '/jobs/awarded' : '/sign-in'}
                              exact
                              component={JobListPage}
                            />
                            <PrivateRoute
                              allow={isAuth && isAllowAction([roles.crew], userRole)}
                              path="/jobs/awarded"
                              redirectTo={isAuth ? '/jobs' : '/sign-in'}
                              exact
                              component={AwardedJobListPage}
                            />
                            <PrivateRoute
                              allow={
                                isAuth &&
                                isAllowAction(
                                  [roles.admin, roles.finance, roles.superintendent],
                                  userRole
                                )
                              }
                              path="/jobs/available/:id"
                              redirectTo="/jobs/awarded"
                              exact
                              component={AvailableJobDetailsPage}
                            />
                            <PrivateRoute
                              allow={isAuth}
                              path="/jobs/awarded/:id"
                              exact
                              component={AwardedJobDetailsPage}
                            />
                            <PrivateRoute
                              allow={
                                isAuth &&
                                isAllowAction(
                                  [roles.admin, roles.finance, roles.superintendent],
                                  userRole
                                )
                              }
                              path="/jobs/warranty/:id"
                              exact
                              component={WarrantyJobDetailsPage}
                            />
                            <PrivateRoute
                              allow={isAuth}
                              path="/financials"
                              exact
                              component={FinancialsPage}
                            />
                            <PrivateRoute
                              allow={isAuth}
                              path="/members"
                              exact
                              component={MembersPage}
                            />
                            <PrivateRoute
                              allow={isAuth}
                              path="/warranty"
                              exact
                              component={WarrantyPage}
                            />
                            <PrivateRoute
                              allow={isAuth}
                              path="/warranty/:id"
                              exact
                              component={WarrantyDetailsPage}
                            />
                            <PrivateRoute
                              allow={isAuth}
                              path="/logout"
                              exact
                              component={LogoutPage}
                            />
                            <PrivateRoute
                              path="/privacy-policy"
                              allow
                              exact
                              component={() => {
                                window.location.href =
                                  'https://www.roofclaim.com/privacy-policy/';
                                return null;
                              }}
                            />
                            <PrivateRoute
                              path="/terms-and-conditions"
                              allow
                              exact
                              component={() => {
                                window.location.href =
                                  'https://www.roofclaim.com/terms-and-conditions/';
                                return null;
                              }}
                            />

                            <PrivateRoute
                              allow={isAuth}
                              path="/contact-us"
                              exact
                              component={ContactUsPage}
                            />

                            <PrivateRoute
                              allow={isAuth}
                              path="/faq"
                              exact
                              component={FAQsPage}
                            />
                            <PrivateRoute
                              allow={isAuth}
                              path="/company-profile"
                              exact
                              component={CompanyProfilePage}
                            />
                            <PrivateRoute
                              allow={!isAuth}
                              path="/changeEmail"
                              exact
                              component={ChangedEmailPage}
                            />
                            <PrivateRoute
                              allow={!isAuth}
                              path="/confirmChangingEmailFromNewEmail"
                              exact
                              component={ConfirmEmailPage}
                            />
                            <PrivateRoute
                              allow={!isAuth || isAuth}
                              path="/confirmChangingEmailFromOldEmail"
                              exact
                              component={ConfirmEmailPage}
                            />
                            <PrivateRoute
                              allow={!isAuth}
                              path="/oauth/success"
                              exact
                              component={OAuthPage}
                            />
                          </>
                        )}
                      </Switch>
                    </ScrollToTop>
                  </LoadScript>
                </LanguageContext.Provider>
              </MobileContext.Provider>
            </TabletContext.Provider>
          </JobsCountContext.Provider>
        </AppConfigContext.Provider>
      </AuthRoleContext.Provider>
    </AppStatusContext.Provider>
  );
}

export default withRouter(App);
