import gql from 'graphql-tag';

export const Auth = {
  fragments: {
    user: gql`
      fragment user on User {
        pgId
        sfId
        accountPgId
        accountSfId
        firstName
        lastName
        name
        contractorStatus
        contractorRole
        contactType
        phone
        mobilePhone
        email
        alternateEmail
        isFirstLogin
        token
        language
        account {
          phone
          email
          pgId
          parentId
          name
          companyName
          companyNameDBA
          companyType
          billingCity
          billingLatitude
          billingLongitude
          billingPostalCode
          billingState
          billingCountry
          billingGeoCodeAccuracy
          billingStreet
          type
          loanNumber
          address
          gateCode
          website
          facebook
          linkedin
          twitter
          createdAt

          contacts {
            pgId
            sfId
            firstName
            lastName
            phone
            mobilePhone
            email
            alternateEmail
            contactPreferenceEmail
            contactPreferenceSms
            contactPreferencePhone
            language
          }
          application {
            contractorSfId
            contractorPgId
            accountPgId
            accountSfId
            sfId
            pgId
            status
          }
        }
      }
    `,
  },
};
