import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import DesktopTabs from 'components/shared/Tabs/Desktop';
import MobileTabs from 'components/shared/Tabs/Mobile';
import MobileContext from 'context/MobileContext';
import AuthRoleContext from 'context/AuthRoleContext';
import { parseQuery, stringifyQuery } from 'utils/parseQueryString';
import AwardedJobsTab from '../JobsListPage/AwardedJobsTab';
import styles from '../pageStyles.module.css';

const activeViews = {
  table: '',
  map: 'map',
};

const JobListPage = ({ location, history, ...props }) => {
  const query = parseQuery(location.search);
  const [currentTabView, setCurrentTabView] = useState(
    activeViews[query?.view] || activeViews.table
  );
  const isMobile = useContext(MobileContext);
  const userCurrentRole = useContext(AuthRoleContext);

  const handleChangePathname = ({ tabIndex, tabView }) => {
    history.push(
      [
        history.location.pathname,
        stringifyQuery({
          view: tabView,
        }),
      ].join('')
    );
  };

  useEffect(() => {
    setCurrentTabView(query.view || activeViews.table);
  }, [query.view]);

  const tabProps = {
    ...props,
    history,
    currentView: currentTabView,
    currentTabIndex: query.page - 1,
    userCurrentRole: userCurrentRole || '',
    handleChangeView: (value) => {
      handleChangePathname({ tabView: activeViews[value] });
    },
  };

  const tabsList = [
    {
      title: 'Awarded jobs',
      children: <AwardedJobsTab {...tabProps} />,
    },
  ];

  return (
    <LayoutWithSidebar>
      <div className={styles.container}>
        {isMobile ? (
          <MobileTabs tabList={tabsList} currentTabIndex={0} handleClick={() => {}} />
        ) : (
          <DesktopTabs tabList={tabsList} currentTabIndex={0} handleClick={() => {}} />
        )}
      </div>
    </LayoutWithSidebar>
  );
};

JobListPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default JobListPage;
