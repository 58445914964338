import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SectionHeader from 'components/shared/SectionHeader';
import Field from 'components/shared/Field';
import {
  FormColumn as Col,
  FormColumn as Column,
  FormContent,
  FormRow as Row,
} from 'components/shared/Form';
import Checkbox from 'components/shared/CheckBox';
import Text from 'components/shared/Text';
import {
  useOnsiteManagementStructure,
  useOnsiteManagementDays,
} from 'enums/application/onsiteManagement';
import { isDisabled } from 'utils/application/checkIsDisabled';
import styles from './styles.module.css';

const CoverageCrewSection = ({
  values: { myApplication },
  errors,
  touched,
  handleChange,
  setFieldValue,
  appStatus,
}) => {
  const { t } = useTranslation();
  const onsiteManagementDays = useOnsiteManagementDays();
  const onsiteManagementStructure = useOnsiteManagementStructure();
  return (
    <div className={styles.section}>
      <SectionHeader
        text={t('myApplication.secondTab.coverage.title', 'Coverage & Crew ')}
      />
      <div className={styles.formWrapper}>
        <form>
          <FormContent>
            <Row>
              <Column>
                <Field
                  label={t('myApplication.secondTab.coverage.numberLabel', '# of crews')}
                  type="number"
                  inputMode="text"
                  name="numberCrews"
                  value={myApplication.numberCrews}
                  onChange={handleChange}
                  isError={
                    touched.myApplication?.numberCrews &&
                    Boolean(errors.myApplication?.numberCrews)
                  }
                />
              </Column>
              <Column>
                <Field
                  label={t(
                    'myApplication.secondTab.coverage.laborerLabel',
                    '# of Laborers Per Crew'
                  )}
                  type="number"
                  name="numberLaborers"
                  value={myApplication.numberLaborers}
                  onChange={handleChange}
                  isError={
                    touched.myApplication?.numberLaborers &&
                    Boolean(errors.myApplication?.numberLaborers)
                  }
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Field
                  label={t(
                    'myApplication.secondTab.coverage.averageLabel',
                    'Crew average # sqs per day'
                  )}
                  type="number"
                  inputMode="text"
                  name="crewAveragePerDay"
                  value={myApplication.crewAveragePerDay}
                  onChange={handleChange}
                  isError={
                    touched.myApplication?.crewAveragePerDay &&
                    Boolean(errors.myApplication?.crewAveragePerDay)
                  }
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Field
                  label={t(
                    'myApplication.secondTab.coverage.structureLabel',
                    'Describe onsite management structure'
                  )}
                  type="select"
                  name="onsiteManagementStructure"
                  options={onsiteManagementStructure}
                  value={myApplication.onsiteManagementStructure}
                  onChange={handleChange}
                  isError={
                    touched.myApplication?.onsiteManagementStructure &&
                    Boolean(errors.myApplication?.onsiteManagementStructure)
                  }
                  isDisabled={isDisabled(appStatus)}
                />
              </Column>
              {myApplication.onsiteManagementStructure ===
              onsiteManagementStructure[1].value ? (
                <Column>
                  <Field
                    withoutLabel
                    type="select"
                    name={t(
                      'myApplication.secondTab.coverage.onsiteLabel',
                      'onsiteManagementDays'
                    )}
                    options={onsiteManagementDays}
                    onChange={handleChange}
                    value={myApplication.onsiteManagementDays}
                    isError={
                      touched.myApplication?.onsiteManagementDays &&
                      Boolean(errors.myApplication?.onsiteManagementDays)
                    }
                    isDisabled={isDisabled(appStatus)}
                  />
                </Column>
              ) : null}
            </Row>
            <Row>
              <Col>
                <div className={styles.checkboxQuestionWrapper}>
                  <div className={styles.questionWrapper}>
                    <Text color="#000" size="s14" weight="wm">
                      {t(
                        'myApplication.secondTab.coverage.dump.title',
                        'Do you have a dump trailer?'
                      )}
                    </Text>
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      isContractorsStyle
                      value="true"
                      text={t('myApplication.secondTab.coverage.yes', 'Yes')}
                      name="hasDumpTrailer"
                      checked={myApplication.hasDumpTrailer === true}
                      isError={touched?.hasDumpTrailer && Boolean(errors?.hasDumpTrailer)}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('myApplication.hasDumpTrailer', true);
                      }}
                    />
                    <Checkbox
                      isContractorsStyle
                      value="false"
                      text={t('myApplication.secondTab.coverage.no', 'No')}
                      name="hasDumpTrailer"
                      checked={myApplication.hasDumpTrailer === false}
                      isError={touched?.hasDumpTrailer && Boolean(errors?.hasDumpTrailer)}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('myApplication.hasDumpTrailer', false);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.checkboxQuestionWrapper}>
                  <div className={styles.questionWrapper}>
                    <Text color="#000" size="s14" weight="wm">
                      {t(
                        'myApplication.secondTab.coverage.permit.title',
                        'Are you staffed to pull permits?'
                      )}
                    </Text>
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      isContractorsStyle
                      value="true"
                      text={t('myApplication.secondTab.coverage.yes', 'Yes')}
                      name="staffedPullPermits"
                      checked={myApplication.staffedPullPermits}
                      isError={
                        touched?.staffedPullPermits && Boolean(errors?.staffedPullPermits)
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('myApplication.staffedPullPermits', true);
                      }}
                    />
                    <Checkbox
                      isContractorsStyle
                      value="false"
                      text={t('myApplication.secondTab.coverage.no', 'No')}
                      name="staffedPullPermits"
                      checked={myApplication.staffedPullPermits === false}
                      isError={
                        touched?.staffedPullPermits && Boolean(errors?.staffedPullPermits)
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('myApplication.staffedPullPermits', false);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </FormContent>
        </form>
      </div>
    </div>
  );
};

CoverageCrewSection.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  appStatus: PropTypes.string.isRequired,
};

export default CoverageCrewSection;
