import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStatusLocal } from 'localization';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import { formatDateTime } from 'utils/dateFormatters';
import ScheduleBlock from './ScheduleBlock';
import styles from './style.module.css';

const MySchedulePart = ({ applicationData }) => {
  const { t } = useTranslation();
  const appStatusLocal = useAppStatusLocal();
  const scheduleDate = [
    {
      title: t(
        'myApplication.fourthTab.p_1.title',
        'Your application has been submitted'
      ),
      text: applicationData?.applicationSubmittedAt
        ? formatDateTime(applicationData?.applicationSubmittedAt)
        : t('myApplication.fourthTab.p_1.text', 'To be scheduled'),
      isScheduled: applicationData?.applicationSubmittedAt,
    },
    {
      title: t(
        'myApplication.fourthTab.p_2.title',
        'Phone Interview with roofclaim.com scheduled on:'
      ),
      text: applicationData?.phoneInterviewAt
        ? formatDateTime(applicationData?.phoneInterviewAt)
        : t('myApplication.fourthTab.p_2.text', 'To be scheduled'),

      isScheduled: applicationData?.phoneInterviewAt,
    },
    {
      title: t(
        'myApplication.fourthTab.p_3.title',
        'Your on site inspection is scheduled on:'
      ),
      text: applicationData?.siteInspectionDate
        ? formatDateTime(applicationData?.siteInspectionDate)
        : t('myApplication.fourthTab.p_3.text', 'To be scheduled'),

      isScheduled: applicationData?.siteInspectionDate,
    },
    {
      title: t('myApplication.fourthTab.p_4.title', 'Application Status:'),
      text: appStatusLocal[applicationData?.status],
      status: applicationData?.status,
      isColorPoint: true,
    },
  ];

  return (
    <div className={styles.tabWrapper}>
      <WrapperBlock title={t('myApplication.fourthTab.title', 'Scheduled Activities')}>
        <div className={styles.scheduleBlockWrapper}>
          {scheduleDate.map(
            ({ title, text, isColorPoint, isScheduled, status }, index) => (
              <ScheduleBlock
                title={title}
                text={text}
                isScheduled={isScheduled}
                isColorPoint={isColorPoint}
                key={title + index}
                status={status}
              />
            )
          )}

          {applicationData?.messageApplicant && (
            <ScheduleBlock
              title={t('myApplication.fourthTab.p_5.title', 'Application Message:')}
              text={applicationData?.messageApplicant}
            />
          )}
        </div>
      </WrapperBlock>
    </div>
  );
};
MySchedulePart.propTypes = {
  applicationData: PropTypes.object,
};
MySchedulePart.defaultProps = {
  applicationData: {},
};
export default MySchedulePart;
