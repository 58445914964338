import React from 'react';
import { useTranslation } from 'react-i18next';
import MessagesTab from 'components/common/MessagesTab';
import InformationTab from './InformationTab';
import ActivityTab from './ActivityTab';

export default ({
  currentAwardedJob,
  isDisableActions,
  isDisableActivityTab,
  ...props
}) => {
  const { t } = useTranslation();
  return [
    {
      title: t('jobs.awarded.details.header.generalTab', 'General'),
      children: (
        <fieldset disabled={isDisableActions}>
          <InformationTab
            {...props}
            {...currentAwardedJob}
            isDisableActions={isDisableActions}
          />
        </fieldset>
      ),
    },
    {
      title: t('jobs.awarded.details.header.activityTab', 'Activity'),
      isDisabled: isDisableActivityTab,
      children: (
        <fieldset disabled={isDisableActions || isDisableActivityTab}>
          <ActivityTab {...props} {...currentAwardedJob} />
        </fieldset>
      ),
    },
    {
      title: t('jobs.awarded.details.header.messagesTab', 'Messages'),
      children: <MessagesTab parentId={currentAwardedJob.sfId} type="workOrder" />,
    },
  ];
};
