import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Checkbox from 'components/shared/CheckBox';
import UploadFileArea from 'components/shared/UploadFile/container';
import WrapperBlock from 'components/shared/WrapperBlock';
import { FormColumn } from 'components/shared/Form';
import styles from '../style.module.css';

const REadViewMaterialSection = ({
  files,
  isDeliveredMaterials: isConfirmed,
  deliveredMaterialsComment: comment,
}) => {
  const { t } = useTranslation();
  return (
    <WrapperBlock
      title={t(
        'jobs.awarded.details.activity.materials.readView.title',
        'Materials & Other'
      )}
      classNameMainWrapper={styles.wrapper}
    >
      <div className={styles.sectionContent}>
        <FormColumn>
          <Checkbox
            isContractorsStyle
            text={t(
              'jobs.awarded.details.activity.materials.readView.agree',
              'I agree that all materials readView.have been delivered and the job description matches the project details.'
            )}
            textSize="large"
            checked={isConfirmed}
            onChange={() => {}}
          />
        </FormColumn>
        {comment && (
          <FormColumn>
            <Text color="#000" weight="wb">
              {t('jobs.awarded.details.activity.materials.readView.note', 'Note:')}{' '}
            </Text>
            <Text color="#837F7D">{comment}</Text>
          </FormColumn>
        )}

        <div>
          <div className={styles.label}>
            <Text color="#837F7D" tag="p">
              {t(
                'jobs.awarded.details.activity.materials.readView.uploaded',
                'Uploaded pics'
              )}
            </Text>
          </div>
          <UploadFileArea
            buttonText={t('uploadButton', 'Upload file(s)')}
            accept=".jpeg, .png, .jpg"
            name="files"
            uploadFiles={files}
            handleChange={() => {}}
            maxCountDocument={5}
          />
        </div>
      </div>
    </WrapperBlock>
  );
};

REadViewMaterialSection.propTypes = {
  files: PropTypes.array,
  isDeliveredMaterials: PropTypes.bool,
  deliveredMaterialsComment: PropTypes.string,
};

REadViewMaterialSection.defaultProps = {
  files: [],
  isDeliveredMaterials: false,
  deliveredMaterialsComment: '',
};

export default REadViewMaterialSection;
