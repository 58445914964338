import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { FormColumn } from 'components/shared/Form';
import Modal from 'components/shared/modals/FormModal';
import FromView from './formView';

const RescheduleJobModal = ({ isShow, ...props }) => {
  const { t } = useTranslation();
  return (
    <Modal isShow={isShow} title={t('rescheduleModal.title', 'Reschedule Job')}>
      <FormColumn>
        <Text color="#000">
          {t(
            'rescheduleModal.text',
            'Please select in the list below, the reason why you need to reschedule this job:'
          )}
        </Text>
      </FormColumn>
      <FromView {...props} />
    </Modal>
  );
};

RescheduleJobModal.propTypes = {
  isShow: PropTypes.bool,
};

RescheduleJobModal.defaultProps = {
  isShow: false,
};

export default RescheduleJobModal;
