import { string, object } from 'yup';
import { emailRegex } from '../emailRegex';

export const addMemberSchema = object().shape({
  firstName: string().required().trim(),
  lastName: string().required().trim(),
  email: string().required().matches(emailRegex).trim(),
  phone: string()
    .required()
    .matches(/^\d{10,10}$/, {
      message: 'Please enter valid phone number',
      excludeEmptyString: true,
    }),
  role: string().required().trim(),
});

export const editMemberSchema = object().shape({
  firstName: string().required().trim(),
  lastName: string().required().trim(),
  email: string().required().matches(emailRegex).trim(),
  phone: string()
    .required()
    .matches(/^\d{10,10}$/, {
      message: 'Please enter valid phone number',
      excludeEmptyString: true,
    }),
  role: string().required().trim(),
  status: string().required().trim(),
});
