import React from 'react';
import PropTypes from 'prop-types';
import bgImage from 'assets/backgrounds/welcome_page_banner_bg.png';
import PageWrapper from 'components/WelcomePage/PageWrapper';
import NoLeadGreetingBlock from 'components/WelcomePage/GreetingBlock/NoLeadGreetingBlock';
import NoLeadPresentationBlock from 'components/WelcomePage/PresentationBlock/NoLeadPresentationBlock';
import BannerWithButton from 'components/BannerWithButton';

const WelcomeNoLead = ({ handleSubmit, handleLoginSubmit }) => {
  return (
    <PageWrapper>
      <NoLeadGreetingBlock
        handleSubmit={handleSubmit}
        handleLoginSubmit={handleLoginSubmit}
      />
      <NoLeadPresentationBlock />
      <BannerWithButton handleSubmit={handleSubmit} bgImage={bgImage} />
    </PageWrapper>
  );
};

WelcomeNoLead.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleLoginSubmit: PropTypes.func.isRequired,
};

WelcomeNoLead.defaultProps = {};
export default WelcomeNoLead;
