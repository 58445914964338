import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from '../../shared/Text';
import { useWarningLocalization } from './warningLocalization';
import styles from './style.module.css';

const PasswordStrength = ({ strength }) => {
  const { t } = useTranslation();
  const errorString = useWarningLocalization(strength.feedback.warning);

  const strengthTypes = {
    weak: {
      text: t('createPassword.strength.weak', 'Weak!'),
      color: '#f44336',
      progressValue: 1,
    },
    normal: {
      text: t('createPassword.strength.normal', 'Normal'),
      color: '#f3c306',
      progressValue: 2,
    },
    strong: {
      text: t('createPassword.strength.strong', 'Strong!'),
      color: '#6C9961',
      progressValue: 3,
    },
  };

  const [strengthType, setData] = useState(strengthTypes.weak);

  useEffect(() => {
    switch (strength.score) {
      case 0:
        setData(strengthTypes.weak);
        break;
      case 1:
        setData(strengthTypes.weak);
        break;
      case 2:
        setData(strengthTypes.normal);
        break;
      case 3:
        setData(strengthTypes.normal);
        break;
      case 4:
        setData(strengthTypes.strong);
        break;
      default:
        setData(strengthTypes.weak);
        break;
    }
  }, [strength]);

  const progressItems = [];
  for (let i = 0; i < strengthType.progressValue; i++) {
    progressItems.push(
      <div
        key={i}
        className={styles.progress}
        style={{ backgroundColor: strengthType.color }}
      />
    );
  }

  return (
    <>
      <div className={styles.wrapper}>
        <label className={styles.label}>
          {t('createPassword.strength.strengthLabel', 'Password Strength:')}{' '}
          <Text size="s13" lineHeight="l14" weight="wb" color={strengthType.color}>
            {strengthType.text}
          </Text>
        </label>
        <div className={styles.progressWrapper}>{progressItems}</div>
      </div>
      {strength?.feedback?.warning ? (
        <Text size="s13" lineHeight="l14" weight="ws" color="837f7d">
          {errorString}
        </Text>
      ) : null}
    </>
  );
};

PasswordStrength.propTypes = {
  strength: PropTypes.object.isRequired,
};

export default PasswordStrength;
