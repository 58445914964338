import { useTranslation } from 'react-i18next';

export const passwordWarning = {
  aaa: 'Las repeticiones como "aaa" son fáciles de adivinar',
  common: 'Esta es una contraseña muy común',
  top: 'Esta es una contraseña común de las 100 principales',
  similar: 'Esto es similar a una contraseña de uso común',
  sequences: 'Secuencias como abc o 6543 son fáciles de adivinar',
  straight: 'Las filas rectas de llaves son fáciles de adivinar',
  short: 'Los patrones de teclado cortos son fáciles de adivinar',
  dates: 'Las fechas suelen ser fáciles de adivinar',
  years: 'Los últimos años son fáciles de adivinar',
};

export const monthLocal = [
  'Ener',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Agos',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

export const useNotifTitlesLocal = () => {
  const { t } = useTranslation();
  return {
    General: t('myAccount.settings.general', 'General'),
    'Warranty Case': t('myAccount.settings.warranty', 'Warranty Case'),
    "Today's Jobs": t('myAccount.settings.today', "Today's Jobs"),
  };
};

export const useNotifTextLocal = () => {
  const { t } = useTranslation();
  return {
    'New Job become available': t(
      'myAccount.settings.text.p_1',
      'New Job become available'
    ),
    'Claimed Job status change': t(
      'myAccount.settings.text.p_2',
      'Claimed Job status change'
    ),

    'Awarded Jobs status change (in-progress, completed, rollover, reschedule-requested)': t(
      'myAccount.settings.text.p_3',
      'Awarded Jobs status change (in-progress, completed, rollover, reschedule-requested)'
    ),

    'Job is assigned to crew': t(
      'myAccount.settings.text.p_4',
      'Job is assigned to crew'
    ),
    'Job is assigned to crew (for admin)': t(
      'myAccount.settings.text.p_5',
      'Job is assigned to crew (for admin)'
    ),

    'Warranty Case status: open': t(
      'myAccount.settings.text.p_6',
      'Warranty Case status: open'
    ),
    'Warranty Case status change': t(
      'myAccount.settings.text.p_7',
      'Warranty Case status change'
    ),

    'Check-in for job required': t(
      'myAccount.settings.text.p_8',
      'Check-in for job required'
    ),
    'Media and photo upload: materials': t(
      'myAccount.settings.text.p_9',
      'Media and photo upload: materials'
    ),
    'Need to update job status': t(
      'myAccount.settings.text.p_10',
      'Need to update job status'
    ),
  };
};

export const useAppConfigLocal = () => {
  const { t } = useTranslation();
  return {
    'Yes, sure. I agree with SMS.': t(
      'appConfig.agreeSMS',
      'Yes, sure. I agree with the SMS.'
    ),
    'Please make sure you provide the documents required for the State or Local Jurisdiction from your defined work areas. A screenshot of the issuer’s website validation will suffice.': t(
      'appConfig.permitText',
      'Please make sure you provide the documents required for the State or Local Jurisdiction from your defined work areas. A screenshot of the issuer’s website validation will suffice.'
    ),
    'Inspection Required?': t(
        'appConfig.inspectText',
        'Inspection Required?'
    )

  };
};

export const useAppStatusLocal = () => {
  const { t } = useTranslation();
  return {
    Draft: t('appStatus.draft', 'Draft'),
    'Pending - RoofClaim Review': t('appStatus.pendingRR', 'Pending - RoofClaim Review'),
    'Pending - Contractor to Update': t(
      'appStatus.pendingCU',
      'Pending - Contractor to Update'
    ),
    Approved: t('appStatus.approved', 'Approved'),
    Denied: t('appStatus.denied', 'Denied'),
  };
};

export const useRolesLocal = () => {
  const { t } = useTranslation();
  return {
    Admin: t('userRole.admin', 'Admin'),
    Superintendent: t('userRole.superintendent', 'Superintendent'),
    Finance: t('userRole.finance', 'Finance'),
    Crew: t('userRole.crew', 'Crew'),
  };
};

export const useRolesOptionsLocal = () => {
  const { t } = useTranslation();
  return [
    {
      value: 'Admin',
      label: t('userRole.admin', 'Admin'),
    },
    {
      value: 'Superintendent',
      label: t('userRole.superintendent', 'Superintendent'),
    },
    {
      value: 'Finance',
      label: t('userRole.finance', 'Finance'),
    },
    {
      value: 'Crew',
      label: t('userRole.crew', 'Crew'),
    },
  ];
};

export const useOtherServicesLocal = () => {
  const { t } = useTranslation();
  return [
    { id: 1, label: t('myApplication.secondTab.other.p_1', 'Contratación general') },
    { id: 2, label: t('myApplication.secondTab.other.p_2', 'Revestimiento') },
    { id: 3, label: t('myApplication.secondTab.other.p_3', 'Canalones') },
    { id: 4, label: t('myApplication.secondTab.other.p_4', 'Paneles solares') },
    { id: 5, label: t('myApplication.secondTab.other.p_5', 'Pantallas') },
    { id: 6, label: t('myApplication.secondTab.other.p_6', 'Insulation') },
    { id: 7, label: t('myApplication.secondTab.other.p_7', 'HVAC') },
    { id: 8, label: t('myApplication.secondTab.other.p_8', 'Ventanas') },
    { id: 9, label: t('myApplication.secondTab.other.p_9', 'Drywall') },
    { id: 10, label: t('myApplication.secondTab.other.p_10', 'Esgrima') },
  ];
};

export const useJobStatusLocal = () => {
  const { t } = useTranslation();
  return {
    New: t('jobStatus.new', 'New'),
    Requested: t('jobStatus.requested', 'Requested'),
    'In Progress': t('jobStatus.in_progress', 'In Progress'),
    Completed: t('jobStatus.completed', 'Completed'),
    Rollover: t('jobStatus.rollover', 'Rollover'),
    Closed: t('jobStatus.closed', 'Closed'),
    Canceled: t('jobStatus.canceled', 'Canceled'),
    Scheduled: t('jobStatus.scheduled', 'Scheduled'),
    'Reschedule Requested': t('jobStatus.reschedule_requested', 'Reschedule Requested'),
    Warranty: t('jobStatus.warranty', 'Warranty'),
  };
};

export const useRoofTypeLocal = () => {
  const { t } = useTranslation();
  return {
    Architectural: t('roofType.architectural', 'Architectural'),
    Other: t('roofType.other', 'Other'),
    Tile: t('roofType.tile', 'Tile'),
    Metal: t('roofType.metal', 'Metal'),
    '3-Tab': t('roofType.3_tab', '3-Tab'),
  };
};

export const useLoseTypeLocal = () => {
  const { t } = useTranslation();
  return {
    Hail: t('loseType.hail', 'Hail'),
    Wind: t('loseType.wind', 'Wind'),
    Hurricane: t('loseType.hurricane', 'Hurricane'),
    'No Claim': t('loseType.no_claim', 'No Claim'),
  };
};

export const useCaseStatusLocal = () => {
  const { t } = useTranslation();
  return {
    New: t('caseStatus.new', 'New'),
    'Completed / Closed': t('caseStatus.completed', 'Completed / Closed'),
    'In Progress': t('caseStatus.in_progress', 'In Progress'),
  };
};

export const useCaseSubjectLocal = () => {
  const { t } = useTranslation();
  return {
    'Warranty claim (app)': t('caseSubject.warrantyClaim', 'Warranty claim (app)'),
  };
};

export const useCaseTypeLocal = () => {
  const { t } = useTranslation();
  return {
    '3rd Party': t('caseType.3rd_party', '3rd Party'),
    CSA: t('caseType.csa', 'CSA'),
    'Failed Final - Paperwork': t('caseType.failed_final', 'Failed Final - Paperwork'),

    'Final Roof Inspection Failed': t(
      'caseType.final_roof',
      'Final Roof Inspection Failed'
    ),
    'Homeowner Responsibility': t('caseType.homeowner', 'Homeowner Responsibility'),
    'Mid Roof Inspection Failed': t('caseType.mid', 'Mid Roof Inspection Failed'),
    'Non-Roof Standard': t('caseType.non_roof', 'Non-Roof Standard'),
    'Pre-Existing Condition': t('caseType.pre_existing', 'Pre-Existing Condition'),
    'Roof Standard': t('caseType.roof', 'Roof Standard'),
    'Tarp Request': t('caseType.tarp', 'Tarp Request'),
  };
};

export const usePaymentStatusLocal = () => {
  const { t } = useTranslation();
  return {
    Paid: t('paymentStatus.new', 'Paid'),
    'Not paid': t('paymentStatus.completed', 'Not paid'),
    'N/A': t('paymentStatus.in_progress', 'N/A'),
  };
};

export const useUserStatusLocal = () => {
  const { t } = useTranslation();
  return {
    Active: t('userStatus.active', 'Active'),
    Disabled: t('userStatus.disabled', 'Disabled'),
  };
};
