import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const PageWrapper = ({children}) => {

    return (
        <div className={styles.container}>
            {children}
        </div>
    );
}

PageWrapper.propTypes = {
    children: PropTypes.node,
};

PageWrapper.defaultProps = {
    children: null,
};

export default PageWrapper;