import React, { useState } from 'react';
import { Circle, GoogleMap as GoogleMapReact, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import locationPinIcon from './locationPin.svg';
import selectedLocationPinIcon from './locationPinBig.svg';
import { circleOptions, defaultCircleColors } from './mapOptions';
import JobsInfo from './JobsInfo';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const GoogleMap = ({
  locations = [],
  zoomLevel = 12,
  zones = [],
  centerLocation,
  isShowMarker,
  isShowCircle,
}) => {
  const [openInfoBoxId, setOpenInfoBoxId] = useState(null);
  console.log("test")
  return (
    <GoogleMapReact
      center={{
        lat: centerLocation.lat,
        lng: centerLocation.lng,
      }}
      zoom={zoomLevel}
      mapContainerStyle={containerStyle}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
      }}
    >
      {isShowCircle &&
        zones.map((zone, index) => {
          const options = zone?.options || defaultCircleColors;
          return (
            <Circle
              center={zone?.center}
              radius={zone?.radius}
              key={index}
              options={{
                ...circleOptions,
                ...options,
              }}
            />
          );
        })}
      {isShowMarker &&
        locations.map((location, index) => (
          <Marker
            position={{ lat: location.lat, lng: location.lng }}
            title={location.address}
            icon={location.isSelected ? selectedLocationPinIcon : locationPinIcon}
            opacity={location.isSelected ? 1 : 0.8}
            key={index}
          >
            {openInfoBoxId === index && (
              <JobsInfo
                position={{ lat: location.lat, lng: location.lng }}
                location={location}
              />
            )}
          </Marker>
        ))}
    </GoogleMapReact>
  );
};

GoogleMap.propTypes = {
  isShowCircle: PropTypes.bool,
  isShowMarker: PropTypes.bool,
  locations: PropTypes.array,
  zoomLevel: PropTypes.number,
  centerLocation: PropTypes.object,
  zones: PropTypes.array,
};

GoogleMap.defaultProps = {
  isShowMarker: true,
  isShowCircle: true,
  locations: [],
  centerLocation: { lat: 41.3851, lng: 2.1 },
  zoomLevel: 12,
  zones: [],
};

export default GoogleMap;
