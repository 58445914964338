import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const stylesColor = {
  primary: styles.primaryLink,
  secondary: styles.secondaryLink,
};

const stylesSize = {
  small: styles.smallLink,
  medium: styles.mediumLink,
  large: styles.largeLink,
  s13: styles.s13Link,
};

const Link = ({
  isUnderlined,
  children,
  bold,
  href,
  color = 'primary',
  size = 'small',
  ...props
}) => {
  const classNames = [styles.link, stylesColor[color], stylesSize[size]];
  if (bold) {
    classNames.push(styles.linkBold);
  }

  if (isUnderlined) {
    classNames.push(styles.linkUnderlined);
  }

  return (
    <a className={classNames.join(' ')} href={href} {...props}>
      {children}
    </a>
  );
};

Link.propTypes = {
  isUnderlined: PropTypes.bool,
  color: PropTypes.string,
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  bold: PropTypes.bool,
  size: PropTypes.string,
};

Link.defaultProps = {
  isUnderlined: false,
  color: 'primary',
  bold: false,
  size: 'small',
};

export default Link;
