import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ErrorMessage from 'components/shared/ErrorMessage';
import Text from 'components/shared/Text';

const WithError = ({ children, handleClose, timeout, message, isShow }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const _handleClose = () => setErrorMessage('');

  useEffect(() => {
    setErrorMessage(message || 'Sorry, something went wrong.');
  }, [message]);

  return (
    <>
      {isShow && errorMessage ?
        <ErrorMessage
          handleClose={handleClose ?? _handleClose}
          timeout={timeout ?? 5000}
        >
          <Text weight="wm" size="s16" color="#000000">{errorMessage}</Text>
        </ErrorMessage>
        : null}
      {children}
    </>
  );
};

export default WithError;

WithError.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func,
  isShow: PropTypes.bool.isRequired,
  message: PropTypes.string,
  timeout: PropTypes.number,
};

WithError.defaultProps = {
  children: null,
  handleClose: null,
  message: '',
  timeout: null,
};
