import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mapIcon, tableIcon, searchIcon } from 'assets/icons';
import Table from 'components/common/Table';
import WrapperIcons from 'components/shared/ToggleViewIcons';
import filterData from 'utils/dataSearch';
import PropTypes from 'prop-types';
import SearchInput from 'components/shared/Input';
import MapView from 'components/common/MapView';
import userRole from 'enums/roles';
import { useDetLang } from 'services/localization';
import Field from 'components/shared/Field';
import Text from 'components/shared/Text';
import styles from './style.module.css';

const items = [
  {
    name: 'table',
    icon: tableIcon,
  },
  {
    name: 'map',
    icon: mapIcon,
  },
];

const TableMapView = ({
  dataList,
  searchValues,
  tableColumns,
  currentView = 'table',
  handleChangeView,
  currentTabIndex,
  children,
  mapOptions,
  tableOptions,
  emptyPageText,
  searchPlaceholder = 'Search by name or address',
  membersList = [],
  userCurrentRole,
  onLoadMore,
}) => {
  const { t } = useTranslation();
  const localPhrase = useDetLang('Todas las miembros', 'All members');

  const [activeView, setActiveView] = useState(currentView || 'table');
  const [searchString, setSearchString] = useState('');
  const [filterValue, setFilterValue] = useState('All members');

  const membersOptions = membersList?.map(({ firstName, lastName }) => {
    return {
      label: `${firstName} ${lastName}`,
      value: `${firstName} ${lastName}`,
    };
  });

  useEffect(() => {
    setActiveView(currentView || 'table');
  }, [currentView]);

  const searchData = useMemo(() => {
    return searchValues
      ? filterData({
          data: dataList,
          searchString,
          searchValue: searchValues,
        })
      : dataList;
  }, [searchString, dataList, searchValues]);

  const data = useMemo(() => {
    return filterValue !== 'All members'
      ? searchData.filter(({ assignedContacts }) =>
          assignedContacts?.some(({ name }) => name === filterValue)
        )
      : searchData;
  }, [filterValue, searchData]);

  if (!dataList.length)
    return <div className={styles.tabContentWrapper}>{emptyPageText}</div>;

  return (
    <div className={styles.tabContentPagination}>
      <div className={[styles.block, styles.headerBlockSpace].join(' ')}>
        <div className={styles.searchWrapper}>
          <div className={styles.inputWrapper}>
            {searchValues && (
              <SearchInput
                rightIcon={searchIcon}
                placeholder={searchPlaceholder}
                name="searchValue"
                value={searchString}
                onChange={(event) => setSearchString(event.target.value)}
              />
            )}
          </div>

          {[userRole.admin, userRole.finance].includes(userCurrentRole) && (
            <div className={styles.filterWrapper}>
              <Field
                type="select"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                options={[
                  { label: localPhrase, value: 'All members' },
                  ...membersOptions,
                ]}
              />
            </div>
          )}
        </div>
        <div className={styles.viewWrapper}>
          <WrapperIcons
            items={items}
            activeIcon={activeView}
            handleClick={(value) => {
              handleChangeView(value);
              setActiveView(value);
            }}
          />
        </div>
      </div>

      {data.length > 0 ? (
        <div>
          {activeView === 'table' ? (
            <Table
              {...tableOptions}
              onLoadMore={onLoadMore}
              currentPageIndex={currentTabIndex}
              columns={tableColumns}
              data={data}
              isSortDefault
              sortIndex={1}
              pagination
            />
          ) : (
            <MapView
              {...mapOptions}
              foundIds={searchString ? data.map(({ sfId }) => sfId) : []}
            >
              {(props) => children({ data, ...props })}
            </MapView>
          )}
        </div>
      ) : (
        <div>
          <Text color="#000" size="s16">
            {t('emptySearch', ' There is no data that matches your search')}
          </Text>
        </div>
      )}
    </div>
  );
};

TableMapView.propTypes = {
  tableColumns: PropTypes.array.isRequired,
  dataList: PropTypes.array,
  searchValues: PropTypes.array,
  membersList: PropTypes.array,
  children: PropTypes.func,
  mapOptions: PropTypes.object,
  emptyPageText: PropTypes.string.isRequired,
  tableOptions: PropTypes.object,
  searchPlaceholder: PropTypes.string,
  currentView: PropTypes.string,
  userCurrentRole: PropTypes.string,
  handleChangeView: PropTypes.func,
  currentTabIndex: PropTypes.number,
  onLoadMore: PropTypes.func,
};

TableMapView.defaultProps = {
  dataList: [],
  membersList: [],
  searchValues: null,
  tableOptions: {},
  mapOptions: {},
  children: () => {},
  searchPlaceholder: 'Search by name or address',
  currentView: 'table',
  handleChangeView: () => {},
  currentTabIndex: 0,
  userCurrentRole: '',
  onLoadMore: () => {},
};

export default TableMapView;
