import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Checkbox from 'components/shared/CheckBox';
import styles from '../styles.module.css';

const SecondScreen = ({ confirmSecondScreen, setConfirmSecondScreen }) => {
  const { t } = useTranslation();

  return (
    <>
      <Text tag="p" color="#000000" weight="wb" size="s14" lineHeight="h20">
        {t(
          'inspectionModal.body.secondScreen.p_1',
          'The correct roofing material was used per slope requirements, starter shingles have proper drip edge alignments, shingles are tarred and properly fastened at rakes, sidewall shingles are properly attached, the course lines match evenly, shingles are properly attached per code/manufacturer, and shingles have proper exposure.'
        )}
      </Text>
      <p />
      <Text tag="p" color="#000000" size="s14" lineHeight="h20" weight="wb">
        {t(
          'inspectionModal.body.secondScreen.p_2',
          'Further, there is no evidence of under surface debris and no lumpy surfaces, there are no exposed fasteners, and there is minimal color variance in the shingles used.'
        )}
      </Text>

      <div className={styles.confirmContainer}>
        <Checkbox
          isContractorsStyle
          value={confirmSecondScreen}
          textSize="big"
          text={t(
            'inspectionModal.confirm',
            'I confirm the above statements are true and accurate.'
          )}
          onChange={() => setConfirmSecondScreen(!confirmSecondScreen)}
        />
      </div>
    </>
  );
};

SecondScreen.propTypes = {
  setConfirmSecondScreen: PropTypes.func.isRequired,
  confirmSecondScreen: PropTypes.bool.isRequired,
};

export default SecondScreen;
